// components
import { Document, Page, Text, Font, Image, View } from "@react-pdf/renderer"

// types and contexts
import { Outing } from "../interfaces/OutingInterface"
import { Pitch } from "../interfaces/PitchInterface"

// util functions
import { formatOutingName } from "../shared/Functions/FormatOutingName"
import { calcOutingStats } from "../shared/Functions/CalcOutingStats"
import { calcPitchStatsForOuting } from "../shared/Functions/CalcPitchStatsForOuting"

// static
import BearHead from "../static/img/BearHead.png"
import fontRegular from "../static/fonts/Poppins-Regular.ttf"
import fontBold from "../static/fonts/Poppins-Bold.ttf"

interface Props {
	outing: Outing
	veloChart: Buffer
	pitchLocations: { [key: string]: Buffer }
}

export const OutingReport = ({ outing, veloChart, pitchLocations }: Props) => {
	const pitchColors: { [key: string]: string } = {
		FB: "#E70B18", // red
		CB: "#2326FA", // blue
		SL: "#118A14", // green
		CH: "#C8C72A", // yellow
		CT: "#21C8C7", // baby blue
		"2S": "#FD8024", // orange
		SPL: "#653896", // purple
		OTH: "#000000", // black
	}

	Font.register({
		family: "Poppins",
		fonts: [
			{ src: fontRegular },
			{ src: fontBold, fontStyle: "normal", fontWeight: 700 },
		],
	})

	// will hold the outing basic info at the top of the PDF
	let outingInfo = {
		pitcherName: "",
		outingName: "",
	}

	// PITCHER NAME
	let pitcherName = ""
	if (outing.pitcher.firstname) {
		pitcherName = `${outing.pitcher.firstname} ${outing.pitcher.lastname}`
	} else {
		pitcherName = `${outing.pitcher.lastname}`
	}

	// OUTING NAME
	const outingName = formatOutingName(outing)

	// ITERATE THROUGH PITCHES
	let pitchTypesThrown: { [key: string]: any } = {}
	outing.at_bats.forEach((atBat) => {
		if (atBat.pitches) {
			atBat.pitches.forEach((pitch: Pitch) => {
				if (!Object.keys(pitchTypesThrown).includes(pitch.pitch_type)) {
					pitchTypesThrown[pitch.pitch_type] = {
						num: 1,
					}
				} else {
					pitchTypesThrown[pitch.pitch_type] = {
						num: pitchTypesThrown[pitch.pitch_type].num + 1,
					}
				}
			})
		}
	})

	// outing stats
	const outingStats: { [key: string]: any } = calcOutingStats(outing.at_bats)

	// pitch stats
	const pitchStats: { [key: string]: any } = calcPitchStatsForOuting(
		outing.at_bats
	)

	outingInfo = {
		pitcherName,
		outingName,
	}

	const pitchTypes = ["FB", "2S", "CB", "SL", "CH", "CT", "SPL", "OTH"]

	// to split up outing stats on two different lines
	const outingStatLabelsOne = [
		["BF", "battersFaced"],
		["H", "hits"],
		["K", "strikeouts"],
		["BB", "walks"],
		["HBP", "hitByPitches"],
	]
	const outingStatLabelsTwo = [
		["Pitches", "pitches"],
		["Strike%", "strikePct"],
		["FPS%", "firstPitchStrikePct"],
		["Hit-Spot%", "hitSpotPct"],
		["Velo", "velocity"],
	]

	return (
		<Document>
			<Page
				size="A4"
				style={{ fontFamily: "Poppins", backgroundColor: "#F5F5F5" }}>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						color: "#FFF",
						backgroundColor: "#2B2D32",
						padding: 12,
						borderBottomLeftRadius: 6,
						borderBottomRightRadius: 6,
					}}>
					<View style={{ flex: 1 }}>
						<Image
							style={{
								width: 74,
								height: 66,
							}}
							src={BearHead}
						/>
					</View>
					<View
						style={{
							flex: 1,
							textAlign: "right",
						}}>
						<Text
							style={{
								fontSize: 22,
								fontWeight: "bold",
							}}>
							{outingInfo.pitcherName.toUpperCase()}
						</Text>
						<Text style={{ fontSize: 10 }}>Outing Report</Text>
						<Text style={{ fontSize: 10 }}>{outingInfo.outingName}</Text>
					</View>
				</View>
				<View
					style={{
						backgroundColor: "#fff",
						marginVertical: 6,
						marginHorizontal: 12,
						padding: 12,
						borderRadius: 6,
					}}>
					<View
						style={{
							borderBottomStyle: "solid",
							borderBottomColor: "#BD2E1F",
							borderBottomWidth: 2,
							marginBottom: 6,
						}}>
						<Text
							style={{
								fontSize: 14,
							}}>
							OUTING SUMMARY
						</Text>
					</View>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-around",
						}}>
						{outingStatLabelsOne.map((outingStat) => (
							<View
								key={outingStat[0]}
								style={{
									textAlign: "center",
									flex: 1,
								}}>
								<Text style={{ fontSize: 12, opacity: 0.5 }}>
									{outingStat[0]}
								</Text>
								<Text style={{ fontSize: 24, fontWeight: "bold" }}>
									{outingStats[outingStat[1]]}
								</Text>
							</View>
						))}
					</View>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-around",
						}}>
						{outingStatLabelsTwo.map((outingStat) => (
							<View
								key={outingStat[0]}
								style={{
									textAlign: "center",
									flex: 1,
								}}>
								<Text style={{ fontSize: 12, opacity: 0.5 }}>
									{outingStat[0]}
								</Text>
								<Text style={{ fontSize: 24, fontWeight: "bold" }}>
									{outingStats[outingStat[1]]}
									{outingStat[1].includes("Pct") && "%"}
								</Text>
							</View>
						))}
					</View>
				</View>
				<View
					style={{
						backgroundColor: "#fff",
						marginVertical: 6,
						marginHorizontal: 12,
						padding: 12,
						borderRadius: 6,
					}}>
					<View
						style={{
							borderBottomStyle: "solid",
							borderBottomColor: "#BD2E1F",
							borderBottomWidth: 2,
							marginBottom: 6,
						}}>
						<Text
							style={{
								fontSize: 14,
							}}>
							PITCHES THROWN
						</Text>
					</View>
					<View
						style={{ flexDirection: "row", justifyContent: "space-around" }}>
						{pitchTypes.map((pitchType) => {
							if (Object.keys(pitchTypesThrown).includes(pitchType)) {
								return (
									<View
										key={pitchType}
										style={{ textAlign: "center", flex: 1 }}>
										<Text
											style={{
												width: 75,
												paddingVertical: 6,
												paddingHorizontal: 12,
												borderRadius: 18,
												color: "#FFF",
												alignSelf: "center",
												marginVertical: 6,
												backgroundColor: pitchColors[pitchType],
											}}>
											{pitchType}
										</Text>
										<Text style={{ fontSize: 24, fontWeight: "bold" }}>
											{Math.round(
												(100 * pitchTypesThrown[pitchType].num) /
													outingStats.pitches
											)}
											%
										</Text>
									</View>
								)
							}
						})}
					</View>
				</View>
				<View
					style={{
						backgroundColor: "#fff",
						marginVertical: 6,
						marginHorizontal: 12,
						padding: 12,
						borderRadius: 6,
					}}>
					<View
						style={{
							borderBottomStyle: "solid",
							borderBottomColor: "#BD2E1F",
							borderBottomWidth: 2,
							marginBottom: 6,
						}}>
						<Text
							style={{
								fontSize: 14,
							}}>
							VELOCITY THROUGHOUT OUTING
						</Text>
					</View>
					<Image src={{ data: veloChart, format: "png" }} />
				</View>
			</Page>
			<Page
				size="A4"
				style={{ fontFamily: "Poppins", backgroundColor: "#F5F5F5" }}>
				{pitchTypes.map((pitchType) => {
					if (Object.keys(pitchTypesThrown).includes(pitchType)) {
						return (
							<View
								key={pitchType}
								wrap={false}
								style={{
									backgroundColor: "white",
									marginVertical: 6,
									marginHorizontal: 12,
									padding: 12,
									borderRadius: 6,
								}}>
								<View
									style={{
										borderBottomStyle: "solid",
										borderBottomColor: pitchColors[pitchType],
										borderBottomWidth: 2,
										marginBottom: 6,
									}}>
									<Text
										style={{
											width: 75,
											paddingVertical: 6,
											paddingHorizontal: 12,
											borderRadius: 18,
											color: "#FFF",
											marginVertical: 6,
											backgroundColor: pitchColors[pitchType],
											textAlign: "center",
										}}>
										{pitchType}
									</Text>
								</View>
								<View style={{ flexDirection: "row" }}>
									<View style={{ flex: 1, textAlign: "center" }}>
										<View style={{ flexDirection: "row" }}>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 12, opacity: 0.5 }}>Min</Text>
												<Text style={{ fontWeight: 600, fontSize: 22 }}>
													{pitchStats[pitchType].veloMin !== 1000
														? pitchStats[pitchType].veloMin
														: "-"}
												</Text>
											</View>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 12, opacity: 0.5 }}>Avg</Text>
												<Text style={{ fontWeight: 600, fontSize: 22 }}>
													{pitchStats[pitchType].pitchesWithVelo
														? (
																pitchStats[pitchType].veloTotal /
																pitchStats[pitchType].pitchesWithVelo
														  ).toFixed(1)
														: "-"}
												</Text>
											</View>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 12, opacity: 0.5 }}>Max</Text>
												<Text style={{ fontWeight: 600, fontSize: 22 }}>
													{pitchStats[pitchType].veloMax !== 0
														? pitchStats[pitchType].veloMax
														: "-"}
												</Text>
											</View>
										</View>
										<View style={{ flexDirection: "row", marginTop: 12 }}>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 12, opacity: 0.5 }}>
													Num Thrown
												</Text>
												<Text style={{ fontWeight: 600, fontSize: 22 }}>
													{pitchStats[pitchType].num}
												</Text>
											</View>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 12, opacity: 0.5 }}>
													Strike %
												</Text>
												<Text style={{ fontWeight: 600, fontSize: 22 }}>
													{Math.round(
														(100 * pitchStats[pitchType].strikes) /
															pitchStats[pitchType].num
													)}
													%
												</Text>
											</View>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 12, opacity: 0.5 }}>
													Whiff %
												</Text>
												<Text style={{ fontWeight: 600, fontSize: 22 }}>
													{pitchStats[pitchType].swings > 0
														? `${Math.round(
																(100 * pitchStats[pitchType].swingingStrikes) /
																	pitchStats[pitchType].swings
														  )}%`
														: "-"}
												</Text>
											</View>
										</View>
										<View style={{ flexDirection: "row", marginTop: 16 }}>
											<View style={{ flex: 1, textAlign: "left" }}>
												<Text style={{ fontSize: 15 }}>
													Thrown {pitchStats[pitchType].firstPitches} times on
													0-0 counts
												</Text>
												<Text style={{ fontSize: 15, marginTop: 10 }}>
													Thrown {pitchStats[pitchType].twoStrikes} times with 2
													strikes
												</Text>
											</View>
										</View>
										<View
											style={{
												flexDirection: "row",
												marginTop: 16,
												justifyContent: "space-around",
											}}>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 12, opacity: 0.5 }}>B</Text>
												<Text style={{ fontWeight: 600, fontSize: 18 }}>
													{pitchStats[pitchType].balls}
												</Text>
											</View>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 12, opacity: 0.5 }}>CS</Text>
												<Text style={{ fontWeight: 600, fontSize: 18 }}>
													{pitchStats[pitchType].calledStrikes}
												</Text>
											</View>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 12, opacity: 0.5 }}>SS</Text>
												<Text style={{ fontWeight: 600, fontSize: 18 }}>
													{pitchStats[pitchType].swingingStrikes}
												</Text>
											</View>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 12, opacity: 0.5 }}>F</Text>
												<Text style={{ fontWeight: 600, fontSize: 18 }}>
													{pitchStats[pitchType].fouls}
												</Text>
											</View>
											<View style={{ flex: 1 }}>
												<Text style={{ fontSize: 12, opacity: 0.5 }}>IP</Text>
												<Text style={{ fontWeight: 600, fontSize: 18 }}>
													{pitchStats[pitchType].inPlay}
												</Text>
											</View>
										</View>
									</View>
									<View style={{ flex: 1 }}>
										<Image
											src={{ data: pitchLocations[pitchType], format: "png" }}
										/>
									</View>
								</View>
							</View>
						)
					}
				})}
			</Page>
		</Document>
	)
}
