import { useContext } from "react"

import { Link } from "react-router-dom"
import axios from "axios"

// components
import { Button, IconButton, TableCell, TableRow } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"

// types
import { Player } from "../../../interfaces/PlayerInterface"

// contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { PitchingPageContext } from "../PitchingPage"
import { FeedbackContext } from "../../../context/FeedbackContext"

interface Props {
	player: Player
}

export const PlayerRow = ({ player }: Props) => {
	const { user, apiHeader } = useContext(GlobalsContext)
	const { alertSuccess, alertError } = useContext(FeedbackContext)
	const { openEditPitcherModal, refetchPlayers } =
		useContext(PitchingPageContext)

	const handleEdit = () => {
		openEditPitcherModal(player)
	}

	const handleDelete = async () => {
		if (confirm("Are you sure you want to delete this player?")) {
			try {
				await axios.delete(`/api/player/${player.id}`, apiHeader)
				alertSuccess("Pitcher deleted!")
				refetchPlayers()
			} catch (e) {
				alertError(
					"Something wen't wrong. This player may have outings or at bats associated with them."
				)
				console.log(e)
			}
		}
	}

	return (
		<TableRow>
			<TableCell>
				<Button component={Link} to={`/pitcher/${player.id}`} color="primary">
					{player.firstname} {player.lastname}
				</Button>
			</TableCell>
			<TableCell align="center">{player.year}</TableCell>
			<TableCell align="center">{player.throws}</TableCell>
			<TableCell align="center">{player.number}</TableCell>
			{user!.admin && (
				<TableCell align="center">
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={handleDelete}>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			)}
		</TableRow>
	)
}
