import React, { useContext } from "react"

import { Grid, Paper } from "@material-ui/core"

import { Base } from "./Base"

import { Context } from "../../TrackTab"

export const Runners = () => {
	const { state, setState } = useContext(Context)
	return (
		<Paper elevation={0} className="pt-outing-state-component">
			<Grid container spacing={0}>
				<Grid id="pt-base-grid-second" item xs={12} className="align-center">
					<Base
						runner={state.pitchEntry.runnerSecond}
						onChange={() =>
							setState({
								...state,
								pitchEntry: {
									...state.pitchEntry,
									runnerSecond: !state.pitchEntry.runnerSecond,
								},
							})
						}
					/>
				</Grid>
				<Grid id="pt-base-grid-third" item xs={6} className="align-right">
					<Base
						runner={state.pitchEntry.runnerThird}
						onChange={() =>
							setState({
								...state,
								pitchEntry: {
									...state.pitchEntry,
									runnerThird: !state.pitchEntry.runnerThird,
								},
							})
						}
					/>
				</Grid>
				<Grid id="pt-base-grid-first" item xs={6} className="align-left">
					<Base
						runner={state.pitchEntry.runnerFirst}
						onChange={() =>
							setState({
								...state,
								pitchEntry: {
									...state.pitchEntry,
									runnerFirst: !state.pitchEntry.runnerFirst,
								},
							})
						}
					/>
				</Grid>
			</Grid>
		</Paper>
	)
}
