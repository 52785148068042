import React from "react"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"

interface Props {
	value: "games" | "practices"
	onChange: (value: "games" | "practices") => void
}

export const GamesPracticesToggle = ({ onChange, value }: Props) => {
	const handleChange = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		value: any
	) => {
		onChange(value)
	}

	return (
		<ToggleButtonGroup value={value} exclusive onChange={handleChange}>
			<ToggleButton value="games" className="toggle-button">
				GAMES
			</ToggleButton>
			<ToggleButton value="practices" className="toggle-button">
				PRACTICES
			</ToggleButton>
		</ToggleButtonGroup>
	)
}
