import { Chip } from "@material-ui/core"

interface PitchTypeChipProps {
	pitchType: string
}

export const PitchTypeChip = ({ pitchType }: PitchTypeChipProps) => {
	return (
		<Chip
			label={pitchType}
			className={`outing-locations-pitch-chip-${pitchType}`}
		/>
	)
}
