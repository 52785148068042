import React, { createContext, useContext, useEffect, useState } from "react"

import {
	Backdrop,
	CircularProgress,
	Grid,
	useMediaQuery,
} from "@material-ui/core"

// main components
import { Header } from "./components/Header/Header"
import { PitchLocation } from "./components/PitchLocation/PitchLocation"
import { OutingStats } from "./components/OutingStats/OutingStats"
import { OutingState } from "./components/OutingState/OutingState"
import { PitchType } from "./components/PitchDataEntry/PitchType"
import { PitchResult } from "./components/PitchDataEntry/PitchResult"
import { DataEntry } from "./components/PitchDataEntry/DataEntry"
import { NotesEntry } from "./components/PitchDataEntry/NotesEntry"
import { SubmitButtons } from "./components/SubmitButtons/SubmitButtons"

// modals
import { FieldModal } from "./components/FieldModal/FieldModal"
import { AtBatResultModal } from "./components/AtBatEndModals/AtBatResultModal"
import { IncludeLastPitchModal } from "./components/AtBatEndModals/IncludeLastPitchModal"

// state, functions, contexts, etc
import { updateOutingState } from "./utils/UpdateOutingState"
import { updateAtBat } from "./utils/AddPitchHandler"
import { GlobalsContext } from "../../../../context/GlobalsContext"
import { initialState, State } from "./utils/Constants"
import { OutingContext } from "../TrackOuting"

// styling
import "../../../../static/css/TrackTab/TrackTab.css"

// user feedback
import { Feedback } from "./components/Feedback/Feedback"
import { ScreenToSmall } from "./components/Feedback/ScreenToSmall"
import { BatterGameResults } from "./components/BatterGameResults/BatterGameResults"

// global context to manage outing state
export const Context = createContext({
	state: null as unknown as State,
	setState: null as unknown as React.Dispatch<React.SetStateAction<State>>,
	setLoading: null as unknown as React.Dispatch<React.SetStateAction<boolean>>,
})

export const TrackTab = () => {
	// just need globals for api header
	const globals = useContext(GlobalsContext)

	// pulls the current outing for this tab
	const { outing, setOuting } = useContext(OutingContext)

	// manage and change state of this component
	const [state, setState] = useState<State>({ ...initialState, outing })

	// backdrop loading element
	const [loading, setLoading] = useState<boolean>(false)

	// if the inning or outs change, update the current
	// 	at bat to have these fields
	useEffect(() => {
		if (state.currentAtBat) {
			updateAtBat(state, globals.apiHeader)

			// update the outing atbats after the atbat changed
			let at_bats = outing.at_bats
			at_bats[at_bats.length - 1].inning = state.outingState.inning
			at_bats[at_bats.length - 1].outs = state.outingState.outs
			setState({ ...state, outing: { ...state.outing!, at_bats } })
		}
	}, [state.outingState.inning, state.outingState.outs])

	// wait until batters are loaded before updating state
	// 	or else the function will choose a selectedBatter
	// 	that doesn't exist yet
	useEffect(() => {
		const updateState = async () => {
			if (state.allBatters.length > 0) {
				setLoading(true)
				await updateOutingState(state, setState, globals.apiHeader)
				setLoading(false)
			}
		}
		updateState()
	}, [state.allBatters])

	// the outing gets updated when updateOutingState runs,
	//	so this updates the global "outing" object which
	// 	gets used by the pitches tab
	useEffect(() => {
		setOuting(state.outing)
	}, [state.outing])

	// this is to prevent someone from trying to track on
	// 	a super small screen
	const screenToSmall = useMediaQuery("(max-width: 767px)")

	return (
		<Context.Provider value={{ state, setState, setLoading }}>
			{screenToSmall ? (
				<ScreenToSmall />
			) : (
				<>
					<Header />
					<Grid container spacing={0}>
						<Grid item xs={6} className="pt-location">
							<PitchLocation />
						</Grid>
						<Grid item xs={6} className="pt-outing-data">
							<OutingStats />
							<OutingState />
							<PitchType />
							<PitchResult />
							<DataEntry />
							<NotesEntry />
							<SubmitButtons />
						</Grid>
					</Grid>
					<BatterGameResults
						open={state.showBatterGameResults}
						close={() => setState({ ...state, showBatterGameResults: false })}
					/>
					<FieldModal />
					<AtBatResultModal />
					<IncludeLastPitchModal />
					<Feedback />
					<Backdrop open={loading} style={{ color: "#fff", zIndex: 10 }}>
						<CircularProgress color="inherit" />
					</Backdrop>
				</>
			)}
		</Context.Provider>
	)
}
