import * as d3 from "d3"
import { useEffect, useRef } from "react"
import { AtBat } from "../../../interfaces/AtBatInterface"
import { GenerateTooltipHTML } from "../../../shared/Functions/GenerateTooltipHTML"

interface Props {
	atBats: AtBat[]
	width: number
	height: number
}

export const Field = ({ atBats, width, height }: Props) => {
	const svgRef = useRef(null)
	const tooltipRef = useRef(null)

	const xScale = d3.scaleLinear().range([width, 0]).domain([250, -250])
	const yScale = d3.scaleLinear().range([height, 0]).domain([-100, 400])

	useEffect(() => {
		const svg = d3.select(svgRef.current)
		svg.selectAll("path").remove()

		const dirtColor = "#99552B"
		const grassColor = "#508632"

		const fieldOutlinePath = `
		M ${xScale(0)},${yScale(-45)}
		  L ${xScale(50)},${yScale(-25)}
		  L ${xScale(242)},${yScale(222)}
		  L ${xScale(145)},${yScale(334)}
		  L ${xScale(68)},${yScale(375)}
		  L ${xScale(0)},${yScale(395)}
		  L ${xScale(-68)},${yScale(375)}
		  L ${xScale(-145)},${yScale(334)}
		  L ${xScale(-242)},${yScale(222)}
		  L ${xScale(-50)},${yScale(-25)}
		  L ${xScale(0)},${yScale(-45)} Z`
		const fieldInnerOutlinePath = `
		M ${xScale(0)},${yScale(-40)}
		  L ${xScale(50)},${yScale(-20)}
		  L ${xScale(237)},${yScale(222)}
		  L ${xScale(145)},${yScale(329)}
		  L ${xScale(68)},${yScale(370)}
		  L ${xScale(0)},${yScale(390)}
		  L ${xScale(-68)},${yScale(370)}
		  L ${xScale(-145)},${yScale(329)}
		  L ${xScale(-237)},${yScale(222)}
		  L ${xScale(-50)},${yScale(-20)}
		  L ${xScale(0)},${yScale(-40)} Z`
		const infieldDirt = `
		M ${xScale(85)},${yScale(80)}
		  L ${xScale(0)},${yScale(170)}
		  L ${xScale(-85)},${yScale(80)}
		  L ${xScale(0)},${yScale(-5)}
		  L ${xScale(85)},${yScale(80)}
		  C ${xScale(85)},${yScale(200)}
		  ${xScale(-85)},${yScale(200)}
		  ${xScale(-85)},${yScale(80)}
		  L ${xScale(0)},${yScale(5)} Z`
		const infieldGrass = `
		M ${xScale(0)},${yScale(5)}
		  L ${xScale(60)},${yScale(60)}
		  L ${xScale(0)},${yScale(120)}
		  L ${xScale(-60)},${yScale(60)} Z`
		const foulLines = `
		M ${xScale(-239)},${yScale(239)}
		  L ${xScale(0)},${yScale(0)}
		  L ${xScale(239)},${yScale(239)}
		  L ${xScale(0)},${yScale(0)} Z`

		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", fieldOutlinePath)
			.style("stroke", "black")
			.style("fill", dirtColor)
			.style("stroke-width", 1)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", fieldInnerOutlinePath)
			.style("fill", grassColor)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", infieldDirt)
			.style("fill", dirtColor)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", infieldGrass)
			.style("fill", grassColor)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", foulLines)
			.style("stroke", "white")
			.style("stroke-width", 1)
	}, [width, height])

	const getColor = (traj: string) => {
		switch (traj) {
			case "FB":
				return "blue"
			case "LD":
				return "green"
			case "GB":
				return "red"
			default:
				return "yellow"
		}
	}

	useEffect(() => {
		const svg = d3.select(svgRef.current)

		svg.selectAll("circle").remove()
		svg.selectAll(".star-path").remove()

		atBats.forEach((atBat) => {
			if (atBat.spray_x && atBat.spray_y) {
				svg
					.append("circle")
					.attr("class", "hover-pointer")
					.attr("cx", xScale(atBat.spray_x))
					.attr("cy", yScale(atBat.spray_y))
					.attr("r", 5)
					.style("fill", getColor(atBat.traj))
					.style("stroke", "black")
					.style("stroke-width", 1)
					.on("mouseover", function (event) {
						const coordinates = d3.pointer(
							event,
							document.querySelector("body")
						)
						d3.select(tooltipRef.current)
							.style("display", "block")
							.style("position", "absolute")
							.style("left", `${coordinates[0] + 10}px`)
							.style("top", `${coordinates[1] + 10}px`)
							.html(
								GenerateTooltipHTML([
									{
										label: "Pitcher",
										value:
											atBat.pitcher!.firstname + " " + atBat.pitcher!.lastname,
									},
									{
										label: "Pitch Type",
										value:
											atBat.pitches && atBat.pitches.length > 0
												? atBat.pitches.slice(-1)[0].pitch_type
												: "N/A",
									},
									{
										label: "Count",
										value:
											atBat.pitches && atBat.pitches.length > 0
												? `${atBat.pitches.slice(-1)[0].balls}-${
														atBat.pitches.slice(-1)[0].strikes
												  }`
												: "N/A",
									},
									{ label: "Result", value: atBat.result },
									{ label: "Trajectory", value: atBat.traj },
									{ label: "Fielder", value: atBat.fielder },
									{ label: "Hit Hard", value: atBat.hit_hard ? "Yes" : "No" },
								])
							)
					})
					.on("mouseout", function (event) {
						d3.select(tooltipRef.current).style("display", "none")
					})

				if (atBat.hit_hard) {
					svg
						.append("path")
						.attr("class", "star-path hover-pointer")
						.attr("d", d3.symbol().type(d3.symbolStar).size(30))
						.attr(
							"transform",
							`translate(${xScale(atBat.spray_x)}, ${yScale(atBat.spray_y)})`
						)
						.attr("fill", "black")
						.on("mouseover", function (event) {
							const coordinates = d3.pointer(
								event,
								document.querySelector("body")
							)
							d3.select(tooltipRef.current)
								.style("display", "block")
								.style("position", "absolute")
								.style("left", `${coordinates[0] + 10}px`)
								.style("top", `${coordinates[1] + 10}px`)
								.html(
									GenerateTooltipHTML([
										{
											label: "Pitcher",
											value:
												atBat.pitcher!.firstname +
												" " +
												atBat.pitcher!.lastname,
										},
										{
											label: "Pitch Type",
											value:
												atBat.pitches && atBat.pitches.length > 0
													? atBat.pitches.slice(-1)[0].pitch_type
													: "N/A",
										},
										{
											label: "Count",
											value:
												atBat.pitches && atBat.pitches.length > 0
													? `${atBat.pitches.slice(-1)[0].balls}-${
															atBat.pitches.slice(-1)[0].strikes
													  }`
													: "N/A",
										},
										{ label: "Result", value: atBat.result },
										{ label: "Trajectory", value: atBat.traj },
										{ label: "Fielder", value: atBat.fielder },
										{ label: "Hit Hard", value: atBat.hit_hard ? "Yes" : "No" },
									])
								)
						})
						.on("mouseout", function (event) {
							d3.select(tooltipRef.current).style("display", "none")
						})
				}
			}
		})
	}, [atBats, width, height])

	return (
		<>
			<svg width={width} height={height} ref={svgRef}></svg>
			<div ref={tooltipRef} />
		</>
	)
}
