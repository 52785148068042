import { useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	Card,
	CardContent,
	Grid,
	List,
	ListItem,
	ListItemText,
} from "@material-ui/core"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"

// types and contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { Game } from "../../../interfaces/GameInterface"

// functions
import { formatDate } from "../../../shared/Functions/FormatDate"
import { Link } from "react-router-dom"

export const RecentGames = () => {
	const globals = useContext(GlobalsContext)

	// while fetching the recent games
	const [loading, setLoading] = useState<boolean>(true)

	// if there was an error or if there are no games
	const [feedback, setFeedback] = useState<string>("")

	// holds at most 3 most recent games
	const [games, setGames] = useState<Game[]>([])

	// fetch this seasons most recent games
	useEffect(() => {
		const fetchGames = async () => {
			try {
				// fetch and save the resposne
				const response = await axios.get(
					`/api/game?team_id=${globals.teamId}&season_id=${globals.currentSeason}&order=desc`,
					globals.apiHeader
				)
				const seasonGames: Game[] = response.data

				// show the user different things depending on number of games returned
				if (seasonGames.length === 0) {
					setFeedback("No games yet this season")
					setGames([])
				} else if (seasonGames.length === 1 || seasonGames.length === 2) {
					setFeedback("")
					setGames(seasonGames)
				} else {
					setFeedback("")
					setGames(seasonGames.slice(0, 3))
				}
			} catch (e) {
				console.log(e)
				setFeedback("Something wen't wrong")
			}
			setLoading(false)
		}
		fetchGames()
	}, [])

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Card className="recent-competitions-card">
			<CardContent className="recent-competitions-card-content">
				<h2 className="recent-competitions-card-title">Recent Games</h2>
				<hr></hr>
				{feedback !== "" && (
					<span className="recent-competitions-card-feedback">{feedback}</span>
				)}
				<List>
					{games.map((game) => (
						<ListItem
							key={game.id}
							component={Link}
							to={`/game/${game.id}`}
							button>
							<ListItemText
								primary={
									<Grid container spacing={0}>
										<Grid item xs={8}>
											{game.home_team.id === globals.teamId
												? `vs. ${game.away_team.name}`
												: `@ ${game.home_team.name}`}
										</Grid>
										<Grid item xs={4} className="align-right">
											{formatDate(game.date as string)}
										</Grid>
									</Grid>
								}
							/>
						</ListItem>
					))}
				</List>
			</CardContent>
		</Card>
	)
}
