import React from "react"

import { Grid, Paper } from "@material-ui/core"

import { Base } from "./Base"

interface Props {
	formState: { [key: string]: any }
	setFormState: (
		value: React.SetStateAction<{
			[key: string]: any
		}>
	) => void
	disabled?: boolean
}

export const Runners = ({ formState, setFormState, disabled }: Props) => {
	return (
		<Paper elevation={0}>
			<Grid container spacing={0}>
				<Grid item xs={12} className="align-center">
					<Base
						runner={formState.runner2}
						onChange={() =>
							setFormState({ ...formState, runner2: !formState.runner2 })
						}
					/>
				</Grid>
				<Grid
					item
					xs={6}
					className="align-right"
					style={{ paddingRight: "14px" }}>
					<Base
						runner={formState.runner3}
						onChange={() =>
							setFormState({ ...formState, runner3: !formState.runner3 })
						}
					/>
				</Grid>
				<Grid
					item
					xs={6}
					className="align-left"
					style={{ paddingLeft: "14px" }}>
					<Base
						runner={formState.runner1}
						onChange={() =>
							setFormState({ ...formState, runner1: !formState.runner1 })
						}
					/>
				</Grid>
			</Grid>
		</Paper>
	)
}
