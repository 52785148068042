import { ChangeEvent, useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	TextField,
} from "@material-ui/core"
import { ButtonApiRequest } from "../../shared/ButtonApiRequest"
import { TransitionUp } from "../../shared/ModalTransitions/TransitionUp"

// types
import { Player } from "../../interfaces/PlayerInterface"

// context
import { GlobalsContext } from "../../context/GlobalsContext"
import { PitchingPageContext } from "./PitchingPage"
import { FeedbackContext } from "../../context/FeedbackContext"

interface EditPitcherModalProps {
	open: boolean
	handleClose: () => void
	player: Player | null
}

export const EditPitcherModal = ({
	open,
	handleClose,
	player,
}: EditPitcherModalProps) => {
	const { apiHeader, teamId } = useContext(GlobalsContext)
	const { refetchPlayers } = useContext(PitchingPageContext)
	const { alertSuccess, alertError, alertWarning } = useContext(FeedbackContext)
	const [loading, setLoading] = useState<boolean>(false)
	const [formValues, setFormValues] = useState<{ [key: string]: any }>({})

	useEffect(() => {
		setFormValues({
			firstname: player ? player.firstname : "",
			lastname: player ? player.lastname : "",
			number: player ? player.number : "",
			bats: player ? player.bats : "",
			throws: player ? player.throws : "",
			year: player ? player.year : "",
			position: player ? player.position : "",
			pitcher: player ? player.pitcher : true,
			batter: player ? player.batter : false,
			team_id: player ? player.team_id : teamId,
			active: player ? player.active : true,
		})
	}, [player])

	const formChange = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const newValues = { ...formValues }
		newValues[event.target.name] = event.target.value
		setFormValues(newValues)
	}

	const editPitcher = async () => {
		if (!formValues.pitcher && !formValues.batter) {
			return alertWarning(
				"The player must be either a pitcher or a hitter (or both)"
			)
		}

		setLoading(true)
		try {
			await axios.patch(`/api/player/${player!.id}`, formValues, apiHeader)
			alertSuccess("Pitcher saved!")
			handleClose()
			refetchPlayers()
		} catch (e) {
			alertError(
				"Something wen't wrong. Please refresh the page and try again!"
			)
			console.log(e)
		}
		setLoading(false)
	}

	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			open={open}
			onClose={handleClose}
			TransitionComponent={TransitionUp}>
			<DialogTitle>Edit Pitcher</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							value={formValues.firstname}
							onChange={formChange}
							name="firstname"
							label="Firstname"
							type="text"
							required
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={formValues.lastname}
							onChange={formChange}
							name="lastname"
							label="Lastname"
							type="text"
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={formValues.number}
							onChange={formChange}
							name="number"
							label="Number"
							type="text"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={formValues.bats}
							onChange={formChange}
							name="bats"
							label="Bats"
							type="text"
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={formValues.throws}
							onChange={formChange}
							name="throws"
							label="Throws"
							type="text"
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={formValues.year}
							onChange={formChange}
							name="year"
							label="Year"
							type="text"
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							value={formValues.position}
							onChange={formChange}
							name="position"
							label="Position"
							type="text"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									checked={formValues.pitcher}
									color="primary"
									onChange={(event) =>
										setFormValues({
											...formValues,
											pitcher: event.target.checked,
										})
									}
								/>
							}
							label="Pitcher"
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									checked={formValues.batter}
									color="primary"
									onChange={(event) =>
										setFormValues({
											...formValues,
											batter: event.target.checked,
										})
									}
								/>
							}
							label="Hitter"
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									checked={formValues.active}
									color="primary"
									onChange={(event) =>
										setFormValues({
											...formValues,
											active: event.target.checked,
										})
									}
								/>
							}
							label="Active"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
				<ButtonApiRequest
					disabled={
						!formValues.firstname ||
						!formValues.lastname ||
						!formValues.bats ||
						!formValues.throws ||
						!formValues.year
					}
					loading={loading}
					onClick={editPitcher}
					color="primary"
					text="Save"
				/>
			</DialogActions>
		</Dialog>
	)
}
