import {
	Card,
	CardContent,
	CardHeader,
	Collapse,
	Container,
	Grid,
	Hidden,
	IconButton,
} from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import React, { useState } from "react"
import { AtBat } from "../../../interfaces/AtBatInterface"
import { MiniField } from "./MiniField"
import { PitchesTable } from "./PitchesTable/PitchesTable"

interface Props {
	atBat: AtBat
}

export const AtBatCard = ({ atBat }: Props) => {
	const [showPitches, setShowPitches] = useState<boolean>(false)
	return (
		<Card key={atBat.id} style={{ marginTop: "20px" }}>
			<CardContent>
				<Grid container spacing={0} className="align-center">
					<Grid item xs={12} sm={4} md={4}>
						<Grid container spacing={0} className="align-center">
							<Grid item xs={6} sm={12}>
								<div className="pt-pitches-ab-field-name">BATTER</div>
								<div className="pt-pitches-ab-field-value">
									#{atBat.player.number} {atBat.player.firstname}{" "}
									{atBat.player.lastname}
									{atBat.player.bats && ` (${atBat.player.bats})`}
								</div>
							</Grid>
							<Grid item xs={6} sm={12} className="pt-pitches-ab-card-result">
								<div className="pt-pitches-ab-field-name">RESULT</div>
								<div className="pt-pitches-ab-field-value">
									{atBat.result ? atBat.result : "N/A"}
								</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						sm={4}
						md={3}
						className="align-center pt-pitches-ab-card-traj-container">
						<Grid container spacing={0}>
							<Grid item xs={6}>
								<div className="pt-pitches-ab-field-name">TRAJECTORY</div>
								<div className="pt-pitches-ab-field-value">
									{atBat.traj ? atBat.traj : "N/A"}
								</div>
							</Grid>
							<Grid item xs={6}>
								<div className="pt-pitches-ab-field-name">FIELDER</div>
								<div className="pt-pitches-ab-field-value">
									{atBat.fielder ? atBat.fielder : "N/A"}
								</div>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={0}
							className="pt-pitches-ab-hit-hard-container">
							<Grid item xs={6}>
								<div className="pt-pitches-ab-field-name">HIT HARD</div>
								<div className="pt-pitches-ab-field-value">
									{atBat.hit_hard
										? "Yes"
										: atBat.hit_hard === null
										? "N/A"
										: "No"}
								</div>
							</Grid>
							<Grid item xs={6}>
								<div className="pt-pitches-ab-field-name">OUTS</div>
								<div className="pt-pitches-ab-field-value">
									{atBat.outs !== null ? atBat.outs : "N/A"}
								</div>
							</Grid>
						</Grid>
					</Grid>
					<Hidden xsDown>
						<Grid item sm={4} md={2} className="align-center">
							<div className="pt-pitches-ab-field-name">NOTES</div>
							<div className="pt-pitches-ab-field-value">
								{atBat.notes !== null && atBat.notes !== ""
									? atBat.notes
									: "N/A"}
							</div>
						</Grid>
					</Hidden>
					<Hidden smUp>
						<Grid item xs={6} className="align-center">
							<div className="pt-pitches-ab-field-name">NOTES</div>
							<div className="pt-pitches-ab-field-value">
								{atBat.notes !== null && atBat.notes !== ""
									? atBat.notes
									: "N/A"}
							</div>
						</Grid>
						<Grid item xs={6} className="align-center">
							<IconButton onClick={() => setShowPitches(!showPitches)}>
								{showPitches ? <ExpandLess /> : <ExpandMore />}
							</IconButton>
						</Grid>
					</Hidden>
					<Hidden smDown>
						<Grid item md={2} className="align-center">
							<MiniField xSpray={atBat.spray_x} ySpray={atBat.spray_y} />
						</Grid>
					</Hidden>
				</Grid>
				<Hidden smUp>
					<Collapse in={showPitches} timeout={0} unmountOnExit>
						<PitchesTable atBat={atBat} />
					</Collapse>
				</Hidden>
				<Hidden xsDown>
					<PitchesTable atBat={atBat} />
				</Hidden>
			</CardContent>
		</Card>
	)
}
