import React, { useState } from "react"
import { Button, TextField, Container } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"

import axios from "axios"

import "../../static/css/ProfilePage.css"

export const ChangePassword = () => {
	const [submitButtonText, setSubmitButtonText] = useState<string>(
		"Change Password"
	)
	const [formState, setFormState] = useState<{ [key: string]: string }>({
		currentPass: "",
		newPass: "",
		repeatPass: "",
	})
	const [error, setError] = useState<boolean>(false)
	const [errorText, setErrorText] = useState<string>("")
	const [success, setSuccess] = useState<boolean>(false)
	const [successText, setSuccessText] = useState<string>("")

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const token = localStorage.getItem("token")
		setSubmitButtonText("Saving.....")
		try {
			await axios.post(
				"/api/user/changepassword",
				{
					current_password: formState.currentPass,
					new_password: formState.newPass,
					repeat_new_password: formState.repeatPass,
				},
				{
					headers: { Authorization: `Bearer ${token}` },
				}
			)
			setSuccessText("Password changed!")
			setError(false)
			setSuccess(true)
			setTimeout(() => setSuccess(false), 3000)
			resetForm()
		} catch (e) {
			if (e.response.data) {
				setErrorText(e.response.data)
			} else {
				setErrorText("Something went wrong")
			}
			setError(true)
			setSuccess(false)
		}
		setSubmitButtonText("Change Password")
	}

	const resetForm = () => {
		setFormState({
			currentPass: "",
			newPass: "",
			repeatPass: "",
		})
	}

	const formChange = (which: string, newValue: string) => {
		let newState = { ...formState }
		newState[which] = newValue
		setFormState(newState)
	}

	return (
		<Container component="main" maxWidth="xs">
			<div className="pp-paper">
				{error ? (
					<Alert className="cp-alert" severity="error">
						{errorText}
					</Alert>
				) : (
					<></>
				)}
				{success ? (
					<Alert className="cp-alert" severity="success">
						{successText}
					</Alert>
				) : (
					<></>
				)}
				<form
					className="cp-form"
					onSubmit={(event) => handleSubmit(event)}
					noValidate>
					<TextField
						onChange={(event) => formChange("currentPass", event.target.value)}
						value={formState.currentPass}
						variant="outlined"
						margin="normal"
						fullWidth
						type="password"
						id="currentPass"
						label="Current Password"
						name="currentPass"
						required
					/>
					<TextField
						onChange={(event) => formChange("newPass", event.target.value)}
						value={formState.newPass}
						variant="outlined"
						margin="normal"
						fullWidth
						name="newPass"
						label="New Password"
						type="password"
						required
					/>
					<TextField
						onChange={(event) => formChange("repeatPass", event.target.value)}
						value={formState.repeatPass}
						variant="outlined"
						margin="normal"
						fullWidth
						name="repeatPass"
						label="Repeat New Password"
						type="password"
						required
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className="cp-submit">
						{submitButtonText}
					</Button>
				</form>
			</div>
		</Container>
	)
}
