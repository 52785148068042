import {
	createStyles,
	Divider,
	Grid,
	Hidden,
	makeStyles,
	Paper,
	Theme,
} from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import { AtBat_processor } from "../../Classes/HitterAtBatProcessor"

interface Props {
	processor: AtBat_processor
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			width: "100%",
			height: "100%",
			margin: "4px",
			paddingLeft: "4px",
			paddingRight: "4px",
		},
	})
)

export const StatsDropdown = ({ processor }: Props) => {
	const classes = useStyles()

	return (
		<Grid container spacing={1}>
			<Grid container item xs={12} spacing={0} justify="center">
				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
			{/* First Pitch Box */}
			<Grid container item xs={12} md={6} spacing={0}>
				<Paper className={classes.paper}>
					<Grid container spacing={0}>
						<Grid container item xs={12} spacing={0} className="align-center">
							<Grid item xs={12}>
								<div>First Pitch Box</div>
							</Grid>
						</Grid>
						<Grid container item xs={12} spacing={0} justify="center">
							<Grid item xs={6}>
								<Divider />
							</Grid>
						</Grid>
						<Grid container item xs={12} spacing={0}>
							<Grid container item xs={12} spacing={0}>
								<Grid item xs={4}>
									Fastball
								</Grid>
								<Grid item xs={4}>
									{processor.first_pitch_fb_pct}%
								</Grid>
								<Grid item xs={4}>
									{processor.fp_fb_ct}
								</Grid>
							</Grid>
							<Grid container item xs={12} spacing={0}>
								<Grid item xs={4}>
									Offspeed
								</Grid>
								<Grid item xs={4}>
									{processor.first_pitch_offspeed_pct}%
								</Grid>
								<Grid item xs={4}>
									{processor.fp_os_ct}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Hidden mdUp>
				<Grid container item xs={12} spacing={0} justify="center">
					<Grid item xs={10}>
						<Divider />
					</Grid>
				</Grid>
			</Hidden>
			{/* second Pitch Box */}
			<Grid container item xs={12} md={6} spacing={0}>
				<Paper className={classes.paper}>
					<Grid container spacing={0}>
						<Grid container item xs={12} spacing={0} className="align-center">
							<Grid item xs={12}>
								<div>Second Pitch Box</div>
							</Grid>
						</Grid>
						<Grid container item xs={12} spacing={0} justify="center">
							<Grid item xs={6}>
								<Divider />
							</Grid>
						</Grid>
						<Grid container item xs={12} spacing={0}>
							<Grid container item xs={12} spacing={0}>
								<Grid item xs={4}>
									Fastball
								</Grid>
								<Grid item xs={4}>
									{processor.second_pitch_fb_pct}%
								</Grid>
								<Grid item xs={4}>
									{processor.sp_fb_ct}
								</Grid>
							</Grid>
							<Grid container item xs={12} spacing={0}>
								<Grid item xs={4}>
									Offspeed
								</Grid>
								<Grid item xs={4}>
									{processor.second_pitch_offspeed_pct}%
								</Grid>
								<Grid item xs={4}>
									{processor.sp_os_ct}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid container item xs={12} spacing={0} justify="center">
				<Grid item xs={10}>
					<Divider />
				</Grid>
			</Grid>
			{/* Comeback Pitch Box */}
			<Grid container item xs={12} spacing={0}>
				<Paper className={classes.paper}>
					<Grid container item spacing={0} xs={12} className="align-center">
						<Grid item xs={12}>
							<div>Comeback Pitch Box</div>
						</Grid>
					</Grid>
					<Grid container item spacing={0} xs={12} justify="center">
						<Grid item xs={6}>
							<Divider />
						</Grid>
					</Grid>
					<Grid container item spacing={0} xs={12}>
						<Grid item xs={4}>
							First Pitch
						</Grid>
						<Grid item xs={4}>
							Result
						</Grid>
						<Grid item xs={4}>
							Second Pitch
						</Grid>
					</Grid>
					<Grid container item spacing={0} xs={12}>
						<Grid item xs={4}>
							<div>Fastball</div>
						</Grid>
						<Grid container item xs={4} spacing={0}>
							<Grid item xs={12}>
								Strike
							</Grid>
							<Grid item xs={12}>
								Ball
							</Grid>
						</Grid>
						<Grid container item xs={4} spacing={0}>
							<Grid item xs={12}>
								{processor.fp_fb_str_sp_fb_pct}% FB (
								{processor.fp_fb_str_sp_fb_ct}/{processor.fp_fb_str_ct})
							</Grid>
							<Grid item xs={12}>
								{processor.fp_fb_bal_sp_fb_pct}% FB (
								{processor.fp_fb_bal_sp_fb_ct}/{processor.fp_fb_bal_ct})
							</Grid>
						</Grid>
					</Grid>
					<Grid container item spacing={0} xs={12}>
						<Grid item xs={4}>
							<div>Offspeed</div>
						</Grid>
						<Grid container item xs={4} spacing={0}>
							<Grid item xs={12}>
								Strike
							</Grid>
							<Grid item xs={12}>
								Ball
							</Grid>
						</Grid>
						<Grid container item xs={4} spacing={0}>
							<Grid item xs={12}>
								{processor.fp_os_str_sp_fb_pct}% FB (
								{processor.fp_os_str_sp_fb_ct}/{processor.fp_os_str_ct})
							</Grid>
							<Grid item xs={12}>
								{processor.fp_os_bal_sp_fb_pct}% FB (
								{processor.fp_os_bal_sp_fb_ct}/{processor.fp_os_bal_ct})
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	)
}
