import { CircularProgress } from "@material-ui/core"
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles"
import axios from "axios"
import { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { FeedbackContext } from "./context/FeedbackContext"
import { GlobalsContext } from "./context/GlobalsContext"
import { PitchType, severity } from "./context/Types"
import { Season } from "./interfaces/SeasonInterface"
import { User } from "./interfaces/UserInterface"
import { AdminPage } from "./pages/AdminPage/AdminPage"
import { CompetitionsPage } from "./pages/CompetitionsPage/CompetitionsPage"
import { GamePage } from "./pages/GamePage/GamePage"
import { HitterPage } from "./pages/HitterPage/HitterPage"
import { HittingPage } from "./pages/HittingPage/HittingPage"
import { HomePage } from "./pages/HomePage/HomePage"
import { LoginPage } from "./pages/LoginPage/LoginPage"
import { OutingPage } from "./pages/OutingPage/OutingPage"
import { TrackOuting } from "./pages/OutingPage/TrackOuting/TrackOuting"
import { PitcherPage } from "./pages/PitcherPage/PitcherPage"
import { PitchingPage } from "./pages/PitchingPage/PitchingPage"
import { PracticePage } from "./pages/PracticePage/PracticePage"
import { ProfilePage } from "./pages/ProfilePage/ProfilePage"
import { ResourcesPage } from "./pages/ResourcesPage/ResourcesPage"
import { ScoutingPage } from "./pages/ScoutingPage/ScoutingPage"
import { TeamScoutPage } from "./pages/ScoutingPage/TeamScoutPage"
import { TeamPage } from "./pages/TeamPage/TeamPage"
import { TeamsPage } from "./pages/TeamsPage/TeamsPage"
import { Feedback } from "./shared/Feedback"
import { NavigationBar } from "./shared/MainLayout/NavigationBar/NavigationBar"
import "./static/css/index.css"
import "./static/fonts/Poppins-Black.ttf"
import "./static/fonts/Poppins-BlackItalic.ttf"
import "./static/fonts/Poppins-Bold.ttf"
import "./static/fonts/Poppins-BoldItalic.ttf"
import "./static/fonts/Poppins-ExtraBold.ttf"
import "./static/fonts/Poppins-ExtraBoldItalic.ttf"
import "./static/fonts/Poppins-ExtraLight.ttf"
import "./static/fonts/Poppins-ExtraLightItalic.ttf"
import "./static/fonts/Poppins-Italic.ttf"
import "./static/fonts/Poppins-Light.ttf"
import "./static/fonts/Poppins-LightItalic.ttf"
import "./static/fonts/Poppins-Medium.ttf"
import "./static/fonts/Poppins-MediumItalic.ttf"
import "./static/fonts/Poppins-Regular.ttf"
import "./static/fonts/Poppins-SemiBold.ttf"
import "./static/fonts/Poppins-SemiBoldItalic.ttf"
import "./static/fonts/Poppins-Thin.ttf"
import "./static/fonts/Poppins-ThinItalic.ttf"

// creates the theme across the application
const theme = createMuiTheme({
	typography: { fontFamily: "Poppins" },
	palette: {
		primary: {
			light: "#bd2e1f",
			main: "#bd2e1f",
			dark: "#bd2e1f",
			contrastText: "#fff",
		},
		secondary: {
			light: "#d3d3d3",
			main: "#d3d3d3",
			dark: "#d3d3d3",
			contrastText: "#000",
		},
	},
})

export const pitchColors: { [key in PitchType]: string } = {
	FB: "#E70B18", // red
	CB: "#2326FA", // blue
	SL: "#118A14", // green
	CH: "#C8C72A", // yellow
	CT: "#21C8C7", // baby blue
	"2S": "#FD8024", // orange
	SPL: "#653896", // purple
	OTH: "#000000", // black
}

export const pitchTypes: PitchType[] = [
	"FB",
	"2S",
	"CB",
	"SL",
	"CH",
	"CT",
	"SPL",
	"OTH",
]

// WashU's teamId
export const teamId = 755

const App = () => {
	// keep track if user is authenticated
	const [auth, setAuth] = useState<boolean>(false)

	// keep track if the user is an admin
	const [admin, setAdmin] = useState<boolean>(false)

	// the current season for the team (id from the db model)
	const [currentSeason, setCurrentSeason] = useState<number | null>(null)

	// the list of all of the seasons
	const [seasons, setSeasons] = useState<Season[]>([])

	// holds the header with a Bearer jwt token for authentication
	const [apiHeader, setApiHeader] = useState<any>(null)

	// whether or not the app is still loading the user
	const [loading, setLoading] = useState<boolean>(true)

	// stores the user themselves for easy access across the app
	const [user, setUser] = useState<User | null>(null)

	// this is a snackbar/alert that drops down from the top to give
	//	the user feedback on an action (usually regarding CRUD operations)
	const [feedback, setFeedback] = useState({
		show: false,
		severity: "success" as severity,
		message: "",
	})

	// runs once on load to get global information for the application
	useEffect(() => {
		// trys to pull the current user if authenticated
		const checkAuth = async () => {
			try {
				const response = await axios.get("/api/user/me", {
					headers: { Authorization: `Bearer ${token}` },
				})
				setAdmin(response.data.admin)
				setUser(response.data)
				setAuth(true)
			} catch (e) {
				setAuth(false)
			}
		}

		// gather the list of all the seasons
		const gatherSeasons = async () => {
			try {
				const response = await axios.get("/api/season/all", {
					headers: { Authorization: `Bearer ${token}` },
				})
				setSeasons(response.data)
			} catch (e) {
				console.log(e)
			}
		}

		// sends a request to API for the current season
		const getCurrentSeason = async () => {
			try {
				const response = await axios.get("/api/season/current", {
					headers: { Authorization: `Bearer ${token}` },
				})
				setCurrentSeason(response.data.id)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}

		// gets the jwt token from local storage if possible
		const token = localStorage.getItem("token")
		if (!token) {
			setAuth(false)
			setLoading(false)
		} else {
			setApiHeader({
				headers: { Authorization: `Bearer ${token}` },
			})
			checkAuth()
			getCurrentSeason()
			gatherSeasons()
		}
	}, [])

	// show a large loader when fetching auth information
	if (loading) {
		return (
			<BrowserRouter>
				<div className="app-loading-div">
					<MuiThemeProvider theme={theme}>
						<CircularProgress className="app-loader" color="primary" />
					</MuiThemeProvider>
				</div>
			</BrowserRouter>
		)
	}

	const setAlert = (message: string, severity: severity) => {
		setFeedback({
			show: true,
			severity,
			message,
		})
	}

	return (
		<BrowserRouter>
			<div>
				<MuiThemeProvider theme={theme}>
					<GlobalsContext.Provider
						value={{
							admin,
							currentSeason,
							teamId,
							apiHeader,
							user,
							seasons,
							setSeasons: (newSeasons: Season[]) => setSeasons(newSeasons),
						}}>
						<FeedbackContext.Provider
							value={{
								setFeedback,
								alertInfo(message: string) {
									setAlert(message, "info")
								},
								alertWarning(message: string) {
									setAlert(message, "warning")
								},
								alertError(message: string) {
									setAlert(message, "error")
								},
								alertSuccess(message: string) {
									setAlert(message, "success")
								},
							}}>
							{auth ? (
								<div>
									<NavigationBar admin={admin} />
									<div id="page-content">
										<Switch>
											<Route path="/pitching" exact component={PitchingPage} />
											<Route path="/hitting" exact component={HittingPage} />
											<Route
												path="/competitions"
												exact
												component={CompetitionsPage}
											/>
											<Route
												path="/practice/:id"
												exact
												component={PracticePage}
											/>
											<Route path="/game/:id" exact component={GamePage} />
											<Route path="/hitter/:id" exact component={HitterPage} />
											<Route path="/teams" exact component={TeamsPage} />
											<Route path="/team/:id" exact component={TeamPage} />
											<Route
												path="/resources"
												exact
												component={ResourcesPage}
											/>
											<Route path="/scouting" exact component={ScoutingPage} />
											<Route
												path="/scouting/:id"
												exact
												component={TeamScoutPage}
											/>
											<Route path="/profile" exact component={ProfilePage} />
											<Route
												path="/pitcher/:id"
												exact
												component={PitcherPage}
											/>
											<Route path="/outing/:id" exact component={OutingPage} />
											{admin && (
												<Route path="/admin" exact component={AdminPage} />
											)}
											{admin && (
												<Route
													path="/outing/:id/track"
													exact
													component={TrackOuting}
												/>
											)}
											<Route path="/" component={HomePage} />
										</Switch>
									</div>
								</div>
							) : (
								<Switch>
									<Route path="/" component={LoginPage} />
								</Switch>
							)}
							<Feedback
								show={feedback.show}
								setShow={() =>
									setFeedback({ ...feedback, show: !feedback.show })
								}
								message={feedback.message}
								severity={feedback.severity}
							/>
						</FeedbackContext.Provider>
					</GlobalsContext.Provider>
				</MuiThemeProvider>
			</div>
		</BrowserRouter>
	)
}

// Not sure if strict mode is necessary but it usually causes some bugs
//	in regards to Modals with material UI when it is enabled so it is off for now

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// )

ReactDOM.render(<App />, document.getElementById("root"))
