import { useContext, useEffect, useState } from "react"

import { useParams } from "react-router-dom"
import axios from "axios"

// components
import { SpraysTab } from "./SpraysTab/SpraysTab"
import { RosterTab } from "./RosterTab/RosterTab"
import { SubnavTemplate } from "../../shared/MainLayout/SubnavTemplate/SubnavTemplate"
import { LoadingBig } from "../../shared/Loading/LoadingBig"

// types and contexts
import { Team } from "../../interfaces/TeamInterface"
import { GlobalsContext } from "../../context/GlobalsContext"

interface ParamTypes {
	id: string
}

export const TeamPage = () => {
	const globals = useContext(GlobalsContext)
	const { id } = useParams<ParamTypes>()

	// while fetching the team
	const [loading, setLoading] = useState<boolean>(true)

	// holds the team (name, id, favorite)
	const [team, setTeam] = useState<Team>()

	// on load, fetch the team
	useEffect(() => {
		const fetchTeam = async () => {
			try {
				const response = await axios.get(`/api/team/${id}`, globals.apiHeader)
				setTeam(response.data)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchTeam()
	}, [])

	if (loading) {
		return <LoadingBig />
	}

	// either team doesn't exist or there was some error
	if (!team) {
		return (
			<div style={{ textAlign: "center", paddingTop: "50px" }}>
				<h1>There was an error loading this team</h1>
			</div>
		)
	}

	const tabs = [
		{
			name: "Roster",
			component: <RosterTab teamId={id} />,
		},
		{
			name: "Sprays",
			component: <SpraysTab teamId={id} />,
		},
	]

	return <SubnavTemplate tabs={tabs} title={team.name} />
}
