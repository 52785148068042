import React, { useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { ButtonApiRequest } from "../../shared/ButtonApiRequest"

// types and contexts
import { ResourceCategory } from "../../interfaces/ResourceCategory"
import { FeedbackContext } from "../../context/FeedbackContext"
import { GlobalsContext } from "../../context/GlobalsContext"
import { ResourcesUpdatedContext } from "./ResourcesPage"
import { Resource } from "../../interfaces/Resource"

interface Props {
	isOpen: boolean
	close: () => void
	category: ResourceCategory
}

export const EditResourceOrderModal = ({ isOpen, close, category }: Props) => {
	const { setFeedback } = useContext(FeedbackContext)
	const globals = useContext(GlobalsContext)

	// once the drag order is done, refetch all resources
	const { refetchResources } = useContext(ResourcesUpdatedContext)

	// holds the dragged and dropped order
	const [order, setOrder] = useState<Resource[]>(category.resources)

	// while adjusting the order, lets the user know that it is loading
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		setOrder(category.resources)
	}, [category.resources])

	// once the "save" button is pressed
	const saveOrder = async () => {
		let index = 0
		setLoading(true)
		for (const resource of order) {
			// if it's not in the right order, send request to api
			if (resource.category_order_num !== index + 1) {
				try {
					await axios.patch(
						`/api/resource/${resource.id}`,
						{ category_order_num: index + 1 },
						globals.apiHeader
					)
				} catch (e) {
					setFeedback({
						show: true,
						severity: "error",
						message: "Something wen't wrong",
					})
					console.log(e)
				}
			}
			index += 1
		}
		close()
		refetchResources()
		setLoading(false)
	}

	// adjusts the state after someone "drops"
	const onDragEnd = ({ ...props }) => {
		const { source, destination } = props

		if (source.index === destination.index) {
			return
		}

		const resource = order[source.index]

		let newOrder = [...order]

		newOrder.splice(source.index, 1)
		newOrder.splice(destination.index, 0, resource)

		setOrder(newOrder)
	}

	return (
		<Dialog fullWidth maxWidth="sm" open={isOpen} onClose={close}>
			<DialogTitle>Edit Order (Drag to reorder below)</DialogTitle>
			<DialogContent style={{ backgroundColor: "#F6F6F6" }}>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId={`droppable`}>
						{(provided) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								{order.map((resource, index) => (
									<div key={resource.id}>
										<Draggable index={index} draggableId={`${resource.id}`}>
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}>
													<br />
													<Card {...provided.dragHandleProps}>
														<CardContent>{resource.title}</CardContent>
													</Card>
												</div>
											)}
										</Draggable>
									</div>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="primary">
					Cancel
				</Button>
				<ButtonApiRequest
					color="primary"
					text="Save"
					loading={loading}
					onClick={saveOrder}
				/>
			</DialogActions>
		</Dialog>
	)
}
