import React, { useContext, useEffect, useState } from "react"

import { Draggable } from "react-beautiful-dnd"

import {
	Card,
	CardContent,
	Collapse,
	Grid,
	Hidden,
	IconButton,
	Tooltip,
	Divider
} from "@material-ui/core"

// Components
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { StatsDropdown } from "./StatsDropDown"

// Interfaces
import { Player } from "../../../interfaces/PlayerInterface"
import { AtBat } from "../../../interfaces/AtBatInterface"

// Util
import { AtBat_processor } from "./../../Classes/HitterAtBatProcessor"


interface Props {
	// pitcher: Player
	atBats: AtBat[]
	name: string
}

export const PitcherCard = ({ atBats, name }: Props) => {
	var rhh_processor = new AtBat_processor(0)
	var lhh_processor = new AtBat_processor(0)
	var total_processor = new AtBat_processor(0)

	const [RHHData, setRHHData] = useState<AtBat_processor>(new AtBat_processor(0))
	const [LHHData, setLHHData] = useState<AtBat_processor>(new AtBat_processor(0))
	const [totalData, setTotalData] = useState<AtBat_processor>(new AtBat_processor(0))

	const [showRHHData, setShowRHHData] = useState<boolean>(false)
	const [showLHHData, setShowLHHData] = useState<boolean>(false)

	const handleShowData = (which: string) => {
		if(which == "RHH"){
			if(showLHHData){setShowLHHData(!showLHHData)}
			setShowRHHData(!showRHHData)
		} else{
			if(showRHHData){setShowRHHData(!showRHHData)}
			setShowLHHData(!showLHHData)
		}
	}

	useEffect(() => {
		lhh_processor.clear()
		rhh_processor.clear()
		total_processor.clear()

		total_processor.process_AtBats(atBats)
		for(var i in atBats){
			let ab = atBats[i]
			if(ab.player.bats){
				if(ab.player.bats == "L"){
					lhh_processor.process_AtBat(ab)
				} else {
					rhh_processor.process_AtBat(ab)
				}
			}
		}

		lhh_processor.do_calculations()
		rhh_processor.do_calculations()
		total_processor.do_calculations()

		setRHHData(rhh_processor)
		setLHHData(lhh_processor)
		setTotalData(total_processor)

	}, [atBats])

	return (
		<Card>
			<CardContent>
				<Grid container spacing={0}>
					<Grid container item xs={12} spacing={0}>
						<Grid item xs={12} sm={4} className="align-center">
							<Grid container spacing={0} >
								<Grid item xs={12}>
									<div>PITCHER</div>
									{/* <div>#{pitcher.number} {pitcher.firstname}{" "}{pitcher.lastname}</div> */}
									<div>{name}</div>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6} sm={2} className="align-center">
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<div>PITCHES</div>
									<div>{totalData.total_pitches}</div>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6} sm={2} className="align-center">
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<div>BB</div>
									<div>{totalData.num_walks}</div>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6} sm={2} className="align-center">
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<div>Hits</div>
									<div>{totalData.num_hits}</div>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6} sm={2} className="align-center">
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<div>K</div>
									<div>{totalData.num_ks}</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container item xs={12} spacing={0} justify="center">
						<Grid item xs={5} className="align-center">
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<span>LHH ({LHHData.num_batters})</span>
									<IconButton onClick={()=> {handleShowData("LHH")}}>
										{showLHHData ? (
											<ExpandLessIcon />
										) : (
											<ExpandMoreIcon />
										)}
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={1}>
							<Divider orientation="vertical" component="div" />
						</Grid>
						<Grid item xs={5} className="align-center">
							<Grid container spacing={0}>
								<Grid item xs={12}>
									<span>RHH ({RHHData.num_batters})</span>
									<IconButton onClick={() => {handleShowData("RHH")}}>
										{showRHHData ? (
											<ExpandLessIcon />
										) : (
											<ExpandMoreIcon />
										)}
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
			<Collapse in={showLHHData} timeout={0} unmountOnExit>
				<StatsDropdown processor={LHHData} />
			</Collapse>
			<Collapse in={showRHHData} timeout={0} unmountOnExit>
				<StatsDropdown processor={RHHData} />
			</Collapse>
		</Card>
	)
}