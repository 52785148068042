import { SubnavTemplate } from "../../shared/MainLayout/SubnavTemplate/SubnavTemplate"
import "../../static/css/AdminPage.css"
import { ResourcesTab } from "./ResourcesTab/ResourcesTab"
import { SeasonsTab } from "./SeasonsTab/SeasonsTab"
import { UsersTab } from "./UsersTab/UsersTab"
import { VideosTab, VideosTabContextProvider } from "./VideosTab"

export const AdminPage = () => {
	const tabs = [
		{
			name: "Videos",
			component: (
				<VideosTabContextProvider>
					<VideosTab />
				</VideosTabContextProvider>
			),
		},
		{ name: "Seasons", component: SeasonsTab },
		{ name: "Users", component: UsersTab },
		{ name: "Resources", component: ResourcesTab },
	]

	return <SubnavTemplate title="Admin" tabs={tabs} />
}
