import { useContext, useState } from "react"

import axios from "axios"

// components
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core"
import { ButtonApiRequest } from "../../shared/ButtonApiRequest"

// types and contexts
import { FeedbackContext } from "../../context/FeedbackContext"
import { GlobalsContext } from "../../context/GlobalsContext"
import { Resource } from "../../interfaces/Resource"
import { ResourcesUpdatedContext } from "./ResourcesPage"

interface Props {
	isOpen: boolean
	close: () => void
	resource: Resource
}

interface FormValues {
	title: string
	description: string
	link: string
	categoryId: number
}

export const EditResourceModal = ({ isOpen, close, resource }: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)

	// refetch is to reflect to the changes of the updated resource after "Save" is pressed
	//  categories is used for the "Select" menu if the user wants to change the category
	const { refetchResources, categories } = useContext(ResourcesUpdatedContext)

	// true while sending request to server
	const [loading, setLoading] = useState<boolean>(false)

	// holds the resource information and changes from user input
	const intitialFormValues: FormValues = {
		categoryId: resource.category_id,
		title: resource.title,
		description: resource.description,
		link: resource.link,
	}
	const [formValues, setFormValues] = useState<FormValues>(intitialFormValues)

	// either "cancel" is pressed or error sending request to server
	const cancel = () => {
		close()
		setFormValues(intitialFormValues)
	}

	const createResource = async () => {
		// titles are required for resources
		if (!formValues.title) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please provide a title",
			})
		}

		// if the resource is a link, make sure a link is present
		if (!formValues.link && resource.link) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please provide a link",
			})
		}

		setLoading(true)

		// attempt to edit the resource
		try {
			await axios.patch(
				`/api/resource/${resource.id}`,
				{
					title: formValues.title,
					description: formValues.description,
					link: formValues.link,
					category_id: formValues.categoryId,
				},
				globals.apiHeader
			)
			setFeedback({
				show: true,
				severity: "success",
				message: "Resource saved!",
			})
			refetchResources()
			close()
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message: "Something wen't wrong. Please try again.",
			})
			cancel()
		}
		setLoading(false)
	}

	return (
		<Dialog fullWidth maxWidth="sm" open={isOpen} onClose={cancel}>
			<DialogTitle>Edit Resource</DialogTitle>
			<DialogContent>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<FormControl required fullWidth>
							<InputLabel>Category</InputLabel>
							<Select
								required
								label="Category"
								value={formValues.categoryId}
								onChange={(event) =>
									setFormValues({
										...formValues,
										categoryId: event.target.value as number,
									})
								}>
								{categories.map((category, i) => (
									<MenuItem key={category.id} value={category.id}>
										{category.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} className="padding-top-12">
						<TextField
							required
							value={formValues.title}
							onChange={(event) =>
								setFormValues({
									...formValues,
									title: event.target.value,
								})
							}
							id="title"
							label="Title"
							type="text"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} className="padding-top-12">
						<TextField
							value={formValues.description}
							onChange={(event) =>
								setFormValues({
									...formValues,
									description: event.target.value,
								})
							}
							id="description"
							label="Description"
							type="text"
							fullWidth
						/>
					</Grid>
					{resource.link && (
						<Grid item xs={12} className="padding-top-12">
							<TextField
								required
								value={formValues.link}
								onChange={(event) =>
									setFormValues({
										...formValues,
										link: event.target.value,
									})
								}
								id="link"
								label="Link"
								type="text"
								fullWidth
							/>
						</Grid>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={cancel} color="primary">
					Cancel
				</Button>
				<ButtonApiRequest
					text="Save"
					loading={loading}
					onClick={createResource}
					color="primary"
				/>
			</DialogActions>
		</Dialog>
	)
}
