import React, { useContext, useEffect, useState } from "react"

import axios from "axios"

// to format date of outing
import moment from "moment"

import {
	Button,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Select,
} from "@material-ui/core"
import VisibilityIcon from "@material-ui/icons/Visibility"

// contexts, types, etc.
import { Context } from "../../TrackTab"
import { GlobalsContext } from "../../../../../../context/GlobalsContext"
import { dispatchError, dispatchWarning } from "../../utils/DispatchFeedback"

// styling
import "../../../../../../static/css/TrackTab/Header.css"
import { Redirect } from "react-router-dom"
import { Outing } from "../../../../../../interfaces/OutingInterface"
import { UndoButtonHandler } from "../../utils/UndoButtonHandler"
import { Player } from "../../../../../../interfaces/PlayerInterface"
import { ChooseHandedness } from "./ChooseHandedness"

export const Header = () => {
	const globals = useContext(GlobalsContext)
	const { state, setState, setLoading } = useContext(Context)
	const [exit, setExit] = useState<boolean>(false)
	const [undoDisabled, setUndoDisabled] = useState<boolean>(false)
	const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null)
	const [chooseHandednessModal, setChooseHandednessModal] =
		useState<boolean>(false)

	// format date MM/DD/YY
	const getOutingInfo = () => {
		let momentObj
		if (state.outing!.practice) {
			momentObj = moment(state.outing!.practice!.date)
			return `${momentObj.format("M/D/YY")} PRACTICE`
		} else {
			momentObj = moment(state.outing!.game!.date)
			if (state.outing) {
				if (state.outing.game) {
					if (state.outing.game.away_team.id === state.outing.pitcher.team_id) {
						return `${momentObj.format("M/D/YY")} @ ${
							state.outing.game.home_team.name
						}`
					} else {
						return `${momentObj.format("M/D/YY")} @ ${
							state.outing.game.away_team.name
						}`
					}
				}
			}
			return `${momentObj.format("M/D/YY")} GAME`
		}
	}
	const outingInfo = getOutingInfo()

	const getBattersTeamId = (outing: Outing) => {
		if (outing.practice) {
			return globals.teamId!
		} else {
			if (outing.pitcher.team_id === outing.game!.home_team.id) {
				return outing.game!.away_team.id
			} else {
				return outing.game!.home_team.id
			}
		}
	}

	// fetches the batters for the outing on first render
	useEffect(() => {
		const fetchBatters = async () => {
			const battersTeamId = getBattersTeamId(state.outing!)
			try {
				const response = await axios.get(
					`/api/player?team_id=${battersTeamId}&active=true&order_by=${
						globals.teamId === battersTeamId ? "lastname" : "number"
					}`,
					globals.apiHeader
				)
				setState({
					...state,
					allBatters: response.data,
				})
			} catch (e) {
				dispatchError(
					state,
					setState,
					"Something wen't wrong loading the batters"
				)
			}
		}
		fetchBatters()
	}, [])

	useEffect(() => {
		if (state.outing) {
			setUndoDisabled(state.outing.at_bats.length === 0)
		}
	}, [state.outing])

	// handle a change to the current batter
	const handleBatterChange = (selectedBatter: number) => {
		if (state.currentAtBat) {
			return dispatchWarning(
				state,
				setState,
				"You can't change a batter in the middle of an at bat"
			)
		}
		setState({ ...state, selectedBatter })
		checkForHandedness(selectedBatter)
	}

	// if the batter doesn't have a R/L in their "bats" property
	const checkForHandedness = (batterId: number) => {
		const batter = state.allBatters.find((batter) => batter.id === batterId)
		if (batter) {
			setSelectedPlayer(batter)
			if (!batter.bats) {
				// ask user to choose Righty or Lefty
				setChooseHandednessModal(true)
			}
		}
	}

	// handle exitting out of tracker
	const handleExit = () => {
		setExit(true)
	}

	const handleUndo = () => {
		UndoButtonHandler(state, setState, globals.apiHeader, setLoading)
	}

	if (exit) {
		return <Redirect push to={`/outing/${state.outing!.id}`} />
	}

	return (
		<Paper elevation={0} className="pt-header-paper">
			<Grid className="pt-header" container spacing={0}>
				<Grid item xs={4} className="pt-exit-button-div">
					<Button
						onClick={handleExit}
						variant="contained"
						color="primary"
						disableElevation>
						Exit
					</Button>
					&nbsp;&nbsp;
					<Button
						onClick={handleUndo}
						variant="outlined"
						color="primary"
						disableElevation
						disabled={undoDisabled}>
						Undo
					</Button>
				</Grid>
				<Grid item xs={4} className="align-center">
					<div id="pt-header-pitcher-name">
						{state.outing!.pitcher.firstname} {state.outing!.pitcher.lastname} #
						{state.outing!.pitcher.number}
					</div>
					<div id="pt-header-outing-info">{outingInfo}</div>
				</Grid>
				<Grid item xs={4} className="align-right">
					<IconButton
						onClick={() => setState({ ...state, showBatterGameResults: true })}
						style={{ marginTop: "5px" }}>
						<VisibilityIcon />
					</IconButton>
					&nbsp;
					<FormControl variant="outlined">
						<InputLabel>At Bat</InputLabel>
						<Select
							className="pt-header-select-batter"
							autoWidth
							value={state.selectedBatter}
							defaultValue={""}
							onChange={(event) =>
								handleBatterChange(event.target.value as number)
							}
							label="At Bat">
							<MenuItem value=""></MenuItem>
							{state.allBatters.map((batter) => (
								<MenuItem key={batter.id} value={batter.id}>
									{batter.lastname} #{batter.number}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
			</Grid>
			{selectedPlayer && (
				<ChooseHandedness
					open={chooseHandednessModal}
					batter={selectedPlayer}
					resolved={(bats: "R" | "L" | "S") => setChooseHandednessModal(false)}
					notResolved={() => {
						setChooseHandednessModal(false)
						handleBatterChange("" as unknown as number)
					}}
				/>
			)}
		</Paper>
	)
}
