interface DataPoint {
  label: string
  value: string | number | boolean
}

export const GenerateTooltipHTML = (dataPoints: DataPoint[]) => {
  let html = `<div class="tooltip-div MuiGrid-root MuiGrid-container">`
  dataPoints.forEach(dataPoint => {
    html += `
      <div class="tooltip-key MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6">
        ${dataPoint.label}:
      </div>
      <div class="tooltip-value MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6">
        ${dataPoint.value}
      </div>
    `
  })
  html += "</div>"
  return html
}