import axios from "axios"
import { initialState, State } from "./Constants"
import { updateOutingState } from "./UpdateOutingState"
import { dispatchError, dispatchWarning } from "./DispatchFeedback"

export const UndoButtonHandler = async (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	apiHeader: any,
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
	setLoading(true)
	try {
		if (state.currentAtBat) {
			if (state.currentAtBat.pitches) {
				if (state.currentAtBat.pitches.length === 1) {
					await deleteAtBatAndLastPitch(state, setState, apiHeader)
				} else {
					await deleteLastPitch(state, setState, apiHeader)
				}
			}
		} else {
			await deleteLastPitchAndAtBatResult(state, setState, apiHeader)
		}
	} catch (e) {
		console.log(e)
		dispatchError(state, setState, "Something wen't wrong")
	}
	setLoading(false)
}

const deleteLastPitch = async (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	apiHeader: any
) => {
	try {
		const lastPitch = state.currentAtBat!.pitches![
			state.currentAtBat!.pitches!.length - 1
		]
		await axios.delete(`/api/pitch/${lastPitch.id}`, apiHeader)
		await updateOutingState(state, setState, apiHeader, {
			...state.pitchEntry,
			xLoc: lastPitch.loc_x ? lastPitch.loc_x : null,
			yLoc: lastPitch.loc_y ? lastPitch.loc_y : null,
			hitSpot: lastPitch.hit_spot !== undefined ? lastPitch.hit_spot : false,
			pitchType: lastPitch.pitch_type,
			pitchResult: lastPitch.pitch_result,
			velocity: lastPitch.velocity ? lastPitch.velocity : "",
			timeToPlate: lastPitch.time_to_plate ? lastPitch.time_to_plate : "",
			rollThrough:
				lastPitch.roll_through !== undefined ? lastPitch.roll_through : false,
			shortSet: lastPitch.short_set !== undefined ? lastPitch.short_set : false,
			notes: lastPitch.notes ? lastPitch.notes : "",
			runnerFirst: lastPitch.runner_1 ? lastPitch.runner_1 : false,
			runnerSecond: lastPitch.runner_2 ? lastPitch.runner_2 : false,
			runnerThird: lastPitch.runner_3 ? lastPitch.runner_3 : false,
		})
	} catch (e) {
		console.log(e)
		dispatchError(state, setState, "Something wen't wrong. Please try again.")
	}
}

const deleteAtBatAndLastPitch = async (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	apiHeader: any
) => {
	try {
		const lastPitch = state.currentAtBat!.pitches![
			state.currentAtBat!.pitches!.length - 1
		]
		const lastAtBat = state.currentAtBat!
		await axios.delete(`/api/atbat/${lastAtBat.id}`, apiHeader)
		await updateOutingState(
			state,
			setState,
			apiHeader,
			{
				...state.pitchEntry,
				xLoc: lastPitch.loc_x ? lastPitch.loc_x : null,
				yLoc: lastPitch.loc_y ? lastPitch.loc_y : null,
				hitSpot: lastPitch.hit_spot !== undefined ? lastPitch.hit_spot : false,
				pitchType: lastPitch.pitch_type,
				pitchResult: lastPitch.pitch_result,
				velocity: lastPitch.velocity ? lastPitch.velocity : "",
				timeToPlate: lastPitch.time_to_plate ? lastPitch.time_to_plate : "",
				rollThrough:
					lastPitch.roll_through !== undefined ? lastPitch.roll_through : false,
				shortSet:
					lastPitch.short_set !== undefined ? lastPitch.short_set : false,
				notes: lastPitch.notes ? lastPitch.notes : "",
				runnerFirst: lastPitch.runner_1 ? lastPitch.runner_1 : false,
				runnerSecond: lastPitch.runner_2 ? lastPitch.runner_2 : false,
				runnerThird: lastPitch.runner_3 ? lastPitch.runner_3 : false,
			},
			lastAtBat.batter
		)
	} catch (e) {
		console.log(e)
		dispatchError(state, setState, "Something wen't wrong. Please try again.")
	}
}

const deleteLastPitchAndAtBatResult = async (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	apiHeader: any
) => {
	try {
		const lastAtBat = state.outing!.at_bats[state.outing!.at_bats.length - 1]
		const lastPitch = lastAtBat.pitches![lastAtBat.pitches!.length - 1]
		await axios.delete(
			`/api/pitch/${lastAtBat.pitches![lastAtBat.pitches!.length - 1].id}`,
			apiHeader
		)
		await axios.patch(
			`/api/atbat/${lastAtBat.id}`,
			{
				result: null,
				traj: null,
				fielder: null,
				spray_x: null,
				spray_y: null,
				hit_hard: null,
				batter_out: null,
			},
			apiHeader
		)
		await updateOutingState(state, setState, apiHeader, {
			...state.pitchEntry,
			xLoc: lastPitch.loc_x ? lastPitch.loc_x : null,
			yLoc: lastPitch.loc_y ? lastPitch.loc_y : null,
			hitSpot: lastPitch.hit_spot !== undefined ? lastPitch.hit_spot : false,
			pitchType: lastPitch.pitch_type,
			pitchResult: lastPitch.pitch_result,
			velocity: lastPitch.velocity ? lastPitch.velocity : "",
			timeToPlate: lastPitch.time_to_plate ? lastPitch.time_to_plate : "",
			rollThrough:
				lastPitch.roll_through !== undefined ? lastPitch.roll_through : false,
			shortSet: lastPitch.short_set !== undefined ? lastPitch.short_set : false,
			notes: lastPitch.notes ? lastPitch.notes : "",
			runnerFirst: lastPitch.runner_1 ? lastPitch.runner_1 : false,
			runnerSecond: lastPitch.runner_2 ? lastPitch.runner_2 : false,
			runnerThird: lastPitch.runner_3 ? lastPitch.runner_3 : false,
		})
	} catch (e) {
		console.log(e)
		dispatchError(state, setState, "Something wen't wrong. Please try again.")
	}
}
