import React, { useState } from "react"

import axios from "axios"

// components
import { TextField, Paper, Grid } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { ButtonApiRequest } from "../../shared/ButtonApiRequest"

// static
import "./LoginPage.css"
import logo1 from "../../static/img/BearHeadColor.png"
import logo2 from "../../static/img/WashUColor.png"

export const LoginPage = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [email, setEmail] = useState<string>("")
	const [password, setPassword] = useState<string>("")

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoading(true)
		try {
			const response = await axios.post("/api/user/login", { email, password })
			setError(false)
			localStorage.setItem("token", response.data.token)
			window.location.href = "/"
		} catch (e) {
			setError(true)
		}
		setLoading(false)
	}

	return (
		<Paper className="login-paper" elevation={4}>
			<form onSubmit={(event) => handleSubmit(event)} noValidate>
				<Grid container spacing={0}>
					<Grid item xs={6}>
						<img src={logo1} className="login-logo" />
					</Grid>
					<Grid item xs={6} className="align-right">
						<img src={logo2} className="login-logo" />
					</Grid>
					<Grid item xs={12} className="align-center">
						<h1>WashU Baseball</h1>
						{error && (
							<Alert severity="error">Email or password incorrect!</Alert>
						)}
					</Grid>
					<Grid item xs={12}>
						<TextField
							onChange={(event) => setEmail(event.target.value)}
							value={email}
							variant="outlined"
							margin="normal"
							fullWidth
							id="email"
							label="Email"
							name="email"
							autoComplete="email"
							autoFocus
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							onChange={(event) => setPassword(event.target.value)}
							value={password}
							variant="outlined"
							margin="normal"
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
					</Grid>
					<Grid item xs={12} className="login-submit-button-div">
						<ButtonApiRequest
							size="large"
							fullWidth
							type="submit"
							loading={loading}
							text="LOGIN"
							color="primary"
							onClick={() => console.log("")}
						/>
					</Grid>
				</Grid>
			</form>
		</Paper>
	)
}
