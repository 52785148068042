import { useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	TextField,
	DialogActions,
	Button,
	CircularProgress,
} from "@material-ui/core"
import { TransitionUp } from "../../shared/ModalTransitions/TransitionUp"
import { SeasonSelector } from "../../shared/SeasonSelector"

// types and contexts
import { GlobalsContext } from "../../context/GlobalsContext"
import { FeedbackContext } from "../../context/FeedbackContext"
import { Outing } from "../../interfaces/OutingInterface"
import { Game } from "../../interfaces/GameInterface"
import { Practice } from "../../interfaces/PracticeInterface"
import { Player } from "../../interfaces/PlayerInterface"

// util functions
import { formatDate } from "../../shared/Functions/FormatDate"
import { LoadingBig } from "../../shared/Loading/LoadingBig"

interface Props {
	outing: Outing
	isOpen: boolean
	close: () => void
	refetchOuting: () => void
}

interface FormState {
	pitcher: number
	season: number | ""
	compType: "game" | "practice"
	practice: number | ""
	game: number | ""
}

export const EditOutingModal = ({
	outing,
	isOpen,
	close,
	refetchOuting,
}: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)

	// disables the save button and shows loading indicator
	const [loading, setLoading] = useState<boolean>(false)

	// holds all of the games the user may choose from
	const [games, setGames] = useState<Game[]>([])

	// holds all of the practices the user may choose from
	const [practices, setPractices] = useState<Practice[]>([])

	// holds all of the pitchers the user may choose from
	const [pitchers, setPitchers] = useState<Player[]>([])

	// holding form information on editting the outing
	const initialFormState = {
		pitcher: outing.pitcher.id,
		season: outing.season_id as number | "",
		compType: outing.game
			? ("game" as "game" | "practice")
			: ("practice" as "game" | "practice"),
		practice: outing.practice
			? (outing.practice.id as number | "")
			: ("" as number | ""),
		game: outing.game ? (outing.game.id as number | "") : ("" as number | ""),
	}
	const [formState, setFormState] = useState<FormState>(initialFormState)

	// run on load to fetch all the active pitchers for this team
	useEffect(() => {
		const fetchPitchers = async () => {
			try {
				const response = await axios.get(
					`/api/player?team_id=${outing.pitcher.team_id}&active=true`,
					globals.apiHeader
				)
				setPitchers(response.data)
			} catch (e) {
				setFeedback({
					show: true,
					severity: "error",
					message: "Something wen't wrong",
				})
				console.log(e)
			}
		}
		fetchPitchers()
	}, [])

	// runs when the season changes to fetch games or practices
	useEffect(() => {
		const fetchGames = async () => {
			if (formState.season) {
				try {
					let teamId = outing.pitcher.team_id
					if (outing.game && teamId === globals.teamId) {
						if (outing.game.home_team.id === teamId) {
							teamId = outing.game.away_team.id
						} else {
							teamId = outing.game.home_team.id
						}
					}
					const response = await axios.get(
						`/api/game?season_id=${formState.season}&team_id=${teamId}`,
						globals.apiHeader
					)
					setGames(response.data)
					if (formState.season === outing.season_id && outing.game) {
						setFormState({
							...formState,
							practice: "",
							game: outing.game.id,
						})
					} else {
						setFormState({
							...formState,
							practice: "",
							game: response.data.length > 0 ? response.data[0].id : "",
						})
					}
				} catch (e) {
					console.log(e)
					setFeedback({
						show: true,
						severity: "error",
						message: "Something went wrong",
					})
				}
			}
		}

		const fetchPractices = async () => {
			if (formState.season) {
				try {
					const response = await axios.get(
						`/api/practice?season_id=${formState.season}`,
						globals.apiHeader
					)
					setPractices(response.data)
					if (formState.season === outing.season_id && outing.practice) {
						setFormState({
							...formState,
							game: "",
							practice: outing.practice.id,
						})
					} else {
						setFormState({
							...formState,
							game: "",
							practice: response.data.length > 0 ? response.data[0].id : "",
						})
					}
				} catch (e) {
					console.log(e)
					setFeedback({
						show: true,
						severity: "error",
						message: "Something went wrong",
					})
				}
			}
		}

		if (formState.compType === "game") {
			fetchGames()
			setPractices([])
		} else {
			fetchPractices()
			setGames([])
		}
	}, [formState.compType, formState.season])

	// when the user presses the "Save" button
	const handleEdit = async () => {
		if (
			!formState.pitcher ||
			!formState.season ||
			(!formState.game && !formState.practice)
		) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please fill out all required fields below",
			})
		}
		setLoading(true)
		try {
			await axios.patch(
				`/api/outing/${outing.id}`,
				{
					pitcher_id: formState.pitcher,
					season_id: formState.season,
					practice_id: formState.practice === "" ? null : formState.practice,
					game_id: formState.game === "" ? null : formState.game,
				},
				globals.apiHeader
			)
			setLoading(false)
			close()
			setFeedback({
				show: true,
				severity: "success",
				message: "Outing saved!",
			})
			refetchOuting()
		} catch (e) {
			setFeedback({
				show: true,
				message: "Something wen't wrong",
				severity: "error",
			})
			console.log(e)
			setLoading(false)
		}
	}

	// handle the modal closing
	const handleClose = () => {
		close()
		setFormState(initialFormState)
	}

	if (practices.length === 0 && games.length === 0) {
		return <LoadingBig />
	}

	return (
		<>
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
				TransitionComponent={TransitionUp}
				onClose={handleClose}>
				<DialogTitle>Edit Outing</DialogTitle>
				<DialogContent>
					<Grid container spacing={1}>
						<Grid item xs={12} style={{ marginTop: "8px" }}>
							<FormControl className="full-width">
								<InputLabel>Select Pitcher</InputLabel>
								<Select
									value={formState.pitcher}
									onChange={(event) => {
										setFormState({
											...formState,
											pitcher: event.target.value as number,
										})
									}}>
									{pitchers.map((pitcher: Player) => (
										<MenuItem key={pitcher.id} value={pitcher.id}>
											{pitcher.number && <>#{pitcher.number} </>}
											{pitcher.firstname && <>{pitcher.firstname} </>}
											{pitcher.lastname}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} style={{ marginTop: "8px" }}>
							<SeasonSelector
								fullWidth
								required={true}
								variant="standard"
								defaultValue={formState.season as number}
								onChange={(season) => setFormState({ ...formState, season })}
							/>
						</Grid>
						{formState.compType === "game" ? (
							<Grid item xs={12} style={{ marginTop: "8px" }}>
								<FormControl className="full-width">
									<InputLabel>Select Game</InputLabel>
									<Select
										value={formState.game}
										onChange={(event) => {
											setFormState({
												...formState,
												game: event.target.value as number,
											})
										}}>
										{games.map((game: Game) => (
											<MenuItem key={game.id} value={game.id}>
												{formatDate(game.date as string)}{" "}
												{game.home_team.id === globals.teamId
													? `vs. ${game.away_team.name}`
													: `@ ${game.home_team.name}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						) : (
							<Grid item xs={12} style={{ marginTop: "8px" }}>
								<FormControl className="full-width">
									<InputLabel>Select Practice</InputLabel>
									<Select
										defaultValue={formState.practice}
										value={formState.practice}
										onChange={(event) => {
											setFormState({
												...formState,
												practice: event.target.value as number,
											})
										}}>
										{practices.map((practice: Practice) => (
											<MenuItem
												key={practice.id}
												value={practice.id}
												selected={practice.id === formState.practice}>
												{formatDate(practice.date as string)}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color="primary" disableElevation onClick={handleClose}>
						Cancel
					</Button>
					<div style={{ position: "relative" }}>
						<Button
							style={{ opacity: loading ? "0.5" : "1.0" }}
							variant="contained"
							color="primary"
							disableElevation
							onClick={handleEdit}>
							Save Changes
						</Button>
						{loading && (
							<CircularProgress
								size={24}
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									marginTop: -12,
									marginLeft: -12,
								}}
							/>
						)}
					</div>
				</DialogActions>
			</Dialog>
		</>
	)
}
