import React, { useContext } from "react"

import { Grid, TextField } from "@material-ui/core"

import { Context } from "../../TrackTab"
import { ToggleButton } from "@material-ui/lab"

export const NotesEntry = () => {
	const { state, setState } = useContext(Context)

	const handlePitchNotesChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setState({
			...state,
			pitchEntry: {
				...state.pitchEntry,
				notes: event.target.value,
			},
		})
	}

	const handleAtBatNotesChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setState({
			...state,
			atBatEntry: {
				...state.atBatEntry,
				notes: event.target.value,
			},
		})
	}

	// toggle the hit spot button
	const changeHitSpot = () => {
		setState({
			...state,
			pitchEntry: {
				...state.pitchEntry,
				hitSpot: !state.pitchEntry.hitSpot,
			},
		})
	}

	return (
		<div>
			<Grid container spacing={0}>
				<Grid item xs={8} className="pt-notes-div">
					<TextField
						className="pt-notes-inputs"
						value={state.pitchEntry.notes}
						label="Pitch Notes"
						type="text"
						variant="outlined"
						onChange={handlePitchNotesChange}
					/>
				</Grid>
				<Grid item xs={2} className="pt-notes-div align-right">
					<ToggleButton
						style={{ height: "56px", width: "90%" }}
						value={state.pitchEntry.rollThrough}
						selected={state.pitchEntry.rollThrough}
						className="pt-toggle-button"
						onChange={() =>
							setState({
								...state,
								pitchEntry: {
									...state.pitchEntry,
									rollThrough: !state.pitchEntry.rollThrough,
									shortSet: false,
								},
							})
						}>
						RT
					</ToggleButton>
				</Grid>
				<Grid item xs={2} className="pt-notes-div align-right">
					<ToggleButton
						style={{ height: "56px", width: "90%" }}
						value={state.pitchEntry.shortSet}
						selected={state.pitchEntry.shortSet}
						className="pt-toggle-button"
						onChange={() =>
							setState({
								...state,
								pitchEntry: {
									...state.pitchEntry,
									rollThrough: false,
									shortSet: !state.pitchEntry.shortSet,
								},
							})
						}>
						SS
					</ToggleButton>
				</Grid>
				<Grid item xs={8} className="pt-notes-div">
					<TextField
						className="pt-notes-inputs"
						label="At Bat Notes"
						type="text"
						variant="outlined"
						value={state.atBatEntry.notes}
						onChange={handleAtBatNotesChange}
					/>
				</Grid>
				<Grid item xs={4} className="pt-notes-div align-right">
					<ToggleButton
						value={state.pitchEntry.hitSpot}
						selected={state.pitchEntry.hitSpot}
						className="pt-toggle-button"
						style={{ height: "56px", width: "95%" }}
						onChange={changeHitSpot}>
						HIT SPOT
					</ToggleButton>
				</Grid>
			</Grid>
		</div>
	)
}
