import { useState, useContext } from "react"

import axios from "axios"

// components
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Switch,
	Grid,
} from "@material-ui/core"

// types and contexts
import { GlobalsContext } from "../../../context/GlobalsContext"

interface Props {
	isOpen: boolean
	close: () => void
	teamId: string
}

export const NewPlayerModal = ({ isOpen, close, teamId }: Props) => {
	const globals = useContext(GlobalsContext)

	// holds the values of the modal form to create a new player
	const [formValues, setFormValues] = useState<{ [key: string]: any }>({
		firstname: "",
		lastname: "",
		number: "",
		bats: "",
		throws: "",
		year: "",
		position: "",
		pitcher: false,
		batter: false,
	})

	// change the formValues state hook when the user types
	const formChange = (field: string, newValue: any) => {
		const newValues = { ...formValues }
		newValues[field] = newValue
		setFormValues(newValues)
	}

	// add the new player to the database
	const addPlayer = async () => {
		try {
			await axios.post(
				"/api/player",
				{ team_id: teamId, ...formValues },
				globals.apiHeader
			)
			resetFormValues()
		} catch (e) {
			console.log(e)
		}
		close()
	}

	// resets the form back to normal
	const resetFormValues = () => {
		setFormValues({
			firstname: "",
			lastname: "",
			number: "",
			bats: "",
			throws: "",
			year: "",
			position: "",
			pitcher: false,
			batter: false,
		})
	}

	const cancel = () => {
		close()
		resetFormValues()
	}

	return (
		<div>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={isOpen}
				onClose={cancel}
				aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Create Player</DialogTitle>
				<DialogContent>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<TextField
								style={{ marginTop: "12px" }}
								value={formValues.firstname}
								onChange={(event) =>
									formChange("firstname", event.target.value)
								}
								id="firstname"
								label="Firstname"
								type="text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={formValues.lastname}
								onChange={(event) => formChange("lastname", event.target.value)}
								style={{ marginTop: "12px" }}
								id="lastname"
								label="Lastname"
								type="text"
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={formValues.number}
								onChange={(event) => formChange("number", event.target.value)}
								style={{ marginTop: "12px" }}
								id="number"
								label="Number"
								type="text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={formValues.bats}
								onChange={(event) => formChange("bats", event.target.value)}
								style={{ marginTop: "12px" }}
								id="bats"
								label="Bats"
								type="text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={formValues.throws}
								onChange={(event) => formChange("throws", event.target.value)}
								style={{ marginTop: "12px" }}
								id="throws"
								label="Throws"
								type="text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={formValues.year}
								onChange={(event) => formChange("year", event.target.value)}
								style={{ marginTop: "12px" }}
								id="year"
								label="Year"
								type="text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={formValues.position}
								onChange={(event) => formChange("position", event.target.value)}
								style={{ marginTop: "12px" }}
								id="position"
								label="Position"
								type="text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControlLabel
								style={{ marginTop: "20px" }}
								control={
									<Switch
										color="primary"
										onChange={(event) =>
											formChange("pitcher", event.target.checked)
										}
										value={formValues.pitcher}
									/>
								}
								label="Pitcher?"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControlLabel
								style={{ marginTop: "20px" }}
								control={
									<Switch
										color="primary"
										onChange={(event) =>
											formChange("batter", event.target.checked)
										}
										value={formValues.batter}
									/>
								}
								label="Batter?"
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={cancel} color="primary">
						Cancel
					</Button>
					<Button onClick={addPlayer} color="primary">
						Create
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
