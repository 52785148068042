import { useContext, useEffect, useState } from "react"

import axios from "axios"
import { svgAsPngUri } from "save-svg-as-png"
import { pdf } from "@react-pdf/renderer"

// other components used
import { Container, Grid, IconButton, Tooltip } from "@material-ui/core"
import { LoadingBig } from "../../Loading/LoadingBig"
import { NewOutingModal } from "./NewOutingModal"
import { OutingTable } from "./OutingTable"
import { HomeAwayToggle } from "../../HomeAwayToggle"
import { OutingReport } from "../../../reports/OutingReport"
import { VeloChart } from "../../../pages/OutingPage/SummaryTab/VeloChart"
import { LocationsD3 } from "../../../pages/OutingPage/LocationsTab/LocationsD3"
import AddIcon from "@material-ui/icons/Add"
import EmailIcon from "@material-ui/icons/Email"

// types and contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { Outing } from "../../../interfaces/OutingInterface"
import { Game } from "../../../interfaces/GameInterface"
import { Practice } from "../../../interfaces/PracticeInterface"
import { Pitch } from "../../../interfaces/PitchInterface"
import { FeedbackContext } from "../../../context/FeedbackContext"

interface Props {
	id: number
	variant: "practice" | "game"
}

const pitchTypes = ["FB", "2S", "CB", "SL", "CH", "CT", "SPL", "OTH"]

export const PitchingTab = ({ id, variant }: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)
	const [loading, setLoading] = useState<boolean>(true)

	// this stores all of the outings for the game or practice
	const [outings, setOutings] = useState<Outing[]>([])

	// this stores either all of the outings (practice) or home/away (game)
	const [filteredOutings, setFilteredOutings] = useState<Outing[]>([])

	// determines whether or not to open the modal to create a new outing
	const [newOutingModal, setNewOutingModal] = useState<boolean>(false)

	// if variant is a game, controls whether the user is looking at home or away
	const [whichTeam, setWhichTeam] = useState<"home" | "away">("home")

	// this is the teamId associated with Home/Away
	const [teamId, setTeamId] = useState<number>(0)

	// if the variant is a game, it will be stored here
	const [game, setGame] = useState<Game>()

	// if the variant is a practice, it will be stored here
	const [practice, setPractice] = useState<Practice>()

	// determines the teamId for creating a new outing
	useEffect(() => {
		if (variant === "game" && game) {
			if (whichTeam === "home") {
				setTeamId(game.home_team.id)
			} else {
				setTeamId(game.away_team.id)
			}
		} else {
			setTeamId(globals.teamId!)
		}
	}, [whichTeam, game, practice])

	// runs on load to fetch the data necessary for the game/practice
	useEffect(() => {
		const fetchOutings = async () => {
			try {
				const response = await axios.get(
					`/api/${variant}/${id}/outings`,
					globals.apiHeader
				)
				setOutings(response.data)
			} catch (e) {
				console.log(e)
			}
		}
		const fetchCompetition = async () => {
			try {
				const response = await axios.get(
					`/api/${variant}/${id}`,
					globals.apiHeader
				)
				if (variant === "game") {
					setGame(response.data)
				} else {
					setPractice(response.data)
				}
			} catch (e) {
				console.log(e)
			}
		}
		const fetchData = async () => {
			await fetchOutings()
			await fetchCompetition()
			setLoading(false)
		}
		fetchData()
	}, [])

	// filter the outings when the load and when the home/away toggle changes
	useEffect(() => {
		if (outings.length > 0 && variant === "game") {
			setFilteredOutings(
				outings.filter((outing: Outing) => {
					if (whichTeam === "home") {
						return outing.game!.home_team.id === outing.pitcher.team_id
					} else {
						return outing.game!.away_team.id === outing.pitcher.team_id
					}
				})
			)
		} else {
			setFilteredOutings(outings)
		}
	}, [outings, whichTeam])

	/**
	 * Takes snapshots of some charts in D3 and sends PDF reports to backend
	 * 	to be emailed to the pitchers
	 * @returns void
	 */
	const sendReports = async () => {
		if (
			!confirm("Are you sure you want to email these pitchers their reports?")
		) {
			return
		}

		setFeedback({
			show: true,
			severity: "info",
			message: "Sending emails.....Stay on this page!",
		})

		// iterate through each outing
		for (const outingIndex in filteredOutings) {
			const outing = filteredOutings[outingIndex]
			try {
				// will be used to get the Buffer data to render the image
				const regex = /^data:.+\/(.+);base64,(.*)$/

				// gets the velo throughout outing chart and snaps a pic
				let veloChartPngData = await svgAsPngUri(
					document.getElementById(`outing-velo-chart-${outing.id}`),
					{}
				)
				const veloChart = veloChartPngData.match(regex)[2]
				const veloChartBuffer = Buffer.from(veloChart, "base64")

				// iterates through each pitch type and snaps a pic of the locations charts
				let pitchCharts: { [key: string]: Buffer } = {}
				for (const index in pitchTypes) {
					let pitchPngData = await svgAsPngUri(
						document.getElementById(`${outing.id}-${pitchTypes[index]}`),
						{}
					)
					const pitchData = pitchPngData.match(regex)[2]
					const pitchBuffer = Buffer.from(pitchData, "base64")
					pitchCharts[pitchTypes[index]] = pitchBuffer
				}

				// create the png and save a blob
				const blob = await pdf(
					<OutingReport
						outing={outing}
						veloChart={veloChartBuffer}
						pitchLocations={pitchCharts}
					/>
				).toBlob()

				// send the pdf to api to email the player
				await axios.post(`/api/outing_report/${outing.id}`, blob, {
					headers: {
						"Content-Type": "application/pdf",
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				})
				setFeedback({
					show: true,
					severity: "success",
					message: `Email for ${outing.pitcher.lastname} sent!`,
				})
			} catch (e) {
				setFeedback({
					show: true,
					severity: "error",
					message: `Email for ${outing.pitcher.lastname} failed`,
				})
				console.log(e)
			}
		}
	}

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container className="padding-top-24">
			<Grid container spacing={0}>
				{variant === "game" && (
					<Grid item xs={12}>
						<HomeAwayToggle
							value={whichTeam}
							onChange={(value: "home" | "away") => {
								if (value) {
									setWhichTeam(value)
								}
							}}
						/>
					</Grid>
				)}
				<Grid item xs={8}>
					<h2>
						{variant === "game" &&
							whichTeam === "home" &&
							`${game!.home_team.name} - `}
						{variant === "game" &&
							whichTeam === "away" &&
							`${game!.away_team.name} - `}
						Outings
					</h2>
				</Grid>
				<Grid
					item
					xs={4}
					className="align-right"
					style={{ paddingTop: "19px" }}>
					{globals.admin && (
						<>
							<Tooltip title="New Outing">
								<IconButton
									color="primary"
									onClick={() => setNewOutingModal(true)}>
									<AddIcon />
								</IconButton>
							</Tooltip>
							{/* <Tooltip title="Email Reports">
								<IconButton color="primary" onClick={sendReports}>
									<EmailIcon />
								</IconButton>
							</Tooltip> */}
						</>
					)}
				</Grid>
				<Grid item xs={12}>
					<OutingTable outings={filteredOutings} />
				</Grid>
			</Grid>
			{/* these are hidden d3 charts that are used for pdf reports that will be emailed
			{filteredOutings.map((outing) => (
				<div key={outing.id} style={{ display: "none" }}>
					<VeloChart
						atBats={outing.at_bats}
						width={800}
						height={373}
						outing={outing}
					/>
					{pitchTypes.map((pitchType) => {
						let pitches: Pitch[] = []
						outing.at_bats.forEach((atBat) => {
							if (atBat.pitches) {
								atBat.pitches.forEach((pitch) => {
									if (pitch.pitch_type === pitchType) {
										pitches.push(pitch)
									}
								})
							}
						})
						return (
							<LocationsD3
								id={`${outing.id}-${pitchType}`}
								key={`${outing.id}-${pitchType}`}
								pitches={pitches}
							/>
						)
					})}
				</div>
			))} */}
			{(game || practice) && (
				<NewOutingModal
					isOpen={newOutingModal}
					close={() => setNewOutingModal(false)}
					teamId={teamId}
					variant={variant}
					seasonId={variant === "game" ? game!.season_id : practice!.season_id}
					date={variant === "game" ? game!.date : practice!.date}
				/>
			)}
			<br />
			<br />
			<br />
		</Container>
	)
}
