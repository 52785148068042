import { useContext, useEffect, useState } from "react"

// components
import { Container, Grid, Paper } from "@material-ui/core"
import { LocationsD3 } from "./LocationsD3"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import { Filter } from "./Filter"

// types
import { PitchType, PitchResult } from "../../../context/Types"
import { DetailedPitch } from "../../../interfaces/DetailedPitch"
import { OutingParser } from "../../../classes/OutingParser"

// contexts
import { PitcherContext } from "../PitcherPage"

// constants
import { pitchTypes } from "../../../index"

// component styling
import "./LocationsTab.css"

// holds all of the possible ways to filter the pitches
interface Filters {
	balls: "All" | 0 | 1 | 2 | 3
	strikes: "All" | 0 | 1 | 2
	atBatResult: "All" | "K" | "ꓘ" | "Out" | "Hit"
	pitchType: PitchType | "All"
	traj: "All" | "GB" | "FB" | "LD"
	outs: "All" | 0 | 1 | 2
	hitHard: "All" | "Yes" | "No"
	pitchResult: PitchResult | "All"
	hitSpot: "All" | "Yes" | "No"
}

const initialFilters: Filters = {
	balls: "All",
	strikes: "All",
	atBatResult: "All",
	pitchType: "All",
	traj: "All",
	outs: "All",
	hitHard: "All",
	pitchResult: "All",
	hitSpot: "All",
}

export const LocationsTab = () => {
	const { outingParsers, loading } = useContext(PitcherContext)

	// holds all of the pitches from this players career
	const [allPitches, setAllPitches] = useState<DetailedPitch[]>([])

	// holds the pitches based on the filters above
	const [filteredPitches, setFilteredPitches] = useState<DetailedPitch[]>([])

	// stores all information related to which filters are being used
	const [filters, setFilters] = useState<Filters>(initialFilters)

	// parses through all the pitches from the pitchers outings
	useEffect(() => {
		let pitches: DetailedPitch[] = []
		outingParsers.forEach((outingParser: OutingParser) => {
			outingParser.getDetailedPitches().forEach((pitch) => pitches.push(pitch))
		})
		setAllPitches(pitches)
	}, [outingParsers])

	// runs everytime the filters are changed
	useEffect(() => {
		setFilteredPitches(
			allPitches.filter((pitch: DetailedPitch) => {
				// based on the "Balls" filter
				if (pitch.balls! !== filters.balls && filters.balls !== "All") {
					return false
				}

				// based on the "Strike" filter
				if (pitch.strikes !== filters.strikes && filters.strikes !== "All") {
					return false
				}

				// based on the "Pitch Type" filter
				if (
					pitch.pitch_type !== filters.pitchType &&
					filters.pitchType !== "All"
				) {
					return false
				}

				// based on the "Pitch Result" filter
				if (
					pitch.pitch_result !== filters.pitchResult &&
					filters.pitchResult !== "All"
				) {
					return false
				}

				// based on the "Hit Spot" filter
				if (
					(pitch.hit_spot && filters.hitSpot === "No") ||
					(!pitch.hit_spot && filters.hitSpot === "Yes")
				) {
					return false
				}

				// based on the "Outs" filter
				if (pitch.outs !== filters.outs && filters.outs !== "All") {
					return false
				}

				// based on the "Hit Hard" filter
				if (
					(filters.hitHard === "Yes" && (!pitch.lastPitch || !pitch.hitHard)) ||
					(filters.hitHard === "No" && (!pitch.lastPitch || pitch.hitHard))
				) {
					return false
				}

				// based on the "Trajectory" filter
				if (
					(filters.traj === "GB" &&
						(!pitch.lastPitch || !(pitch.traj === "GB"))) ||
					(filters.traj === "LD" &&
						(!pitch.lastPitch || !(pitch.traj === "LD"))) ||
					(filters.traj === "FB" &&
						(!pitch.lastPitch || !(pitch.traj === "FB")))
				) {
					return false
				}

				// based on the "AB Result" filer
				if (
					(filters.atBatResult === "K" &&
						(!pitch.lastPitch || !(pitch.atBatResult === "K"))) ||
					(filters.atBatResult === "ꓘ" &&
						(!pitch.lastPitch || !(pitch.atBatResult === "ꓘ"))) ||
					(filters.atBatResult === "Out" &&
						(!pitch.lastPitch ||
							!["Out", "FC", "Double Play", "Triple Play", "Error"].includes(
								pitch.atBatResult
							))) ||
					(filters.atBatResult === "Hit" &&
						(!pitch.lastPitch ||
							!["1B", "2B", "3B", "HR"].includes(pitch.atBatResult)))
				) {
					return false
				}

				// if the pitch passes all conditions above
				return true
			})
		)
	}, [allPitches, filters])

	if (loading) {
		return <LoadingBig />
	}

	const filterComponents = [
		{
			label: "Balls",
			value: filters.balls,
			handleChange(value: any) {
				setFilters({
					...filters,
					balls: value as "All" | 0 | 1 | 2 | 3,
				})
			},
			options: [
				{
					label: "All",
					value: "All",
				},
				{
					label: 0,
					value: 0,
				},
				{
					label: 1,
					value: 1,
				},
				{
					label: 2,
					value: 2,
				},
				{
					label: 3,
					value: 3,
				},
			],
		},
		{
			label: "Strikes",
			value: filters.strikes,
			handleChange(value: any) {
				setFilters({
					...filters,
					strikes: value as "All" | 0 | 1 | 2,
				})
			},
			options: [
				{
					label: "All",
					value: "All",
				},
				{
					label: 0,
					value: 0,
				},
				{
					label: 1,
					value: 1,
				},
				{
					label: 2,
					value: 2,
				},
			],
		},
		{
			label: "Pitch Type",
			value: filters.pitchType,
			handleChange(value: any) {
				setFilters({
					...filters,
					pitchType: value as PitchType | "All",
				})
			},
			options: [
				{ label: "All", value: "All" },
				...pitchTypes.map((pitchType) => {
					return {
						label: pitchType,
						value: pitchType,
					}
				}),
			],
		},
		{
			label: "Pitch Result",
			value: filters.pitchResult,
			handleChange(value: any) {
				setFilters({
					...filters,
					pitchResult: value as "All" | PitchResult,
				})
			},
			options: [
				{
					label: "All",
					value: "All",
				},
				{
					label: "B",
					value: "B",
				},
				{
					label: "CS",
					value: "CS",
				},
				{
					label: "SS",
					value: "SS",
				},
				{
					label: "F",
					value: "F",
				},
				{
					label: "IP",
					value: "IP",
				},
			],
		},
		{
			label: "Hit Spot",
			value: filters.hitSpot,
			handleChange(value: any) {
				setFilters({
					...filters,
					hitSpot: value as "All" | "Yes" | "No",
				})
			},
			options: [
				{
					label: "All",
					value: "All",
				},
				{
					label: "Yes",
					value: "Yes",
				},
				{
					label: "No",
					value: "No",
				},
			],
		},
		{
			label: "Outs",
			value: filters.outs,
			handleChange(value: any) {
				setFilters({
					...filters,
					outs: value as "All" | 0 | 1 | 2,
				})
			},
			options: [
				{
					label: "All",
					value: "All",
				},
				{
					label: 0,
					value: 0,
				},
				{
					label: 1,
					value: 1,
				},
				{
					label: 2,
					value: 2,
				},
			],
		},
		{
			label: "Hit Hard",
			value: filters.hitHard,
			handleChange(value: any) {
				setFilters({
					...filters,
					hitHard: value as "All" | "Yes" | "No",
				})
			},
			options: [
				{
					label: "All",
					value: "All",
				},
				{
					label: "Yes",
					value: "Yes",
				},
				{
					label: "No",
					value: "No",
				},
			],
		},
		{
			label: "Trajectory",
			value: filters.traj,
			handleChange(value: any) {
				setFilters({
					...filters,
					traj: value as "All" | "GB" | "LD" | "FB",
				})
			},
			options: [
				{
					label: "All",
					value: "All",
				},
				{
					label: "GB",
					value: "GB",
				},
				{
					label: "LD",
					value: "LD",
				},
				{
					label: "FB",
					value: "FB",
				},
			],
		},
		{
			label: "AB Result",
			value: filters.atBatResult,
			handleChange(value: any) {
				setFilters({
					...filters,
					atBatResult: value as "All" | "K" | "ꓘ" | "Out" | "Hit",
				})
			},
			options: [
				{
					label: "All",
					value: "All",
				},
				{
					label: "K",
					value: "K",
				},
				{
					label: "ꓘ",
					value: "ꓘ",
				},
				{
					label: "Out",
					value: "Out",
				},
				{
					label: "Hit",
					value: "Hit",
				},
			],
		},
	]

	return (
		<Container className="tab-container">
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<Paper className="pitcher-locations-filters-paper">
						<h2>Filters</h2>
						<Grid container spacing={2}>
							{filterComponents.map((filter, idx) => (
								<Grid item xs={6} lg={12} key={idx}>
									<Filter
										label={filter.label}
										value={filter.value}
										handleChange={filter.handleChange}
										options={filter.options}
									/>
								</Grid>
							))}
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12} lg={6} className="align-center">
					<Paper className="pitcher-locations-chart-paper">
						<LocationsD3 pitches={filteredPitches} />
					</Paper>
				</Grid>
			</Grid>
		</Container>
	)
}
