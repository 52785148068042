import { useContext } from "react"

import axios from "axios"
import { Link } from "react-router-dom"

// components
import { Button, IconButton, TableCell, TableRow } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"

// types
import { Practice } from "../../../interfaces/PracticeInterface"

// contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { CompetitionsPageContext } from "../CompetitionsPage"

// utils
import { formatDate } from "../../../shared/Functions/FormatDate"

interface Props {
	practice: Practice
	handleEdit: () => void
}

export const PracticeRow = ({ practice, handleEdit }: Props) => {
	const { user, apiHeader } = useContext(GlobalsContext)
	const { alertSuccess, alertError } = useContext(FeedbackContext)
	const { refetchCompetitions } = useContext(CompetitionsPageContext)

	const handleDelete = async () => {
		if (confirm("Are you sure you wan't to delete this practice?")) {
			try {
				await axios.delete(`/api/practice/${practice.id}`, apiHeader)
				alertSuccess("Practice deleted!")
				refetchCompetitions()
			} catch (e) {
				alertError(
					"Something went wrong. Try deleting the outings for the practice first."
				)
			}
		}
	}

	return (
		<TableRow>
			<TableCell align="left">{formatDate(practice.date as string)}</TableCell>
			<TableCell align="left">
				<Button
					to={`/practice/${practice.id}`}
					component={Link}
					color="primary">
					Practice
				</Button>
			</TableCell>
			{user!.admin && (
				<TableCell align="center">
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={handleDelete}>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			)}
		</TableRow>
	)
}
