import {
	Dialog,
	DialogTitle,
	List,
	ListItem,
	ListItemText,
} from "@material-ui/core"
import axios from "axios"
import React, { useContext } from "react"
import { GlobalsContext } from "../../../../../../context/GlobalsContext"
import { Player } from "../../../../../../interfaces/PlayerInterface"

interface Props {
	open: boolean
	batter: Player
	resolved: (bats: "R" | "L" | "S") => void
	notResolved: () => void
}

export const ChooseHandedness = ({
	open,
	batter,
	resolved,
	notResolved,
}: Props) => {
	const globals = useContext(GlobalsContext)

	const handleClose = () => {
		notResolved()
	}

	const chooseHandedness = async (bats: "R" | "L" | "S") => {
		try {
			await axios.patch(`/api/player/${batter.id}`, { bats }, globals.apiHeader)
			resolved(bats)
		} catch (e) {
			notResolved()
		}
	}

	return (
		<Dialog onClose={handleClose} open={open}>
			<DialogTitle>
				{batter.firstname && `${batter.firstname} `}
				{batter.lastname}
			</DialogTitle>
			<List>
				<ListItem button onClick={() => chooseHandedness("R")}>
					<ListItemText primary="Righty" />
				</ListItem>
				<ListItem button onClick={() => chooseHandedness("L")}>
					<ListItemText primary="Lefty" />
				</ListItem>
				<ListItem button onClick={() => chooseHandedness("S")}>
					<ListItemText primary="Switch" />
				</ListItem>
			</List>
		</Dialog>
	)
}
