import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { GlobalsContext } from "../../context/GlobalsContext"
import { Player } from "../../interfaces/PlayerInterface"
import { LoadingBig } from "../../shared/Loading/LoadingBig"
import { SubnavTemplate } from "../../shared/MainLayout/SubnavTemplate/SubnavTemplate"
import { AtBatsTab } from "./AtBatsTab/AtBatsTab"
import { HitterGameReport } from "./GameReport/GameReport"
import { SpraysTab } from "./SpraysTab/SpraysTab"
import { VideosTab } from "./VideosTab/VideosTab"

interface ParamTypes {
	id: string | undefined
}

export const HitterPage = () => {
	const globals = useContext(GlobalsContext)
	const { id } = useParams<ParamTypes>()
	const [player, setPlayer] = useState<Player>()
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		const fetchPlayer = async () => {
			try {
				const response = await axios.get(`/api/player/${id}`, globals.apiHeader)
				setPlayer(response.data)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchPlayer()
	}, [])

	if (loading || !player) {
		return <LoadingBig />
	}

	const tabs = [
		{ name: "At Bats", component: <AtBatsTab playerId={player.id} /> },
		{ name: "Sprays", component: <SpraysTab playerId={player.id} /> },
		{ name: "Reports", component: <HitterGameReport playerId={player.id} /> },
		{ name: "Videos", component: <VideosTab playerId={player.id} /> },
	]

	return (
		<SubnavTemplate
			title={`${player.firstname} ${player.lastname}`}
			tabs={tabs}
		/>
	)
}
