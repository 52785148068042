import { Outing } from "../../interfaces/OutingInterface"
import { calcOutingStats } from "./CalcOutingStats"

export const calcTotalsForMultipleOutings = (outings: Outing[]) => {
	let battersFaced = 0
	let pitches = 0
	let hits = 0
	let strikeouts = 0
	let walks = 0
	let hitByPitches = 0
	let strikes = 0
	let firstPitchStrikes = 0
	let hitSpots = 0
	let veloTotal = 0
	let pitchesWithVelo = 0

	outings.forEach((outing) => {
		const outingStats = calcOutingStats(outing.at_bats)
		battersFaced += outingStats.battersFaced
		pitches += outingStats.pitches
		hits += outingStats.hits
		strikeouts += outingStats.strikeouts
		walks += outingStats.walks
		hitByPitches += outingStats.hitByPitches

		outing.at_bats.forEach((atBat) => {
			if (atBat.pitches) {
				atBat.pitches.forEach((pitch) => {
					if (pitch.pitch_result !== "B") {
						strikes += 1
						if (pitch.ab_pitch_num === 1) {
							firstPitchStrikes += 1
						}
					}
					if (pitch.hit_spot) {
						hitSpots += 1
					}
					if (pitch.velocity && ["FB", "2S"].includes(pitch.pitch_type)) {
						veloTotal += pitch.velocity
						pitchesWithVelo += 1
					}
				})
			}
		})
	})

	return {
		battersFaced,
		pitches,
		hits,
		strikeouts,
		walks,
		hitByPitches,
		strikePct:
			pitches > 0 ? `${Math.round((100 * strikes) / pitches)}%` : "N/A",
		firstPitchStrikePct:
			battersFaced > 0
				? `${Math.round((100 * firstPitchStrikes) / battersFaced)}%`
				: "N/A",
		hitSpotPct:
			pitches > 0 ? `${Math.round((100 * hitSpots) / pitches)}%` : "N/A",
		velocity:
			pitchesWithVelo > 0
				? `${(veloTotal / pitchesWithVelo).toFixed(1)}`
				: "N/A",
		avgVelocities: {},
	}
}
