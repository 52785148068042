import React, { useContext } from "react"

import { Grid, TextField } from "@material-ui/core"
import NumberFormat from "react-number-format"

import { Context } from "../../TrackTab"

interface Props {
	inputRef: (instance: NumberFormat | null) => void
	onChange: (event: {
		target: { name: string; value: number | string }
	}) => void
	name: string
}

export const TimeToPlateInput = ({
	inputRef,
	onChange,
	name,
	...other
}: Props) => {
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: name,
						value:
							values.formattedValue !== ""
								? ((values.formattedValue as unknown) as number)
								: "",
					},
				})
			}}
			format="#.##"
		/>
	)
}

export const DataEntry = () => {
	const { state, setState } = useContext(Context)

	const handleVelocityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({
			...state,
			pitchEntry: {
				...state.pitchEntry,
				velocity: event.target.valueAsNumber,
			},
		})
	}

	const handleTimeToPlateChange = (event: {
		target: { name: string; value: number | string }
	}) => {
		setState({
			...state,
			pitchEntry: {
				...state.pitchEntry,
				timeToPlate: event.target.value,
			},
		})
	}

	return (
		<div className="pt-data-entry-div">
			<Grid container spacing={0}>
				<Grid item xs={6}>
					<TextField
						className="pt-data-entry-inputs"
						label="Velocity"
						type="number"
						variant="outlined"
						value={state.pitchEntry.velocity}
						onChange={handleVelocityChange}
					/>
				</Grid>
				<Grid item xs={6} className="align-right">
					<TextField
						className="pt-data-entry-inputs"
						label="Time to Plate"
						variant="outlined"
						name="time-to-plate"
						value={state.pitchEntry.timeToPlate}
						onChange={handleTimeToPlateChange}
						InputProps={{
							inputComponent: TimeToPlateInput as any,
						}}
					/>
				</Grid>
			</Grid>
		</div>
	)
}
