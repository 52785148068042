import { Card, CardContent, CardHeader } from "@material-ui/core"
import { Video } from "../interfaces/VideoInterface"
import { formatDate } from "./Functions/FormatDate"
import { getVideoId } from "./Functions/GetVideoId"

interface VideoCardProps {
	video: Video
}

export const VideoCard = ({ video }: VideoCardProps) => {
	return (
		<Card>
			<CardHeader
				title={video.title}
				subheader={formatDate(video.date)}></CardHeader>
			<CardContent>
				{video.upload_type === "YouTube" && (
					<iframe
						width="100%"
						height="200px"
						src={`https://www.youtube-nocookie.com/embed/${getVideoId(
							video.link
						)}`}
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen={true}
					/>
				)}
				{video.upload_type === "Upload" && (
					<video width="100%" height="200px" src={video.link} controls />
				)}
			</CardContent>
		</Card>
	)
}
