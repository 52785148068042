import { useContext } from "react"

// components
import { Container, Paper, Grid, IconButton } from "@material-ui/core"
import { LoadingBig } from "../../shared/Loading/LoadingBig"
import AddIcon from "@material-ui/icons/Add"
import { HitterTable } from "./HitterTable/HitterTable"

// contexts
import { GlobalsContext } from "../../context/GlobalsContext"
import { HittingPageContext } from "./HittingPage"

export const ActiveTab = () => {
	const { user } = useContext(GlobalsContext)
	const { loading, players, openNewHitterModal } =
		useContext(HittingPageContext)

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container className="tab-container">
			<Grid spacing={0} container component={Paper}>
				<Grid item xs={9} className="hitting-roster-table-title">
					<span>Active Hitters</span>
				</Grid>
				{user!.admin && (
					<Grid item xs={3} className="hitting-roster-table-add">
						<IconButton onClick={openNewHitterModal}>
							<AddIcon />
						</IconButton>
					</Grid>
				)}
				<Grid item xs={12}>
					<HitterTable
						players={players.filter(
							(pitcher) => pitcher.batter && pitcher.active
						)}
					/>
				</Grid>
			</Grid>
		</Container>
	)
}
