import React, { useContext, useEffect, useState } from "react"

import axios from "axios"

import {
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TableCell,
	TableRow,
	TextField,
} from "@material-ui/core"

import EditIcon from "@material-ui/icons/EditOutlined"
import DoneIcon from "@material-ui/icons/Done"
import ClearIcon from "@material-ui/icons/Clear"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"

import { Player } from "../../../interfaces/PlayerInterface"
import { UsersTabContext } from "./TypesAndContexts"
import { GlobalsContext } from "../../../context/GlobalsContext"

interface UserValues {
	id?: number
	firstname: string
	lastname: string
	email: string
	current_coach: boolean
	current_player: boolean
	admin: boolean
	player?: Player
	player_id?: any
}

const initialUserValues: UserValues = {
	id: 0,
	firstname: "",
	lastname: "",
	email: "",
	current_coach: false,
	current_player: false,
	admin: false,
	player_id: "",
}

interface Props {
	userId: number
}

export const UserRow = ({ userId }: Props) => {
	const globals = useContext(GlobalsContext)
	const { state, dispatch } = useContext(UsersTabContext)
	const [editMode, setEditMode] = useState<boolean>(false)
	const [user, setUser] = useState<UserValues>(initialUserValues)
	const [userValues, setUserValues] = useState<UserValues>(initialUserValues)

	// called initially and thats it
	useEffect(() => {
		fetchUser()
	}, [userId])

	// called initially as well as after a change has been made
	const fetchUser = async () => {
		try {
			const response = await axios.get(`/api/user/${userId}`, globals.apiHeader)
			const theUser = response.data
			setUser(theUser)
			setUserValues({
				firstname: theUser.firstname,
				lastname: theUser.lastname,
				email: theUser.email,
				current_coach: theUser.current_coach,
				current_player: theUser.current_player,
				admin: theUser.admin,
				player_id: theUser.player ? theUser.player.id : "",
			})
		} catch (e) {
			dispatch({ type: "FETCH_ERROR" })
		}
	}

	// revert changes
	const reset = () => {
		setEditMode(false)
		setUserValues({
			firstname: user.firstname,
			lastname: user.lastname,
			email: user.email,
			current_coach: user.current_coach,
			current_player: user.current_player,
			admin: user.admin,
			player_id: user.player ? user.player.id : "",
		})
	}

	// delete user button
	const deleteUser = async () => {
		if (!confirm("Are you sure you want to delete this user?")) return
		try {
			await axios.delete(`/api/user/${user.id}`, globals.apiHeader)
			setEditMode(false)
			dispatch({ type: "USER_DELETED" })
		} catch (e) {
			reset()
			dispatch({ type: "USER_UPDATED_FAILED" })
		}
	}

	// save changes button
	const saveChanges = async () => {
		const data = { ...userValues }
		if (data.player_id === "") data.player_id = null
		try {
			await axios.patch(`/api/user/${user.id}`, data, globals.apiHeader)
			await fetchUser()
			setEditMode(false)
			dispatch({ type: "USER_UPDATED" })
		} catch (e) {
			reset()
			dispatch({ type: "USER_UPDATED_FAILED" })
		}
	}

	if (editMode) {
		return (
			<TableRow>
				<TableCell>
					<IconButton onClick={saveChanges}>
						<DoneIcon />
					</IconButton>
					<IconButton onClick={reset}>
						<ClearIcon />
					</IconButton>
					<IconButton onClick={deleteUser}>
						<DeleteOutlineIcon />
					</IconButton>
				</TableCell>
				<TableCell>
					<TextField
						value={userValues.firstname}
						onChange={(e) =>
							setUserValues({ ...userValues, firstname: e.target.value })
						}
					/>
				</TableCell>
				<TableCell>
					<TextField
						value={userValues.lastname}
						onChange={(e) =>
							setUserValues({ ...userValues, lastname: e.target.value })
						}
					/>
				</TableCell>
				<TableCell>
					<TextField
						value={userValues.email}
						onChange={(e) =>
							setUserValues({ ...userValues, email: e.target.value })
						}
					/>
				</TableCell>
				<TableCell>
					<Switch
						color="primary"
						checked={userValues.current_player}
						value={userValues.current_player}
						onChange={(e) =>
							setUserValues({ ...userValues, current_player: e.target.checked })
						}
					/>
				</TableCell>
				<TableCell>
					<Switch
						color="primary"
						checked={userValues.current_coach}
						value={userValues.current_coach}
						onChange={(e) =>
							setUserValues({ ...userValues, current_coach: e.target.checked })
						}
					/>
				</TableCell>
				<TableCell>
					<Switch
						color="primary"
						checked={userValues.admin}
						value={userValues.admin}
						onChange={(e) =>
							setUserValues({ ...userValues, admin: e.target.checked })
						}
					/>
				</TableCell>
				<TableCell>
					<FormControl style={{ minWidth: "150px" }}>
						<InputLabel>Select Player</InputLabel>
						<Select
							value={userValues.player_id}
							onChange={(e) =>
								setUserValues({ ...userValues, player_id: e.target.value })
							}>
							<MenuItem value="">None</MenuItem>
							{state.players.map((player) => (
								<MenuItem key={player.id} value={player.id}>
									{player.firstname} {player.lastname} #{player.number}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</TableCell>
			</TableRow>
		)
	}

	return (
		<TableRow>
			<TableCell>
				<IconButton onClick={() => setEditMode(true)}>
					<EditIcon />
				</IconButton>
			</TableCell>
			<TableCell>{user.firstname}</TableCell>
			<TableCell>{user.lastname}</TableCell>
			<TableCell>{user.email}</TableCell>
			<TableCell>{user.current_player ? <>Yes</> : <>No</>}</TableCell>
			<TableCell>{user.current_coach ? <>Yes</> : <>No</>}</TableCell>
			<TableCell>{user.admin ? <>Yes</> : <>No</>}</TableCell>
			<TableCell>{user.player ? <>#{user.player.number}</> : <></>}</TableCell>
		</TableRow>
	)
}
