import React, { useContext, useEffect, useState } from "react"

import {
	Checkbox,
	Chip,
	IconButton,
	Input,
	MenuItem,
	Select,
	TableCell,
	TableRow,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/EditOutlined"

import { ViewRunners } from "./ViewRunners"
import { MiniZone } from "./MiniZone"

import { Pitch } from "../../../../interfaces/PitchInterface"
import { AtBat } from "../../../../interfaces/AtBatInterface"
import { GlobalsContext } from "../../../../context/GlobalsContext"
import { FeedbackContext, OutingContext } from "../PitchesTab"
import axios from "axios"

interface Props {
	pitch: Pitch
}

const pitchTypes = ["FB", "2S", "CH", "SL", "CB", "CT", "SPL", "OTH"]

export const PitchRow = ({ pitch }: Props) => {
	const { outing } = useContext(OutingContext)
	const { setFeedback } = useContext(FeedbackContext)
	const [thePitch, setThePitch] = useState<Pitch>(pitch)
	const globals = useContext(GlobalsContext)
	const [authToChangePitchType, setAuthToChangePitchType] = useState<boolean>(
		false
	)
	const handlePitchTypeChange = async (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>,
		child: React.ReactNode
	) => {
		try {
			await axios.patch(
				`/api/pitch/${pitch.id}/pitch_type`,
				{ pitch_type: event.target.value },
				globals.apiHeader
			)
			setThePitch({ ...thePitch, pitch_type: event.target.value as string })
			setFeedback({
				show: true,
				severity: "success",
				message: "Pitch changed!",
			})
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message: "Something wen't wrong",
			})
			console.log(e)
		}
	}

	const handleRollThroughChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		try {
			await axios.patch(
				`/api/pitch/${pitch.id}/roll_through`,
				{ roll_through: checked },
				globals.apiHeader
			)
			setThePitch({ ...thePitch, roll_through: checked })
			setFeedback({
				show: true,
				severity: "success",
				message: "Pitch changed!",
			})
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message: "Something wen't wrong",
			})
			console.log(e)
		}
	}
	const handleShortSetChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		try {
			await axios.patch(
				`/api/pitch/${pitch.id}/short_set`,
				{ short_set: checked },
				globals.apiHeader
			)
			setThePitch({ ...thePitch, short_set: checked })
			setFeedback({
				show: true,
				severity: "success",
				message: "Pitch changed!",
			})
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message: "Something wen't wrong",
			})
			console.log(e)
		}
	}

	useEffect(() => {
		if (globals.admin) {
			return setAuthToChangePitchType(true)
		} else {
			if (globals.user) {
				if (globals.user.player) {
					if (outing.pitcher.id === globals.user.player.id) {
						setAuthToChangePitchType(true)
					}
				}
			}
		}
	}, [])

	return (
		<TableRow>
			<TableCell align="center">{thePitch.ab_pitch_num!}</TableCell>
			<TableCell align="center">{thePitch.hit_spot ? "Yes" : "No"}</TableCell>
			<TableCell align="center">
				<Checkbox
					disabled={!authToChangePitchType}
					checked={thePitch.roll_through}
					onChange={handleRollThroughChange}
					color="primary"
				/>
			</TableCell>
			<TableCell align="center">
				<Checkbox
					disabled={!authToChangePitchType}
					checked={thePitch.short_set}
					onChange={handleShortSetChange}
					color="primary"
				/>
			</TableCell>
			<TableCell align="center">
				{authToChangePitchType ? (
					<Select
						value={thePitch.pitch_type}
						onChange={handlePitchTypeChange}
						input={<Input />}
						renderValue={(selected) => (
							<Chip
								style={{ margin: "2px" }}
								label={selected as string}
								className={`outing-locations-pitch-chip-${selected}`}
							/>
						)}>
						{pitchTypes.map((pitchType) => (
							<MenuItem key={pitchType} value={pitchType}>
								{pitchType}
							</MenuItem>
						))}
					</Select>
				) : (
					<Chip
						style={{ margin: "2px" }}
						label={thePitch.pitch_type}
						className={`outing-locations-pitch-chip-${thePitch.pitch_type}`}
					/>
				)}
			</TableCell>
			<TableCell align="center">{thePitch.pitch_result}</TableCell>
			<TableCell align="center">{thePitch.velocity}</TableCell>
			<TableCell align="center">{thePitch.time_to_plate}</TableCell>
			<TableCell align="center" style={{ minWidth: "100px" }}>
				<ViewRunners
					runner1={thePitch.runner_1!}
					runner2={thePitch.runner_2!}
					runner3={thePitch.runner_3!}
				/>
			</TableCell>
			<TableCell align="center">
				{thePitch.balls}-{thePitch.strikes}
			</TableCell>
			<TableCell align="center">{thePitch.notes}</TableCell>
			<TableCell align="center" style={{ padding: "0px" }}>
				<MiniZone xLoc={thePitch.loc_x!} yLoc={thePitch.loc_y!} />
			</TableCell>
		</TableRow>
	)
}
