import React, { createContext, useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import { Paper, Container } from "@material-ui/core"
import { Subnav } from "../../shared/Subnav/Subnav"
import { TabPanel } from "../../shared/Subnav/TabPanel"
import { CategoryTab } from "./CategoryTab"
import { LoadingBig } from "../../shared/Loading/LoadingBig"
import { NewResourceModal } from "./NewResourceModal"

// types and contexts
import { ResourceCategory } from "../../interfaces/ResourceCategory"
import { GlobalsContext } from "../../context/GlobalsContext"
import { FeedbackContext } from "../../context/FeedbackContext"

// styles
import "../../static/css/ResourcesPage.css"

// indicates to parent component to refetch all the categories
export const ResourcesUpdatedContext = createContext({
	refetchResources: (null as unknown) as () => void,
	openNewResourceModal: (null as unknown) as (
		category: ResourceCategory
	) => void,
	categories: [] as ResourceCategory[],
})

export const ResourcesPage = () => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)

	// while fetching the categories
	const [loading, setLoading] = useState<boolean>(true)

	// holds the categories and its resources
	const [categories, setCategories] = useState<ResourceCategory[]>([])

	// indicates which category is selected (which tab is open)
	const [category, setCategory] = useState<number>(0)

	// changes anytime a resource was created, updated, or deleted
	const [resourcesUpdated, setResourcesUpdated] = useState<boolean>(false)

	// holds state for the modal to create a new resource
	const [newResourceModal, setNewResourceModal] = useState<{
		[key: string]: any
	}>({ open: false, category: null })

	// runs once and everytime a resource was created, updated, or deleted
	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const response = await axios.get(
					"/api/resource_category/all",
					globals.apiHeader
				)
				setCategories(response.data)
			} catch (e) {
				setFeedback({
					show: true,
					severity: "error",
					message: "Something went wrong while fetching the categories",
				})
				console.log(e)
			}
			setLoading(false)
		}
		fetchCategories()
	}, [resourcesUpdated])

	if (loading) {
		return <LoadingBig />
	}

	return (
		<ResourcesUpdatedContext.Provider
			value={{
				refetchResources: () => setResourcesUpdated(!resourcesUpdated),
				openNewResourceModal: (category: ResourceCategory) =>
					setNewResourceModal({ open: true, category }),
				categories,
			}}>
			<div id="resources-page">
				<Paper className="resources-page-header">
					<Container>
						<h1>Team Resources</h1>
					</Container>
				</Paper>
				<Subnav
					currentTab={category}
					tabChangeHandler={(event: React.ChangeEvent<{}>, value: number) =>
						setCategory(value)
					}
					tabs={categories.map((cat) => cat.name)}
				/>
				{categories.map((cat, index) => (
					<TabPanel key={index} index={index} value={category}>
						<CategoryTab category={cat} />
					</TabPanel>
				))}
			</div>
			<NewResourceModal
				isOpen={newResourceModal.open}
				close={() => setNewResourceModal({ ...newResourceModal, open: false })}
				category={newResourceModal.category}
			/>
		</ResourcesUpdatedContext.Provider>
	)
}
