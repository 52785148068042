import axios from "axios"
import {
	createContext,
	MouseEvent,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react"
import { useAlert, useGlobals } from "../../../hooks"
import { Video } from "../../../interfaces/VideoInterface"
import { SortOrder } from "../../../shared/Table/utils"

// this flattens the videos to not have any nested properties, making it easier to filter and display
export interface SimplifiedVideo {
	id: number
	title: string
	date: string
	link: string
	category: "Pitching" | "Hitting"
	uploadType: "youtube" | "gdrive" | "vimeo" | "other"
	playerName: string
	season: string
	seasonId: number
	playerId: number
	outingId?: number
	processing: boolean
}

// globals state to be used throughout the videos tab
const VideosTabContext = createContext({
	closeEditVideoModal: null as unknown as () => void,
	closeNewVideoModal: null as unknown as () => void,
	editVideoModal: undefined as SimplifiedVideo | undefined,
	fetchingVideos: true,
	fetchVideos: null as unknown as () => Promise<void>,
	filteredVideos: [] as SimplifiedVideo[],
	handleChangePage: null as unknown as (
		event: unknown,
		newPage: number
	) => void,
	handleChangeRowsPerPage: null as unknown as (
		event: React.ChangeEvent<HTMLInputElement>
	) => void,
	handleDelete: null as unknown as (id: number) => Promise<void>,
	handleEdit: null as unknown as (id: number) => void,
	handleSortChange: null as unknown as (
		newOrder: SortOrder,
		newOrderBy: string
	) => void,
	handleTableRequestSort: null as unknown as (
		property: string
	) => (event: MouseEvent<unknown>) => void,
	newVideoModalOpen: false,
	openNewVideoModal: null as unknown as () => void,
	order: "desc" as SortOrder,
	orderBy: "date" as string,
	page: 0,
	rowsPerPage: 10,
	selectedPlayer: undefined as number | undefined,
	selectedPlayerChanged: null as unknown as (
		newSelectedPlayer?: number
	) => void,
	videos: [] as SimplifiedVideo[],
})

type VideosTabContextProviderProps = {
	children: ReactNode
}

function VideosTabContextProvider({ children }: VideosTabContextProviderProps) {
	const { apiHeader } = useGlobals()
	const { alertError, alertSuccess } = useAlert()

	const [newVideoModalOpen, setNewVideoModalOpen] = useState<boolean>(false)
	const [editVideoModal, setEditVideoModal] = useState<SimplifiedVideo>()
	const [fetchingVideos, setFetchingVideos] = useState<boolean>(true)
	const [videos, setVideos] = useState<SimplifiedVideo[]>([])
	const [filteredVideos, setFilteredVideos] = useState<SimplifiedVideo[]>([])
	const [order, setOrder] = useState<SortOrder>("desc")
	const [orderBy, setOrderBy] = useState<string>("date")
	const [page, setPage] = useState<number>(0)
	const [rowsPerPage, setRowsPerPage] = useState<number>(10)
	const [selectedPlayer, setSelectedPlayer] = useState<number>()

	const handleTableRequestSort =
		(property: string) => (event: MouseEvent<unknown>) => {
			const isAsc = orderBy === property && order === "asc"
			setOrder(isAsc ? "desc" : "asc")
			setOrderBy(property)
		}

	const handleSortChange = (newOrder: SortOrder, newOrderBy: string) => {
		setOrder(newOrder)
		setOrderBy(newOrderBy)
	}

	const handleChangePage = (event: unknown, newPage: number) => setPage(newPage)

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value))
		setPage(0)
	}

	const openNewVideoModal = () => setNewVideoModalOpen(true)
	const closeNewVideoModal = () => setNewVideoModalOpen(false)
	const closeEditVideoModal = () => setEditVideoModal(undefined)

	const fetchVideos = async () => {
		try {
			const response = await axios.get(`/api/video`, apiHeader)
			const fetchedVideos = response.data.map(
				({
					id,
					title,
					date,
					link,
					category,
					upload_type: uploadType,
					player,
					season,
					outing_id,
					processing,
				}: Video) => ({
					id,
					title,
					date,
					link,
					category,
					uploadType,
					playerName: `${player.lastname}, ${player.firstname}`,
					season: `${season.year}-${season.name}`,
					seasonId: season.id,
					outingId: outing_id,
					playerId: player.id,
					processing,
				})
			)
			setVideos(fetchedVideos)
			setFilteredVideos(fetchedVideos)
		} catch (e) {
			setVideos([])
			alertError("Something wen't wrong fetching all the videos")
			console.error(e)
		}
		setFetchingVideos(false)
	}

	const handleEdit = (id: number) => {
		setEditVideoModal(videos?.find((video) => video.id === id))
	}

	const handleDelete = async (id: number) => {
		if (confirm("Are you sure you want to delete this video?")) {
			try {
				await axios.delete(`/api/video/${id}`, apiHeader)
				alertSuccess("Video deleted!")
				fetchVideos()
			} catch (e) {
				alertError("Something wen't wrong deleting the video.")
				console.error(e)
			}
		}
	}

	const selectedPlayerChanged = (newSelectedPlayer?: number) => {
		setSelectedPlayer(newSelectedPlayer)
		setPage(0)
	}

	useEffect(() => {
		fetchVideos()
	}, [])

	useEffect(() => {
		if (selectedPlayer) {
			setFilteredVideos(
				videos.filter(({ playerId }) => selectedPlayer === playerId)
			)
		} else {
			setFilteredVideos(videos)
		}
	}, [selectedPlayer, videos])

	return (
		<VideosTabContext.Provider
			value={{
				closeEditVideoModal,
				closeNewVideoModal,
				editVideoModal,
				fetchingVideos,
				fetchVideos,
				filteredVideos,
				handleChangePage,
				handleChangeRowsPerPage,
				handleDelete,
				handleEdit,
				handleSortChange,
				handleTableRequestSort,
				newVideoModalOpen,
				openNewVideoModal,
				order,
				orderBy,
				page,
				rowsPerPage,
				selectedPlayer,
				selectedPlayerChanged,
				videos,
			}}>
			{children}
		</VideosTabContext.Provider>
	)
}

const useVideosTabContext = () => useContext(VideosTabContext)

export { VideosTabContextProvider, useVideosTabContext }
