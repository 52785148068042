import React, { useContext, useState } from "react"

import axios from "axios"

// components
import {
	Card,
	CardActions,
	Button,
	CardHeader,
	IconButton,
	Menu,
	MenuItem,
} from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { EditResourceModal } from "./EditResourceModal"

// types and contexts
import { Resource } from "../../interfaces/Resource"
import { FeedbackContext } from "../../context/FeedbackContext"
import { GlobalsContext } from "../../context/GlobalsContext"
import { ResourcesUpdatedContext } from "./ResourcesPage"

interface Props {
	resource: Resource
}

export const ResourceCard = ({ resource }: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)

	// runs if the resource was deleted
	const { refetchResources } = useContext(ResourcesUpdatedContext)

	// which element the menu items (edit and delete) are anchoring off of
	const [cardMenuAnchor, setCardAnchorMenu] = useState<null | HTMLElement>(null)

	// whether or not to show the EditResourceModal
	const [editResourceModal, setEditResourceModal] = useState<boolean>(false)

	// function to delete this resource
	const deleteResource = async () => {
		try {
			await axios.delete(`/api/resource/${resource.id}`, globals.apiHeader)
			refetchResources()
			setFeedback({
				show: true,
				severity: "success",
				message: "Resource deleted!",
			})
		} catch (e) {
			console.log(e)
			setFeedback({
				show: true,
				severity: "error",
				message: "Something wen't wrong. Please try again.",
			})
		}
	}

	return (
		<>
			<Card className="resource-card">
				<CardHeader
					title={resource.title}
					subheader={resource.description}
					action={
						<>
							{globals.admin && (
								<>
									<IconButton
										aria-label="settings"
										aria-haspopup="true"
										onClick={(event: React.MouseEvent<HTMLElement>) =>
											setCardAnchorMenu(event.currentTarget)
										}
										color="inherit">
										<MoreVertIcon />
									</IconButton>
									<Menu
										anchorEl={cardMenuAnchor}
										keepMounted
										open={Boolean(cardMenuAnchor)}
										onClose={() => setCardAnchorMenu(null)}>
										<MenuItem
											onClick={() => {
												setCardAnchorMenu(null)
												setEditResourceModal(true)
											}}>
											Edit
										</MenuItem>
										<MenuItem
											onClick={() => {
												if (
													confirm(
														"Are you sure you want to delete this resource?"
													)
												) {
													deleteResource()
												}
												setCardAnchorMenu(null)
											}}>
											Delete
										</MenuItem>
									</Menu>
								</>
							)}
						</>
					}
				/>
				<CardActions>
					{resource.link ? (
						<Button
							color="primary"
							size="small"
							onClick={() => window.open(resource.link, "_blank")}>
							See Resource
						</Button>
					) : (
						<Button
							href={resource.file_path}
							color="primary"
							size="small"
							target="_blank">
							See Resource
						</Button>
					)}
				</CardActions>
			</Card>
			<EditResourceModal
				resource={resource}
				isOpen={editResourceModal}
				close={() => setEditResourceModal(false)}
			/>
		</>
	)
}
