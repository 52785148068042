import React, { useState, useContext, useEffect } from "react"

import axios from "axios"

// components
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
} from "@material-ui/core"

// types and contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { CategoriesUpdatedContext } from "./ResourcesTab"
import { ResourceCategory } from "../../../interfaces/ResourceCategory"
import { ButtonApiRequest } from "../../../shared/ButtonApiRequest"

// holds the information about the category
interface FormValues {
	name: string
}

interface Props {
	open: boolean
	close: () => void
	category: ResourceCategory
}

export const EditCategoryModal = ({ open, close, category }: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)
	const { refetchCategories } = useContext(CategoriesUpdatedContext)
	const [loading, setLoading] = useState<boolean>(false)

	// holds all the intitial category information before user edits it
	const initialFormValues: FormValues = {
		name: category.name,
	}

	// stores the updated values as the user edits the category
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues)

	useEffect(() => {
		resetFormValues()
	}, [category])

	// when the user presses "Save"
	const editCategory = async () => {
		// make sure all three important parts of a category are there
		if (!formValues.name) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please fill out all the required fields below",
			})
		}

		// send a request api to adjust the category
		try {
			setLoading(true)
			await axios.patch(
				`/api/resource_category/${category.id}`,
				{
					name: formValues.name,
				},
				globals.apiHeader
			)
			close()
			setFeedback({
				show: true,
				severity: "success",
				message: "Category saved!",
			})
			refetchCategories()
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message: "Something went wrong",
			})
			resetFormValues()
		}
		setLoading(false)
	}

	const resetFormValues = () => {
		setFormValues(initialFormValues)
	}

	const cancel = () => {
		close()
		resetFormValues()
	}

	return (
		<Dialog fullWidth maxWidth="sm" open={open} onClose={cancel}>
			<DialogTitle>Edit Category</DialogTitle>
			<DialogContent>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<TextField
							required
							fullWidth
							value={formValues.name}
							label="Name"
							type="text"
							variant="outlined"
							onChange={(event) =>
								setFormValues({ ...formValues, name: event.target.value })
							}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={cancel} color="primary">
					Cancel
				</Button>
				<ButtonApiRequest
					color="primary"
					text="Save"
					loading={loading}
					onClick={editCategory}
				/>
			</DialogActions>
		</Dialog>
	)
}
