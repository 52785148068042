import React, { useEffect } from "react"

import { Dialog, DialogContent } from "@material-ui/core"

import { TransitionUp } from "../../../../../shared/ModalTransitions/TransitionUp"
import { FieldD3 } from "./FieldD3"

interface Props {
	formState: { [key: string]: any }
	setFormState: React.Dispatch<
		React.SetStateAction<{
			[key: string]: any
		}>
	>
	showFieldModal: boolean
	setShowFieldModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const FieldModal = ({
	formState,
	setFormState,
	showFieldModal,
	setShowFieldModal,
}: Props) => {
	const handleClose = () => {
		setShowFieldModal(false)
	}

	useEffect(() => {
		handleClose()
	}, [formState.xSpray, formState.ySpray])

	return (
		<Dialog
			scroll="body"
			open={showFieldModal}
			onClose={handleClose}
			TransitionComponent={TransitionUp}>
			<DialogContent>
				<FieldD3 formState={formState} setFormState={setFormState} />
			</DialogContent>
		</Dialog>
	)
}
