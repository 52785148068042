import React, { useContext, useEffect, useState } from "react"

import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	Snackbar,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"

import { TransitionUp } from "../../../../../../shared/ModalTransitions/TransitionUp"
import { TransitionDown } from "../../../../../../shared/ModalTransitions/TransitionDown"
import { FieldD3 } from "./FieldD3"
import { ABResult } from "./ABResult"
import { Trajectory } from "./Trajectory"

import { Context } from "../../TrackTab"
import { GlobalsContext } from "../../../../../../context/GlobalsContext"

import { AddPitchHandler } from "../../utils/AddPitchHandler"

import "../../../../../../static/css/TrackTab/FieldModal.css"

/**
 * When the pitch type is IP, show the field to allow the user
 * 	to end spray information
 */
export const FieldModal = () => {
	const globals = useContext(GlobalsContext)
	const { state, setState, setLoading } = useContext(Context)
	const [feedback, setFeedback] = useState<boolean>(false)
	const [show, setShow] = useState<boolean>(state.showFieldModal)

	useEffect(() => {
		setShow(state.showFieldModal)
	}, [state.showFieldModal])

	// reset the spray information on close
	const handleClose = () => {
		setState({
			...state,
			showFieldModal: false,
			atBatEntry: {
				...state.atBatEntry,
				traj: null,
				result: null,
				hitHard: false,
				fielder: null,
				xSpray: null,
				ySpray: null,
			},
		})
	}

	// make sure information provided from user is good and then add the pitch
	const handleFinish = () => {
		if (
			!state.atBatEntry.fielder ||
			!state.atBatEntry.result ||
			!state.atBatEntry.traj ||
			!state.atBatEntry.xSpray ||
			!state.atBatEntry.ySpray
		) {
			return setFeedback(true)
		}
		let batterOut = false
		if (
			["Out", "Double Play", "Triple Play"].includes(state.atBatEntry.result)
		) {
			batterOut = true
		}
		setShow(false)
		AddPitchHandler(
			{ ...state, atBatEntry: { ...state.atBatEntry, batterOut } },
			setState,
			globals.apiHeader,
			setLoading
		)
	}

	return (
		<div>
			<Dialog
				scroll="body"
				open={show}
				onClose={handleClose}
				TransitionComponent={TransitionUp}>
				<DialogContent>
					<FieldD3 />
					<Trajectory />
					<ABResult />
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={handleClose}>
						Go Back
					</Button>
					<Button color="primary" onClick={handleFinish}>
						Finish
					</Button>
				</DialogActions>
				<Snackbar
					className="pt-field-modal-feedback"
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={feedback}
					autoHideDuration={5000}
					TransitionComponent={TransitionDown}
					onClose={() => setFeedback(false)}>
					<Alert onClose={() => setFeedback(false)} severity="error">
						Please select a fielder, spray location, trajectory, and at bat
						result.
					</Alert>
				</Snackbar>
			</Dialog>
		</div>
	)
}
