import { useContext, useState } from "react"

import axios from "axios"
import { Link } from "react-router-dom"

// components
import {
	IconButton,
	Menu,
	MenuItem,
	Switch,
	TableCell,
	TableRow,
	TextField,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/EditOutlined"
import DoneIcon from "@material-ui/icons/Done"
import ClearIcon from "@material-ui/icons/Clear"
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"
import VisibilityIcon from "@material-ui/icons/Visibility"

// types and contexts
import { Player } from "../../../interfaces/PlayerInterface"
import { GlobalsContext } from "../../../context/GlobalsContext"
import { FeedbackContext } from "../../../context/FeedbackContext"

interface Props {
	player: Player
	playerDeleted: () => void
}

export const PlayerRow = ({ player, playerDeleted }: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)

	// shows the options to view the player page (either pitching or hitting)
	const [
		visibilityMenuAnchor,
		setVisibilityMenuAnchor,
	] = useState<null | HTMLElement>(null)

	// whether or not the row is being editted or not
	const [editMode, setEditMode] = useState<boolean>(false)

	// this is for form values when edit mode is on
	const [playerValues, setPlayerValues] = useState<{ [key: string]: any }>({
		firstname: player.firstname,
		lastname: player.lastname,
		number: player.number,
		bats: player.bats,
		throws: player.throws,
		year: player.year,
		position: player.position,
		pitcher: player.pitcher,
		batter: player.batter,
		active: player.active,
	})

	// to handle input change when in edit mode
	const handleChange = (field: string, newValue: any) => {
		const newValues = { ...playerValues }
		newValues[field] = newValue
		setPlayerValues(newValues)
	}

	// revert changes
	const reset = () => {
		setEditMode(false)
		setPlayerValues({
			firstname: player.firstname,
			lastname: player.lastname,
			number: player.number,
			bats: player.bats,
			throws: player.throws,
			year: player.year,
			position: player.position,
			pitcher: player.pitcher,
			batter: player.batter,
			active: player.active,
		})
	}

	// delete player button
	const deletePlayer = async () => {
		if (confirm("Are you sure you want to delete this player?")) {
			try {
				await axios.delete(`/api/player/${player.id}`, globals.apiHeader)
				setEditMode(false)
				setFeedback({
					show: true,
					severity: "success",
					message: "Player deleted!",
				})
				playerDeleted()
			} catch (e) {
				console.log(e)
				setFeedback({
					show: true,
					severity: "error",
					message: "Something wen't wrong",
				})
				reset()
			}
		}
	}

	// save changes button
	const saveChanges = async () => {
		if (!playerValues.lastname) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please at least include a lastname for the player",
			})
		}
		try {
			await axios.patch(
				`/api/player/${player.id}`,
				{
					...playerValues,
					player_id:
						playerValues.player_id === "" ? null : playerValues.player_id,
				},
				globals.apiHeader
			)
			setEditMode(false)
			setFeedback({
				show: true,
				severity: "success",
				message: "Player saved!",
			})
		} catch (e) {
			reset()
			setFeedback({
				show: true,
				severity: "error",
				message: "Something wen't wrong",
			})
		}
	}

	if (editMode) {
		return (
			<TableRow>
				{globals.admin && (
					<TableCell>
						<IconButton onClick={saveChanges}>
							<DoneIcon />
						</IconButton>
						<IconButton onClick={reset}>
							<ClearIcon />
						</IconButton>
						<IconButton onClick={deletePlayer}>
							<DeleteOutlineIcon />
						</IconButton>
					</TableCell>
				)}
				<TableCell>
					<TextField
						value={playerValues.firstname}
						onChange={(e) => handleChange("firstname", e.target.value)}
					/>
				</TableCell>
				<TableCell>
					<TextField
						value={playerValues.lastname}
						onChange={(e) => handleChange("lastname", e.target.value)}
					/>
				</TableCell>
				<TableCell>
					<TextField
						type="number"
						value={playerValues.number}
						onChange={(e) => handleChange("number", e.target.value)}
					/>
				</TableCell>
				<TableCell>
					<TextField
						value={playerValues.throws}
						onChange={(e) => handleChange("throws", e.target.value)}
					/>
				</TableCell>
				<TableCell>
					<TextField
						value={playerValues.bats}
						onChange={(e) => handleChange("bats", e.target.value)}
					/>
				</TableCell>
				<TableCell>
					<TextField
						value={playerValues.year}
						onChange={(e) => handleChange("year", e.target.value)}
					/>
				</TableCell>
				<TableCell>
					<TextField
						value={playerValues.position}
						onChange={(e) => handleChange("position", e.target.value)}
					/>
				</TableCell>
				<TableCell>
					<Switch
						color="primary"
						checked={playerValues.pitcher}
						value={playerValues.pitcher}
						onChange={(e) => handleChange("pitcher", e.target.checked)}
					/>
				</TableCell>
				<TableCell>
					<Switch
						color="primary"
						checked={playerValues.batter}
						value={playerValues.batter}
						onChange={(e) => handleChange("batter", e.target.checked)}
					/>
				</TableCell>
				<TableCell>
					<Switch
						color="primary"
						checked={playerValues.active}
						value={playerValues.active}
						onChange={(e) => handleChange("active", e.target.checked)}
					/>
				</TableCell>
			</TableRow>
		)
	}

	return (
		<TableRow>
			<TableCell>
				{globals.admin && (
					<IconButton onClick={() => setEditMode(true)}>
						<EditIcon />
					</IconButton>
				)}
				<IconButton
					onClick={(event) => setVisibilityMenuAnchor(event.currentTarget)}>
					<VisibilityIcon />
				</IconButton>
				<Menu
					anchorEl={visibilityMenuAnchor}
					keepMounted
					open={Boolean(visibilityMenuAnchor)}
					onClose={() => setVisibilityMenuAnchor(null)}>
					<MenuItem component={Link} to={`/pitcher/${player.id}`}>
						Pitching
					</MenuItem>
					<MenuItem component={Link} to={`/hitter/${player.id}`}>
						Hitting
					</MenuItem>
				</Menu>
			</TableCell>
			<TableCell>{playerValues.firstname}</TableCell>
			<TableCell>{playerValues.lastname}</TableCell>
			<TableCell>{playerValues.number}</TableCell>
			<TableCell>{playerValues.throws}</TableCell>
			<TableCell>{playerValues.bats}</TableCell>
			<TableCell>{playerValues.year}</TableCell>
			<TableCell>{playerValues.position}</TableCell>
			<TableCell>{playerValues.pitcher ? "Yes" : "No"}</TableCell>
			<TableCell>{playerValues.batter ? "Yes" : "No"}</TableCell>
			<TableCell>{playerValues.active ? "Yes" : "No"}</TableCell>
		</TableRow>
	)
}
