import React, { useContext, useEffect, useState } from "react"

import {
	Chip,
	IconButton,
	Input,
	MenuItem,
	Select,
	TableCell,
	TableRow,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/EditOutlined"

import { ViewRunners } from "./ViewRunners"
import { MiniZone } from "./MiniZone"

import { Pitch } from "../../../../interfaces/PitchInterface"
import { AtBat } from "../../../../interfaces/AtBatInterface"
import { GlobalsContext } from "../../../../context/GlobalsContext"
import axios from "axios"

interface Props {
	pitch: Pitch
}

const pitchTypes = ["FB", "2S", "CH", "SL", "CB", "CT", "SPL", "OTH"]

export const PitchRow = ({ pitch }: Props) => {
	const [thePitch, setThePitch] = useState<Pitch>(pitch)
	const globals = useContext(GlobalsContext)

	return (
		<TableRow>
			<TableCell align="center">{thePitch.ab_pitch_num!}</TableCell>
			<TableCell align="center">{thePitch.hit_spot ? "Yes" : "No"}</TableCell>
			<TableCell align="center">
				<Chip
					style={{ margin: "2px" }}
					label={thePitch.pitch_type}
					className={`outing-locations-pitch-chip-${thePitch.pitch_type}`}
				/>
			</TableCell>
			<TableCell align="center">{thePitch.pitch_result}</TableCell>
			<TableCell align="center">{thePitch.velocity}</TableCell>
			<TableCell align="center">
				{thePitch.balls}-{thePitch.strikes}
			</TableCell>
			<TableCell align="center">{thePitch.notes}</TableCell>
			<TableCell align="center" style={{ padding: "0px" }}>
				<MiniZone xLoc={thePitch.loc_x!} yLoc={thePitch.loc_y!} />
			</TableCell>
		</TableRow>
	)
}
