interface Props {
	teamId: string
}

export const SpraysTab = ({ teamId }: Props) => {
	return (
		<div style={{ textAlign: "center", paddingTop: "25px" }}>
			<h1>
				For up to date spray charts on this team, go to{" "}
				<a
					href={`http://www.statmansprays.com/sprays?team=${teamId}`}
					target="_blank">
					Statman Sprays
				</a>{" "}
				founded by WashU's finest: Kenny Dorian.
			</h1>
		</div>
	)
}
