import { useContext, useState } from "react"

// components
import { Button, Container, Grid } from "@material-ui/core"
import { ResourceCard } from "./ResourceCard"

// types and contexts
import { ResourceCategory } from "../../interfaces/ResourceCategory"
import { ResourcesUpdatedContext } from "./ResourcesPage"
import { GlobalsContext } from "../../context/GlobalsContext"
import { EditResourceOrderModal } from "./EditResourceOrderModal"

interface Props {
	category: ResourceCategory
}

export const CategoryTab = ({ category }: Props) => {
	const globals = useContext(GlobalsContext)

	// function to open the new resource modal with a specific category
	const { openNewResourceModal } = useContext(ResourcesUpdatedContext)

	// whether the edit resource order modal is open or closed
	const [editResourceOrder, setEditResourceOrder] = useState<boolean>(false)

	return (
		<Container className="padding-top-24">
			<Grid container spacing={3}>
				{globals.admin && (
					<>
						<Grid item xs={6}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => openNewResourceModal(category)}>
								New {category.name} Resource
							</Button>
						</Grid>
						<Grid item xs={6} className="align-right">
							<Button
								variant="outlined"
								color="primary"
								onClick={() => setEditResourceOrder(true)}>
								Edit Order
							</Button>
						</Grid>
					</>
				)}
				{category.resources.map((resource) => (
					<Grid key={resource.id} item xs={12} sm={6} md={4} lg={3} xl={3}>
						<ResourceCard resource={resource} />
					</Grid>
				))}
				{globals.admin && (
					<EditResourceOrderModal
						category={category}
						isOpen={editResourceOrder}
						close={() => setEditResourceOrder(false)}
					/>
				)}
			</Grid>
		</Container>
	)
}
