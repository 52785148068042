import { useEffect, useRef, useState, useContext } from "react"
import * as d3 from "d3"

// to adjust the width of the strikezone if needed
import { useMediaQuery } from "@material-ui/core"

import { Context } from "../../TrackTab"

// icon to show where the pitch location was
import BaseballIcon from "../../../../../../static/img/BaseballIcon.png"

export const StrikeZoneD3 = () => {
	const { state, setState } = useContext(Context)
	const svgRef = useRef(null)
	const [height, setHeight] = useState<number>(525)
	const [width, setWidth] = useState<number>(488)
	const large = useMediaQuery("(min-width: 1024px)")
	const medium = useMediaQuery("(max-width: 1023px)")

	// adjusts the screen width
	useEffect(() => (medium ? setWidth(360) : setWidth(488)), [medium, large])

	let xScale = d3.scaleLinear().range([width, 0]).domain([2, -2])
	let yScale = d3.scaleLinear().range([height, 0]).domain([0, 4])

	const changePitchLoc = (xLoc: number, yLoc: number) => {
		setState({
			...state,
			pitchEntry: { ...state.pitchEntry, xLoc, yLoc },
		})
	}

	// runs the first time and any time the screen size changes
	useEffect(() => {
		// remove everything on the svg
		const svg = d3.select(svgRef.current)
		svg.selectAll("*").remove()

		// some colors for the makeshift field
		const dirtColor = "#99552B"
		const skyColor = "#4DB0E3"
		const fenceColor = "#444444"
		const grassColor = "#508632"

		// append a green rect that takes up whole svg
		// 	which will be the grass
		svg
			.append("rect")
			.attr("x", 0)
			.attr("y", 0)
			.attr("width", xScale(2) - xScale(-4))
			.attr("height", yScale(0))
			.style("fill", grassColor)

		// add the sky
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-2)}, ${yScale(4)}
					L ${xScale(-2)}, ${yScale(3.6)}
					C ${xScale(-2)}, ${yScale(3.6)}
					${xScale(0)}, ${yScale(3.65)}
					${xScale(2)}, ${yScale(3.6)}
					L ${xScale(2)}, ${yScale(4)} Z`
			)
			.style("fill", skyColor)

		// add the fence
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-2)}, ${yScale(3.6)}
					L ${xScale(-2)}, ${yScale(3.3)}
					C ${xScale(-2)}, ${yScale(3.3)}
					${xScale(0)}, ${yScale(3.35)}
					${xScale(2)}, ${yScale(3.3)}
					L ${xScale(2)}, ${yScale(3.6)}
					C ${xScale(2)}, ${yScale(3.6)}
					${xScale(0)}, ${yScale(3.65)}
					${xScale(-2)}, ${yScale(3.6)}`
			)
			.style("fill", fenceColor)

		// add the outfield grass
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-2)}, ${yScale(3.1)}
				L ${xScale(-2)}, ${yScale(3)}
				C ${xScale(-2)}, ${yScale(3)}
				${xScale(0)}, ${yScale(3.05)}
				${xScale(2)}, ${yScale(3)}
				L ${xScale(2)}, ${yScale(3.1)}
				C ${xScale(2)}, ${yScale(3.1)}
				${xScale(0)}, ${yScale(3.15)}
				${xScale(-2)}, ${yScale(3.1)}`
			)
			.style("fill", dirtColor)

		// add the infield dirt
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-2)}, ${yScale(2)}  
					C ${xScale(-2)}, ${yScale(2)} 
					${xScale(0)}, ${yScale(2.2)}
					${xScale(2)}, ${yScale(2)}
					L ${xScale(2)}, ${yScale(0)}
					L ${xScale(-2)}, ${yScale(0)} Z`
			)
			.style("fill", dirtColor)

		// add the batters box/foul lines LEFT
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-1.4)}, ${yScale(0)}
				L ${xScale(-1.15)}, ${yScale(1.2)}
				L ${xScale(-2)}, ${yScale(1.2)}
				M ${xScale(-1.7)}, ${yScale(1.2)}
				L ${xScale(-2)}, ${yScale(1.4)}`
			)
			.style("stroke", "white")
			.style("fill", "none")
			.style("stroke-width", 10)

		// add the batters box/foul lines RIGHT
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(1.4)}, ${yScale(0)}
				L ${xScale(1.15)}, ${yScale(1.2)}
				L ${xScale(2)}, ${yScale(1.2)}
				M ${xScale(1.7)}, ${yScale(1.2)}
				L ${xScale(2)}, ${yScale(1.4)}`
			)
			.style("stroke", "white")
			.style("fill", "none")
			.style("stroke-width", 10)

		// add the dirt around home plate
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-1)}, ${yScale(2.7)}
				C ${xScale(-1)}, ${yScale(2.7)}
				${xScale(0)}, ${yScale(2.6)}
				${xScale(1)}, ${yScale(2.7)}
				L ${xScale(0.3)}, ${yScale(2.9)}
				L ${xScale(-0.3)}, ${yScale(2.9)}
				L ${xScale(-1)}, ${yScale(2.7)}`
			)
			.style("fill", dirtColor)

		// add the mound
		svg
			.append("rect")
			.attr("width", xScale(0.1) - xScale(-0.1))
			.attr("height", yScale(2.89) - yScale(2.9))
			.attr("x", xScale(-0.1))
			.attr("y", yScale(2.9))
			.attr("fill", "white")

		// 2nd base
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-0.1)}, ${yScale(3.08)}
				L ${xScale(0)}, ${yScale(3.07)}
				L ${xScale(0.1)}, ${yScale(3.08)}
				L ${xScale(0)}, ${yScale(3.09)}
				L ${xScale(-0.1)}, ${yScale(3.08)}`
			)
			.style("fill", "white")

		// strikezone
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-0.833)},${yScale(1.75)}
					L ${xScale(-0.833)},${yScale(3.4)}
					L ${xScale(0.833)},${yScale(3.4)}
					L ${xScale(0.833)},${yScale(1.75)}
					L ${xScale(-0.858)},${yScale(1.75)}`
			)
			.style("stroke", "rgba(255,255,255, 0.4)")
			.style("fill", "none")
			.style("stroke-width", 6)

		// homeplate
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(0)},${yScale(0.2)}
				L ${xScale(-0.863)},${yScale(0.4)}
				L ${xScale(-0.833)},${yScale(0.7)}
				L ${xScale(0.83)},${yScale(0.7)}
				L ${xScale(0.863)},${yScale(0.4)}
				L ${xScale(0)},${yScale(0.2)}`
			)
			.style("stroke", "black")
			.style("fill", "white")
			.style("stroke-width", 1)
	}, [width])

	// when the pitch locations change, append the circle
	//	or when the width passes the breakpoint
	useEffect(() => {
		const svg = d3.select(svgRef.current)
		svg.selectAll("circle").remove()
		if (state.pitchEntry.xLoc && state.pitchEntry.yLoc) {
			svg
				.append("circle")
				.attr("class", "pt-field-spray-loc")
				.attr("cx", xScale(state.pitchEntry.xLoc))
				.attr("cy", yScale(state.pitchEntry.yLoc))
				.attr("r", "15")
				.attr("fill", "white")
		}
	}, [state.pitchEntry.xLoc, state.pitchEntry.yLoc, width])

	return (
		<svg
			className="d3-strike-zone-clickable"
			width={width}
			height={height}
			ref={svgRef}
			onClick={(event) => {
				const [x, y] = d3.pointer(event)
				const xLoc = Math.round(xScale.invert(x) * 100) / 100
				const yLoc = Math.round(yScale.invert(y) * 100) / 100
				changePitchLoc(xLoc, yLoc)
			}}></svg>
	)
}
