import {
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
} from "@material-ui/core"
import axios from "axios"
import { createContext, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { OutingParser } from "../../classes/OutingParser"
import { GlobalsContext } from "../../context/GlobalsContext"
import { Outing } from "../../interfaces/OutingInterface"
import { Player } from "../../interfaces/PlayerInterface"
import { Video } from "../../interfaces/VideoInterface"
import { LoadingBig } from "../../shared/Loading/LoadingBig"
import { SubnavTemplateCustomTitle } from "../../shared/MainLayout/SubnavTemplateCustomTitle/SubnavTemplateCustomTitle"
import { SeasonSelector } from "../../shared/SeasonSelector"
import { LocationsTab } from "./LocationsTab/LocationsTab"
import { OutingsTab } from "./OutingsTab/OutingsTab"
import "./PitcherPage.css"
import { StatsTab } from "./StatsTab/StatsTab"
import { VeloTab } from "./VeloTab/VeloTab"
import { VideosTab } from "./VideosTab/VideosTab"

interface ParamTypes {
	id: string | undefined
}

export const PitcherContext = createContext({
	pitcher: null as unknown as Player,
	outingParsers: [] as OutingParser[],
	loading: true,
	videos: [] as Video[],
})

export const PitcherPage = () => {
	const { apiHeader, currentSeason } = useContext(GlobalsContext)

	// the id of the player in the database
	const { id } = useParams<ParamTypes>()

	// stores all information about the pitcher
	const [pitcher, setPitcher] = useState<Player>()

	// stores all the outings for the pitcher ordered by date
	const [allOutingParsers, setAllOutingParsers] = useState<OutingParser[]>([])
	const [filteredOutingParsers, setFilteredOutingParsers] = useState<
		OutingParser[]
	>([])

	// how to filter the outings
	const [selectedSeason, setSelectedSeason] = useState<number>(currentSeason!)
	const [outingType, setOutingType] = useState<"All" | "Games" | "Practices">(
		"All"
	)

	// holds all of this pitchers videos
	const [videos, setVideos] = useState<Video[]>([])

	// whilte fetching the player, outings, and videos
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		const fetchPitcher = async () => {
			try {
				const response = await axios.get(`/api/player/${id}`, apiHeader)
				setPitcher(response.data)
			} catch (e) {
				console.log(e)
			}
		}
		fetchPitcher()
	}, [])

	useEffect(() => {
		const fetchOutings = async () => {
			setLoading(true)
			let url = `/api/outing?pitcher_id=${id}&order_by=date`
			if (selectedSeason !== 0) url += `&season_id=${selectedSeason}`
			try {
				const response = await axios.get(url, apiHeader)
				setAllOutingParsers(
					response.data.map((outing: Outing) => new OutingParser(outing))
				)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchOutings()
	}, [selectedSeason])

	useEffect(() => {
		const fetchVideos = async () => {
			try {
				const response = await axios.get(
					`/api/video?player_id=${id}&category=Pitching&season_id=${selectedSeason}&processing=False`,
					apiHeader
				)
				setVideos(response.data)
			} catch (e) {
				console.log(e)
			}
		}
		fetchVideos()
	}, [selectedSeason])

	useEffect(() => {
		setFilteredOutingParsers(
			allOutingParsers.filter((outingParser: OutingParser) => {
				switch (outingType) {
					case "Games":
						return outingParser.outing.game !== null
					case "Practices":
						return outingParser.outing.practice !== null
					default:
						return true
				}
			})
		)
	}, [outingType, allOutingParsers])

	if (!pitcher) {
		return <LoadingBig />
	}

	const tabs = [
		{
			name: "Outings",
			component: <OutingsTab />,
		},
		{
			name: "Velo",
			component: <VeloTab />,
		},
		{
			name: "Locations",
			component: <LocationsTab />,
		},
		{
			name: "Stats",
			component: <StatsTab />,
		},
		{
			name: "Videos",
			component: <VideosTab />,
		},
	]

	const title = (
		<Paper className="pitcher-title-paper">
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={8} className="align-left">
						<span className="pitcher-title-name">
							{`${pitcher.firstname} ${pitcher.lastname}`}
						</span>
					</Grid>
					<Grid item xs={12} sm={3} md={2}>
						<SeasonSelector
							disabled={loading}
							includeAll
							fullWidth
							onChange={(value: number) => setSelectedSeason(value)}
						/>
					</Grid>
					<Grid item xs={12} sm={3} md={2}>
						<FormControl
							disabled={loading}
							fullWidth
							variant="outlined"
							className="pitcher-title-outing-type-fc">
							<InputLabel>Outing Type</InputLabel>
							<Select
								disabled={loading}
								fullWidth
								label="Outing Type"
								value={outingType}
								onChange={(event) =>
									setOutingType(
										event.target.value as "All" | "Games" | "Practices"
									)
								}>
								<MenuItem value="All">All</MenuItem>
								<MenuItem value="Games">Games</MenuItem>
								<MenuItem value="Practices">Practices</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Container>
		</Paper>
	)

	return (
		<PitcherContext.Provider
			value={{
				pitcher,
				outingParsers: filteredOutingParsers,
				loading,
				videos,
			}}>
			<SubnavTemplateCustomTitle tabs={tabs} title={title} />
		</PitcherContext.Provider>
	)
}
