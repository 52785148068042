import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import { GlobalsContext } from "../context/GlobalsContext"

interface Props {
	onChange: (value: number) => void
	variant?: "filled" | "outlined" | "standard"
	fullWidth?: boolean
	required?: boolean
	defaultValue?: number
	includeAll?: boolean
	disabled?: boolean
}

export const SeasonSelector = ({
	onChange,
	variant,
	fullWidth,
	required,
	defaultValue,
	includeAll,
	disabled,
}: Props) => {
	const { seasons, currentSeason } = useContext(GlobalsContext)
	const [selectedSeason, setSelectedSeason] = useState<number>(
		defaultValue !== undefined ? defaultValue : currentSeason!
	)

	useEffect(() => {
		onChange(selectedSeason)
	}, [selectedSeason])

	return (
		<FormControl
			disabled={disabled}
			required={required}
			fullWidth={fullWidth}
			variant={variant ? variant : "outlined"}
			style={{ backgroundColor: "white", minWidth: "100px" }}>
			<InputLabel>Season</InputLabel>
			<Select
				disabled={disabled}
				required={required}
				label="Season"
				value={seasons.length === 0 ? "" : selectedSeason}
				onChange={(event) => setSelectedSeason(event.target.value as number)}>
				{includeAll && (
					<MenuItem selected={selectedSeason === 0} value={0}>
						All
					</MenuItem>
				)}
				{seasons.map((s, i) => (
					<MenuItem key={s.id} value={s.id}>
						{s.year}-{s.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
