import React from "react"

import { ChangePassword } from "./ChangePassword"
import { SubnavTemplate } from "../../shared/MainLayout/SubnavTemplate/SubnavTemplate"

export const ProfilePage = () => {
	const tabs = [{ name: "Change Password", component: <ChangePassword /> }]

	return <SubnavTemplate tabs={tabs} title="Profile" />
}
