import { ChangeEvent, Fragment, useContext, useState } from "react"

import axios from "axios"

// components
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core"
import { ButtonApiRequest } from "../../shared/ButtonApiRequest"
import { TransitionUp } from "../../shared/ModalTransitions/TransitionUp"

// context
import { GlobalsContext } from "../../context/GlobalsContext"
import { HittingPageContext } from "./HittingPage"
import { FeedbackContext } from "../../context/FeedbackContext"

interface NewHitterModalProps {
	open: boolean
	handleClose: () => void
}

export const NewHitterModal = ({ open, handleClose }: NewHitterModalProps) => {
	const { apiHeader, teamId } = useContext(GlobalsContext)
	const { refetchPlayers, players } = useContext(HittingPageContext)
	const { alertSuccess, alertError } = useContext(FeedbackContext)
	const activePitchers = players.filter(
		(player) => player.active && !player.batter && player.pitcher
	)
	const [pitcherSelected, setPitcherSelected] = useState<number | string>(
		activePitchers.length > 0 ? activePitchers[0].id : ""
	)
	const [loading, setLoading] = useState<boolean>(false)
	const [chooseFromPitchers, setChooseFromPitchers] = useState<"Yes" | "No">(
		"No"
	)
	const [formValues, setFormValues] = useState<{ [key: string]: any }>({
		firstname: "",
		lastname: "",
		number: "",
		bats: "",
		throws: "",
		year: "",
		position: "",
		pitcher: false,
		batter: true,
		team_id: teamId,
		active: true,
	})

	const clearForm = () => {
		setChooseFromPitchers("No")
		setPitcherSelected("")
		setFormValues({
			firstname: "",
			lastname: "",
			number: "",
			bats: "",
			throws: "",
			year: "",
			position: "",
			pitcher: false,
			batter: true,
			team_id: teamId,
			active: true,
		})
	}

	const formChange = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const newValues = { ...formValues }
		newValues[event.target.name] = event.target.value
		setFormValues(newValues)
	}

	const addHitter = async () => {
		setLoading(true)
		try {
			if (chooseFromPitchers === "Yes") {
				await axios.patch(
					`/api/player/${pitcherSelected}`,
					{ batter: true },
					apiHeader
				)
				alertSuccess("Pitcher now marked as a hitter as well!")
			} else {
				await axios.post("/api/player", formValues, apiHeader)
				alertSuccess("Hitter created!")
			}
			handleClose()
			clearForm()
			refetchPlayers()
		} catch (e) {
			alertError(
				"Something wen't wrong. Please refresh the page and try again!"
			)
			console.log(e)
		}
		setLoading(false)
	}

	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			open={open}
			onClose={handleClose}
			TransitionComponent={TransitionUp}>
			<DialogTitle>New Hitter</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormControl fullWidth>
							<InputLabel>Choose from Existing Pitchers</InputLabel>
							<Select
								fullWidth
								label="Choose from Existing Pitchers"
								value={chooseFromPitchers}
								onChange={(event) => {
									setChooseFromPitchers(event.target.value as "No" | "Yes")
								}}>
								<MenuItem value={"No"}>No</MenuItem>
								<MenuItem value={"Yes"}>Yes</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{chooseFromPitchers === "Yes" && (
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel>Active Pitchers</InputLabel>
								<Select
									fullWidth
									label="Active Pitchers"
									value={pitcherSelected}
									onChange={(event) =>
										setPitcherSelected(event.target.value as number)
									}>
									{activePitchers.map((player) => (
										<MenuItem value={player.id} key={player.id}>
											{player.firstname} {player.lastname}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}
					{chooseFromPitchers === "No" && (
						<Fragment>
							<Grid item xs={12}>
								<TextField
									value={formValues.firstname}
									onChange={formChange}
									name="firstname"
									label="Firstname"
									type="text"
									required
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.lastname}
									onChange={formChange}
									name="lastname"
									label="Lastname"
									type="text"
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.number}
									onChange={formChange}
									name="number"
									label="Number"
									type="text"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.bats}
									onChange={formChange}
									name="bats"
									label="Bats"
									type="text"
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.throws}
									onChange={formChange}
									name="throws"
									label="Throws"
									type="text"
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.year}
									onChange={formChange}
									name="year"
									label="Year"
									type="text"
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.position}
									onChange={formChange}
									name="position"
									label="Position"
									type="text"
									fullWidth
								/>
							</Grid>
						</Fragment>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
				<ButtonApiRequest
					disabled={
						(chooseFromPitchers === "No" &&
							(!formValues.firstname ||
								!formValues.lastname ||
								!formValues.bats ||
								!formValues.throws ||
								!formValues.year)) ||
						(chooseFromPitchers === "Yes" && pitcherSelected === "")
					}
					loading={loading}
					onClick={addHitter}
					color="primary"
					text={chooseFromPitchers === "Yes" ? "Mark as Hitter Too" : "Create"}
				/>
			</DialogActions>
		</Dialog>
	)
}
