import { AtBat } from "../../../../../interfaces/AtBatInterface"
import { Outing } from "../../../../../interfaces/OutingInterface"
import { Player } from "../../../../../interfaces/PlayerInterface"

import { AddPitchHandler } from "./AddPitchHandler"

const defaultAtBatEntry = {
	result: null,
	traj: null,
	fielder: null,
	xSpray: null,
	ySpray: null,
	hitHard: null,
	inning: null,
	notes: "",
	outs: null,
	batterOut: null,
}

const atBatEntries = {
	strikeoutSwinging: {
		...defaultAtBatEntry,
		result: "K",
		batterOut: true,
	},
	strikeoutLooking: {
		...defaultAtBatEntry,
		result: "ꓘ",
		batterOut: true,
	},
	walk: {
		...defaultAtBatEntry,
		result: "BB",
		batterOut: false,
	},
	hitByPitch: {
		...defaultAtBatEntry,
		result: "HBP",
		batterOut: false,
	},
	inPlayOut: {
		...defaultAtBatEntry,
		result: "In Play (Out)",
		batterOut: true,
	},
	single: {
		...defaultAtBatEntry,
		result: "1B",
		batterOut: false,
	},
	double: {
		...defaultAtBatEntry,
		result: "2B",
		batterOut: false,
	},
	triple: {
		...defaultAtBatEntry,
		result: "3B",
		batterOut: false,
	},
	homerun: {
		...defaultAtBatEntry,
		result: "HR",
		batterOut: false,
	},
	doublePlay: {
		...defaultAtBatEntry,
		result: "Double Play",
		batterOut: true,
	},
	triplePlay: {
		...defaultAtBatEntry,
		result: "Triple Play",
		batterOut: true,
	},
	fieldersChoice: {
		...defaultAtBatEntry,
		result: "FC",
		batterOut: false,
	},
	error: {
		...defaultAtBatEntry,
		result: "Error",
		batterOut: false,
	},
	dropThirdOut: {
		...defaultAtBatEntry,
		result: "Drop Third (Out)",
		batterOut: true,
	},
	dropThirdSafe: {
		...defaultAtBatEntry,
		result: "Drop Third (Safe)",
		batterOut: false,
	},
	catchersInterference: {
		...defaultAtBatEntry,
		result: "Catcher's Interference",
		batterOut: false,
	},
	intentionalWalk: {
		...defaultAtBatEntry,
		result: "IBB",
		batterOut: false,
	},
	other: {
		...defaultAtBatEntry,
		result: "Other",
		batterOut: null,
	},
	otherOut: {
		...defaultAtBatEntry,
		result: "Other (Out)",
		batterOut: true,
	},
	otherSafe: {
		...defaultAtBatEntry,
		result: "Other (Safe)",
		batterOut: false,
	},
}

const results = {
	strikeoutSwinging: {
		description: "Strikout Swinging",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.strikeoutSwinging,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	strikeoutLooking: {
		description: "Strikout Looking",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.strikeoutLooking,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	walk: {
		description: "Walk",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.walk,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	hitByPitch: {
		description: "Hit By Pitch",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.hitByPitch,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	inPlayOut: {
		description: "In Play (Out)",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.inPlayOut,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	single: {
		description: "Single",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.single,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	double: {
		description: "Double",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.double,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	triple: {
		description: "Triple",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.triple,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	homerun: {
		description: "Homerun",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.homerun,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	doublePlay: {
		description: "Double Play",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.doublePlay,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	triplePlay: {
		description: "Triple Play",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.triplePlay,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	fieldersChoice: {
		description: "Fielders Choice",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.fieldersChoice,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	error: {
		description: "Error",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.error,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	dropThirdOut: {
		description: "Drop Third (Out)",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.dropThirdOut,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	dropThirdSafe: {
		description: "Drop Third (Safe)",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.dropThirdSafe,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	catchersInterference: {
		description: "Catchers Interference",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.catchersInterference,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	intentionalWalk: {
		description: "Intentional Walk",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.intentionalWalk,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	other: {
		description: "Other",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.other,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	otherOut: {
		description: "Other (Out)",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.otherOut,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
	otherSafe: {
		description: "Other (Safe)",
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) =>
			AddPitchHandler(
				{
					...state,
					atBatEntry: {
						...atBatEntries.otherSafe,
						outs: state.outingState.outs,
						inning: state.outingState.inning,
					},
				},
				setState,
				apiHeader,
				setLoading
			),
	},
}

export const atBatResultOptions = {
	swingingStrikeWithTwoStrikes: [
		results.strikeoutSwinging,
		results.dropThirdOut,
		results.dropThirdSafe,
		results.hitByPitch,
		results.otherOut,
		results.otherSafe,
		results.other,
	],
	calledStrikeWithTwoStrikes: [
		results.strikeoutLooking,
		results.dropThirdOut,
		results.dropThirdSafe,
		results.hitByPitch,
		results.otherOut,
		results.otherSafe,
		results.other,
	],
	ballWithThreeBalls: [
		results.walk,
		results.otherOut,
		results.otherSafe,
		results.other,
	],
	inPlay: [
		results.inPlayOut,
		results.single,
		results.double,
		results.triple,
		results.homerun,
		results.doublePlay,
		results.triplePlay,
		results.error,
		results.fieldersChoice,
		results.otherOut,
		results.otherSafe,
		results.other,
	],
	endAtBatEarlyWithPitch: [
		results.catchersInterference,
		results.strikeoutSwinging,
		results.strikeoutLooking,
		results.walk,
		results.hitByPitch,
		results.dropThirdOut,
		results.dropThirdSafe,
		results.otherOut,
		results.otherSafe,
		results.other,
	],
	endAtBatEarlyWithoutPitch: [
		results.intentionalWalk,
		results.walk,
		results.otherOut,
		results.otherSafe,
		results.other,
	],
}

export interface State {
	outing: Outing | null
	allBatters: Player[]
	selectedBatter: number | string
	currentAtBat: AtBat | null
	showFieldModal: boolean
	showAtBatResultModal: boolean
	showIncludeLastPitchModal: boolean
	showEndAtBatModal: boolean
	showBatterGameResults: boolean
	feedback: {
		show: boolean
		text: string
		severity: string // possible options are error, warning, information, success
	}
	currentAtBatResultOptions: {
		description: string
		action: (
			state: State,
			setState: React.Dispatch<React.SetStateAction<State>>,
			apiHeader: any,
			setLoading: React.Dispatch<React.SetStateAction<boolean>>
		) => void
	}[]
	endAtBatWithoutPitch: boolean
	pitchEntry: {
		xLoc: number | null
		yLoc: number | null
		hitSpot: boolean
		pitchType: string | null
		pitchResult: string | null
		velocity: number | string
		timeToPlate: number | string
		rollThrough: boolean
		shortSet: boolean
		notes: string
		runnerFirst: boolean
		runnerSecond: boolean
		runnerThird: boolean
	}
	atBatEntry: {
		result: string | null
		traj: string | null
		fielder: string | null
		xSpray: number | null
		ySpray: number | null
		hitHard: boolean | null
		inning: number | null
		notes: string
		outs: number | null
		batterOut: boolean | null
	}
	outingState: {
		inning: number
		balls: number
		strikes: number
		outs: number
	}
}

export const initialState: State = {
	outing: null,
	allBatters: [],
	selectedBatter: "",
	currentAtBat: null,
	showFieldModal: false,
	showAtBatResultModal: false,
	showIncludeLastPitchModal: false,
	showEndAtBatModal: false,
	showBatterGameResults: false,
	currentAtBatResultOptions: atBatResultOptions.swingingStrikeWithTwoStrikes,
	endAtBatWithoutPitch: false,
	feedback: {
		show: false,
		text: "",
		severity: "success",
	},
	pitchEntry: {
		xLoc: null,
		yLoc: null,
		hitSpot: false,
		rollThrough: false,
		shortSet: false,
		pitchType: null,
		pitchResult: null,
		velocity: "",
		timeToPlate: "",
		notes: "",
		runnerFirst: false,
		runnerSecond: false,
		runnerThird: false,
	},
	atBatEntry: {
		result: null,
		traj: null,
		fielder: null,
		xSpray: null,
		ySpray: null,
		hitHard: false,
		inning: null,
		notes: "",
		outs: null,
		batterOut: null,
	},
	outingState: {
		inning: 1,
		balls: 0,
		strikes: 0,
		outs: 0,
	},
}
