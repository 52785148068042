import { createContext } from "react"
import { Season } from "../interfaces/SeasonInterface"
import { User } from "../interfaces/UserInterface"

export const GlobalsContext = createContext({
	admin: false,
	currentSeason: null as number | null,
	teamId: null as number | null,
	apiHeader: null as any,
	user: null as User | null,
	seasons: [] as Season[],
	setSeasons: null as unknown as (newSeasons: Season[]) => void,
})
