import React, { useState, useContext } from "react"

import axios from "axios"

// components
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
} from "@material-ui/core"

// types and contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { CategoriesUpdatedContext } from "./ResourcesTab"
import { ButtonApiRequest } from "../../../shared/ButtonApiRequest"

// holds the information about the category
interface FormValues {
	name: string
}

interface Props {
	open: boolean
	close: () => void
}

export const NewCategoryModal = ({ open, close }: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)
	const { refetchCategories } = useContext(CategoriesUpdatedContext)
	const [loading, setLoading] = useState<boolean>(false)

	// holds all the intitial category information before user edits it
	const initialFormValues: FormValues = {
		name: "",
	}

	// stores the updated values as the user edits the category
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues)

	// when the user presses "Create"
	const createCategory = async () => {
		// make sure all three important parts of a category are there
		if (!formValues.name) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please fill out all the required fields below",
			})
		}

		// send a request to api to create the category
		try {
			setLoading(true)
			await axios.post(
				`/api/resource_category`,
				{
					name: formValues.name,
				},
				globals.apiHeader
			)
			close()
			setFeedback({
				show: true,
				severity: "success",
				message: "Category created!",
			})
			refetchCategories()
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message: "Something went wrong while creating the category",
			})
		}
		resetFormValues()
		setLoading(false)
	}

	const resetFormValues = () => {
		setFormValues(initialFormValues)
	}

	const cancel = () => {
		close()
		resetFormValues()
	}

	return (
		<div>
			<Dialog fullWidth maxWidth="sm" open={open} onClose={cancel}>
				<DialogTitle>Create Category</DialogTitle>
				<DialogContent>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								value={formValues.name}
								label="Name"
								type="text"
								variant="outlined"
								onChange={(event) =>
									setFormValues({ ...formValues, name: event.target.value })
								}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={cancel} color="primary">
						Cancel
					</Button>
					<ButtonApiRequest
						text="Create"
						loading={loading}
						onClick={createCategory}
						color="primary"
					/>
				</DialogActions>
			</Dialog>
		</div>
	)
}
