import {
	Container,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core"
import React from "react"
import { Outing } from "../../../interfaces/OutingInterface"
import { PitchTypeDataRow } from "./PitchTypeDataRow"

interface Props {
	outing: Outing
}

const pitchTypes = ["FB", "2S", "CH", "SL", "CB", "CT", "SPL", "OTH"]

export const StatsTab = ({ outing }: Props) => {
	return (
		<Container style={{ marginTop: "20px" }}>
			<Grid container spacing={0} component={Paper}>
				<Grid item xs={12} md={12}>
					<TableContainer>
						<Table size="medium">
							<TableHead>
								<TableRow>
									<TableCell align="center">Pitch</TableCell>
									<TableCell align="center">#</TableCell>
									<TableCell align="center">Avg</TableCell>
									<TableCell align="center">Max</TableCell>
									<TableCell align="center">Min</TableCell>
									<TableCell align="center">Usage%</TableCell>
									<TableCell align="center">Strike%</TableCell>
									<TableCell align="center">Whiff%</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{pitchTypes.map((pitchType) => (
									<TableRow key={pitchType}>
										<PitchTypeDataRow outing={outing} pitchType={pitchType} />
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				{/* <Grid item xs={12} md={6}>
					[VELO OVER OUTING CHART WILL BE HERE]
				</Grid> */}
			</Grid>
			<br />
			<br />
			<br />
			<br />
		</Container>
	)
}
