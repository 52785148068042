import { useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core"
import { GamesPracticesToggle } from "../../../shared/GamesPracticesToggle"
import { AtBatCard } from "./AtBatCard"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"

// types and contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { AtBat } from "../../../interfaces/AtBatInterface"

// util functions
import { formatDate } from "../../../shared/Functions/FormatDate"
import { SeasonSelector } from "../../../shared/SeasonSelector"

interface Props {
	playerId: number
}

export const AtBatsTab = ({ playerId }: Props) => {
	const globals = useContext(GlobalsContext)
	const [loading, setLoading] = useState<boolean>(true)

	// the season to select the at bats from
	const [selectedSeason, setSelectedSeason] = useState<number>(
		globals.currentSeason!
	)

	// if the user wants to look at a players atbats for games or practices
	const [compType, setCompType] = useState<"games" | "practices">("games")

	// which competition (practice or game id) that is selected
	const [selectedComp, setSelectedComp] = useState<number | "">("")

	// all the competitions whether "Games" or "Practices" is selected
	const [competitions, setCompetitions] = useState<{ [key: string]: any }[]>([])

	// the atBats based on the filter
	const [atBats, setAtBats] = useState<AtBat[]>([])

	// anytime the competition type changes, refetch competitions
	useEffect(() => {
		const fetchCompetitions = async () => {
			setLoading(true)
			try {
				const response = await axios.get(
					`/api/season/${selectedSeason}/${compType}`,
					globals.apiHeader
				)
				setCompetitions(response.data)
				if (response.data.length > 0) {
					setSelectedComp(response.data[response.data.length - 1].id)
				} else {
					setSelectedComp("")
				}
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchCompetitions()
	}, [compType, selectedSeason])

	// anytime the selected competition changes, refetch at bats
	useEffect(() => {
		const fetchAtBatsForComp = async () => {
			setLoading(true)

			// if user selected a blank competition
			if (selectedComp === "") {
				setAtBats([])
				return setLoading(false)
			}

			// either practice_id or game_id
			let url = `/api/atbat?player_id=${playerId}&practice_id=${selectedComp}`
			if (compType === "games") {
				url = `/api/atbat?player_id=${playerId}&game_id=${selectedComp}`
			}

			// get the at bats for the filter
			try {
				const response = await axios.get(url, globals.apiHeader)
				setAtBats(response.data)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchAtBatsForComp()
	}, [selectedComp])

	return (
		<Container className="padding-top-24 padding-bottom-24">
			<Grid container spacing={0}>
				<Grid item xs={12} md={4}>
					<SeasonSelector
						onChange={(value: number) => setSelectedSeason(value)}
					/>
				</Grid>
				<Grid item xs={12} md={4} className="align-center">
					<GamesPracticesToggle
						value={compType}
						onChange={(value: "games" | "practices") => {
							if (value) {
								setCompType(value)
							}
						}}
					/>
				</Grid>
				<Grid item xs={12} md={4} className="align-right">
					<FormControl
						style={{
							backgroundColor: "white",
							minWidth: "150px",
							textAlign: "left",
						}}
						variant="outlined">
						<InputLabel>
							{compType === "games" ? "Game" : "Practice"}
						</InputLabel>
						<Select
							disabled={loading}
							label={compType === "games" ? "Game" : "Practice"}
							value={selectedComp}
							onChange={(event) =>
								setSelectedComp(event.target.value as number)
							}>
							{competitions.map((comp: { [key: string]: any }) => (
								<MenuItem key={comp.id} value={comp.id}>
									{!comp.home_team ? (
										formatDate(comp.date as string)
									) : (
										<>
											{comp.home_team.id === globals.teamId
												? `${formatDate(comp.date)} vs. ${comp.away_team.name}`
												: `${formatDate(comp.date)} @ ${comp.home_team.name}`}
										</>
									)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					{loading ? (
						<LoadingBig />
					) : (
						<>
							{atBats.length === 0 ? (
								<h1>No at bats</h1>
							) : (
								<div>
									{atBats.map((atBat) => (
										<AtBatCard key={atBat.id} atBat={atBat} />
									))}
								</div>
							)}
						</>
					)}
				</Grid>
			</Grid>
		</Container>
	)
}
