import React, { useState } from "react"

// to format date of outing
import moment from "moment"

import { Button, Container, Grid, Paper } from "@material-ui/core"
import { Outing } from "../../../../../interfaces/OutingInterface"
import { Redirect } from "react-router-dom"

interface Props {
	outing: Outing
}

export const Header = ({ outing }: Props) => {
	const [exit, setExit] = useState<boolean>(false)

	// format date MM/DD/YY
	const getOutingInfo = () => {
		let momentObj
		if (outing.practice) {
			momentObj = moment(outing.practice!.date)
			return `${momentObj.format("M/D/YY")} PRACTICE`
		} else {
			momentObj = `${moment(outing.game!.date)} GAME`
		}
	}
	const outingInfo = getOutingInfo()

	if (exit) {
		return <Redirect push to={`/outing/${outing.id}`} />
	}

	return (
		<Paper elevation={0} className="pt-pitches-header-paper">
			<Container>
				<Grid className="pt-pitches-header" container spacing={0}>
					<Grid item xs={3} className="pt-pitches-exit-button-div">
						<Button
							onClick={() => setExit(true)}
							variant="contained"
							color="primary"
							disableElevation>
							Exit
						</Button>
					</Grid>
					<Grid item xs={6} className="align-center">
						<div id="pt-pitches-header-pitcher-name">
							{outing.pitcher.firstname} {outing.pitcher.lastname} #
							{outing.pitcher.number}
						</div>
						<div id="pt-pitches-header-outing-info">{outingInfo}</div>
					</Grid>
					<Grid item xs={3} className="align-right"></Grid>
				</Grid>
			</Container>
		</Paper>
	)
}
