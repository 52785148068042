import React, { ReactNode, useContext, useState } from "react"

import { Paper, Container } from "@material-ui/core"

import { Subnav } from "./Subnav"
import { TabPanel } from "./TabPanel"
import { GlobalsContext } from "../../../context/GlobalsContext"

interface Tab {
	name: string
	component: ReactNode
	admin?: boolean
}

interface Props {
	title: ReactNode
	tabs: Array<Tab>
}

export const SubnavTemplateCustomTitle = ({ title, tabs }: Props) => {
	const globals = useContext(GlobalsContext)
	const [tab, setTab] = useState<number>(0)

	tabs = tabs.filter((tab) => !tab.admin || (tab.admin && globals.admin))
	return (
		<div>
			{title}
			<Subnav
				currentTab={tab}
				tabChangeHandler={(event: React.ChangeEvent<{}>, value: number) => {
					setTab(value)
				}}
				tabs={tabs.map((tab) => tab.name)}
			/>
			{tabs.map((t: Tab, i: number) => (
				<TabPanel key={i} index={i} value={tab}>
					{t.component}
				</TabPanel>
			))}
		</div>
	)
}
