import { useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	Card,
	CardContent,
	Grid,
	List,
	ListItem,
	ListItemText,
} from "@material-ui/core"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"

// types and contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { Practice } from "../../../interfaces/PracticeInterface"

// functions
import { formatDate } from "../../../shared/Functions/FormatDate"
import { Link } from "react-router-dom"

export const RecentPractices = () => {
	const globals = useContext(GlobalsContext)

	// while fetching the recent practices
	const [loading, setLoading] = useState<boolean>(true)

	// if there was an error or if there are no practices
	const [feedback, setFeedback] = useState<string>("")

	// holds at most 3 most recent practices
	const [practices, setPractices] = useState<Practice[]>([])

	// fetch this seasons most recent practices
	useEffect(() => {
		const fetchPractices = async () => {
			try {
				// fetch and save the resposne
				const response = await axios.get(
					`/api/practice?season_id=${globals.currentSeason}&order=desc`,
					globals.apiHeader
				)
				const seasonPractices: Practice[] = response.data

				// show the user different things depending on number of practices returned
				if (seasonPractices.length === 0) {
					setFeedback("No games yet this season")
					setPractices([])
				} else if (
					seasonPractices.length === 1 ||
					seasonPractices.length === 2
				) {
					setFeedback("")
					setPractices(seasonPractices)
				} else {
					setFeedback("")
					setPractices(seasonPractices.slice(0, 3))
				}
			} catch (e) {
				console.log(e)
				setFeedback("Something wen't wrong")
			}
			setLoading(false)
		}
		fetchPractices()
	}, [])

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Card className="recent-competitions-card">
			<CardContent className="recent-competitions-card-content">
				<h2 className="recent-competitions-card-title">Recent Practices</h2>
				<hr></hr>
				{feedback !== "" && (
					<span className="recent-competitions-card-feedback">{feedback}</span>
				)}
				<List>
					{practices.map((practice) => (
						<ListItem
							key={practice.id}
							component={Link}
							to={`/practice/${practice.id}`}
							button>
							<ListItemText
								primary={
									<Grid container spacing={0}>
										<Grid item xs={8}>
											Practice
										</Grid>
										<Grid item xs={4} className="align-right">
											{formatDate(practice.date as string)}
										</Grid>
									</Grid>
								}
							/>
						</ListItem>
					))}
				</List>
			</CardContent>
		</Card>
	)
}
