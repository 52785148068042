import React, { useState, useEffect, useContext } from "react"

import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Switch,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"

import axios from "axios"

import { GlobalsContext } from "../../../context/GlobalsContext"
import { UsersTabContext } from "./TypesAndContexts"

interface FormValues {
	firstname: string
	lastname: string
	email: string
	password: string
	current_coach: boolean
	current_player: boolean
	admin: boolean
	player_id: any
}

interface Error {
	text: string
	show: boolean
}

const initialFormValues: FormValues = {
	firstname: "",
	lastname: "",
	email: "",
	password: "",
	current_coach: false,
	current_player: true,
	admin: false,
	player_id: "",
}

const initialError: Error = {
	text: "",
	show: false,
}

export const NewUserModal = () => {
	const globals = useContext(GlobalsContext)
	const { state, dispatch } = useContext(UsersTabContext)
	const [error, setError] = useState<Error>(initialError)
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues)

	const addUser = async () => {
		const data = { ...formValues }
		if (data.player_id === "") data.player_id = null
		try {
			await axios.post("/api/user", data, globals.apiHeader)
			dispatch({ type: "NEW_USER_CREATED" })
			resetModalState()
		} catch (e) {
			if (e.response.status === 400) {
				if (e.response.data) {
					setError({ text: "Email already taken", show: true })
				} else {
					setError({ text: "Fill out all required fields!", show: true })
				}
			} else {
				dispatch({ type: "ADD_USER_ERROR" })
				resetModalState()
			}
		}
	}

	const closeModal = () => {
		dispatch({ type: "CLOSE_NEW_USER_MODAL" })
		resetModalState()
	}

	const resetModalState = () => {
		setError(initialError)
		setFormValues(initialFormValues)
	}

	const [passwordLabel, setPasswordLabel] = useState<string>(
		"Password (they can change it later)"
	)
	window.addEventListener("resize", () => {
		if (window.innerWidth < 400) setPasswordLabel("Password")
	})

	return (
		<div>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={state.newUserModal}
				onClose={closeModal}
				aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Create User</DialogTitle>
				<DialogContent>
					<Grid container spacing={0}>
						{error.show && (
							<Grid item xs={12}>
								<Alert severity="error">{error.text}</Alert>
							</Grid>
						)}
						<Grid item xs={12}>
							<TextField
								required
								style={{ marginTop: "12px" }}
								value={formValues.firstname}
								onChange={(event) =>
									setFormValues({
										...formValues,
										firstname: event.target.value,
									})
								}
								id="firstname"
								label="Firstname"
								type="text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								value={formValues.lastname}
								onChange={(event) =>
									setFormValues({
										...formValues,
										lastname: event.target.value,
									})
								}
								style={{ marginTop: "12px" }}
								id="lastname"
								label="Lastname"
								type="text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								value={formValues.email}
								onChange={(event) =>
									setFormValues({
										...formValues,
										email: event.target.value,
									})
								}
								style={{ marginTop: "12px" }}
								id="email"
								label="Email"
								type="text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								value={formValues.password}
								onChange={(event) =>
									setFormValues({
										...formValues,
										password: event.target.value,
									})
								}
								style={{ marginTop: "12px" }}
								id="password"
								label={passwordLabel}
								type="text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormControlLabel
								style={{ marginTop: "20px" }}
								control={
									<Switch
										color="primary"
										onChange={(event) =>
											setFormValues({
												...formValues,
												current_coach: event.target.checked,
											})
										}
										value={formValues.current_coach}
									/>
								}
								label="Coach?"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormControlLabel
								style={{ marginTop: "20px" }}
								control={
									<Switch
										color="primary"
										onChange={(event) =>
											setFormValues({
												...formValues,
												current_player: event.target.checked,
											})
										}
										defaultChecked
										value={formValues.current_player}
									/>
								}
								label="Player?"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<FormControlLabel
								style={{ marginTop: "20px" }}
								control={
									<Switch
										color="primary"
										onChange={(event) =>
											setFormValues({
												...formValues,
												admin: event.target.checked,
											})
										}
										value={formValues.admin}
									/>
								}
								label="Admin?"
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl style={{ width: "100%", marginTop: "20px" }}>
								<InputLabel>Select Player</InputLabel>
								<Select
									value={formValues.player_id}
									onChange={(event) =>
										setFormValues({
											...formValues,
											player_id: event.target.value,
										})
									}>
									<MenuItem value="">None</MenuItem>
									{state.players.map((player) => (
										<MenuItem key={player.id} value={player.id}>
											{player.firstname} {player.lastname} #{player.number}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeModal} color="primary">
						Cancel
					</Button>
					<Button onClick={addUser} color="primary">
						Create
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
