import DateFnsUtils from "@date-io/date-fns"
import {
	AppBar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import axios from "axios"
import "date-fns"
import { useContext, useEffect, useState } from "react"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { GlobalsContext } from "../../../context/GlobalsContext"
import { Outing } from "../../../interfaces/OutingInterface"
import { ButtonApiRequest } from "../../../shared/ButtonApiRequest"
import { formatOutingName } from "../../../shared/Functions/FormatOutingName"
import { getDateFromString } from "../../../shared/Functions/GetDateFromString"
import { isYouTubeUrl } from "../../../shared/Functions/IsYoutubeUrl"
import { TransitionUp } from "../../../shared/ModalTransitions/TransitionUp"
import { PlayerSelector } from "../../../shared/PlayerSelector"
import { SeasonSelector } from "../../../shared/SeasonSelector"
import { useVideosTabContext } from "./VideoTabState"

// Whether the video is of the player pitching or hitting
export type Category = "Pitching" | "Hitting"

// the values of the form the user will interact with
type EditVideoFormState = {
	youtubeLink: string
	playerId?: number
	title: string
	date: Date
	seasonId?: number
	outingId: number | ""
	category: Category
}

export const EditVideoModal = () => {
	const { currentSeason, apiHeader } = useContext(GlobalsContext)
	const { alertError } = useContext(FeedbackContext)
	const { closeEditVideoModal, fetchVideos, editVideoModal } =
		useVideosTabContext()

	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

	const initialVideoFormState: EditVideoFormState = {
		youtubeLink:
			editVideoModal?.uploadType === "youtube" ? editVideoModal?.link : "",
		title: editVideoModal?.title ?? "",
		date: getDateFromString(editVideoModal?.date),
		category: editVideoModal?.category ?? "Pitching",
		outingId: editVideoModal?.outingId ?? "",
		playerId: editVideoModal?.playerId,
		seasonId: editVideoModal?.seasonId ?? currentSeason ?? undefined,
	}
	const [outings, setOutings] = useState<Outing[]>([])
	const [fetchingOutings, setFetchingOutings] = useState<boolean>(true)
	const [savingVideo, setSavingVideo] = useState<boolean>(false)

	const [videoFormState, setVideoFormState] = useState<EditVideoFormState>(
		initialVideoFormState
	)

	const handleClose = () => {
		setVideoFormState(initialVideoFormState)
		closeEditVideoModal()
	}

	const handleSaveChanges = async () => {
		const { title, date, category, playerId, seasonId, outingId, youtubeLink } =
			videoFormState

		const data: { [key: string]: any } = {
			title: title,
			date,
			category,
			player_id: playerId,
			season_id: seasonId,
			outing_id: outingId === "" ? null : outingId,
		}

		if (editVideoModal?.uploadType === "youtube") {
			data.link = youtubeLink
		}

		setSavingVideo(true)
		try {
			await axios.patch(`/api/video/${editVideoModal?.id}`, data, apiHeader)
			await fetchVideos()
			handleClose()
		} catch (e) {
			console.error(e)
			alertError("Something wen't wrong while saving the video")
		}
		setSavingVideo(false)
	}

	// listen for changes to playerId, seasonId, and category to refetch a list of outings for the player
	useEffect(() => {
		const { category, playerId, seasonId } = videoFormState
		if (category == "Pitching" && playerId && seasonId) {
			const fetchOutings = async () => {
				setFetchingOutings(true)
				try {
					const response = await axios.get(
						`/api/outing?pitcher_id=${playerId}&season_id=${seasonId}&bare=true&order_by=date`,
						apiHeader
					)
					setOutings(response.data)
					const outing = response.data.find(
						(outing: Outing) => outing.id === videoFormState.outingId
					)
					setVideoFormState({
						...videoFormState,
						outingId: outing ? outing.id : "",
					})
				} catch (e) {
					setOutings([])
					alertError("Something wen't wrong while fetching the players outing")
					console.error(e)
				}
				setFetchingOutings(false)
			}
			fetchOutings()
		} else {
			setOutings([])
		}
	}, [
		videoFormState.playerId,
		videoFormState.seasonId,
		videoFormState.category,
	])

	const { youtubeLink, playerId, title, date, seasonId } = videoFormState
	const disableSubmit = Boolean(
		(editVideoModal?.uploadType === "youtube" && !isYouTubeUrl(youtubeLink)) ||
			!playerId ||
			!title ||
			!date ||
			!seasonId
	)

	return (
		<Dialog
			disableBackdropClick={savingVideo}
			fullScreen={fullScreen}
			scroll="body"
			fullWidth
			maxWidth="sm"
			open={true}
			onClose={handleClose}
			TransitionComponent={TransitionUp}>
			{!fullScreen && <DialogTitle>Edit Video</DialogTitle>}
			<DialogContent
				style={{
					marginBottom: "32px",
					marginTop: fullScreen ? "56px" : "0px",
				}}>
				<Grid container spacing={2} alignItems="center">
					{fullScreen && (
						<Grid item xs={12}>
							<AppBar>
								<Toolbar style={{ alignItems: "center" }}>
									<IconButton
										edge="start"
										color="inherit"
										onClick={handleClose}>
										<CloseIcon />
									</IconButton>
									<Typography variant="h6">Edit Video</Typography>
									<div style={{ flexGrow: 1, textAlign: "right" }}>
										<ButtonApiRequest
											loading={savingVideo}
											disabled={disableSubmit}
											onClick={handleSaveChanges}
											color="inherit"
											text="Save Changes"
											variant="text"
										/>
									</div>
								</Toolbar>
							</AppBar>
						</Grid>
					)}
					<Grid item xs={12}>
						<div style={{ fontSize: "16px", paddingBottom: "4px" }}>
							Video Content
						</div>
						<ToggleButtonGroup
							value={videoFormState.category}
							exclusive
							onChange={(event, value) => {
								if (value) {
									setVideoFormState({
										...videoFormState,
										category: value as Category,
										outingId: "",
									})
								}
							}}>
							<ToggleButton value="Pitching" className="toggle-button">
								Pitching
							</ToggleButton>
							<ToggleButton value="Hitting" className="toggle-button">
								Hitting
							</ToggleButton>
						</ToggleButtonGroup>
					</Grid>
					<Grid item xs={12} style={{ marginTop: "8px" }}>
						<PlayerSelector
							required
							initialValue={videoFormState.playerId}
							variant="standard"
							handleChange={(playerId: number) => {
								setVideoFormState({
									...videoFormState,
									playerId,
									outingId: "",
								})
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							value={videoFormState.title}
							onChange={(
								event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
							) =>
								setVideoFormState({
									...videoFormState,
									title: event.target.value as string,
								})
							}
							name="title"
							label="Title"
							type="text"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								required
								style={{ width: "100%" }}
								disableToolbar
								inputVariant="standard"
								format="MM/dd/yyyy"
								margin="normal"
								label="Date"
								autoOk={true}
								value={videoFormState.date}
								onChange={(date) =>
									setVideoFormState({
										...videoFormState,
										date: date as Date,
									})
								}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12}>
						<SeasonSelector
							defaultValue={editVideoModal?.seasonId}
							variant="standard"
							fullWidth
							onChange={(seasonId) =>
								setVideoFormState({ ...videoFormState, seasonId })
							}
						/>
					</Grid>
					{videoFormState.category === "Pitching" && videoFormState.playerId && (
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel>Outing</InputLabel>
								<Select
									disabled={fetchingOutings}
									label="Outing"
									value={videoFormState.outingId}
									onChange={(event) =>
										setVideoFormState({
											...videoFormState,
											outingId: event.target.value as number,
										})
									}>
									<MenuItem value="">None</MenuItem>
									{outings.map((outing) => (
										<MenuItem key={outing.id} value={outing.id}>
											{formatOutingName(outing)}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}
					{editVideoModal?.uploadType === "youtube" && (
						<Grid item xs={12}>
							<TextField
								value={videoFormState.youtubeLink}
								onChange={(event) =>
									setVideoFormState({
										...videoFormState,
										youtubeLink: event.target.value as string,
									})
								}
								name="link"
								label="Link"
								type="text"
								fullWidth
							/>
						</Grid>
					)}
				</Grid>
			</DialogContent>
			{!fullScreen && (
				<DialogActions>
					<Button onClick={handleClose} color="primary" disabled={savingVideo}>
						Cancel
					</Button>
					<ButtonApiRequest
						loading={savingVideo}
						disabled={disableSubmit}
						onClick={handleSaveChanges}
						color="primary"
						variant="contained"
						text="Save Changes"
					/>
				</DialogActions>
			)}
		</Dialog>
	)
}
