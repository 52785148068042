// components
import { Container, Grid } from "@material-ui/core"
import { useContext } from "react"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import { VideoCard } from "../../../shared/VideoCard"
// contexts
import { PitcherContext } from "../PitcherPage"

export const VideosTab = () => {
	const { loading, videos } = useContext(PitcherContext)

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container className="tab-container">
			<Grid container spacing={2}>
				{videos.map((video) => (
					<Grid key={video.id} item xs={12} sm={6} md={4}>
						<VideoCard video={video} />
					</Grid>
				))}
			</Grid>
		</Container>
	)
}
