export type SortOrder = "asc" | "desc"

export interface TableHeadCell {
	id: string
	label: string
	numeric: boolean
}

function descendingComparator(
	a: { [key: string]: any },
	b: { [key: string]: any },
	orderBy: string
) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

export function getComparator(order: SortOrder, orderBy: string) {
	return order === "desc"
		? (a: any, b: any) => descendingComparator(a, b, orderBy)
		: (a: any, b: any) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility
export function stableSort(
	array: any[],
	comparator: (a: any, b: any) => number
) {
	const stabilizedThis = array.map((el, index) => [el, index] as [any, number])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}
