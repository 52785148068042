import {
	Button,
	ButtonGroup,
	Container,
	Grid,
	Link as MuiLink,
	Paper,
	useMediaQuery,
	useTheme,
} from "@material-ui/core"
import axios from "axios"
import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import { Link, Redirect, useParams } from "react-router-dom"
import { FeedbackContext } from "../../context/FeedbackContext"
import { GlobalsContext } from "../../context/GlobalsContext"
import { Outing } from "../../interfaces/OutingInterface"
import { LoadingBig } from "../../shared/Loading/LoadingBig"
import { SubnavTemplateCustomTitle } from "../../shared/MainLayout/SubnavTemplateCustomTitle/SubnavTemplateCustomTitle"
import "../../static/css/OutingPage.css"
import { EditOutingModal } from "./EditOutingModal"
import { LocationsTab } from "./LocationsTab/LocationsTab"
import { PitchesTab } from "./PitchesTab/PitchesTab"
import { StatsTab } from "./StatsTab/StatsTab"
import { SummaryTab } from "./SummaryTab/SummaryTab"
import { VideosTab } from "./VideosTab/VideosTab"

interface ParamTypes {
	id: string | undefined
}

export const OutingPage = () => {
	const globals = useContext(GlobalsContext)
	const { id } = useParams<ParamTypes>()
	const { setFeedback } = useContext(FeedbackContext)
	const [loading, setLoading] = useState<boolean>(true)
	const [outing, setOuting] = useState<Outing>()
	const theme = useTheme()
	const xs = useMediaQuery(theme.breakpoints.up("sm"))
	const [editOutingModal, setEditOutingModal] = useState<boolean>(false)

	const fetchOuting = async () => {
		setLoading(true)
		try {
			const response = await axios.get(`/api/outing/${id}`, globals.apiHeader)
			setOuting(response.data)
		} catch (e) {
			console.log(e)
		}
		setLoading(false)
	}

	useEffect(() => {
		fetchOuting()
	}, [])

	const deleteOuting = async () => {
		if (
			confirm(
				"Are you sure you want to delete the outing? This will delete all at bats and pitches associated with it!"
			) &&
			outing
		) {
			try {
				await axios.delete(`/api/outing/${outing.id}`, globals.apiHeader)
				setFeedback({
					show: true,
					severity: "success",
					message: "Outing Deleted!",
				})
				setOuting(undefined)
			} catch (e) {
				setFeedback({
					show: true,
					severity: "error",
					message: "Something went wrong",
				})
				console.log(e)
			}
		}
	}

	let pitcherName = ""
	let competition = ""
	let linkToComp = ""
	if (outing) {
		pitcherName = `${outing?.pitcher.firstname} ${outing?.pitcher.lastname}`
	}
	if (outing?.practice) {
		const date = moment(outing.practice.date).format("M/D/YY")
		competition = `${date} Practice`
		linkToComp = `/practice/${outing.practice.id}`
	} else if (outing?.game) {
		const date = moment(outing.game.date).format("M/D/YY")
		if (outing.game.home_team.id === globals.teamId) {
			competition = `${date} vs. ${outing.game.away_team.name}`
		} else {
			competition = `${date} vs. ${outing.game.home_team.name}`
		}
		linkToComp = `/game/${outing.game.id}`
	}

	if (loading) {
		return <LoadingBig />
	}

	if (outing) {
		const tabs = [
			{
				name: "Summary",
				component: <SummaryTab outing={outing} />,
			},
			{ name: "Stats", component: <StatsTab outing={outing} /> },
			{ name: "Pitches", component: <PitchesTab outing={outing} /> },
			{ name: "Locations", component: <LocationsTab outing={outing} /> },
			{
				name: "Videos",
				component: <VideosTab outingId={outing.id} />,
			},
			{
				name: "Track",
				component: <Redirect push to={`/outing/${outing.id}/track`} />,
				admin: true,
			},
		]

		return (
			<>
				<EditOutingModal
					outing={outing}
					isOpen={editOutingModal}
					close={() => setEditOutingModal(false)}
					refetchOuting={fetchOuting}
				/>
				<SubnavTemplateCustomTitle
					title={
						<Paper
							style={{
								padding: "5px 0px",
								fontWeight: "bold",
							}}>
							<Container>
								<Grid container spacing={0}>
									<Grid item xs={12} sm={8} className="align-left">
										<h1>
											<MuiLink color="primary" component={Link} to={linkToComp}>
												{competition}
											</MuiLink>{" "}
											- {pitcherName}
										</h1>
									</Grid>
									{globals.admin && (
										<Grid
											item
											xs={12}
											sm={4}
											className={xs ? "align-right" : ""}>
											<h1>
												<ButtonGroup disableElevation color="primary">
													<Button onClick={() => setEditOutingModal(true)}>
														Edit
													</Button>
													<Button onClick={deleteOuting}>Delete</Button>
												</ButtonGroup>
											</h1>
										</Grid>
									)}
								</Grid>
							</Container>
						</Paper>
					}
					tabs={tabs}
				/>
			</>
		)
	}

	return <Redirect to="/" />
}
