import React, { createContext, useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	Button,
	Container,
	Grid,
	List,
	ListItem,
	ListItemText,
	Divider,
	Paper,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import { NewScoutingPageModal } from "./NewScoutingPageModal"
import { LoadingBig } from "../../shared/Loading/LoadingBig"

// types and contexts
import { GlobalsContext } from "../../context/GlobalsContext"
import { TeamScoutingPage } from "../../interfaces/TeamScoutingPageInterface"

// functions
import { formatDate } from "../../shared/Functions/FormatDate"

// holds all the existing scouting pages
export const ScoutPagesContext = createContext({
	scoutPages: null as unknown as TeamScoutingPage[],
})

export const ScoutingPage = () => {
	// for api request headers
	const globals = useContext(GlobalsContext)

	// while fetching the pages
	const [loading, setLoading] = useState<boolean>(true)

	// whether or not to show the modal to create a new page
	const [newScoutingPageModal, setNewScoutingPageModal] =
		useState<boolean>(false)

	// holds the list of scouting pages and their basic info
	const [scoutingPages, setScoutingPages] = useState<TeamScoutingPage[]>([])

	// changes when a new one has been created, updated, or deleted
	const [refetchPages, setRefetchPages] = useState<boolean>(false)

	// fetch pages on load and when requested
	useEffect(() => {
		const fetchPages = async () => {
			setLoading(true)
			try {
				const response = await axios.get(
					"/api/team_scout_page/all",
					globals.apiHeader
				)
				setScoutingPages(response.data)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchPages()
	}, [refetchPages])

	// while fetching the list of pages
	if (loading) {
		return <LoadingBig />
	}

	return (
		<ScoutPagesContext.Provider value={{ scoutPages: scoutingPages }}>
			<Container className="padding-top-24 padding-bottom-24">
				<Grid container spacing={0}>
					{globals.admin && (
						<Grid item xs={12} className="padding-bottom-24">
							<Button
								color="primary"
								variant="contained"
								onClick={() => setNewScoutingPageModal(true)}>
								New Scout Page
							</Button>
						</Grid>
					)}
					<Grid item xs={12} component={Paper}>
						{scoutingPages.length === 0 ? (
							<h1 style={{ marginLeft: "12px" }}>No scout pages created yet</h1>
						) : (
							<List>
								{scoutingPages.map((scoutingPage: TeamScoutingPage) => (
									<div key={scoutingPage.id}>
										<ListItem
											button
											component={Link}
											to={`/scouting/${scoutingPage.id}`}>
											<ListItemText
												primary={scoutingPage.team.name}
												secondary={`Last Updated: ${formatDate(
													scoutingPage.last_updated
												)}`}
											/>
										</ListItem>
										<Divider variant="fullWidth" component="li" />
									</div>
								))}
							</List>
						)}
					</Grid>
				</Grid>
				<NewScoutingPageModal
					open={newScoutingPageModal}
					close={() => setNewScoutingPageModal(false)}
					refetchScoutPages={() => setRefetchPages(!refetchPages)}
				/>
			</Container>
		</ScoutPagesContext.Provider>
	)
}
