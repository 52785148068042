import { useRef, useEffect, useState } from "react"

// for creating the visual
import * as d3 from "d3"

// types and contexts
import { Pitch } from "../../../interfaces/PitchInterface"
import { AtBat } from "../../../interfaces/AtBatInterface"

// util functions
import { GenerateTooltipHTML } from "../../../shared/Functions/GenerateTooltipHTML"
import { Outing } from "../../../interfaces/OutingInterface"

interface Props {
	atBats: AtBat[]
	width: number
	height: number
	outing: Outing
}

// adds a few parameters for helpful information
interface PitchAdjusted extends Pitch {
	pitchNum: number
	inning: number
	batterName: string
}

export const VeloChart = ({ atBats, width, height, outing }: Props) => {
	const gRef = useRef(null)
	const svgRef = useRef(null)
	const tooltipRef = useRef(null)

	// used for left, right, and bottom margins
	const margins = 50

	// keep the colors of each pitch consistent
	const pitchColors: { [key: string]: string } = {
		FB: "#E70B18", // red
		CB: "#2326FA", // blue
		SL: "#118A14", // green
		CH: "#C8C72A", // yellow
		CT: "#21C8C7", // baby blue
		"2S": "#FD8024", // orange
		SPL: "#653896", // purple
		OTH: "#000000", // black
	}

	// so the tick labels don't intersect each other on smaller screens
	const getXAxisTicks = (pitchNum: number) => {
		if (pitchNum > 40) {
			return Math.round(pitchNum / 4)
		} else {
			return pitchNum
		}
	}

	// runs on load and every time the atbats or size passed to it gets updated
	useEffect(() => {
		// remove everything from the svg group element
		const viz = d3.select(gRef.current)
		viz.selectAll("*").remove()

		// store the pitches data by pitch type
		let pitches: { [key: string]: PitchAdjusted[] } = {
			FB: [],
			CB: [],
			SL: [],
			CH: [],
			CT: [],
			"2S": [],
			SPL: [],
			OTH: [],
		}

		// iterate through the pitches and store helpful information
		let velocities: Array<string> = []
		let inningStarts: number[] = []
		let pitchNum = 0
		let inning = 1
		atBats.forEach((atBat) => {
			if (atBat.pitches) {
				atBat.pitches!.forEach((pitch) => {
					pitchNum += 1
					if (inning !== atBat.inning) {
						inning = atBat.inning
						inningStarts.push(pitchNum)
					}
					if (pitch.velocity) {
						velocities.push((pitch.velocity as unknown) as string)
						pitches[pitch.pitch_type].push({
							...pitch,
							inning: atBat.inning,
							pitchNum,
							batterName: atBat.player.lastname,
						})
					}
				})
			}
		})

		// x scale based on the total number of pitches
		let xScale = d3.scaleLinear().domain([1, pitchNum]).range([0, width])

		// y scale based on the velocity range
		let yScale = d3
			.scaleLinear()
			.domain([
				(d3.max(velocities) as unknown) as number,
				(d3.min(velocities) as unknown) as number,
			])
			.range([0, height])

		// add the x axis
		viz
			.append("g")
			.attr("transform", `translate(0, ${height})`)
			.call(d3.axisBottom(xScale).ticks(getXAxisTicks(pitchNum)))
		viz
			.append("text")
			.attr("x", width / 2)
			.attr("y", height + (4 * margins) / 5)
			.text("Pitch Num")
			.attr("text-anchor", "middle")

		// add the y axis
		viz.append("g").call(d3.axisLeft(yScale))
		viz
			.append("text")
			.attr("x", -height / 2)
			.attr("y", (-2 * margins) / 3)
			.text("Velocity (MPH)")
			.attr("transform", "rotate(-90)")
			.attr("text-anchor", "middle")

		// to indicate where a new inning starts for the outing
		inningStarts.forEach((pitchNum) => {
			viz
				.append("line")
				.attr("x1", xScale(pitchNum))
				.attr("y1", -5)
				.attr("x2", xScale(pitchNum))
				.attr("y2", height)
				.attr("stroke", "black")
				.attr("stroke-width", "1px")
				.attr("fill", "none")
				.attr("stroke-dasharray", "10")
		})

		// generates the line for each pitch type in the game
		const line = d3
			.line<PitchAdjusted>()
			.x((d) => xScale(d.pitchNum))
			.y((d) => yScale(d.velocity!))

		// iterate through each pitch type
		Object.keys(pitches).forEach((pitchType) => {
			// draw the lines
			viz
				.append("path")
				.attr("class", ".velo-chart-line")
				.attr("stroke-width", "2px")
				.attr("stroke", pitchColors[pitchType])
				.attr("fill", "none")
				.attr("d", (d) => line(pitches[pitchType]))

			// draw the circles
			viz
				.selectAll(`circle-${pitchType}`)
				.data(pitches[pitchType])
				.enter()
				.append("circle")
				.attr("cx", (d) => xScale(d.pitchNum))
				.attr("cy", (d) => yScale(d.velocity as number))
				.attr("r", 4)
				.attr("fill", pitchColors[pitchType])
				.attr("class", `velo-chart-circle circle-${pitchType}`)
				.on("mouseover", function (event) {
					// generate a tooltip on hover
					const data: PitchAdjusted = event.srcElement.__data__
					const coordinates = d3.pointer(event, document.querySelector("body"))
					d3.select(tooltipRef.current)
						.style("display", "block")
						.style("position", "absolute")
						.style("left", `${coordinates[0] + 10}px`)
						.style("top", `${coordinates[1] + 10}px`)
						.html(
							GenerateTooltipHTML([
								{
									label: "Num",
									value: data.pitchNum,
								},
								{
									label: "Batter",
									value: data.batterName,
								},
								{
									label: "Type",
									value: data.pitch_type,
								},
								{
									label: "Velo",
									value: data.velocity!,
								},
								{
									label: "Result",
									value: data.pitch_result,
								},
								{
									label: "Count",
									value: `${data.balls}-${data.strikes}`,
								},
								{
									label: "Spot",
									value: data.hit_spot ? "Yes" : "No",
								},
								{
									label: "Notes",
									value: data.notes ? data.notes : "",
								},
							])
						)
				})
				.on("mouseout", function (event) {
					// hide the tooltip when the mouse leaves the circle
					d3.select(tooltipRef.current).style("display", "none")
				})
		})
	}, [atBats, width, height])

	return (
		<>
			<svg
				style={{ backgroundColor: "white", fontFamily: "Poppins" }}
				ref={svgRef}
				id={`outing-velo-chart-${outing.id}`}
				width={width + 2 * margins}
				height={height + (3 * margins) / 2}>
				<g ref={gRef} transform={`translate(${margins}, ${margins / 2})`}></g>
			</svg>
			<div ref={tooltipRef} />
		</>
	)
}
