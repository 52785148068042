import { Container, Drawer } from "@material-ui/core"
import { useContext, useEffect, useState } from "react"
import { AtBat } from "../../../../../../interfaces/AtBatInterface"
import { Context } from "../../TrackTab"
import { AtBatCard } from "./AtBatCard"

interface Props {
	open: boolean
	close: () => void
}

export const BatterGameResults = ({ open, close }: Props) => {
	const [atBats, setAtBats] = useState<AtBat[]>([])
	const { state } = useContext(Context)

	useEffect(() => {
		if (state.outing) {
			setAtBats(
				state.outing.at_bats.filter(
					(atBat) => atBat.batter === state.selectedBatter
				)
			)
		}
	}, [state.outing!.at_bats, state.selectedBatter])

	return (
		<Drawer anchor="right" open={open} onClose={close}>
			<Container
				style={{ backgroundColor: "#f5f5f5" }}
				className="padding-top-24 padding-bottom-24">
				{atBats.length > 0 ? (
					<>
						{atBats.map((atBat) => (
							<AtBatCard atBat={atBat} key={atBat.id} />
						))}
					</>
				) : (
					<div>No at bats yet today</div>
				)}
			</Container>
		</Drawer>
	)
}
