import { useState, useEffect, useContext } from "react"

import axios from "axios"

// components
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
} from "@material-ui/core"
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import { SeasonSelector } from "../../../shared/SeasonSelector"
import { TransitionUp } from "../../../shared/ModalTransitions/TransitionUp"
import { ButtonApiRequest } from "../../../shared/ButtonApiRequest"

// for the date picker
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"

// contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { CompetitionsPageContext } from "../CompetitionsPage"

// holds the necessary information for the practice
interface FormValues {
	date: any
	season: number | string | null
}

const initialFormValues: FormValues = {
	date: new Date(),
	season: 0,
}

interface Props {
	open: boolean
	handleClose: () => void
	defaultSeason: number
}

export const NewPracticeModal = ({
	open,
	handleClose,
	defaultSeason,
}: Props) => {
	const { apiHeader, currentSeason } = useContext(GlobalsContext)
	const { alertSuccess, alertError } = useContext(FeedbackContext)
	const { refetchCompetitions } = useContext(CompetitionsPageContext)

	const [loading, setLoading] = useState<boolean>(false)
	const [formValues, setFormValues] = useState<FormValues>({
		...initialFormValues,
		season: defaultSeason !== 0 ? defaultSeason : currentSeason,
	})

	useEffect(() => {
		resetFormValues()
	}, [defaultSeason])

	const formatDate = (date: Date) =>
		`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

	const addPractice = async () => {
		setLoading(true)
		try {
			await axios.post(
				"/api/practice",
				{
					season_id: formValues.season,
					date: formatDate(formValues.date),
				},
				apiHeader
			)
			alertSuccess("Practice created!")
			handleClose()
			refetchCompetitions()
		} catch (e) {
			alertError("Something went wrong")
		}
		resetFormValues()
		setLoading(false)
	}

	const resetFormValues = () => {
		setFormValues({
			...initialFormValues,
			season: defaultSeason !== 0 ? defaultSeason : currentSeason,
		})
	}

	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			open={open}
			onClose={handleClose}
			TransitionComponent={TransitionUp}>
			<DialogTitle>New Practice</DialogTitle>
			<DialogContent>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								fullWidth
								required
								disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								margin="normal"
								label="Date"
								autoOk={true}
								value={formValues.date}
								onChange={(date) => setFormValues({ ...formValues, date })}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12} className="padding-top-12">
						<SeasonSelector
							defaultValue={formValues.season as number}
							required
							fullWidth
							variant="standard"
							onChange={(value: number) =>
								setFormValues({ ...formValues, season: value })
							}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
				<ButtonApiRequest
					text="Create"
					disabled={isNaN(formValues.date) || !formValues.season}
					color="primary"
					onClick={addPractice}
					loading={loading}
				/>
			</DialogActions>
		</Dialog>
	)
}
