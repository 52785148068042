import { useEffect, useRef } from "react"

import * as d3 from "d3"
import { useMediaQuery } from "@material-ui/core"

// types
import { DetailedPitch } from "../../../interfaces/DetailedPitch"
import { PitchType } from "../../../context/Types"

// constants
import { pitchColors } from "../../../index"

interface Props {
	pitches: DetailedPitch[]
}

export const LocationsD3 = ({ pitches }: Props) => {
	const svgRef = useRef(null)
	const tooltipRef = useRef(null)

	const smallScreen = useMediaQuery("(max-width: 600px)")

	const width = smallScreen ? 300 : 500
	const height = smallScreen ? 300 : 500

	let xScale = d3.scaleLinear().range([width, 0]).domain([2, -2])
	let yScale = d3.scaleLinear().range([height, 0]).domain([0, 4])

	useEffect(() => {
		const svg = d3.select(svgRef.current)
		svg.selectAll("*").remove()

		// strikezone
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-0.833)},${yScale(1.75)}
					L ${xScale(-0.833)},${yScale(3.4)}
					L ${xScale(0.833)},${yScale(3.4)}
					L ${xScale(0.833)},${yScale(1.75)}
					L ${xScale(-0.833)},${yScale(1.75)}`
			)
			.style("stroke", "black")
			.style("fill", "white")
			.style("stroke-width", 1)

		// homeplate
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(0)},${yScale(0.2)}
				L ${xScale(-0.863)},${yScale(0.4)}
				L ${xScale(-0.833)},${yScale(0.7)}
				L ${xScale(0.83)},${yScale(0.7)}
				L ${xScale(0.863)},${yScale(0.4)}
				L ${xScale(0)},${yScale(0.2)}`
			)
			.style("stroke", "black")
			.style("fill", "white")
			.style("stroke-width", 1)
	}, [width, height])

	useEffect(() => {
		const svg = d3.select(svgRef.current)
		svg.selectAll("circle").remove()
		pitches.forEach((pitch) => {
			if (pitch.loc_x && pitch.loc_y) {
				svg
					.append("circle")
					.attr("class", "outing-locations-pitch-circle")
					.attr("cx", xScale(pitch.loc_x))
					.attr("cy", yScale(pitch.loc_y))
					.attr("r", 7)
					.style("fill", pitchColors[pitch.pitch_type as PitchType])
			}
		})
	}, [pitches, width, height])

	return (
		<>
			<svg
				style={{ fill: "white" }}
				width={width}
				height={height}
				ref={svgRef}></svg>
			<div ref={tooltipRef} />
		</>
	)
}
