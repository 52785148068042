import React, { useContext, useState } from "react"

import axios from "axios"

import {
	Checkbox,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	TableCell,
	TableRow,
	TextField,
	Tooltip,
} from "@material-ui/core"
import DoneIcon from "@material-ui/icons/DoneOutlined"
import CloseIcon from "@material-ui/icons/Close"
import DeleteIcon from "@material-ui/icons/Delete"

// other components
import { Runners } from "./Runners"
import { MiniZone } from "./MiniZone"
import { ZoneModal } from "./ZoneModal"

// types and contexts
import { Pitch } from "../../../../../../interfaces/PitchInterface"
import { GlobalsContext } from "../../../../../../context/GlobalsContext"
import { FeedbackContext } from "../../../../../../context/FeedbackContext"
import { AtBat } from "../../../../../../interfaces/AtBatInterface"

interface Props {
	pitch: Pitch
	closeEdit: () => void
	updatedAtBat: (atBat: AtBat) => void
}

export const EditModeRow = ({ pitch, closeEdit, updatedAtBat }: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)
	const [showZoneModal, setShowZoneModal] = useState<boolean>(false)
	const [formState, setFormState] = useState<{ [key: string]: any }>({
		pitchNum: pitch.ab_pitch_num ? pitch.ab_pitch_num : 0,
		hitSpot: pitch.hit_spot ? true : false,
		pitchType: pitch.pitch_type,
		pitchResult: pitch.pitch_result,
		velocity: pitch.velocity ? pitch.velocity : "",
		timeToPlate: pitch.time_to_plate ? pitch.time_to_plate : "",
		runner1: pitch.runner_1,
		runner2: pitch.runner_2,
		runner3: pitch.runner_3,
		notes: pitch.notes,
		xLoc: pitch.loc_x,
		yLoc: pitch.loc_y,
	})

	const deletePitch = async () => {
		if (!confirm("Are you sure you want to delete this pitch?")) {
			return
		}
		try {
			const response = await axios.delete(
				`/api/pitch/${pitch.id}?return_at_bat=true`,
				globals.apiHeader
			)
			updatedAtBat(response.data)
			setFeedback({
				show: true,
				severity: "success",
				message: "Pitch deleted!",
			})
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message:
					"Something wen't wrong while deleting trying to delete the pitch",
			})
			console.log(e)
		}
	}

	const saveChanges = async () => {
		if (!formState.pitchNum || formState.pitchNum < 1) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please enter a pitch # at least 1",
			})
		}
		try {
			const response = await axios.patch(
				`/api/pitch/${pitch.id}?return_at_bat=true`,
				{
					ab_pitch_num: formState.pitchNum,
					hit_spot: formState.hitSpot,
					pitch_type: formState.pitchType,
					pitch_result: formState.pitchResult,
					velocity: formState.velocity === "" ? null : formState.velocity,
					time_to_plate:
						formState.timeToPlate === "" ? null : formState.timeToPlate,
					runner_1: formState.runner1,
					runner_2: formState.runner2,
					runner_3: formState.runner3,
					notes: formState.notes,
					loc_x: formState.xLoc,
					loc_y: formState.yLoc,
				},
				globals.apiHeader
			)
			updatedAtBat(response.data)
			setFeedback({
				show: true,
				severity: "success",
				message: "Pitch saved!",
			})
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message: "Something wen't wrong while saving the pitch",
			})
			console.log(e)
		}
		closeEdit()
	}

	return (
		<>
			<TableRow>
				<TableCell align="center">
					<Tooltip title="Save Changes">
						<IconButton onClick={saveChanges}>
							<DoneIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Cancel">
						<IconButton onClick={closeEdit}>
							<CloseIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Delete Pitch">
						<IconButton onClick={deletePitch}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</TableCell>
				<TableCell>
					<TextField
						style={{ textAlign: "center" }}
						value={formState.pitchNum}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
						) => setFormState({ ...formState, pitchNum: event.target.value })}
					/>
				</TableCell>
				<TableCell align="center">
					<Checkbox
						checked={formState.hitSpot}
						color="primary"
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>,
							hitSpot: boolean
						) => setFormState({ ...formState, hitSpot })}
					/>
				</TableCell>
				<TableCell align="center">
					<FormControl>
						<Select
							value={formState.pitchType}
							onChange={(
								event: React.ChangeEvent<{
									name?: string | undefined
									value: unknown
								}>,
								child: React.ReactNode
							) =>
								setFormState({ ...formState, pitchType: event.target.value })
							}>
							<MenuItem value="FB">FB</MenuItem>
							<MenuItem value="2S">2S</MenuItem>
							<MenuItem value="CB">CB</MenuItem>
							<MenuItem value="SL">SL</MenuItem>
							<MenuItem value="CH">CH</MenuItem>
							<MenuItem value="CT">CT</MenuItem>
							<MenuItem value="SPL">SPL</MenuItem>
							<MenuItem value="OTH">OTH</MenuItem>
						</Select>
					</FormControl>
				</TableCell>
				<TableCell align="center">
					<FormControl>
						<Select
							value={formState.pitchResult}
							onChange={(
								event: React.ChangeEvent<{
									name?: string | undefined
									value: unknown
								}>,
								child: React.ReactNode
							) =>
								setFormState({ ...formState, pitchResult: event.target.value })
							}>
							<MenuItem value="B">B</MenuItem>
							<MenuItem value="CS">CS</MenuItem>
							<MenuItem value="SS">SS</MenuItem>
							<MenuItem value="F">F</MenuItem>
							<MenuItem value="IP">IP</MenuItem>
						</Select>
					</FormControl>
				</TableCell>
				<TableCell align="center">
					<TextField
						type="number"
						value={formState.velocity}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
						) => setFormState({ ...formState, velocity: event.target.value })}
					/>
				</TableCell>
				<TableCell align="center">
					<TextField
						type="number"
						value={formState.timeToPlate}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
						) =>
							setFormState({ ...formState, timeToPlate: event.target.value })
						}
					/>
				</TableCell>
				<TableCell align="center" style={{ minWidth: "175px" }}>
					<Runners formState={formState} setFormState={setFormState} />
				</TableCell>
				<TableCell align="center">
					{pitch.balls}-{pitch.strikes}
				</TableCell>
				<TableCell align="center">
					<TextField
						value={formState.notes}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
						) => setFormState({ ...formState, notes: event.target.value })}
					/>
				</TableCell>
				<TableCell
					className="pt-pitches-zone"
					onClick={() => setShowZoneModal(true)}>
					<MiniZone xLoc={formState.xLoc} yLoc={formState.yLoc} />
				</TableCell>
			</TableRow>
			<ZoneModal
				open={showZoneModal}
				closeModal={() => setShowZoneModal(false)}
				xLoc={formState.xLoc}
				yLoc={formState.yLoc}
				changePitchLoc={(xLoc: number, yLoc: number) => {
					setShowZoneModal(false)
					setFormState({ ...formState, xLoc, yLoc })
				}}
			/>
		</>
	)
}
