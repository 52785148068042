import {
	Card,
	CardContent,
	CardHeader,
	Container,
	Grid,
	Hidden,
	IconButton,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Paper,
} from "@material-ui/core"
import axios from "axios"
import React, { createContext, useContext, useEffect, useState } from "react"
import { GlobalsContext } from "../../../context/GlobalsContext"
// Interfaces
import { AtBat } from "../../../interfaces/AtBatInterface"
// Components
import { Field } from "./Field"
import { DensityField } from "./DensityField"
import { SpraysTable } from "./SpraysTable"
import useWindowDimensions from "../../../shared/windowDimensions"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import { SeasonSelector } from "../../../shared/SeasonSelector"

interface Props {
	playerId: number
}

const initialFeedback = {
	show: false,
	severity: "success" as "success" | "error" | "warning" | undefined,
	message: "",
}

export const FeedbackContext = createContext({
	feedback: initialFeedback,
	setFeedback: (null as unknown) as React.Dispatch<
		React.SetStateAction<{
			show: boolean
			severity: "success" | "error" | "warning" | undefined
			message: string
		}>
	>,
})

export const SpraysTab = ({ playerId }: Props) => {
	const globals = useContext(GlobalsContext)
	const [atBats, setAtBats] = useState<AtBat[]>([])
	const [filteredAtBats, setFilteredAtBats] = useState<AtBat[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	const [selectedTraj, setSelectedTraj] = useState<string>("all")
	const [selectedResult, setSelectedResult] = useState<string>("all")
	const [selectedBalls, setSelectedBalls] = useState<number>(-1)
	const [selectedStrikes, setSelectedStrikes] = useState<number>(-1)
	const [selectedPitchType, setSelectedPitchType] = useState<string>("all")
	const [selectedSeason, setSelectedSeason] = useState<number>(
		globals.currentSeason!
	)

	const { height, width } = useWindowDimensions()
	const svg_width = width < 625 ? Math.round(width * 0.8) : 500

	// Runs only once at start
	// Get the at_bats for a player
	useEffect(() => {
		const fetchAtBats = async () => {
			try {
				const response = await axios.get(
					`/api/player/${playerId}/all_atbats`,
					globals.apiHeader
				)

				setAtBats(response.data)
				setFilteredAtBats(response.data)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}

		fetchAtBats()
	}, [])

	useEffect(() => {
		let fab = atBats
		if (selectedTraj !== "all") {
			fab = fab.filter((ab) => ab.traj === selectedTraj)
		}
		if (selectedResult !== "all") {
			fab = fab.filter(function (ab) {
				let res: string = ab.result
				if (selectedResult === "hit") {
					return res === "1B" || res === "2B" || res === "3B" || res === "HR"
				} else if (selectedResult === "out") {
					return !(res === "1B" || res === "2B" || res === "3B" || res === "HR")
				} else {
					return res === selectedResult
				}
			})
		}
		if (selectedBalls != -1) {
			fab = fab.filter((ab) => {
				if (ab.pitches && ab.pitches.length > 0) {
					return ab.pitches.slice(-1)[0].balls == selectedBalls
				}
				return false
			})
		}
		if (selectedStrikes != -1) {
			fab = fab.filter((ab) => {
				if (ab.pitches && ab.pitches.length > 0) {
					return ab.pitches.slice(-1)[0].strikes == selectedStrikes
				}
				return false
			})
		}
		if (selectedPitchType !== "all") {
			fab = fab.filter(function (ab) {
				if (ab.pitches && ab.pitches.length > 0) {
					let p_type: string = ab.pitches.slice(-1)[0].pitch_type
					if (selectedPitchType == "fastball") {
						return p_type === "FB" || p_type == "2S" || p_type === "CT"
					} else {
						return (
							p_type === "CB" ||
							p_type === "CU" ||
							p_type === "SL" ||
							p_type === "CH" ||
							p_type === "SPL"
						)
					}
				}
				return false
			})
		}
		fab = fab.filter(function (ab) {
			if (ab.season_id) {
				return ab.season_id == selectedSeason
			} else {
				return false
			}
		})
		setFilteredAtBats(fab)
	}, [
		atBats,
		selectedTraj,
		selectedResult,
		selectedBalls,
		selectedStrikes,
		selectedPitchType,
		selectedSeason,
	])

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container className="padding-top-24 padding-bottom-24">
			<Grid container spacing={1}>
				<Grid container item xs={12}>
					<Grid container item spacing={4}>
						<Grid item xs={6} sm={4} md={2}>
							<SeasonSelector
								fullWidth
								defaultValue={globals.currentSeason!}
								variant="outlined"
								onChange={(value: number) => setSelectedSeason(value)}
							/>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<FormControl
								fullWidth
								style={{ backgroundColor: "white" }}
								variant="outlined">
								<InputLabel>Trajectory</InputLabel>
								<Select
									label="Trajectory"
									value={selectedTraj}
									onChange={(event) => {
										setSelectedTraj(event.target.value as string)
									}}>
									<MenuItem value="all">All</MenuItem>
									<MenuItem value="GB">GB</MenuItem>
									<MenuItem value="LD">LD</MenuItem>
									<MenuItem value="FB">FB</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<FormControl
								fullWidth
								style={{ backgroundColor: "white" }}
								variant="outlined">
								<InputLabel>Result</InputLabel>
								<Select
									label="Result"
									value={selectedResult}
									onChange={(event) => {
										setSelectedResult(event.target.value as string)
									}}>
									<MenuItem value="all">All</MenuItem>
									<MenuItem value="out">Out</MenuItem>
									<MenuItem value="hit">Hit</MenuItem>
									<MenuItem value="1B">1B</MenuItem>
									<MenuItem value="2B">2B</MenuItem>
									<MenuItem value="3B">3B</MenuItem>
									<MenuItem value="HR">HR</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<FormControl
								fullWidth
								style={{ backgroundColor: "white" }}
								variant="outlined">
								<InputLabel>Balls</InputLabel>
								<Select
									label="Balls"
									value={selectedBalls}
									onChange={(event) => {
										setSelectedBalls(event.target.value as number)
									}}>
									<MenuItem value="-1">All</MenuItem>
									<MenuItem value="0">0</MenuItem>
									<MenuItem value="1">1</MenuItem>
									<MenuItem value="2">2</MenuItem>
									<MenuItem value="3">3</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<FormControl
								fullWidth
								style={{ backgroundColor: "white" }}
								variant="outlined">
								<InputLabel>Strikes</InputLabel>
								<Select
									label="Strikes"
									value={selectedStrikes}
									onChange={(event) => {
										setSelectedStrikes(event.target.value as number)
									}}>
									<MenuItem value="-1">All</MenuItem>
									<MenuItem value="0">0</MenuItem>
									<MenuItem value="1">1</MenuItem>
									<MenuItem value="2">2</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={6} sm={4} md={2}>
							<FormControl
								fullWidth
								style={{ backgroundColor: "white" }}
								variant="outlined">
								<InputLabel>Pitch Type</InputLabel>
								<Select
									label="Pitch Type"
									value={selectedPitchType}
									onChange={(event) => {
										setSelectedPitchType(event.target.value as string)
									}}>
									<MenuItem value="all">All</MenuItem>
									<MenuItem value="fastball">Fastballs</MenuItem>
									<MenuItem value="offspeed">Offspeed</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item xs={12} md={6} className="align-center">
							<Field
								atBats={filteredAtBats}
								width={svg_width}
								height={svg_width}
							/>
						</Grid>
						<Grid item xs={12} md={6} className="align-center">
							<DensityField
								atBats={filteredAtBats}
								width={svg_width}
								height={svg_width}
							/>
						</Grid>
						<Grid item xs={12} component={Paper}>
							<SpraysTable atBats={filteredAtBats} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}
