import React, { useContext } from "react"

import { Grid, IconButton, Paper } from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"

import { Context } from "../../TrackTab"

export const Outs = () => {
	const { state, setState } = useContext(Context)

	const handleOutsChange = (type: "INCREASE" | "DECREASE") => {
		if (type === "INCREASE") {
			setState({
				...state,
				outingState: {
					...state.outingState,
					outs: state.outingState.outs + 1,
				},
			})
		} else {
			setState({
				...state,
				outingState: {
					...state.outingState,
					outs: state.outingState.outs - 1,
				},
			})
		}
	}

	return (
		<Paper elevation={0} className="pt-outing-state-component">
			<div className="pt-outing-state-name">OUTS</div>
			<Grid container spacing={0}>
				<Grid item xs={4} className="align-right pt-outs-decrease">
					<IconButton
						disabled={state.outingState.outs === 0}
						size="small"
						color="primary"
						onClick={() => handleOutsChange("DECREASE")}>
						<KeyboardArrowDownIcon color="primary" />
					</IconButton>
				</Grid>
				<Grid item xs={4}>
					<div className="pt-outing-state-value">{state.outingState.outs}</div>
				</Grid>
				<Grid item xs={4} className="align-left pt-outs-increase">
					<IconButton
						disabled={state.outingState.outs === 2}
						size="small"
						color="primary"
						onClick={() => handleOutsChange("INCREASE")}>
						<KeyboardArrowUpIcon color="primary" />
					</IconButton>
				</Grid>
			</Grid>
		</Paper>
	)
}
