import { Paper, Grid, Chip } from "@material-ui/core"
import React from "react"
import { AtBat } from "../../../../../../interfaces/AtBatInterface"
import { Field } from "./Field"
import { Strikezone } from "./Strikezone"

interface Props {
	atBat: AtBat
}

export const AtBatCard = ({ atBat }: Props) => {
	return (
		<Paper style={{ marginBottom: "12px", padding: "12px" }}>
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<Grid
						container
						spacing={0}
						className="align-center"
						style={{ fontSize: "16px" }}>
						<Grid item xs={3}>
							<div>Inning</div>
							<div style={{ fontWeight: "bold" }}>{atBat.inning}</div>
						</Grid>
						<Grid item xs={3}>
							<div>Result</div>
							<div style={{ fontWeight: "bold" }}>{atBat.result}</div>
						</Grid>
						<Grid item xs={3}>
							<div>Hard Hit</div>
							<div style={{ fontWeight: "bold" }}>
								{atBat.spray_x ? (atBat.hit_hard ? "Yes" : "No") : "-"}
							</div>
						</Grid>
						<Grid item xs={3}>
							<div>Traj</div>
							<div style={{ fontWeight: "bold" }}>
								{atBat.traj ? atBat.traj : "-"}
							</div>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					style={{
						flexDirection: "row",
						display: "flex",
						paddingTop: "24px",
					}}>
					{atBat.pitches!.map((pitch) => (
						<div style={{ flex: 1 }} key={pitch.id}>
							<div className="align-center">
								<Chip
									key={pitch.id}
									style={{ margin: "2px" }}
									label={pitch.pitch_type}
									className={`outing-locations-pitch-chip-${pitch.pitch_type}`}
								/>
							</div>
							<div className="align-center">
								<span style={{ margin: "2px" }}>{pitch.pitch_result}</span>
							</div>
						</div>
					))}
				</Grid>
				<Grid item xs={6} className="align-center padding-top-24">
					<Field xSpray={atBat.spray_x} ySpray={atBat.spray_y} />
				</Grid>
				<Grid item xs={6} className="align-center padding-top-24">
					<Strikezone pitches={atBat.pitches!} />
				</Grid>
			</Grid>
		</Paper>
	)
}
