import { useContext, useState, useEffect, createContext } from "react"

import axios from "axios"

// components
import { SubnavTemplate } from "../../shared/MainLayout/SubnavTemplate/SubnavTemplate"
import { ActiveTab } from "./ActiveTab"
import { InactiveTab } from "./InactiveTab"
import { NewHitterModal } from "./NewHitterModal"
import { EditHitterModal } from "./EditHitterModal"

// types
import { Player } from "../../interfaces/PlayerInterface"

// contexts
import { GlobalsContext } from "../../context/GlobalsContext"

// styling
import "./HittingPage.css"

export const HittingPageContext = createContext({
	loading: true,
	players: [] as Player[],
	refetchPlayers: null as unknown as () => void,
	openNewHitterModal: null as unknown as () => void,
	openEditHitterModal: null as unknown as (player: Player) => void,
})

export const HittingPage = () => {
	const { apiHeader, teamId } = useContext(GlobalsContext)
	const [loading, setLoading] = useState<boolean>(true)
	const [players, setPlayers] = useState<Player[]>([])
	const [playersUpdated, setPlayersUpdated] = useState<boolean>(false)
	const [newHitterModal, setNewHitterModal] = useState<boolean>(false)
	const [editHitterModal, setEditHitterModal] = useState<Player | null>(null)

	useEffect(() => {
		const fetchPlayers = async () => {
			try {
				const response = await axios.get(
					`/api/team/${teamId}/players`,
					apiHeader
				)
				setPlayers(response.data)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchPlayers()
	}, [playersUpdated])

	const tabs = [
		{
			name: "Active",
			component: <ActiveTab />,
		},
		{
			name: "Inactive",
			component: <InactiveTab />,
		},
	]

	const refetchPlayers = () => setPlayersUpdated(!playersUpdated)
	const openNewHitterModal = () => setNewHitterModal(true)
	const openEditHitterModal = (player: Player) => setEditHitterModal(player)

	return (
		<HittingPageContext.Provider
			value={{
				loading,
				players,
				refetchPlayers,
				openNewHitterModal,
				openEditHitterModal,
			}}>
			<SubnavTemplate title="Hitting" tabs={tabs} />
			<NewHitterModal
				open={newHitterModal}
				handleClose={() => setNewHitterModal(false)}
			/>
			<EditHitterModal
				open={editHitterModal !== null}
				handleClose={() => setEditHitterModal(null)}
				player={editHitterModal}
			/>
		</HittingPageContext.Provider>
	)
}
