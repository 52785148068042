import { useContext, useState } from "react"

import axios from "axios"

// components
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
} from "@material-ui/core"
import { ButtonApiRequest } from "../../shared/ButtonApiRequest"

// types and contexts
import { FeedbackContext } from "../../context/FeedbackContext"
import { GlobalsContext } from "../../context/GlobalsContext"
import { ResourceCategory } from "../../interfaces/ResourceCategory"
import { ResourcesUpdatedContext } from "./ResourcesPage"

interface Props {
	isOpen: boolean
	close: () => void
	category: ResourceCategory | null
}

interface FormValues {
	title: string
	description: string
	link: string
	file: File | null
}

const intitialFormValues: FormValues = {
	title: "",
	description: "",
	link: "",
	file: null,
}

export const NewResourceModal = ({ isOpen, close, category }: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)

	// run if the request to create a resource was successful
	const { refetchResources } = useContext(ResourcesUpdatedContext)

	// while attempting to create a new resource
	const [loading, setLoading] = useState<boolean>(false)

	// stores the resource information the user is providing
	const [formValues, setFormValues] = useState<FormValues>(intitialFormValues)

	// either an error or if "CANCEL" is pressed
	const cancel = () => {
		close()
		setFormValues(intitialFormValues)
	}

	// gets authentication information to add a resource to S3 bucket
	const getSignedRequest = async (file: File) => {
		try {
			const response = await axios.get(
				`/api/resource/sign_s3?file_name=${file.name}&file_type=${file.type}`,
				globals.apiHeader
			)
			return response.data
		} catch (e) {
			console.log(e)
		}
	}

	// this function uploads the resource file to S3 bucket using
	// 	presigned information from server
	const uploadFile = async (
		file: File,
		s3Data: { url: string; fields: { [key: string]: any } }
	) => {
		try {
			const fileUploadData = new FormData()

			// takes presigned auth data for S3 bucket to send file
			for (let key in s3Data.fields) {
				fileUploadData.append(key, s3Data.fields[key])
			}

			// add the file to the request
			fileUploadData.append("file", file)

			// upload the file
			await axios.post(s3Data.url, fileUploadData)
		} catch (e) {
			console.log(e)
		}
	}

	const createResource = async () => {
		// titles are required for resources
		if (!formValues.title) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please provide a title",
			})
		}

		// the resource must either be a file or a link
		if (!formValues.link && !formValues.file) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please either provide a link or a file",
			})
		}

		// make sure title isn't too long
		if (formValues.title.length > 63) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Title must be less than 64 characters",
			})
		}

		// make sure description isn't too long
		if (formValues.description.length > 255) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Description must be less than 256 characters",
			})
		}

		setLoading(true)

		// attempt to create the resource
		try {
			let file_path = ""
			if (formValues.file) {
				const s3Data = await getSignedRequest(formValues.file)
				await uploadFile(formValues.file, s3Data)
				file_path = `${s3Data.url}/${formValues.file.name}`
			}
			await axios.post(
				"/api/resource",
				{
					title: formValues.title,
					description: formValues.description,
					link: formValues.link,
					category_id: category!.id,
					file_path,
				},
				globals.apiHeader
			)
			setFeedback({
				show: true,
				severity: "success",
				message: "Resource created!",
			})
			refetchResources()
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message:
					"Something wen't wrong. If a file was uploaded, rename the file and try again.",
			})
		}
		setLoading(false)
		cancel()
	}

	return (
		<Dialog fullWidth maxWidth="sm" open={isOpen} onClose={cancel}>
			<DialogTitle>New {category && `${category.name} `}Resource</DialogTitle>
			<DialogContent>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<TextField
							required
							value={formValues.title}
							onChange={(event) =>
								setFormValues({
									...formValues,
									title: event.target.value,
								})
							}
							id="title"
							label="Title"
							type="text"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} className="padding-top-12">
						<TextField
							value={formValues.description}
							onChange={(event) =>
								setFormValues({
									...formValues,
									description: event.target.value,
								})
							}
							id="description"
							label="Description"
							type="text"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} className="padding-top-12">
						<TextField
							required
							value={formValues.link}
							disabled={formValues.file ? true : false}
							onChange={(event) =>
								setFormValues({
									...formValues,
									link: event.target.value,
								})
							}
							id="link"
							label="Link"
							type="text"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} className="padding-top-24">
						<label htmlFor="upload-photo">
							<input
								style={{ display: "none" }}
								id="upload-photo"
								name="upload-photo"
								type="file"
								onChange={(event) =>
									setFormValues({ ...formValues, file: event.target.files![0] })
								}
							/>
							<Button
								disabled={formValues.link ? true : false}
								color="primary"
								variant="outlined"
								component="span">
								Upload File*
							</Button>
						</label>
						{formValues.file && <span>{` ${formValues.file.name}`}</span>}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={cancel} color="primary">
					Cancel
				</Button>
				<ButtonApiRequest
					text="Create"
					loading={loading}
					onClick={createResource}
					color="primary"
				/>
			</DialogActions>
		</Dialog>
	)
}
