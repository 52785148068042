import React, { useContext, useEffect, useState } from "react"

import { List, ListItem, Dialog, DialogTitle } from "@material-ui/core"

import { TransitionUp } from "../../../../../../shared/ModalTransitions/TransitionUp"

import { Context } from "../../TrackTab"
import { GlobalsContext } from "../../../../../../context/GlobalsContext"

export const AtBatResultModal = () => {
	const globals = useContext(GlobalsContext)
	const { state, setState, setLoading } = useContext(Context)
	const [show, setShow] = useState<boolean>(state.showAtBatResultModal)

	const handleClose = () => {
		setState({ ...state, showAtBatResultModal: false })
	}

	useEffect(() => {
		setShow(state.showAtBatResultModal)
	}, [state.showAtBatResultModal])

	// will show the at bat result options that make the most sense
	//	for the pitch that was thrown (or not thrown if at bat ended early)
	return (
		<Dialog
			TransitionComponent={TransitionUp}
			fullWidth
			maxWidth="xs"
			onClose={handleClose}
			open={show}>
			<DialogTitle>At Bat Result</DialogTitle>
			<List>
				{state.currentAtBatResultOptions.map((option, index) => (
					<ListItem
						key={index}
						autoFocus
						button
						onClick={() => {
							setShow(false)
							option.action(
								{ ...state, showAtBatResultModal: false },
								setState,
								globals.apiHeader,
								setLoading
							)
						}}>
						{option.description}
					</ListItem>
				))}
			</List>
		</Dialog>
	)
}
