import React from "react"
import { CircularProgress } from "@material-ui/core"
import ReactLoading from "react-loading"

export const LoadingBig = () => {
	return (
		<div style={{ textAlign: "center", paddingTop: "50px" }}>
			<CircularProgress className="app-loader" color="primary" />
		</div>
	)
}

// export const LoadingBig = () => {
// 	return (
// 		<div style={{ marginLeft: "33.33%" }}>
// 			<ReactLoading type="bubbles" color="#bd2e1f" width="50%" />
// 		</div>
// 	)
// }
