import React, { createContext, useContext, useEffect, useState } from "react"

import axios from "axios"

import { DragDropContext, Droppable } from "react-beautiful-dnd"

import { Container } from "@material-ui/core"

// other components in on page
import { Feedback } from "../../../../shared/Feedback"
import { Header } from "./components/Header"
import { AtBatCard } from "./components/AtBatCard"
import { LoadingBig } from "../../../../shared/Loading/LoadingBig"

// contexts and types
import { GlobalsContext } from "../../../../context/GlobalsContext"
import { OutingContext } from "../TrackOuting"
import { AtBat } from "../../../../interfaces/AtBatInterface"

// styles
import "../../../../static/css/PitchesTab/PitchesTab.css"
import { FeedbackContext } from "../../../../context/FeedbackContext"

export const PitchesTab = () => {
	const globals = useContext(GlobalsContext)
	const { outing } = useContext(OutingContext)
	const { setFeedback } = useContext(FeedbackContext)

	// holds an object of innings where each inning has all the at bats that occurred in that inning
	const [innings, setInnings] = useState<{ [key: number]: AtBat[] }>({
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
		7: [],
		8: [],
		9: [],
	})

	// will likely be called after the at bat was updated in some way
	//	and the change should be reflected in the state of the pitches tab
	const updatedAtBat = (atBat: AtBat) => {
		let adjustedInnings = { ...innings }
		Object.keys(innings).forEach((key: string) => {
			let inningNum = (key as unknown) as number
			adjustedInnings[inningNum] = innings[inningNum].map((ab) => {
				return ab.id === atBat.id ? atBat : ab
			})
		})
		setInnings(adjustedInnings)
	}

	// this will run when the user deletes an at bat
	const deletedAtBat = (atBat: AtBat) => {
		let adjustedInnings = { ...innings }
		Object.keys(innings).forEach((key: string) => {
			let inningNum = (key as unknown) as number
			adjustedInnings[inningNum] = innings[inningNum].filter(
				(ab) => ab.id !== atBat.id
			)
		})
		setInnings(adjustedInnings)
	}

	// setup the inning droppables on load and when the outing changes
	useEffect(() => {
		if (!outing) return

		let newInnings = { ...innings }
		outing.at_bats.forEach((atBat) => {
			if (atBat.inning) {
				newInnings[atBat.inning].push(atBat)
			} else {
				newInnings[1].push(atBat)
			}
		})
		setInnings(newInnings)
	}, [outing])

	// when the atbats change (the innings state object)
	// make sure order and inning are maintained on the server
	useEffect(() => {
		const editAtBatOrders = () => {
			let atBatNum = 0
			Object.keys(innings).forEach((key: string) => {
				let inningNum = (key as unknown) as number
				innings[inningNum].forEach(async (atBat) => {
					atBatNum += 1
					if (
						atBatNum !== atBat.outing_ab_num ||
						inningNum.toString() !== atBat.inning.toString()
					) {
						try {
							await axios.patch(
								`/api/atbat/${atBat.id}`,
								{
									outing_ab_num: atBatNum,
									inning: inningNum,
								},
								globals.apiHeader
							)
						} catch (e) {
							console.log(e)
						}
					}
				})
			})
		}
		editAtBatOrders()
	}, [innings])

	// handle a reorder or at bats
	const onDragEnd = ({ ...props }) => {
		const { source, destination } = props

		// adjusted innings variable
		let adjustedInnings = { ...innings }

		// if the atbat didn't move
		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return
		}

		// get the source and destination inning numbers
		const prevInningNum = source.droppableId.split("-")[1]
		const newInningNum = destination.droppableId.split("-")[1]

		// get the array of at bats for those innings
		const prevInning = adjustedInnings[(prevInningNum as unknown) as number]
		const newInning = adjustedInnings[(newInningNum as unknown) as number]

		// the atbat being moved
		let atBat = prevInning[source.index]

		// adjust the inning arrays
		prevInning.splice(source.index, 1)
		newInning.splice(destination.index, 0, atBat)

		// save the innings arrays
		adjustedInnings[(prevInningNum as unknown) as number] = prevInning
		adjustedInnings[(newInningNum as unknown) as number] = newInning

		setInnings(adjustedInnings)
	}

	// this is here while the outing is waiting to be loaded
	if (!outing) {
		return <LoadingBig />
	}

	// if there hasn't been an at bat added or pitch thrown yet
	if (outing.at_bats.length === 0) {
		return (
			<div style={{ textAlign: "center", padding: "50px" }}>
				<h1>
					No pitches thrown for this outing yet. Go to the "Track" tab to add at
					bats and pitches.
				</h1>
			</div>
		)
	}

	// get the correct opponent for the batters list when editing an at bat
	let opponentId = 0
	if (outing.practice) {
		opponentId = globals.teamId!
	} else if (outing.game) {
		if (outing.pitcher.team_id === outing.game!.home_team.id) {
			opponentId = outing.game.away_team.id
		} else {
			opponentId = outing.game.home_team.id
		}
	}

	return (
		<>
			<Header outing={outing} />
			<Container className="pt-pitches-tab-container">
				<DragDropContext onDragEnd={onDragEnd}>
					{Object.keys(innings).map((inning: string) => (
						<div key={inning}>
							<div className="pt-pitches-inning-title">INNING {inning}</div>
							<Droppable droppableId={`droppable-${inning}`}>
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
										className="pt-pitches-inning-droppable">
										{innings[(inning as unknown) as number].map(
											(atBat, index) => (
												<AtBatCard
													index={index}
													key={atBat.id}
													atBat={atBat}
													updatedAtBat={updatedAtBat}
													deletedAtBat={deletedAtBat}
													opponentId={opponentId}
												/>
											)
										)}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
							<br />
							<hr />
						</div>
					))}
				</DragDropContext>
			</Container>
		</>
	)
}
