import { useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow,
} from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { EditCategoryModal } from "./EditCategoryModal"

// types and contexts
import { FeedbackContext } from "../../../context/FeedbackContext"
import { GlobalsContext } from "../../../context/GlobalsContext"
import { CategoriesUpdatedContext } from "./ResourcesTab"
import { ResourceCategory } from "../../../interfaces/ResourceCategory"

interface Props {
	category: ResourceCategory
}

export const CategoryRow = ({ category }: Props) => {
	const { setFeedback } = useContext(FeedbackContext)
	const { refetchCategories } = useContext(CategoriesUpdatedContext)
	const globals = useContext(GlobalsContext)

	// whether or not to show the EditCategoryModal
	const [editCategoryModal, setEditCategoryModal] = useState<boolean>(false)

	// used for the menu to edit or delete the category
	const [
		categoryMenuAnchorEl,
		setCategoryMenuAnchorEl,
	] = useState<null | HTMLElement>(null)

	// when the "Delete" menu item was selected
	const handleDelete = async () => {
		if (confirm("Are you sure you want to delete this category?")) {
			try {
				await axios.delete(
					`/api/resource_category/${category.id}`,
					globals.apiHeader
				)
				setFeedback({
					show: true,
					severity: "success",
					message: "Category deleted!",
				})
				refetchCategories()
			} catch (e) {
				console.log(e)
				setFeedback({
					show: true,
					severity: "error",
					message: "Something wen't wrong. Please try again.",
				})
			}
		}
	}

	return (
		<>
			<TableRow>
				<TableCell align="center">{category.order_num}</TableCell>
				<TableCell align="left">{category.name}</TableCell>
				<TableCell align="center">{category.resources.length}</TableCell>
				<TableCell align="center">
					<IconButton
						onClick={(event) => setCategoryMenuAnchorEl(event.currentTarget)}>
						<MoreVertIcon />
					</IconButton>
				</TableCell>
			</TableRow>
			<Menu
				anchorEl={categoryMenuAnchorEl}
				keepMounted
				open={Boolean(categoryMenuAnchorEl)}
				onClose={() => setCategoryMenuAnchorEl(null)}>
				<MenuItem
					onClick={() => {
						setCategoryMenuAnchorEl(null)
						setEditCategoryModal(true)
					}}>
					Edit
				</MenuItem>
				<MenuItem
					onClick={() => {
						setCategoryMenuAnchorEl(null)
						handleDelete()
					}}>
					Delete
				</MenuItem>
			</Menu>
			<EditCategoryModal
				open={editCategoryModal}
				close={() => setEditCategoryModal(false)}
				category={category}
			/>
		</>
	)
}
