import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import { AppBar, Tabs, Tab } from "@material-ui/core"

interface SubnavProps {
  currentTab: number | boolean
  tabChangeHandler: (event: React.ChangeEvent<{}>, newTab: number) => void
  tabs: Array<string>
}

const useTabStyles = makeStyles({
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: 0,
  },
})

export const Subnav = ({ currentTab, tabChangeHandler, tabs }: SubnavProps) => {
  const classes = useTabStyles()
  return (
    <AppBar position="static" className="subnav" elevation={0}>
      <Tabs
        classes={{ root: classes.root, scroller: classes.scroller }}
        value={currentTab}
        onChange={tabChangeHandler}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
      >
        {tabs.map((tab, index) => (
          <Tab label={tab} key={index} />
        ))}
      </Tabs>
    </AppBar>
  )
}
