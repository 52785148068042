import { Button, CircularProgress } from "@material-ui/core"

interface Props {
	loading: boolean
	onClick: () => void
	text: string
	color: "primary" | "secondary" | "inherit"
	disabled?: boolean
	type?: "submit" | "button"
	fullWidth?: boolean
	size?: "small" | "medium" | "large"
	variant?: "contained" | "outlined" | "text"
}

export const ButtonApiRequest = ({
	loading,
	onClick,
	text,
	color,
	disabled = false,
	type,
	fullWidth,
	size,
	variant,
}: Props) => {
	return (
		<div style={{ position: "relative" }}>
			<Button
				size={size}
				fullWidth={fullWidth !== undefined ? fullWidth : false}
				type={type !== undefined ? type : "button"}
				style={{ opacity: loading ? "0.5" : "1.0" }}
				variant={variant ?? "contained"}
				color={color}
				disabled={loading || disabled}
				disableElevation
				onClick={onClick}>
				{text}
			</Button>
			{loading && (
				<CircularProgress
					size={24}
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						marginTop: -12,
						marginLeft: -12,
					}}
				/>
			)}
		</div>
	)
}
