import { useContext, useEffect, useState } from "react"

import axios from "axios"
import { Redirect, useParams } from "react-router-dom"

// components
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"

// types and contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { Player } from "../../../interfaces/PlayerInterface"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { ButtonApiRequest } from "../../ButtonApiRequest"

interface Props {
	isOpen: boolean
	close: () => void
	teamId: number
	variant: "game" | "practice"
	seasonId: number
	date: string | Date
}

// gets the id of the competition from the url query string
interface ParamTypes {
	id: string | undefined
}

export const NewOutingModal = ({
	isOpen,
	close,
	teamId,
	variant,
	seasonId,
	date,
}: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)
	const { id } = useParams<ParamTypes>()

	// holds all of the pitchers to select from to create a new outing
	const [pitchers, setPitchers] = useState<Player[]>([])

	// which pitcher the user selected
	const [pitcherSelected, setPitcherSelected] = useState<number | string>("")

	// once a new outing is created, this is set, and the user is redirected
	const [newOutingId, setNewOutingId] = useState<number | null>(null)

	// true when code is running that is creating the outing to disable the button
	const [creatingOuting, setCreatingOuting] = useState<boolean>(false)

	// gets all of the current pitchers for the team requested
	useEffect(() => {
		if (isOpen) {
			const fetchPitchers = async () => {
				try {
					const response = await axios.get(
						`/api/player?team_id=${teamId}&active=true&order_by=${
							globals.teamId === teamId ? "lastname" : "number"
						}`,
						globals.apiHeader
					)
					setPitchers(response.data)
				} catch (e) {
					console.log(e)
				}
			}
			fetchPitchers()
		}
	}, [teamId, isOpen])

	// when the create outing button is pressed, create a new outing and redirect to track
	const createOuting = async () => {
		if (!pitcherSelected) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please select a pitcher for the outing",
			})
		}
		setCreatingOuting(true)
		try {
			const response = await axios.post(
				"/api/outing",
				{
					pitcher_id: pitcherSelected,
					practice_id: variant === "practice" ? id : null,
					game_id: variant === "game" ? id : null,
					season_id: seasonId,
					date,
				},
				globals.apiHeader
			)
			setNewOutingId(response.data.id)
		} catch (e) {
			setCreatingOuting(false)
			setFeedback({
				show: true,
				severity: "error",
				message: "Something wen't wrong",
			})
			console.log(e)
		}
	}

	// if the new outing was created, this redirects
	if (newOutingId) return <Redirect to={`/outing/${newOutingId}/track`} />

	return (
		<Dialog fullWidth maxWidth="sm" open={isOpen} onClose={close}>
			<DialogTitle>Start New Outing</DialogTitle>
			<DialogContent>
				<FormControl className="full-width">
					<InputLabel>Select Pitcher</InputLabel>
					<Select
						value={pitcherSelected}
						onChange={(event) => {
							setPitcherSelected(event.target.value as number)
						}}>
						{pitchers.map((pitcher) => (
							<MenuItem key={pitcher.id} value={pitcher.id}>
								#{pitcher.number} {pitcher.firstname} {pitcher.lastname}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="primary">
					Cancel
				</Button>
				<ButtonApiRequest
					color="primary"
					text="Start"
					loading={creatingOuting}
					onClick={createOuting}
				/>
			</DialogActions>
		</Dialog>
	)
}
