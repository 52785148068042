import {
	Chip,
	Container,
	FormControl,
	Input,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core"
import React, { useState } from "react"
import { Outing } from "../../../interfaces/OutingInterface"
import { Pitch } from "../../../interfaces/PitchInterface"
import { LocationsD3 } from "./LocationsD3"

interface Props {
	outing: Outing
}

// adds a few parameters for helpful information
export interface PitchAdjusted extends Pitch {
	pitchNum: number
	inning: number
	batterName: string
}

export const LocationsTab = ({ outing }: Props) => {
	const pitchTypes = ["FB", "2S", "CB", "SL", "CH", "CT", "SPL", "OTH"]
	const [selectedPitches, setSelectedPitches] = useState<string[]>(pitchTypes)

	let pitches: PitchAdjusted[] = []
	let pitchNum = 0
	outing.at_bats.forEach((atBat) => {
		if (atBat.pitches) {
			atBat.pitches.forEach((pitch) => {
				pitchNum += 1
				if (selectedPitches.includes(pitch.pitch_type)) {
					pitches.push({
						...pitch,
						pitchNum,
						inning: atBat.inning,
						batterName: atBat.player.lastname,
					})
				}
			})
		}
	})

	const handleChange = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>,
		child: React.ReactNode
	) => {
		setSelectedPitches((event.target.value as unknown) as string[])
	}

	return (
		<Container style={{ paddingTop: "20px" }} className="align-center">
			<div>
				<FormControl>
					<InputLabel>Pitches</InputLabel>
					<Select
						style={{ width: "300px" }}
						multiple
						value={selectedPitches}
						onChange={handleChange}
						input={<Input />}
						renderValue={(selected) => (
							<div style={{ flexWrap: "wrap", display: "flex" }}>
								{(selected as string[]).map((value: string) => (
									<Chip
										style={{ margin: "2px" }}
										key={value}
										label={value}
										className={`outing-locations-pitch-chip-${value}`}
									/>
								))}
							</div>
						)}>
						{pitchTypes.map((pitchType) => (
							<MenuItem key={pitchType} value={pitchType}>
								{pitchType}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<div>
				<LocationsD3 pitches={pitches} />
			</div>
			<br />
			<br />
			<br />
			<br />
			<br />
		</Container>
	)
}
