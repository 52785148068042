import { useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	Button,
	Container,
	FormControlLabel,
	Grid,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core"
import { NewPlayerModal } from "./NewPlayerModal"
import { PlayerRow } from "./PlayerRow"

// types and contexts
import { Player } from "../../../interfaces/PlayerInterface"
import { GlobalsContext } from "../../../context/GlobalsContext"

// styles
import "../../../static/css/TeamPage.css"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"

interface Props {
	teamId: string
}

export const RosterTab = ({ teamId }: Props) => {
	const globals = useContext(GlobalsContext)

	// while fetching the players
	const [loading, setLoading] = useState<boolean>(true)

	// holds the roster of players
	const [players, setPlayers] = useState<Array<Player>>([])

	// toggles whether the user wants to see inactive players
	const [showInactive, setShowInactive] = useState<boolean>(false)

	// determines whether the "Add Player" modal is open or not
	const [newPlayerModal, setNewPlayerModal] = useState<boolean>(false)

	// fetch the players on load and after you create a new player/show inactive
	useEffect(() => {
		const fetchPlayers = async () => {
			setLoading(true)
			try {
				const response = await axios.get(
					`/api/player?team_id=${teamId}&active=${!showInactive}&order_by=number`,
					globals.apiHeader
				)
				setPlayers(response.data)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchPlayers()
	}, [newPlayerModal, showInactive])

	const playerDeleted = (id: number) => {
		setPlayers(players.filter((player) => player.id != id))
	}

	return (
		<Container className="padding-top-24">
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<FormControlLabel
						control={
							<Switch
								color="primary"
								onChange={() => setShowInactive(!showInactive)}
								value={showInactive}
							/>
						}
						label="Show Inactive"
					/>
				</Grid>
				<Grid item xs={6} className="align-right">
					{globals.admin && (
						<Button
							variant="contained"
							color="primary"
							onClick={() => setNewPlayerModal(true)}>
							Add Player
						</Button>
					)}
				</Grid>
			</Grid>
			<br />
			{loading ? (
				<LoadingBig />
			) : (
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell className="table-header" align="left"></TableCell>
								<TableCell className="table-header" align="left">
									Firstname
								</TableCell>
								<TableCell className="table-header" align="left">
									Lastname
								</TableCell>
								<TableCell className="table-header" align="left">
									Number
								</TableCell>
								<TableCell className="table-header" align="left">
									Throws
								</TableCell>
								<TableCell className="table-header" align="left">
									Bats
								</TableCell>
								<TableCell className="table-header" align="left">
									Year
								</TableCell>
								<TableCell className="table-header" align="left">
									Position
								</TableCell>
								<TableCell className="table-header" align="left">
									Pitcher?
								</TableCell>
								<TableCell className="table-header" align="left">
									Batter?
								</TableCell>
								<TableCell className="table-header" align="left">
									Active
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{players.map((player) => (
								<PlayerRow
									key={player.id}
									player={player}
									playerDeleted={() => playerDeleted(player.id)}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<NewPlayerModal
				isOpen={newPlayerModal}
				close={() => setNewPlayerModal(false)}
				teamId={teamId}
			/>
		</Container>
	)
}
