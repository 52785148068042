import React from "react"

import { Dialog, DialogContent } from "@material-ui/core"

import { TransitionUp } from "../../../../../../shared/ModalTransitions/TransitionUp"
import { ZoneD3 } from "./ZoneD3"

interface Props {
	open: boolean
	closeModal: () => void
	xLoc: number
	yLoc: number
	changePitchLoc: (xLoc: number, yLoc: number) => void
}

export const ZoneModal = ({
	open,
	closeModal,
	xLoc,
	yLoc,
	changePitchLoc,
}: Props) => {
	return (
		<Dialog
			scroll="body"
			open={open}
			onClose={closeModal}
			TransitionComponent={TransitionUp}>
			<DialogContent>
				<ZoneD3 xLoc={xLoc} yLoc={yLoc} changePitchLoc={changePitchLoc} />
			</DialogContent>
		</Dialog>
	)
}
