import {
	Button,
	Container,
	Grid,
	Paper,
	useMediaQuery,
	useTheme,
} from "@material-ui/core"
import { Outing } from "../../../interfaces/OutingInterface"
import { VeloChart } from "./VeloChart"
import { saveSvgAsPng } from "save-svg-as-png"
import { VoidFunctionComponent } from "react"

interface Props {
	outing: Outing
}

export const SummaryTab = ({ outing }: Props) => {
	const theme = useTheme()
	const sm = useMediaQuery(theme.breakpoints.up("sm"))
	const md = useMediaQuery(theme.breakpoints.up("md"))
	const lg = useMediaQuery(theme.breakpoints.up("lg"))

	const downloadPNG = () => {
		saveSvgAsPng(
			document.getElementById("velo-over-outing-chart"),
			"../veloChart.png"
		)
	}

	return (
		<Container className="padding-top-24">
			<Grid container spacing={0}>
				<Grid
					item
					xs={12}
					className="align-center"
					component={Paper}
					style={{ overflow: "scroll" }}>
					<h1>Velocity Throughout Outing</h1>
					<VeloChart
						outing={outing}
						atBats={outing.at_bats}
						width={lg ? 1100 : md ? 850 : 500}
						height={400}
					/>
				</Grid>
			</Grid>
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
		</Container>
	)
}
