export const predictNextBaserunners = (
	runner1: boolean,
	runner2: boolean,
	runner3: boolean,
	atBatResult: string,
	outs: 0 | 1 | 2,
	traj: "GB" | "FB" | "LD"
) => {
	// strikeouts
	if (["K", "ꓘ", "Drop Third (Out)"].includes(atBatResult)) {
		if (outs === 2) {
			return [false, false, false]
		} else {
			return [runner1, runner2, runner3]
		}
	}

	// BB, HBP, Catcher's Interference, IBB
	if (["BB", "HBP", "IBB", "Catcher's Interference"].includes(atBatResult)) {
		return [
			true,
			runner1 ? true : runner2,
			runner2 && runner1 ? runner2 : runner3,
		]
	}

	// 1B, Error, Drop Third (Safe)
	if (["1B", "Error", "Drop Third (Safe)"].includes(atBatResult)) {
		return [true, runner1, runner2]
	}

	// In Play (Out)
	if (["Out"].includes(atBatResult)) {
		if (outs === 2) {
			return [false, false, false]
		} else {
			if (traj === "GB") {
				return [false, runner1, runner2]
			} else {
				return [runner1, runner2, runner3]
			}
		}
	}

	// 2B
	if (["2B"].includes(atBatResult)) {
		return [false, true, runner1]
	}

	// 3B
	if (["3B"].includes(atBatResult)) {
		return [false, false, true]
	}

	// HR
	if (["HR"].includes(atBatResult)) {
		return [false, false, false]
	}

	// Double Play
	if (["Double Play"].includes(atBatResult)) {
		return [false, false, runner2]
	}

	// Triple Play
	if (["Triple Play"].includes(atBatResult)) {
		return [false, false, false]
	}

	// FC
	if (["FC"].includes(atBatResult)) {
		if (outs === 2) {
			return [false, false, false]
		} else {
			if (
				(runner1 && !runner2 && !runner3) ||
				(!runner1 && runner2 && !runner3) ||
				(!runner1 && !runner2 && runner3)
			) {
				return [true, false, false]
			} else {
				return [true, runner1, runner2]
			}
		}
	}

	return [false, false, false]
}
