// components
import { AllTeamsTab } from "./AllTeamsTab/AllTeamsTab"
import { SubnavTemplate } from "../../shared/MainLayout/SubnavTemplate/SubnavTemplate"

export const TeamsPage = () => {
	const tabs = [
		{
			name: "All Teams",
			component: <AllTeamsTab />,
		},
	]

	return <SubnavTemplate title="Teams" tabs={tabs} />
}
