import * as d3 from "d3"
import { useEffect, useRef } from "react"
import { Pitch } from "../../../../../../interfaces/PitchInterface"

interface Props {
	pitches: Pitch[]
}

export const Strikezone = ({ pitches }: Props) => {
	const svgRef = useRef(null)

	let xScale = d3.scaleLinear().range([150, 0]).domain([2, -2])
	let yScale = d3.scaleLinear().range([150, 0]).domain([0, 4])

	const pitchColors: { [key: string]: string } = {
		FB: "#E70B18", // red
		CB: "#2326FA", // blue
		SL: "#118A14", // green
		CH: "#C8C72A", // yellow
		CT: "#21C8C7", // baby blue
		"2S": "#FD8024", // orange
		SPL: "#653896", // purple
		OTH: "#000000", // black
	}

	useEffect(() => {
		const svg = d3.select(svgRef.current)
		svg.selectAll("*").remove()

		// strikezone
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-0.833)},${yScale(1.75)}
					L ${xScale(-0.833)},${yScale(3.4)}
					L ${xScale(0.833)},${yScale(3.4)}
					L ${xScale(0.833)},${yScale(1.75)}
					L ${xScale(-0.833)},${yScale(1.75)}`
			)
			.style("stroke", "black")
			.style("fill", "white")
			.style("stroke-width", 1)

		// homeplate
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(0)},${yScale(0.2)}
				L ${xScale(-0.863)},${yScale(0.4)}
				L ${xScale(-0.833)},${yScale(0.7)}
				L ${xScale(0.83)},${yScale(0.7)}
				L ${xScale(0.863)},${yScale(0.4)}
				L ${xScale(0)},${yScale(0.2)}`
			)
			.style("stroke", "black")
			.style("fill", "white")
			.style("stroke-width", 1)

		svg.selectAll("circle").remove()

		pitches.forEach((pitch) => {
			if (pitch.loc_x && pitch.loc_y) {
				svg
					.append("circle")
					.attr("class", "outing-locations-pitch-circle")
					.attr("cx", xScale(pitch.loc_x))
					.attr("cy", yScale(pitch.loc_y))
					.attr("r", 6)
					.style("fill", pitchColors[pitch.pitch_type])

				svg
					.append("text")
					.attr("x", xScale(pitch.loc_x))
					.attr("y", yScale(pitch.loc_y) + 3)
					.attr("text-anchor", "middle")
					.attr("font-size", "10px")
					.text(pitch.ab_pitch_num!)
			}
		})
	}, [])

	return (
		<svg style={{ fill: "white" }} width={150} height={150} ref={svgRef}></svg>
	)
}
