import {
	Card,
	CardContent,
	CardHeader,
	Container,
	Grid,
	Hidden,
	IconButton,
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import React, { createContext, useState } from "react"
import { Outing } from "../../../interfaces/OutingInterface"
import { Feedback } from "../../../shared/Feedback"
import { AtBatCard } from "./AtBatCard"
import { MiniField } from "./MiniField"

interface Props {
	outing: Outing
}

export const OutingContext = createContext({
	outing: (null as unknown) as Outing,
})

const initialFeedback = {
	show: false,
	severity: "success" as "success" | "error" | "warning" | undefined,
	message: "",
}

export const FeedbackContext = createContext({
	feedback: initialFeedback,
	setFeedback: (null as unknown) as React.Dispatch<
		React.SetStateAction<{
			show: boolean
			severity: "success" | "error" | "warning" | undefined
			message: string
		}>
	>,
})

export const PitchesTab = ({ outing }: Props) => {
	const [feedback, setFeedback] = useState(initialFeedback)

	let inning = 0

	return (
		<FeedbackContext.Provider value={{ feedback, setFeedback }}>
			<OutingContext.Provider value={{ outing }}>
				<Container>
					{outing.at_bats.map((atBat) => {
						if (atBat.inning !== inning) {
							inning = atBat.inning
							return (
								<>
									<h1
										style={{
											textDecoration: "underline",
										}}>
										INNING {inning}
									</h1>
									<AtBatCard key={atBat.id} atBat={atBat} />
								</>
							)
						} else {
							return <AtBatCard key={atBat.id} atBat={atBat} />
						}
					})}
					<br />
					<br />
					<br />
				</Container>
				<Feedback
					show={feedback.show}
					setShow={() => setFeedback({ ...feedback, show: false })}
					severity={feedback.severity}
					message={feedback.message}
				/>
			</OutingContext.Provider>
		</FeedbackContext.Provider>
	)
}
