import React, { useContext, useEffect, useState } from "react"

import { Grid, Paper } from "@material-ui/core"
import { ToggleButton } from "@material-ui/lab"

import { StrikeZoneD3 } from "./StrikeZoneD3"

import { Context } from "../../TrackTab"

// styles
import "../../../../../../static/css/TrackTab/PitchLocation.css"
import { AtBat } from "../../../../../../interfaces/AtBatInterface"

export const PitchLocation = () => {
	const { state, setState } = useContext(Context)

	// to store outing location stats (gets updated every pitch)
	const [strikePct, setStrikePct] = useState<number>(0)
	const [fpsPct, setFpsPct] = useState<number>(0)
	const [spotPct, setSpotPct] = useState<number>(0)
	const [fpFastball, setFpFastball] = useState<string>("N/A")
	const [fpOffspeed, setFpOffspeed] = useState<string>("N/A")

	// runs every time the outing itself was updated
	useEffect(() => {
		let pitches = 0
		let atBats = 0
		let strikes = 0
		let firstPitchStrikes = 0
		let hitSpots = 0
		let fastballs = 0
		let fastballStrikes = 0
		let offspeed = 0
		let offspeedStrikes = 0
		state.outing!.at_bats.forEach((atBat: AtBat) => {
			pitches += atBat.pitches!.length
			if (atBat.pitches!.length > 0) atBats += 1
			atBat.pitches!.forEach((pitch, i) => {
				if (pitch.pitch_result !== "B") strikes += 1

				if (i === 0 && pitch.pitch_result !== "B") firstPitchStrikes += 1

				if (
					pitch.ab_pitch_num === 1 &&
					["FB", "2S"].includes(pitch.pitch_type)
				) {
					fastballs += 1
				}

				if (
					pitch.ab_pitch_num === 1 &&
					["FB", "2S"].includes(pitch.pitch_type) &&
					pitch.pitch_result !== "B"
				) {
					fastballStrikes += 1
				}

				if (
					pitch.ab_pitch_num === 1 &&
					!["FB", "2S"].includes(pitch.pitch_type)
				) {
					offspeed += 1
				}

				if (
					pitch.ab_pitch_num === 1 &&
					!["FB", "2S"].includes(pitch.pitch_type) &&
					pitch.pitch_result !== "B"
				) {
					offspeedStrikes += 1
				}

				if (pitch.hit_spot!) {
					hitSpots += 1
				}
			})
			setStrikePct(Math.round((100 * strikes) / pitches))
			setFpsPct(Math.round((100 * firstPitchStrikes) / atBats))
			setSpotPct(Math.round((100 * hitSpots) / pitches))
			setFpFastball(fastballs !== 0 ? `${fastballStrikes}/${fastballs}` : "N/A")
			setFpOffspeed(offspeed !== 0 ? `${offspeedStrikes}/${offspeed}` : "N/A")
		})
	}, [state.outing])

	return (
		<div>
			<Grid container spacing={0}>
				<Grid item xs={12} className="align-center">
					<Paper elevation={0} className="pt-location-stats-paper">
						<Grid container spacing={0}>
							<Grid item xs={3}>
								<span className="pt-location-stat-value">{strikePct}%</span>
								<span className="pt-location-stat-name"> STRIKES</span>
							</Grid>
							<Grid item xs={2}>
								<span className="pt-location-stat-value">{fpsPct}%</span>
								<span className="pt-location-stat-name"> FPS</span>
							</Grid>
							<Grid item xs={3}>
								<span className="pt-location-stat-value">{spotPct}%</span>
								<span className="pt-location-stat-name"> SPOT</span>
							</Grid>
							<Grid item xs={2}>
								<span className="pt-location-stat-value">{fpFastball}</span>
								<span className="pt-location-stat-name"> FB</span>
							</Grid>
							<Grid item xs={2}>
								<span className="pt-location-stat-value">{fpOffspeed}</span>
								<span className="pt-location-stat-name"> OS</span>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
			<Grid container spacing={0} className="pt-strikezone-container">
				<Grid item xs={12}>
					<StrikeZoneD3 />
				</Grid>
			</Grid>
		</div>
	)
}
