import React, { useContext } from "react"
import {
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core"

import { SpraysTableRow } from "./SpraysTableRow"
import { AtBat } from "../../../interfaces/AtBatInterface"

interface Props {
	atBats: AtBat[]
}

export const SpraysTable = ({ atBats }: Props) => {
	return (
		<Container>
			<h2>At Bat Results</h2>
			<TableContainer>
				<Table size="medium">
					<TableHead>
						<TableRow>
							<TableCell className="table-header">#</TableCell>
							<TableCell className="table-header">Date</TableCell>
							<TableCell className="table-header">Pitcher</TableCell>
							<TableCell className="table-header">Pitch Type</TableCell>
							<TableCell className="table-header">Count</TableCell>
							<TableCell className="table-header">Result</TableCell>
							<TableCell className="table-header">GB/FB/LD</TableCell>
							<TableCell className="table-header">Fielder</TableCell>
							<TableCell className="table-header">Hit Hard?</TableCell>
							<TableCell className="table-header">Notes</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{atBats.map((atBat, index) => (
							<SpraysTableRow key={index} atBat={atBat} index={index + 1} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Container>
	)
}
