import React, { useContext } from "react"

import {
	Container,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core"

import { PitchRow } from "./PitchRow"

import { AtBat } from "../../../../interfaces/AtBatInterface"

interface Props {
	atBat: AtBat
}

export const PitchesTable = ({ atBat }: Props) => {
	return (
		<Container>
			<h2>Pitches</h2>
			<TableContainer>
				<Table size="medium">
					<TableHead>
						<TableRow>
							<TableCell align="center">#</TableCell>
							<TableCell align="center">Hit Spot</TableCell>
							<TableCell align="center">RT</TableCell>
							<TableCell align="center">SS</TableCell>
							<TableCell align="center">Pitch Type</TableCell>
							<TableCell align="center">Pitch Result</TableCell>
							<TableCell align="center">Velocity</TableCell>
							<TableCell align="center">Time to Plate</TableCell>
							<TableCell align="center">Runners</TableCell>
							<TableCell align="center">Count</TableCell>
							<TableCell align="center">Notes</TableCell>
							<TableCell align="center">Location</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{atBat.pitches!.map((pitch) => (
							<PitchRow key={pitch.id} pitch={pitch} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<br />
		</Container>
	)
}
