import React, { useState, useEffect, useContext } from "react"

import axios from "axios"

// components
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { DeleteOutline } from "@material-ui/icons"
import { ButtonApiRequest } from "../../shared/ButtonApiRequest"

// types and contexts
import { GlobalsContext } from "../../context/GlobalsContext"
import { FeedbackContext } from "../../context/FeedbackContext"
import { Team } from "../../interfaces/TeamInterface"
import { Player } from "../../interfaces/PlayerInterface"
import { ScoutPagesContext } from "./ScoutingPage"

// holds the information for the scout on a specific team
interface FormValues {
	lastUpdated: any
	teamId: number | null
	generalNotes: string
	videoLinks: string[]
	videoAccessNotes: string[]
	pitchers: (number | string)[]
	pitcherNotes: string[]
	hitters: (number | string)[]
	hitterNotes: string[]
}

const initialFormValues: FormValues = {
	lastUpdated: new Date().toLocaleDateString(),
	teamId: 0,
	generalNotes: "",
	videoLinks: [],
	videoAccessNotes: [],
	pitchers: [],
	pitcherNotes: [],
	hitters: [],
	hitterNotes: [],
}

interface Props {
	open: boolean
	close: () => void
	refetchScoutPages: () => void
}

export const NewScoutingPageModal = ({
	open,
	close,
	refetchScoutPages,
}: Props) => {
	// used throughout app
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)

	// when the api requests to make the scout page is happening
	const [loading, setLoading] = useState<boolean>(false)

	// the list of already existing scout pages
	const { scoutPages } = useContext(ScoutPagesContext)

	// holds the complete list of teams to choose from
	const [teams, setTeams] = useState<Team[]>([])

	// will keep track of the user entering information for the scout
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues)

	// will hold the players of the team selected for the scout
	const [players, setPlayers] = useState<Player[]>([])

	// only fetch teams when the modal is open
	useEffect(() => {
		if (open) {
			const fetchTeams = async () => {
				try {
					const teams = await axios.get("/api/team/all", globals.apiHeader)
					setTeams(teams.data)
				} catch (e) {
					setFeedback({
						show: true,
						severity: "error",
						message: "Something went wrong fetching teams",
					})
				}
			}
			fetchTeams()
		}
	}, [open])

	// refetch the players when the user changes the team
	useEffect(() => {
		const fetchPlayers = async () => {
			try {
				const response = await axios.get(
					`/api/player?team_id=${formValues.teamId}&active=true`,
					globals.apiHeader
				)
				setPlayers(response.data)
			} catch (e) {
				console.log(e)
			}
		}
		fetchPlayers()
	}, [formValues.teamId])

	// add the scout page and all information associated with it
	const addScoutPage = async () => {
		// make sure the user selects a team
		if (!formValues.teamId) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please select a team",
			})
		}

		// make sure there isn't a scout page for this team already
		for (let page in scoutPages) {
			if (scoutPages[page].team_id === formValues.teamId) {
				return setFeedback({
					show: true,
					severity: "warning",
					message: "There already exists a scout page for this team",
				})
			}
		}

		setLoading(true)
		try {
			// create the scout page itself
			await axios.post(
				"/api/team_scout_page",
				{
					team_id: formValues.teamId,
					last_updated: formValues.lastUpdated,
				},
				globals.apiHeader
			)

			// add the general scouting notes for the team
			await axios.patch(
				`/api/team/${formValues.teamId}`,
				{ general_notes: formValues.generalNotes },
				globals.apiHeader
			)

			// add the video links to livestreams
			for (let i = 0; i < formValues.videoLinks.length; i++) {
				await axios.post(
					"/api/team_stream_link",
					{
						team_id: formValues.teamId,
						link: formValues.videoLinks[i],
						access_notes: formValues.videoAccessNotes[i],
					},
					globals.apiHeader
				)
			}

			// add scouting notes on their pitchers
			for (let i = 0; i < formValues.pitchers.length; i++) {
				await axios.patch(
					`/api/player/${formValues.pitchers[i]}`,
					{
						pitching_notes: formValues.pitcherNotes[i],
					},
					globals.apiHeader
				)
			}

			// add scouting notes on their hitters
			for (let i = 0; i < formValues.hitters.length; i++) {
				await axios.patch(
					`/api/player/${formValues.hitters[i]}`,
					{
						hitting_notes: formValues.hitterNotes[i],
					},
					globals.apiHeader
				)
			}
			setLoading(false)
			close()
			setFeedback({
				show: true,
				severity: "success",
				message: "Page created!",
			})
			refetchScoutPages()
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message: "Something went wrong",
			})
		}
		resetFormValues()
	}

	const resetFormValues = () => {
		setFormValues(initialFormValues)
	}

	const cancel = () => {
		close()
		resetFormValues()
	}

	return (
		<div>
			<Dialog fullWidth maxWidth="xl" open={open} onClose={cancel}>
				<DialogTitle>Create Scout Page</DialogTitle>
				<DialogContent className="padding-bottom-24">
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<h2>Basic Team Information</h2>
							<Autocomplete
								options={teams.sort(
									(a, b) => (b.favorite as any) - (a.favorite as any)
								)}
								groupBy={(option) => (option.favorite ? "Favorites" : "Rest")}
								getOptionLabel={(option) => option.name}
								onChange={(
									event: React.ChangeEvent<{}>,
									value: Team | null
								) => {
									if (value == null) {
										return setFormValues({
											...initialFormValues,
											generalNotes: formValues.generalNotes,
										})
									}
									return setFormValues({ ...formValues, teamId: value.id })
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										required
										label="Team"
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} className="padding-top-24">
							<TextField
								label="General Team Notes"
								multiline
								rows={10}
								variant="outlined"
								fullWidth
								value={formValues.generalNotes}
								onChange={(event) =>
									setFormValues({
										...formValues,
										generalNotes: event.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<h2>Videos</h2>
							{formValues.videoLinks.map((link, index) => (
								<Grid
									key={index}
									container
									spacing={0}
									className="padding-bottom-24">
									<Grid item xs={5}>
										<TextField
											label="Stream/Video Link"
											variant="outlined"
											style={{ width: "95%" }}
											value={link}
											onChange={(event) =>
												setFormValues({
													...formValues,
													videoLinks: formValues.videoLinks.map((l, i) => {
														if (i === index) {
															return event.target.value
														} else {
															return l
														}
													}),
												})
											}
											fullWidth
										/>
									</Grid>
									<Grid item xs={6} className="align-right">
										<TextField
											value={formValues.videoAccessNotes[index]}
											onChange={(event) =>
												setFormValues({
													...formValues,
													videoAccessNotes: formValues.videoAccessNotes.map(
														(p, i) => {
															if (i === index) {
																return event.target.value
															} else {
																return p
															}
														}
													),
												})
											}
											label="Notes"
											variant="outlined"
											style={{ width: "95%" }}
											fullWidth
										/>
									</Grid>
									<Grid item xs={1} className="align-right">
										<IconButton
											onClick={() => {
												setFormValues({
													...formValues,
													videoLinks: formValues.videoLinks.filter(
														(p, i) => i !== index
													),
													videoAccessNotes: formValues.videoAccessNotes.filter(
														(p, i) => i !== index
													),
												})
											}}>
											<DeleteOutline />
										</IconButton>
									</Grid>
								</Grid>
							))}
							<Grid container spacing={0} className="padding-top-12">
								<Grid item xs={12}>
									<Button
										disabled={
											formValues.teamId === 0 || formValues.teamId === null
										}
										color="primary"
										onClick={() =>
											setFormValues({
												...formValues,
												videoLinks: [...formValues.videoLinks, ""],
												videoAccessNotes: [...formValues.videoAccessNotes, ""],
											})
										}>
										Add Video
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<h2>Hitters</h2>
							{formValues.hitters.map((hitter, index) => (
								<Grid
									key={index}
									container
									spacing={0}
									className="padding-bottom-24">
									<Grid item xs={5}>
										<FormControl variant="outlined" style={{ width: "95%" }}>
											<InputLabel>Select Hitter</InputLabel>
											<Select
												label="Select Hitter"
												variant="outlined"
												value={formValues.hitters[index]}
												onChange={(event) => {
													setFormValues({
														...formValues,
														hitters: formValues.hitters.map((p, i) => {
															if (i === index) {
																return event.target.value as number
															} else {
																return p
															}
														}),
													})
												}}>
												{players.map((hitter) => (
													<MenuItem key={hitter.id} value={hitter.id}>
														#{hitter.number} {hitter.firstname}{" "}
														{hitter.lastname}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6} className="align-right">
										<TextField
											value={formValues.hitterNotes[index]}
											onChange={(event) =>
												setFormValues({
													...formValues,
													hitterNotes: formValues.hitterNotes.map((p, i) => {
														if (i === index) {
															return event.target.value
														} else {
															return p
														}
													}),
												})
											}
											label="Notes"
											variant="outlined"
											style={{ width: "95%" }}
											fullWidth
										/>
									</Grid>
									<Grid item xs={1} className="align-right">
										<IconButton
											onClick={() => {
												setFormValues({
													...formValues,
													hitters: formValues.hitters.filter(
														(p, i) => i !== index
													),
													hitterNotes: formValues.hitterNotes.filter(
														(p, i) => i !== index
													),
												})
											}}>
											<DeleteOutline />
										</IconButton>
									</Grid>
								</Grid>
							))}
							<Grid container spacing={0} className="padding-top-12">
								<Grid item xs={12}>
									<Button
										disabled={
											formValues.teamId === 0 || formValues.teamId === null
										}
										color="primary"
										onClick={() =>
											setFormValues({
												...formValues,
												hitters: [...formValues.hitters, ""],
												hitterNotes: [...formValues.hitterNotes, ""],
											})
										}>
										Add Hitter
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<h2>Pitchers</h2>
							{formValues.pitchers.map((pitcher, index) => (
								<Grid
									key={index}
									container
									spacing={0}
									className="padding-bottom-24">
									<Grid item xs={5}>
										<FormControl variant="outlined" style={{ width: "95%" }}>
											<InputLabel>Select Pitcher</InputLabel>
											<Select
												label="Select Pitcher"
												variant="outlined"
												value={formValues.pitchers[index]}
												onChange={(event) => {
													setFormValues({
														...formValues,
														pitchers: formValues.pitchers.map((p, i) => {
															if (i === index) {
																return event.target.value as number
															} else {
																return p
															}
														}),
													})
												}}>
												{players.map((pitcher) => (
													<MenuItem key={pitcher.id} value={pitcher.id}>
														#{pitcher.number} {pitcher.firstname}{" "}
														{pitcher.lastname}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6} className="align-right">
										<TextField
											value={formValues.pitcherNotes[index]}
											onChange={(event) =>
												setFormValues({
													...formValues,
													pitcherNotes: formValues.pitcherNotes.map((p, i) => {
														if (i === index) {
															return event.target.value
														} else {
															return p
														}
													}),
												})
											}
											label="Notes"
											variant="outlined"
											style={{ width: "95%" }}
											fullWidth
										/>
									</Grid>
									<Grid item xs={1} className="align-right">
										<IconButton
											onClick={() => {
												setFormValues({
													...formValues,
													pitchers: formValues.pitchers.filter(
														(p, i) => i !== index
													),
													pitcherNotes: formValues.pitcherNotes.filter(
														(p, i) => i !== index
													),
												})
											}}>
											<DeleteOutline />
										</IconButton>
									</Grid>
								</Grid>
							))}
							<Grid container spacing={0} className="padding-top-12">
								<Grid item xs={12}>
									<Button
										disabled={
											formValues.teamId === 0 || formValues.teamId === null
										}
										color="primary"
										onClick={() =>
											setFormValues({
												...formValues,
												pitchers: [...formValues.pitchers, ""],
												pitcherNotes: [...formValues.pitcherNotes, ""],
											})
										}>
										Add Pitcher
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={cancel} color="primary">
						Cancel
					</Button>
					<ButtonApiRequest
						loading={loading}
						onClick={addScoutPage}
						color="primary"
						text="Create"
					/>
				</DialogActions>
			</Dialog>
		</div>
	)
}
