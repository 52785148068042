import { createContext, useContext, useEffect, useState } from "react"

import axios from "axios"
import { useParams } from "react-router-dom"

// components used
import { SubnavTemplate } from "../../shared/MainLayout/SubnavTemplate/SubnavTemplate"
import { HittingTab } from "../../shared/CompetitionsPages/HittingTab/HittingTab"
import { PitchingTab } from "../../shared/CompetitionsPages/PitchingTab/PitchingTab"

// contexts and types
import { GlobalsContext } from "../../context/GlobalsContext"
import { Practice } from "../../interfaces/PracticeInterface"

// util functions
import { formatDate } from "../../shared/Functions/FormatDate"

interface ParamTypes {
	id: string | undefined
}

export const PracticePage = () => {
	const globals = useContext(GlobalsContext)

	// used for the title of the page
	const [practiceDate, setPracticeDate] = useState<string>("")

	// pulled from the URL
	const { id } = useParams<ParamTypes>()

	// fetch the practcie to get the date
	useEffect(() => {
		const fetchPracticeDate = async () => {
			try {
				const response = await axios.get(
					`/api/practice/${id}`,
					globals.apiHeader
				)
				if (response.data.date) {
					setPracticeDate(formatDate(response.data.date))
				}
			} catch (e) {
				console.log(e)
			}
		}
		fetchPracticeDate()
	}, [])

	const tabs = [
		{
			name: "Pitching",
			component: (
				<PitchingTab variant="practice" id={(id as unknown) as number} />
			),
		},
		{
			name: "Hitting",
			component: (
				<HittingTab variant="practice" id={(id as unknown) as number} />
			),
		},
	]

	return <SubnavTemplate title={`Practice - ${practiceDate}`} tabs={tabs} />
}
