import { useContext, useState } from "react"

// components
import {
	Container,
	Table,
	TableContainer,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
	Paper,
	Grid,
	IconButton,
} from "@material-ui/core"
import { NewPracticeModal } from "./NewPracticeModal"
import { PracticeRow } from "./PracticeRow"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import { EditPracticeModal } from "./EditPracticeModal"
import AddIcon from "@material-ui/icons/Add"

// types
import { Practice } from "../../../interfaces/PracticeInterface"

// contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { CompetitionsPageContext } from "../CompetitionsPage"

export const PracticesTab = () => {
	const { user } = useContext(GlobalsContext)
	const { loading, season, practices } = useContext(CompetitionsPageContext)

	const [newPracticeModal, setNewPracticeModal] = useState<boolean>(false)
	const [editPracticeModal, setEditPracticeModal] = useState<Practice | null>(
		null
	)

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container className="tab-container">
			<Grid container spacing={0} component={Paper}>
				<Grid item xs={9} className="comps-table-title">
					<span>Practices</span>
				</Grid>
				{user!.admin && (
					<Grid item xs={3} className="comps-table-add">
						<IconButton onClick={() => setNewPracticeModal(true)}>
							<AddIcon />
						</IconButton>
					</Grid>
				)}
				<Grid item xs={12}>
					{practices.length > 0 ? (
						<TableContainer>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell className="table-header" align="left">
											Date
										</TableCell>
										<TableCell className="table-header" align="left">
											&nbsp;&nbsp;Practice
										</TableCell>
										{user!.admin && (
											<TableCell className="table-header" align="center">
												Actions
											</TableCell>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{practices.map((practice: Practice) => (
										<PracticeRow
											key={practice.id}
											practice={practice}
											handleEdit={() => setEditPracticeModal(practice)}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Container className="padding-bottom-24">
							No practices yet for this season
						</Container>
					)}
				</Grid>
			</Grid>
			<NewPracticeModal
				open={newPracticeModal}
				handleClose={() => setNewPracticeModal(false)}
				defaultSeason={season}
			/>
			<EditPracticeModal
				open={editPracticeModal !== null}
				practice={editPracticeModal}
				handleClose={() => setEditPracticeModal(null)}
			/>
		</Container>
	)
}
