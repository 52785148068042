import axios from "axios"
import { atBatResultOptions } from "./Constants"
import { initialState, State } from "./Constants"
import { updateOutingState } from "./UpdateOutingState"
import { dispatchError, dispatchWarning } from "./DispatchFeedback"

export const AddPitchHandler = async (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	apiHeader: any,
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
	if (
		!state.endAtBatWithoutPitch &&
		(!state.pitchEntry.xLoc ||
			!state.pitchEntry.yLoc ||
			!state.pitchEntry.pitchResult ||
			!state.pitchEntry.pitchType)
	) {
		return dispatchWarning(
			state,
			setState,
			"Make sure to select a pitch location, pitch type, and pitch result"
		)
	}
	setLoading(true)
	if (state.endAtBatWithoutPitch) {
		await atBatEndedEarlyWithoutPitch(state, setState, apiHeader)
	} else if (!state.currentAtBat) {
		await addAtBatAndFirstPitch(state, setState, apiHeader)
	} else if (state.currentAtBat && state.atBatEntry.result) {
		await addPitchAndAtBatResult(state, setState, apiHeader)
	} else {
		await addPitchOrPromptForAtBatResult(state, setState, apiHeader)
	}
	setLoading(false)
}

const addAtBatAndFirstPitch = async (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	apiHeader: any
) => {
	if (state.selectedBatter === "") {
		return dispatchWarning(state, setState, "Please select a batter")
	} else if (
		state.pitchEntry.pitchResult === "IP" &&
		!state.atBatEntry.result
	) {
		return setState({ ...state, showFieldModal: true })
	}
	try {
		const atBat = await addAtBat(state, apiHeader)
		try {
			await addPitch({ ...state, currentAtBat: atBat.data }, apiHeader)
			await updateOutingState(state, setState, apiHeader)
		} catch (e) {
			console.log(e)
			await axios.delete(`/api/atbat/${atBat.data.id}`, apiHeader)
			dispatchError(
				{ ...state, atBatEntry: initialState.atBatEntry },
				setState,
				"Something went wrong"
			)
		}
	} catch (e) {
		console.log(e)
		dispatchError(
			{ ...state, atBatEntry: initialState.atBatEntry },
			setState,
			"Something wen't wrong"
		)
	}
}

const addPitchAndAtBatResult = async (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	apiHeader: any
) => {
	try {
		const pitch = await addPitch(state, apiHeader)
		try {
			await updateAtBat(state, apiHeader)
			await updateOutingState(state, setState, apiHeader)
		} catch (e) {
			await axios.delete(`/api/pitch/${pitch.data.id}`, apiHeader)
			dispatchError(
				{ ...state, atBatEntry: initialState.atBatEntry },
				setState,
				"Something went wrong"
			)
		}
	} catch (e) {
		console.log(e)
		dispatchError(
			{ ...state, atBatEntry: initialState.atBatEntry },
			setState,
			"Something went wrong"
		)
	}
}

const atBatEndedEarlyWithoutPitch = async (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	apiHeader: any
) => {
	if (state.selectedBatter === "") {
		return dispatchWarning(state, setState, "Please select a batter")
	}
	try {
		if (state.currentAtBat) {
			await updateAtBat(state, apiHeader)
		} else {
			await addAtBat(state, apiHeader)
		}
		await updateOutingState(state, setState, apiHeader)
	} catch (e) {
		console.log(e)
		dispatchError(
			{ ...state, atBatEntry: initialState.atBatEntry },
			setState,
			"Something wen't wrong"
		)
	}
}

const addPitchOrPromptForAtBatResult = async (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	apiHeader: any
) => {
	if (
		state.outingState.strikes === 2 &&
		state.pitchEntry.pitchResult === "SS"
	) {
		setState({
			...state,
			showAtBatResultModal: true,
			currentAtBatResultOptions:
				atBatResultOptions.swingingStrikeWithTwoStrikes,
		})
	} else if (
		state.outingState.strikes === 2 &&
		state.pitchEntry.pitchResult === "CS"
	) {
		setState({
			...state,
			showAtBatResultModal: true,
			currentAtBatResultOptions: atBatResultOptions.calledStrikeWithTwoStrikes,
		})
	} else if (
		state.outingState.balls === 3 &&
		state.pitchEntry.pitchResult === "B"
	) {
		setState({
			...state,
			showAtBatResultModal: true,
			currentAtBatResultOptions: atBatResultOptions.ballWithThreeBalls,
		})
	} else if (state.pitchEntry.pitchResult === "IP") {
		setState({
			...state,
			showFieldModal: true,
			currentAtBatResultOptions: atBatResultOptions.inPlay,
		})
	} else {
		try {
			await addPitch(state, apiHeader)
			try {
				await updateAtBat(state, apiHeader)
			} catch (e) {
				console.log(e)
				dispatchError(
					{ ...state, atBatEntry: initialState.atBatEntry },
					setState,
					"Something wen't wrong"
				)
			}
			await updateOutingState(state, setState, apiHeader)
		} catch (e) {
			console.log(e)
			dispatchError(
				{ ...state, atBatEntry: initialState.atBatEntry },
				setState,
				"Something wen't wrong"
			)
		}
	}
}

export const updateAtBat = async (state: State, apiHeader: any) => {
	if (!state.currentAtBat) return
	let notes = ""
	if (state.currentAtBat!.notes !== "" && state.atBatEntry.notes !== "") {
		notes = `${state.currentAtBat!.notes}; ${state.atBatEntry.notes}`
	} else if (
		state.currentAtBat!.notes !== "" &&
		state.atBatEntry.notes === ""
	) {
		notes = state.currentAtBat!.notes
	} else {
		notes = state.atBatEntry.notes
	}
	return await axios.patch(
		`/api/atbat/${state.currentAtBat!.id}`,
		{
			result: state.atBatEntry.result,
			traj: state.atBatEntry.traj,
			fielder: state.atBatEntry.fielder,
			spray_x: state.atBatEntry.xSpray,
			spray_y: state.atBatEntry.ySpray,
			hit_hard: state.atBatEntry.hitHard,
			inning: state.outingState.inning,
			notes,
			outs: state.outingState.outs,
			batter_out: state.atBatEntry.batterOut,
		},
		apiHeader
	)
}

const addAtBat = async (state: State, apiHeader: any) => {
	return await axios.post(
		"/api/atbat",
		{
			outing_id: state.outing!.id,
			batter: state.selectedBatter as number,
			inning: state.outingState.inning,
			outs: state.outingState.outs,
			notes: state.atBatEntry.notes,
			traj: state.atBatEntry.traj,
			fielder: state.atBatEntry.fielder,
			spray_x: state.atBatEntry.xSpray,
			spray_y: state.atBatEntry.ySpray,
			hit_hard: state.atBatEntry.hitHard,
			batter_out: state.atBatEntry.batterOut,
			result: state.atBatEntry.result,
		},
		apiHeader
	)
}

const addPitch = async (state: State, apiHeader: any) => {
	return await axios.post(
		"/api/pitch",
		{
			atbat_id: state.currentAtBat!.id,
			velocity:
				state.pitchEntry.velocity !== "" ? state.pitchEntry.velocity : null,
			runner_1: state.pitchEntry.runnerFirst,
			runner_2: state.pitchEntry.runnerSecond,
			runner_3: state.pitchEntry.runnerThird,
			time_to_plate:
				state.pitchEntry.timeToPlate !== ""
					? state.pitchEntry.timeToPlate
					: null,
			pitch_type: state.pitchEntry.pitchType,
			pitch_result: state.pitchEntry.pitchResult,
			roll_through: state.pitchEntry.rollThrough,
			short_set: state.pitchEntry.shortSet,
			loc_x: state.pitchEntry.xLoc,
			loc_y: state.pitchEntry.yLoc,
			hit_spot: state.pitchEntry.hitSpot,
			notes: state.pitchEntry.notes,
			balls: state.outingState.balls,
			strikes: state.outingState.strikes,
		},
		apiHeader
	)
}
