import {
	Box,
	Hidden,
	List,
	ListItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
} from "@material-ui/core"
import { Fragment } from "react"
import { useVideosTabContext } from ".."
import {
	getComparator,
	stableSort,
	TableHeadCell,
} from "../../../../shared/Table/utils"
import { VideoListItem } from "./VideoListItem"
import { VideoRow } from "./VideoRow"

export const VideoTable = () => {
	const {
		filteredVideos,
		rowsPerPage,
		page,
		handleChangePage,
		handleChangeRowsPerPage,
		orderBy,
		order,
		handleTableRequestSort,
	} = useVideosTabContext()

	// this is helpful for sorting
	const headCells: TableHeadCell[] = [
		{
			id: "date",
			numeric: false,
			label: "Date",
		},
		{
			id: "season",
			numeric: false,
			label: "Season",
		},
		{
			id: "playerName",
			numeric: false,
			label: "Player",
		},
		{
			id: "title",
			numeric: false,
			label: "Title",
		},
		{
			id: "category",
			numeric: false,
			label: "Category",
		},
		{
			id: "uploadType",
			numeric: false,
			label: "Type",
		},
	]

	const rows = stableSort(filteredVideos, getComparator(order, orderBy)).slice(
		page * rowsPerPage,
		page * rowsPerPage + rowsPerPage
	)

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredVideos.length) : 0

	return (
		<Fragment>
			<Hidden mdUp>
				<List>
					{rows.map((video) => (
						<VideoListItem video={video} key={video.id} />
					))}
					{emptyRows > 0 && (
						<ListItem
							style={{
								height: 72 * emptyRows,
							}}></ListItem>
					)}
				</List>
			</Hidden>
			<Hidden smDown>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow>
								{headCells.map((headCell) => (
									<TableCell
										className="table-header"
										key={headCell.id}
										align={headCell.numeric ? "right" : "left"}
										sortDirection={orderBy === headCell.id ? order : false}>
										<TableSortLabel
											active={orderBy === headCell.id}
											direction={orderBy === headCell.id ? order : "asc"}
											onClick={handleTableRequestSort(headCell.id)}>
											{headCell.label}
											{orderBy === headCell.id ? (
												<Box component="span" style={{ display: "none" }}>
													{order === "desc"
														? "sorted descending"
														: "sorted ascending"}
												</Box>
											) : null}
										</TableSortLabel>
									</TableCell>
								))}
								<TableCell className="table-header" align="center">
									Actions
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((video) => (
								<VideoRow key={video.id} video={video} />
							))}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 33 * emptyRows,
									}}>
									<TableCell colSpan={7} />
								</TableRow>
							)}
						</TableBody>
					</Table>
					{filteredVideos.length === 0 && <h3>No videos!</h3>}
				</TableContainer>
			</Hidden>
			<TablePagination
				rowsPerPageOptions={[10, 25, 50]}
				component="div"
				count={filteredVideos.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</Fragment>
	)
}
