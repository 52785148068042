// components
import {
	Button,
	Container,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core"
import axios from "axios"
import React, { createContext, useContext, useEffect, useState } from "react"
// types and contexts
import { FeedbackContext } from "../../../context/FeedbackContext"
import { GlobalsContext } from "../../../context/GlobalsContext"
import { Season } from "../../../interfaces/SeasonInterface"
import { NewSeasonModal } from "./NewSeasonModal"
import { SeasonRow } from "./SeasonRow"

// indicates to parent component to refetch all the seasons
export const SeasonsUpdatedContext = createContext({
	refetchSeasons: null as unknown as () => void,
})

export const SeasonsTab = () => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)

	// holds all of the seasons in the system
	const [seasons, setSeasons] = useState<Season[]>([])

	// changes when a season was edited, deleted, or created
	const [seasonsUpdated, setSeasonsUpdated] = useState<boolean>(false)

	// whether the new season modal is open
	const [newSeasonModal, setNewSeasonModal] = useState<boolean>(false)

	useEffect(() => {
		const fetchSeasons = async () => {
			try {
				const response = await axios.get("/api/season/all", globals.apiHeader)
				setSeasons(response.data)
				globals.setSeasons(response.data)
			} catch (e) {
				setFeedback({
					show: true,
					severity: "error",
					message: "Something went wrong while fetching the seasons",
				})
				console.log(e)
			}
		}
		fetchSeasons()
	}, [seasonsUpdated])

	return (
		<SeasonsUpdatedContext.Provider
			value={{ refetchSeasons: () => setSeasonsUpdated(!seasonsUpdated) }}>
			<Container className="padding-top-24 padding-bottom-24">
				<Grid container spacing={0}>
					<Grid item xs={12} className="align-right">
						<Button
							variant="contained"
							color="primary"
							onClick={() => setNewSeasonModal(true)}>
							New Season
						</Button>
					</Grid>
					<Grid
						item
						xs={12}
						className="margin-top-24"
						component={Paper}
						style={{ overflow: "scroll" }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell className="table-header" align="left">
										Name
									</TableCell>
									<TableCell className="table-header" align="left">
										Year
									</TableCell>
									<TableCell className="table-header" align="center">
										#Games
									</TableCell>
									<TableCell className="table-header" align="center">
										#Practices
									</TableCell>
									<TableCell className="table-header" align="center">
										Current Season
									</TableCell>
									<TableCell className="table-header" align="center">
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{seasons.map((season) => (
									<SeasonRow key={season.id} season={season} />
								))}
							</TableBody>
						</Table>
					</Grid>
				</Grid>
				<NewSeasonModal
					open={newSeasonModal}
					close={() => setNewSeasonModal(false)}
				/>
			</Container>
		</SeasonsUpdatedContext.Provider>
	)
}
