import React, { useState, useEffect, useContext } from "react"

import axios from "axios"

// components
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import { SeasonSelector } from "../../../shared/SeasonSelector"
import { HomeAwayToggle } from "../../../shared/HomeAwayToggle"
import { TransitionUp } from "../../../shared/ModalTransitions/TransitionUp"
import { ButtonApiRequest } from "../../../shared/ButtonApiRequest"

// for the date picker
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"

// types
import { Team } from "../../../interfaces/TeamInterface"

// contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { CompetitionsPageContext } from "../CompetitionsPage"

// holds the necessary information for the game
interface FormValues {
	date: any
	opponent: number | null
	home: boolean
	season: number | string | null
}

const initialFormValues: FormValues = {
	date: new Date(),
	opponent: 0,
	home: true,
	season: 0,
}

interface Props {
	open: boolean
	handleClose: () => void
	defaultSeason: number
}

export const NewGameModal = ({ open, handleClose, defaultSeason }: Props) => {
	const { teamId, apiHeader, currentSeason } = useContext(GlobalsContext)
	const { alertSuccess, alertError } = useContext(FeedbackContext)
	const { refetchCompetitions } = useContext(CompetitionsPageContext)

	const [loading, setLoading] = useState<boolean>(false)
	const [teams, setTeams] = useState<Array<Team>>([])
	const [formValues, setFormValues] = useState<FormValues>({
		...initialFormValues,
		season: defaultSeason !== 0 ? defaultSeason : currentSeason,
	})

	useEffect(() => {
		resetFormValues()
	}, [defaultSeason])

	useEffect(() => {
		if (open) {
			const fetchTeams = async () => {
				try {
					const teams = await axios.get("/api/team/all", apiHeader)
					setTeams(teams.data)
				} catch (e) {
					alertError("Something went wrong fetching teams")
				}
			}
			fetchTeams()
		}
	}, [open])

	const formatDate = (date: Date) =>
		`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

	const addGame = async () => {
		setLoading(true)
		try {
			await axios.post(
				"/api/game",
				{
					home_team_id: formValues.home ? teamId : formValues.opponent,
					away_team_id: formValues.home ? formValues.opponent : teamId,
					season_id: formValues.season,
					date: formatDate(formValues.date),
				},
				apiHeader
			)
			alertSuccess("Game created!")
			handleClose()
			refetchCompetitions()
		} catch (e) {
			alertError("Something went wrong")
		}
		resetFormValues()
		setLoading(false)
	}

	const resetFormValues = () => {
		setFormValues({
			...initialFormValues,
			season: defaultSeason !== 0 ? defaultSeason : currentSeason,
		})
	}

	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			open={open}
			onClose={handleClose}
			TransitionComponent={TransitionUp}>
			<DialogTitle>New Game</DialogTitle>
			<DialogContent>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								fullWidth
								required
								className="game-date-picker"
								disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								margin="normal"
								label="Date"
								autoOk={true}
								value={formValues.date}
								onChange={(date) => setFormValues({ ...formValues, date })}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							options={teams.sort(
								(a, b) => (b.favorite as any) - (a.favorite as any)
							)}
							groupBy={(option) => (option.favorite ? "Favorites" : "Rest")}
							getOptionLabel={(option) => option.name}
							onChange={(event: React.ChangeEvent<{}>, value: Team | null) => {
								if (value == null) {
									return setFormValues({ ...formValues, opponent: 0 })
								}
								return setFormValues({ ...formValues, opponent: value.id })
							}}
							renderInput={(params) => (
								<TextField {...params} required label="Opponent" />
							)}
						/>
					</Grid>
					<Grid item xs={12} className="padding-top-12">
						<SeasonSelector
							defaultValue={formValues.season as number}
							required
							fullWidth
							variant="standard"
							onChange={(value: number) =>
								setFormValues({ ...formValues, season: value })
							}
						/>
					</Grid>
					<Grid item xs={12} className="padding-top-24">
						<HomeAwayToggle
							value={formValues.home ? "home" : "away"}
							onChange={(value: "home" | "away") =>
								setFormValues({ ...formValues, home: value === "home" })
							}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
				<ButtonApiRequest
					text="Create"
					disabled={
						isNaN(formValues.date) || !formValues.opponent || !formValues.season
					}
					color="primary"
					onClick={addGame}
					loading={loading}
				/>
			</DialogActions>
		</Dialog>
	)
}
