import React from "react"

import { Snackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"

import { TransitionDown } from "./ModalTransitions/TransitionDown"

interface Props {
	show: boolean
	setShow: React.Dispatch<React.SetStateAction<boolean>>
	message: string
	severity: "success" | "info" | "warning" | "error" | undefined
}

export const Feedback = ({ show, setShow, message, severity }: Props) => {
	const handleClose = () => {
		setShow(false)
	}

	return (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={show}
			TransitionComponent={TransitionDown}
			autoHideDuration={4000}
			onClose={handleClose}>
			<MuiAlert elevation={6} onClose={handleClose} severity={severity}>
				{message}
			</MuiAlert>
		</Snackbar>
	)
}
