import { DetailedPitch } from "../../../interfaces/DetailedPitch"

export class PitchStatistics {
	pitchType: string
	numPitches: number

	veloSamples: number
	veloTotal: number
	veloAvg: number
	veloMin: number
	veloMax: number
	veloVariance: number
	veloSquaredScores: number
	veloStdDev: number

	numStrikes: number
	strikePct: number
	numSwingingStrikes: number
	numCalledStrikes: number
	cswPct: number
	numFirstPitches: number
	fpsPct: number
	numFirstPitchStrikes: number

	ballNum: number
	ballPct: number
	calledStrikeNum: number
	calledStrikePct: number
	swingingStrikeNum: number
	swingingStrikePct: number
	foulBallNum: number
	foulBallPct: number
	inPlayNum: number
	inPlayPct: number

	groundballPct: number
	groundballNum: number
	flyballPct: number
	flyballNum: number
	linedrivePct: number
	linedriveNum: number
	hitHardPct: number
	hitHardNum: number

	constructor(type: string) {
		this.pitchType = type

		this.numPitches = 0
		this.veloSamples = 0
		this.veloTotal = 0
		this.veloAvg = 0
		this.veloMin = 999
		this.veloMax = 0
		this.veloVariance = 0
		this.veloSquaredScores = 0
		this.veloStdDev = 0

		this.numStrikes = 0
		this.strikePct = 0
		this.numSwingingStrikes = 0
		this.numCalledStrikes = 0
		this.cswPct = 0
		this.numFirstPitches = 0
		this.numFirstPitchStrikes = 0
		this.fpsPct = 0

		this.ballPct = 0
		this.ballNum = 0
		this.calledStrikePct = 0
		this.calledStrikeNum = 0
		this.swingingStrikePct = 0
		this.swingingStrikeNum = 0
		this.foulBallPct = 0
		this.foulBallNum = 0
		this.inPlayPct = 0
		this.inPlayNum = 0

		this.hitHardPct = 0
		this.hitHardNum = 0
		this.groundballPct = 0
		this.groundballNum = 0
		this.flyballPct = 0
		this.flyballNum = 0
		this.linedrivePct = 0
		this.linedriveNum = 0
	}

	addVeloForPitch(x: number) {
		this.veloTotal += x
		this.veloSquaredScores += x ** 2
		this.veloSamples += 1
		if (x < this.veloMin) {
			this.veloMin = x
		}
		if (x > this.veloMax) {
			this.veloMax = x
		}
	}

	addPitch(p: DetailedPitch) {
		this.numPitches += 1
		if (p.velocity) {
			this.addVeloForPitch(p.velocity)
		}
		if (p.ab_pitch_num === 1) {
			this.numFirstPitches += 1
		}

		if (!["B"].includes(p.pitch_result)) {
			this.numStrikes += 1
			if (p.ab_pitch_num === 1) {
				this.numFirstPitchStrikes += 1
			}
			if (["CS"].includes(p.pitch_result)) {
				this.calledStrikeNum += 1
			} else {
				if (["SS"].includes(p.pitch_result)) {
					this.swingingStrikeNum += 1
				} else {
					if (["F"].includes(p.pitch_result)) {
						this.foulBallNum += 1
					} else {
						if (p.traj) {
							this.inPlayNum += 1
							if (p.hitHard) {
								this.hitHardNum += 1
							}
							switch (p.traj) {
								case "GB":
									this.groundballNum += 1
									break
								case "LD":
									this.linedriveNum += 1
									break
								case "FB":
									this.flyballNum += 1
									break
								default:
									break
							}
						}
					}
				}
			}
		} else {
			this.ballNum += 1
		}
	}

	calculateStatistics() {
		// VELO STATISTICS
		this.veloAvg = this.veloSamples > 0 ? this.veloTotal / this.veloSamples : 0
		this.veloMin = this.veloMin == 999 ? 0 : this.veloMin
		this.veloVariance =
			this.veloSamples > 0
				? this.veloSquaredScores / this.veloSamples - this.veloAvg ** 2
				: 0
		this.veloStdDev = Math.sqrt(this.veloVariance)

		// COMMAND/STUFF STATISTICS
		this.strikePct =
			this.numPitches > 0 ? (100 * this.numStrikes) / this.numPitches : 0
		this.fpsPct =
			this.numFirstPitches > 0
				? (100 * this.numFirstPitchStrikes) / this.numFirstPitches
				: 0
		this.cswPct =
			this.numPitches > 0
				? (100 * (this.swingingStrikeNum + this.calledStrikeNum)) /
				  this.numPitches
				: 0

		// BALL IN PLAY STATISTICS
		this.hitHardPct =
			this.inPlayNum > 0 ? (100 * this.hitHardNum) / this.inPlayNum : 0
		this.groundballPct =
			this.inPlayNum > 0 ? (100 * this.groundballNum) / this.inPlayNum : 0
		this.flyballPct =
			this.inPlayNum > 0 ? (100 * this.flyballNum) / this.inPlayNum : 0
		this.linedrivePct =
			this.inPlayNum > 0 ? (100 * this.linedriveNum) / this.inPlayNum : 0

		// PITCH RESULT STATISTICS
		this.ballPct =
			this.numPitches > 0 ? (100 * this.ballNum) / this.numPitches : 0
		this.calledStrikePct =
			this.numPitches > 0 ? (100 * this.calledStrikeNum) / this.numPitches : 0
		this.swingingStrikePct =
			this.numPitches > 0 ? (100 * this.swingingStrikeNum) / this.numPitches : 0
		this.foulBallPct =
			this.numPitches > 0 ? (100 * this.foulBallNum) / this.numPitches : 0
		this.inPlayPct =
			this.numPitches > 0 ? (100 * this.inPlayNum) / this.numPitches : 0
	}

	getVeloStatistics() {
		return {
			type: this.pitchType,
			num: this.numPitches,
			avg: this.veloAvg,
			max: this.veloMax,
			min: this.veloMin,
			stdev: this.veloStdDev,
			variance: this.veloVariance,
		}
	}

	getCommandStatistics() {
		return {
			type: this.pitchType,
			num: this.numPitches,
			strikePct: this.strikePct,
			fpsPct: this.fpsPct,
			cswPct: this.cswPct,
		}
	}

	getInPlayStatistics() {
		return {
			type: this.pitchType,
			hitHardPct: this.hitHardPct,
			groundballPct: this.groundballPct,
			linedrivePct: this.linedrivePct,
			flyballPct: this.flyballPct,
		}
	}

	getPitchResultStatistics() {
		return {
			type: this.pitchType,
			ballPct: this.ballPct,
			calledStrikePct: this.calledStrikePct,
			swingingStrikePct: this.swingingStrikePct,
			foulBallPct: this.foulBallPct,
			inPlayPct: this.inPlayPct,
		}
	}
}
