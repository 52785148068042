import { AtBat } from "../../interfaces/AtBatInterface"
import { Pitch } from "../../interfaces/PitchInterface"

/**
 * 
 * @param atBats array of AtBat objects
 * @returns a dictionary calculating stats for each pitch type
 * 						- num: number of pitches for that pitch type,
							- strikes: number of strikes for that pitch type,
			        - veloMin: min velo for that pitch type,
							- veloMax: max velo for that pitch type,
			        - pitchesWithVelo: num of pitches with a velo reading,
							- veloTotal: total velos for that pitch type,
							- swings: number of swings off of that pitch type,
							- firstPitches: number of times thrown 0-0,
							- twoStrikes: number of times thrown with 2 strikes,
							- balls: number of times it was a ball,
							- calledStrikes: number of times it was a called strike,
							- swingingStrikes: number of time it was a swinging strike,
							- fouls: number of times it was fouled off,
							- inPlay: number of times it was put in play,
 */
export const calcPitchStatsForOuting = (atBats: AtBat[]) => {
	const pitchTypes = ["FB", "CB", "SL", "CH", "CT", "2S", "SPL", "OTH"]

	let pitchStats: { [key: string]: any } = {}
	pitchTypes.forEach((pitchType) => {
		pitchStats[pitchType] = {
			num: 0,
			strikes: 0,
			veloMin: 1000,
			veloMax: 0,
			pitchesWithVelo: 0,
			veloTotal: 0,
			swings: 0,
			firstPitches: 0,
			twoStrikes: 0,
			balls: 0,
			calledStrikes: 0,
			swingingStrikes: 0,
			fouls: 0,
			inPlay: 0,
		}
	})

	atBats.forEach((atBat: AtBat) => {
		if (atBat.pitches) {
			atBat.pitches.forEach((pitch: Pitch, index: number) => {
				if (index === 0) {
					pitchStats[pitch.pitch_type].firstPitches += 1
				}
				if (pitch.strikes === 2) {
					pitchStats[pitch.pitch_type].twoStrikes += 1
				}
				pitchStats[pitch.pitch_type].num += 1
				if (pitch.pitch_result !== "B") {
					pitchStats[pitch.pitch_type].strikes += 1
				}
				if (pitch.velocity) {
					pitchStats[pitch.pitch_type].pitchesWithVelo += 1
					pitchStats[pitch.pitch_type].veloTotal += pitch.velocity
					if (pitch.velocity < pitchStats[pitch.pitch_type].veloMin) {
						pitchStats[pitch.pitch_type].veloMin = pitch.velocity
					}
					if (pitch.velocity > pitchStats[pitch.pitch_type].veloMax) {
						pitchStats[pitch.pitch_type].veloMax = pitch.velocity
					}
				}
				if (["SS", "F", "IP"].includes(pitch.pitch_result)) {
					pitchStats[pitch.pitch_type].swings += 1
				}
				if (pitch.pitch_result === "SS") {
					pitchStats[pitch.pitch_type].swingingStrikes += 1
				} else if (pitch.pitch_result === "CS") {
					pitchStats[pitch.pitch_type].calledStrikes += 1
				} else if (pitch.pitch_result === "F") {
					pitchStats[pitch.pitch_type].fouls += 1
				} else if (pitch.pitch_result === "B") {
					pitchStats[pitch.pitch_type].balls += 1
				} else {
					pitchStats[pitch.pitch_type].inPlay += 1
				}
			})
		}
	})
	return pitchStats
}
