import { createContext } from "react"
import { severity } from "./Types"

export const FeedbackContext = createContext({
	setFeedback: null as unknown as React.Dispatch<
		React.SetStateAction<{
			show: boolean
			severity: severity
			message: string
		}>
	>,
	alertInfo: null as unknown as (message: string) => void,
	alertWarning: null as unknown as (message: string) => void,
	alertError: null as unknown as (message: string) => void,
	alertSuccess: null as unknown as (message: string) => void,
})
