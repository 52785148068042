import React, { useContext, useState, useEffect, createContext } from "react"

import axios from "axios"

// components
import {
	Container,
	Grid,
	Button,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@material-ui/core"
import { CategoryRow } from "./CategoryRow"
import { NewCategoryModal } from "./NewCategoryModal"

// types and contexts
import { FeedbackContext } from "../../../context/FeedbackContext"
import { GlobalsContext } from "../../../context/GlobalsContext"
import { ResourceCategory } from "../../../interfaces/ResourceCategory"
import { EditOrderModal } from "./EditOrderModal"

// indicates to parent component to refetch all the categories
export const CategoriesUpdatedContext = createContext({
	refetchCategories: (null as unknown) as () => void,
})

export const ResourcesTab = () => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)

	// holds all of the resource categories in the system
	const [categories, setCategories] = useState<ResourceCategory[]>([])

	// changes when a category was edited, deleted, or created
	const [categoriesUpdated, setCategoriesUpdated] = useState<boolean>(false)

	// whether the new category modal is open
	const [newCategoryModal, setNewCategoryModal] = useState<boolean>(false)

	// whether the edit order modal
	const [editOrderModal, setEditOrderModal] = useState<boolean>(false)

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const response = await axios.get(
					"/api/resource_category/all",
					globals.apiHeader
				)
				setCategories(response.data)
			} catch (e) {
				setFeedback({
					show: true,
					severity: "error",
					message: "Something went wrong while fetching the categories",
				})
				console.log(e)
			}
		}
		fetchCategories()
	}, [categoriesUpdated])

	return (
		<CategoriesUpdatedContext.Provider
			value={{
				refetchCategories: () => setCategoriesUpdated(!categoriesUpdated),
			}}>
			<Container className="padding-top-24 padding-bottom-24">
				<Grid container spacing={0}>
					<Grid item xs={6} className="align-left">
						<Button
							style={{ backgroundColor: "white" }}
							variant="outlined"
							color="primary"
							onClick={() => setEditOrderModal(true)}>
							Edit Order
						</Button>
					</Grid>
					<Grid item xs={6} className="align-right">
						<Button
							disableElevation
							variant="contained"
							color="primary"
							onClick={() => setNewCategoryModal(true)}>
							New Category
						</Button>
					</Grid>
					<Grid
						item
						xs={12}
						className="margin-top-24"
						component={Paper}
						style={{ overflow: "scroll" }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell
										className="table-header"
										align="center"></TableCell>
									<TableCell className="table-header" align="left">
										Name
									</TableCell>
									<TableCell className="table-header" align="center">
										#Resources
									</TableCell>
									<TableCell className="table-header" align="center">
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{categories.map((category) => (
									<CategoryRow key={category.id} category={category} />
								))}
							</TableBody>
						</Table>
					</Grid>
				</Grid>
				<NewCategoryModal
					open={newCategoryModal}
					close={() => setNewCategoryModal(false)}
				/>
				<EditOrderModal
					categories={categories}
					open={editOrderModal}
					close={() => setEditOrderModal(false)}
				/>
			</Container>
		</CategoriesUpdatedContext.Provider>
	)
}
