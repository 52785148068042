import { useContext, useState } from "react"

// components
import {
	Container,
	Table,
	TableContainer,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
	Paper,
	Grid,
	IconButton,
} from "@material-ui/core"
import { NewGameModal } from "./NewGameModal"
import { GameRow } from "./GameRow"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import { EditGameModal } from "./EditGameModal"
import AddIcon from "@material-ui/icons/Add"

// types
import { Game } from "../../../interfaces/GameInterface"

// contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { CompetitionsPageContext } from "../CompetitionsPage"

export const GamesTab = () => {
	const { user } = useContext(GlobalsContext)
	const { loading, season, games } = useContext(CompetitionsPageContext)

	const [newGameModal, setNewGameModal] = useState<boolean>(false)
	const [editGameModal, setEditGameModal] = useState<Game | null>(null)

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container className="tab-container">
			<Grid container spacing={0} component={Paper}>
				<Grid item xs={9} className="comps-table-title">
					<span>Games</span>
				</Grid>
				{user!.admin && (
					<Grid item xs={3} className="comps-table-add">
						<IconButton onClick={() => setNewGameModal(true)}>
							<AddIcon />
						</IconButton>
					</Grid>
				)}
				<Grid item xs={12}>
					{games.length > 0 ? (
						<TableContainer>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell className="table-header" align="left">
											Date
										</TableCell>
										<TableCell className="table-header" align="left">
											&nbsp;&nbsp;Game
										</TableCell>
										{user!.admin && (
											<TableCell className="table-header" align="center">
												Actions
											</TableCell>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{games.map((game: Game) => (
										<GameRow
											key={game.id}
											game={game}
											handleEdit={() => setEditGameModal(game)}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Container className="padding-bottom-24">
							No games yet for this season
						</Container>
					)}
				</Grid>
			</Grid>
			<NewGameModal
				open={newGameModal}
				handleClose={() => setNewGameModal(false)}
				defaultSeason={season}
			/>
			<EditGameModal
				open={editGameModal !== null}
				game={editGameModal}
				handleClose={() => setEditGameModal(null)}
			/>
		</Container>
	)
}
