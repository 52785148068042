import { useContext, useEffect, useState } from "react"

import axios from "axios"
import { useParams } from "react-router-dom"

// components
import { HittingTab } from "../../shared/CompetitionsPages/HittingTab/HittingTab"
import { SubnavTemplate } from "../../shared/MainLayout/SubnavTemplate/SubnavTemplate"
import { PitchingTab } from "../../shared/CompetitionsPages/PitchingTab/PitchingTab"
import { ReportTab } from "../../shared/CompetitionsPages/ReportsTab/ReportsTab"

// types and contexts
import { GlobalsContext } from "../../context/GlobalsContext"

// util functions
import { formatDate } from "../../shared/Functions/FormatDate"

interface ParamTypes {
	id: string | undefined
}

export const GamePage = () => {
	const globals = useContext(GlobalsContext)

	// from the URL query parameter
	const { id } = useParams<ParamTypes>()

	// holds the title of the page for better information
	const [gameInfo, setGameInfo] = useState<string>("")

	// fetches the game and comes up with the title for the page
	useEffect(() => {
		const fetchGameInfo = async () => {
			try {
				const response = await axios.get(`/api/game/${id}`, globals.apiHeader)
				const game = response.data
				if (game.home_team.id === globals.teamId) {
					setGameInfo(`${formatDate(game.date)} vs. ${game.away_team.name}`)
				} else {
					setGameInfo(`${formatDate(game.date)} @ ${game.home_team.name}`)
				}
			} catch (e) {
				console.log(e)
			}
		}
		fetchGameInfo()
	}, [])

	const tabs = [
		{
			name: "Pitching",
			component: <PitchingTab variant="game" id={(id as unknown) as number} />,
		},
		{
			name: "Hitting",
			component: <HittingTab variant="game" id={(id as unknown) as number} />,
		},
		{
			name: "Reports",
			component: <ReportTab variant="game" id={(id as unknown) as number} />
 		}
	]

	return <SubnavTemplate tabs={tabs} title={gameInfo} />
}
