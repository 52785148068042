import { useContext, useEffect, useState } from "react"

import axios from "axios"
import { useHistory, useParams } from "react-router-dom"

// components
import { Container, Grid, IconButton } from "@material-ui/core"
import { LoadingBig } from "../../shared/Loading/LoadingBig"
import { EditScoutingPageModal } from "./EditScoutingPage"

// types and contexts
import { GlobalsContext } from "../../context/GlobalsContext"
import { Player } from "../../interfaces/PlayerInterface"
import { Team } from "../../interfaces/TeamInterface"
import { TeamScoutingPage } from "../../interfaces/TeamScoutingPageInterface"
import { TeamStreamLink } from "../../interfaces/TeamStreamLink"

// functions
import { formatDate } from "../../shared/Functions/FormatDate"
import { DeleteOutline, EditOutlined } from "@material-ui/icons"
import { FeedbackContext } from "../../context/FeedbackContext"

interface ParamTypes {
	id: string | undefined
}

export const TeamScoutPage = () => {
	let history = useHistory()

	const { setFeedback } = useContext(FeedbackContext)

	// for the api request headers
	const globals = useContext(GlobalsContext)

	// the id of the scout page to fetch
	const { id } = useParams<ParamTypes>()

	// while fetching all info for the scout page
	const [loading, setLoading] = useState<boolean>(true)

	// holds all static info for the scout page
	const [scoutPage, setScoutPage] = useState<TeamScoutingPage>()
	const [team, setTeam] = useState<Team>()
	const [players, setPlayers] = useState<Player[]>([])
	const [videoLinks, setVideoLinks] = useState<TeamStreamLink[]>([])

	// if there was a fetch error while gathering all of the info above
	const [error, setError] = useState<boolean>(false)

	// whether or not to show the edit modal
	const [editScoutPageModal, setEditScoutPageModal] = useState<boolean>(false)

	// changes when the scout page should be refetched
	const [refetchInfo, setRefetchInfo] = useState<boolean>(false)

	useEffect(() => {
		// gathers all the information for the scout for the team
		const fetchScoutPage = async () => {
			try {
				// the scout page resource
				const response = await axios.get(
					`/api/team_scout_page/${id}`,
					globals.apiHeader
				)
				setScoutPage(response.data)

				// the team information
				const teamInfo = await axios.get(
					`/api/team/${response.data.team_id}`,
					globals.apiHeader
				)
				setTeam(teamInfo.data)

				// the players from the team
				const playerResponse = await axios.get(
					`/api/player?team_id=${response.data.team_id}&active=true&order_by=number`,
					globals.apiHeader
				)
				setPlayers(playerResponse.data)

				// the video links for the team
				const streamLinks = await axios.get(
					`/api/team_stream_link?team_id=${response.data.team_id}`,
					globals.apiHeader
				)
				setVideoLinks(streamLinks.data)
			} catch (e) {
				console.log(e)
				setError(true)
			}
			setLoading(false)
		}
		fetchScoutPage()
	}, [refetchInfo])

	const handleDelete = async () => {
		if (confirm("Are you sure you want to delete this page?")) {
			try {
				await axios.delete(`/api/team_scout_page/${id}`, globals.apiHeader)
				for (let i = 0; i < videoLinks.length; i++) {
					await axios.delete(
						`/api/team_stream_link/${videoLinks[i].id}`,
						globals.apiHeader
					)
				}
				await axios.patch(
					`/api/team/${team!.id}`,
					{ general_notes: "" },
					globals.apiHeader
				)
				history.push("/scouting")
				setFeedback({
					show: true,
					severity: "success",
					message: "Page deleted!",
				})
			} catch (e) {
				console.log(e)
				history.push("/scouting")
				setFeedback({
					show: true,
					severity: "error",
					message: "Something went wrong",
				})
			}
		}
	}

	if (loading) {
		return <LoadingBig />
	}

	if (error) {
		return (
			<Container className="padding-top-24 padding-bottom-24">
				There was an error getting this scout page. Please try again.
			</Container>
		)
	}

	return (
		<Container className="padding-top-24 padding-bottom-24">
			<Grid container spacing={0}>
				<Grid item xs={10}>
					<div style={{ fontWeight: "bold", fontSize: "44px" }}>
						{team!.name} Scout Page
					</div>
					<div>Last Updated: {formatDate(scoutPage!.last_updated)}</div>
				</Grid>
				{globals.admin && (
					<Grid item xs={2} className="align-right">
						<IconButton onClick={() => setEditScoutPageModal(true)}>
							<EditOutlined />
						</IconButton>
						<IconButton onClick={handleDelete}>
							<DeleteOutline />
						</IconButton>
					</Grid>
				)}
				<Grid item xs={12} className="padding-top-24">
					<hr />
					<div style={{ fontWeight: "bold", fontSize: "24px" }}>
						Video/Stream Links
					</div>
					{videoLinks.map((link, i) => (
						<div key={link.id} className="padding-top-12">
							<a href={link.link} target="_blank">
								Link {i + 1}
							</a>
							<div>{link.access_notes}</div>
						</div>
					))}
				</Grid>
				<Grid item xs={12} className="padding-top-24">
					<hr />
					<div style={{ fontWeight: "bold", fontSize: "24px" }}>
						General Notes
					</div>
					<div style={{ whiteSpace: "pre-wrap" }}>{team!.general_notes}</div>
				</Grid>
				<Grid item xs={12} className="padding-top-24">
					<hr />
					<div style={{ fontWeight: "bold", fontSize: "24px" }}>Pitchers</div>
					{players.map((player) => {
						if (player.pitching_notes) {
							return (
								<div key={player.id} className="padding-top-12">
									<div style={{ fontSize: "18px" }}>
										{player.number && `#${player.number} `}
										{player.firstname} {player.lastname}{" "}
										{player.throws && `(${player.throws})`}
									</div>
									<div>{player.pitching_notes}</div>
								</div>
							)
						} else {
							return <div key={player.id}></div>
						}
					})}
				</Grid>
				<Grid item xs={12} className="padding-top-24">
					<hr />
					<div style={{ fontWeight: "bold", fontSize: "24px" }}>Hitters</div>
					{players.map((player) => {
						if (player.hitting_notes) {
							return (
								<div key={player.id} className="padding-top-12">
									<div style={{ fontSize: "18px" }}>
										{player.number && `#${player.number} `}
										{player.firstname} {player.lastname}
										{player.bats && `(${player.bats})`}
									</div>
									<div>{player.hitting_notes}</div>
								</div>
							)
						} else {
							return <div key={player.id}></div>
						}
					})}
				</Grid>
			</Grid>
			<EditScoutingPageModal
				open={editScoutPageModal}
				close={() => setEditScoutPageModal(false)}
				refetchScoutPage={() => setRefetchInfo(!refetchInfo)}
				currentScoutInfo={{
					scoutPage: scoutPage!,
					team: team!,
					videoLinks,
					players,
				}}
			/>
		</Container>
	)
}
