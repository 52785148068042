import React, { useContext, useState } from "react"
import {
	Toolbar,
	Tabs,
	Tab,
	IconButton,
	Menu,
	MenuItem,
	Box,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import AccountCircle from "@material-ui/icons/AccountCircle"

import BearHead from "../../../../static/img/BearHead.png"
import { GlobalsContext } from "../../../../context/GlobalsContext"

interface Props {
	page: any
	setPage: React.Dispatch<any>
	handlePageChange: (event: React.ChangeEvent<{}>, newPage: number) => void
	logout: () => void
	admin: boolean
}

export const BigScreenNav = ({
	page,
	setPage,
	handlePageChange,
	logout,
	admin,
}: Props) => {
	const globals = useContext(GlobalsContext)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const handleProfileClick = () => {
		setAnchorEl(null)
		setPage(false)
	}

	return (
		<Toolbar id="screen-large">
			<Box display="flex" flexGrow={1}>
				<IconButton
					edge="start"
					color="inherit"
					aria-label="home"
					component={Link}
					to="/"
					onClick={() => setPage(false)}>
					<img className="app-bar-logo" src={BearHead} alt="bear-head" />
				</IconButton>
				<Tabs
					value={page}
					onChange={handlePageChange}
					indicatorColor="secondary">
					<Tab label="Pitching" component={Link} to="/pitching" />
					<Tab label="Hitting" component={Link} to="/hitting" />
					<Tab label="Competitions" component={Link} to="/competitions" />
					<Tab label="Teams" component={Link} to="/teams" />
					<Tab label="Resources" component={Link} to="/resources" />
					<Tab label="Scouting" component={Link} to="/scouting" />
				</Tabs>
			</Box>
			<IconButton
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={(event: React.MouseEvent<HTMLElement>) =>
					setAnchorEl(event.currentTarget)
				}
				color="inherit">
				<AccountCircle />
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}>
				<MenuItem onClick={handleProfileClick} component={Link} to="/profile">
					Profile
				</MenuItem>
				{globals.admin && (
					<MenuItem onClick={handleProfileClick} component={Link} to="/admin">
						Admin
					</MenuItem>
				)}
				<MenuItem onClick={logout}>Logout</MenuItem>
			</Menu>
		</Toolbar>
	)
}
