import { useState, useContext, useEffect } from "react"

import axios from "axios"

// components
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
} from "@material-ui/core"
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import { SeasonSelector } from "../../../shared/SeasonSelector"
import { ButtonApiRequest } from "../../../shared/ButtonApiRequest"
import { TransitionUp } from "../../../shared/ModalTransitions/TransitionUp"

// for the date picker
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"

// types
import { Practice } from "../../../interfaces/PracticeInterface"

// contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { CompetitionsPageContext } from "../CompetitionsPage"

interface Props {
	open: boolean
	handleClose: () => void
	practice: Practice | null
}

export const EditPracticeModal = ({ open, handleClose, practice }: Props) => {
	const { apiHeader, teamId } = useContext(GlobalsContext)
	const { alertSuccess, alertError } = useContext(FeedbackContext)
	const { refetchCompetitions } = useContext(CompetitionsPageContext)

	const [loading, setLoading] = useState<boolean>(false)

	const getDate = (d: string | Date) => {
		const date = new Date(d)
		date.setDate(date.getDate() + 1)
		return date
	}

	const [formValues, setFormValues] = useState<{ [key: string]: any }>({
		id: practice ? practice.id : 0,
		date: practice ? getDate(practice.date) : new Date(),
		season: practice ? practice.season_id : "",
	})

	useEffect(() => {
		setFormValues({
			id: practice ? practice.id : 0,
			date: practice ? getDate(practice.date) : new Date(),
			season: practice ? practice.season_id : "",
		})
	}, [practice])

	const formatDate = (date: Date) => {
		return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
	}

	const editPractice = async () => {
		setLoading(true)
		try {
			await axios.patch(
				`/api/practice/${formValues.id}`,
				{
					home_team_id: formValues.home ? teamId : formValues.opponent,
					away_team_id: formValues.home ? formValues.opponent : teamId,
					season_id: formValues.season,
					date: formatDate(formValues.date),
				},
				apiHeader
			)
			handleClose()
			alertSuccess("Practice saved!")
			refetchCompetitions()
		} catch (e) {
			alertError(
				"Something wen't wrong. Please refresh the page and try again."
			)
		}
		setLoading(false)
	}

	return (
		<div>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={open}
				onClose={handleClose}
				TransitionComponent={TransitionUp}>
				<DialogTitle>Edit Practice</DialogTitle>
				<DialogContent>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									required
									fullWidth
									disableToolbar
									variant="inline"
									format="MM/dd/yyyy"
									margin="normal"
									label="Date"
									autoOk={true}
									value={formValues.date}
									onChange={(date) => setFormValues({ ...formValues, date })}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12} className="padding-top-12">
							<SeasonSelector
								defaultValue={formValues.season as number | undefined}
								required
								fullWidth
								variant="standard"
								onChange={(value: number) =>
									setFormValues({ ...formValues, season: value })
								}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<ButtonApiRequest
						color="primary"
						disabled={isNaN(formValues.date) || !formValues.season}
						loading={loading}
						onClick={editPractice}
						text="Save"
					/>
				</DialogActions>
			</Dialog>
		</div>
	)
}
