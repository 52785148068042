import { useState, useContext } from "react"

import axios from "axios"

// components
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
} from "@material-ui/core"
import { DeleteOutline } from "@material-ui/icons"
import { ButtonApiRequest } from "../../shared/ButtonApiRequest"

// types and contexts
import { GlobalsContext } from "../../context/GlobalsContext"
import { FeedbackContext } from "../../context/FeedbackContext"
import { Team } from "../../interfaces/TeamInterface"
import { Player } from "../../interfaces/PlayerInterface"
import { ScoutPagesContext } from "./ScoutingPage"
import { TeamStreamLink } from "../../interfaces/TeamStreamLink"
import { TeamScoutingPage } from "../../interfaces/TeamScoutingPageInterface"

// holds the information for the scout on a specific team
interface FormValues {
	lastUpdated: any
	generalNotes: string
	videoLinks: string[]
	videoAccessNotes: string[]
	pitchers: (number | string)[]
	pitcherNotes: string[]
	hitters: (number | string)[]
	hitterNotes: string[]
}

interface Props {
	open: boolean
	close: () => void
	refetchScoutPage: () => void
	currentScoutInfo: {
		scoutPage: TeamScoutingPage
		team: Team
		videoLinks: TeamStreamLink[]
		players: Player[]
	}
}

export const EditScoutingPageModal = ({
	open,
	close,
	refetchScoutPage,
	currentScoutInfo,
}: Props) => {
	// used throughout app
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)

	// when the api requests to adjust the scout page
	const [loading, setLoading] = useState<boolean>(false)

	// will keep track of the user entering information for the scout
	const initialFormValues: FormValues = {
		lastUpdated: new Date().toLocaleDateString(),
		generalNotes: currentScoutInfo.team.general_notes,
		videoLinks: currentScoutInfo.videoLinks.map((link) => link.link),
		videoAccessNotes: currentScoutInfo.videoLinks.map(
			(link) => link.access_notes
		),
		pitchers: currentScoutInfo.players
			.filter(
				(player) =>
					player.pitching_notes !== "" && player.pitching_notes !== null
			)
			.map((player) => player.id),
		pitcherNotes: currentScoutInfo.players
			.filter(
				(player) =>
					player.pitching_notes !== "" && player.pitching_notes !== null
			)
			.map((player) => player.pitching_notes),
		hitters: currentScoutInfo.players
			.filter(
				(player) => player.hitting_notes !== "" && player.hitting_notes !== null
			)
			.map((player) => player.id),
		hitterNotes: currentScoutInfo.players
			.filter(
				(player) => player.hitting_notes !== "" && player.hitting_notes !== null
			)
			.map((player) => player.hitting_notes),
	}
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues)

	// save the changes
	const editScoutPage = async () => {
		setLoading(true)
		try {
			// adjust the scout page itself
			await axios.patch(
				`/api/team_scout_page/${currentScoutInfo.scoutPage.id}`,
				{
					last_updated: formValues.lastUpdated,
				},
				globals.apiHeader
			)

			// adjust the general scouting notes for the team
			await axios.patch(
				`/api/team/${currentScoutInfo.team.id}`,
				{ general_notes: formValues.generalNotes },
				globals.apiHeader
			)

			// adjust the video links to livestreams
			for (let i = 0; i < currentScoutInfo.videoLinks.length; i++) {
				await axios.delete(
					`/api/team_stream_link/${currentScoutInfo.videoLinks[i].id}`,
					globals.apiHeader
				)
			}
			for (let i = 0; i < formValues.videoLinks.length; i++) {
				await axios.post(
					"/api/team_stream_link",
					{
						team_id: currentScoutInfo.team.id,
						link: formValues.videoLinks[i],
						access_notes: formValues.videoAccessNotes[i],
					},
					globals.apiHeader
				)
			}

			// adjust scouting notes on their pitchers and hitters as necessary
			for (let i = 0; i < currentScoutInfo.players.length; i++) {
				// only set the "player notes" to "" if they used to have some notes
				if (
					!formValues.pitchers.includes(currentScoutInfo.players[i].id) &&
					currentScoutInfo.players[i].pitching_notes !== ""
				) {
					await axios.patch(
						`/api/player/${currentScoutInfo.players[i].id}`,
						{
							pitching_notes: "",
						},
						globals.apiHeader
					)
				}
				if (
					!formValues.hitters.includes(currentScoutInfo.players[i].id) &&
					currentScoutInfo.players[i].hitting_notes !== ""
				) {
					await axios.patch(
						`/api/player/${currentScoutInfo.players[i].id}`,
						{
							hitting_notes: "",
						},
						globals.apiHeader
					)
				}
			}

			// pitchers
			for (let i = 0; i < formValues.pitchers.length; i++) {
				await axios.patch(
					`/api/player/${formValues.pitchers[i]}`,
					{
						pitching_notes: formValues.pitcherNotes[i],
					},
					globals.apiHeader
				)
			}

			// hitters
			for (let i = 0; i < formValues.hitters.length; i++) {
				await axios.patch(
					`/api/player/${formValues.hitters[i]}`,
					{
						hitting_notes: formValues.hitterNotes[i],
					},
					globals.apiHeader
				)
			}
			setLoading(false)
			close()
			setFeedback({
				show: true,
				severity: "success",
				message: "Changes saved!",
			})
			refetchScoutPage()
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message: "Something went wrong",
			})
		}
		resetFormValues()
	}

	const resetFormValues = () => {
		setFormValues(initialFormValues)
	}

	const cancel = () => {
		close()
		resetFormValues()
	}

	return (
		<div>
			<Dialog fullWidth maxWidth="xl" open={open} onClose={cancel}>
				<DialogTitle>Edit Scout Page</DialogTitle>
				<DialogContent className="padding-bottom-24">
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<h2>{currentScoutInfo.team.name} Scout Page</h2>
						</Grid>
						<Grid item xs={12} className="padding-top-24">
							<TextField
								label="General Team Notes"
								multiline
								rows={10}
								variant="outlined"
								fullWidth
								value={formValues.generalNotes}
								onChange={(event) =>
									setFormValues({
										...formValues,
										generalNotes: event.target.value,
									})
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<h2>Videos</h2>
							{formValues.videoLinks.map((link, index) => (
								<Grid
									key={index}
									container
									spacing={0}
									className="padding-bottom-24">
									<Grid item xs={5}>
										<TextField
											label="Stream/Video Link"
											variant="outlined"
											style={{ width: "95%" }}
											value={link}
											onChange={(event) =>
												setFormValues({
													...formValues,
													videoLinks: formValues.videoLinks.map((l, i) => {
														if (i === index) {
															return event.target.value
														} else {
															return l
														}
													}),
												})
											}
											fullWidth
										/>
									</Grid>
									<Grid item xs={6} className="align-right">
										<TextField
											value={formValues.videoAccessNotes[index]}
											onChange={(event) =>
												setFormValues({
													...formValues,
													videoAccessNotes: formValues.videoAccessNotes.map(
														(p, i) => {
															if (i === index) {
																return event.target.value
															} else {
																return p
															}
														}
													),
												})
											}
											label="Notes"
											variant="outlined"
											style={{ width: "95%" }}
											fullWidth
										/>
									</Grid>
									<Grid item xs={1} className="align-right">
										<IconButton
											onClick={() => {
												setFormValues({
													...formValues,
													videoLinks: formValues.videoLinks.filter(
														(p, i) => i !== index
													),
													videoAccessNotes: formValues.videoAccessNotes.filter(
														(p, i) => i !== index
													),
												})
											}}>
											<DeleteOutline />
										</IconButton>
									</Grid>
								</Grid>
							))}
							<Grid container spacing={0} className="padding-top-12">
								<Grid item xs={12}>
									<Button
										disabled={
											currentScoutInfo.team.id === 0 ||
											currentScoutInfo.team.id === null
										}
										color="primary"
										onClick={() =>
											setFormValues({
												...formValues,
												videoLinks: [...formValues.videoLinks, ""],
												videoAccessNotes: [...formValues.videoAccessNotes, ""],
											})
										}>
										Add Video
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<h2>Hitters</h2>
							{formValues.hitters.map((hitter, index) => (
								<Grid
									key={index}
									container
									spacing={0}
									className="padding-bottom-24">
									<Grid item xs={5}>
										<FormControl variant="outlined" style={{ width: "95%" }}>
											<InputLabel>Select Hitter</InputLabel>
											<Select
												label="Select Hitter"
												variant="outlined"
												value={formValues.hitters[index]}
												onChange={(event) => {
													setFormValues({
														...formValues,
														hitters: formValues.hitters.map((p, i) => {
															if (i === index) {
																return event.target.value as number
															} else {
																return p
															}
														}),
													})
												}}>
												{currentScoutInfo.players.map((hitter) => (
													<MenuItem key={hitter.id} value={hitter.id}>
														#{hitter.number} {hitter.firstname}{" "}
														{hitter.lastname}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6} className="align-right">
										<TextField
											value={formValues.hitterNotes[index]}
											onChange={(event) =>
												setFormValues({
													...formValues,
													hitterNotes: formValues.hitterNotes.map((p, i) => {
														if (i === index) {
															return event.target.value
														} else {
															return p
														}
													}),
												})
											}
											label="Notes"
											variant="outlined"
											style={{ width: "95%" }}
											fullWidth
										/>
									</Grid>
									<Grid item xs={1} className="align-right">
										<IconButton
											onClick={() => {
												setFormValues({
													...formValues,
													hitters: formValues.hitters.filter(
														(p, i) => i !== index
													),
													hitterNotes: formValues.hitterNotes.filter(
														(p, i) => i !== index
													),
												})
											}}>
											<DeleteOutline />
										</IconButton>
									</Grid>
								</Grid>
							))}
							<Grid container spacing={0} className="padding-top-12">
								<Grid item xs={12}>
									<Button
										disabled={
											currentScoutInfo.team.id === 0 ||
											currentScoutInfo.team.id === null
										}
										color="primary"
										onClick={() =>
											setFormValues({
												...formValues,
												hitters: [...formValues.hitters, ""],
												hitterNotes: [...formValues.hitterNotes, ""],
											})
										}>
										Add Hitter
									</Button>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<h2>Pitchers</h2>
							{formValues.pitchers.map((pitcher, index) => (
								<Grid
									key={index}
									container
									spacing={0}
									className="padding-bottom-24">
									<Grid item xs={5}>
										<FormControl variant="outlined" style={{ width: "95%" }}>
											<InputLabel>Select Pitcher</InputLabel>
											<Select
												label="Select Pitcher"
												variant="outlined"
												value={formValues.pitchers[index]}
												onChange={(event) => {
													setFormValues({
														...formValues,
														pitchers: formValues.pitchers.map((p, i) => {
															if (i === index) {
																return event.target.value as number
															} else {
																return p
															}
														}),
													})
												}}>
												{currentScoutInfo.players.map((pitcher) => (
													<MenuItem key={pitcher.id} value={pitcher.id}>
														#{pitcher.number} {pitcher.firstname}{" "}
														{pitcher.lastname}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={6} className="align-right">
										<TextField
											value={formValues.pitcherNotes[index]}
											onChange={(event) =>
												setFormValues({
													...formValues,
													pitcherNotes: formValues.pitcherNotes.map((p, i) => {
														if (i === index) {
															return event.target.value
														} else {
															return p
														}
													}),
												})
											}
											label="Notes"
											variant="outlined"
											style={{ width: "95%" }}
											fullWidth
										/>
									</Grid>
									<Grid item xs={1} className="align-right">
										<IconButton
											onClick={() => {
												setFormValues({
													...formValues,
													pitchers: formValues.pitchers.filter(
														(p, i) => i !== index
													),
													pitcherNotes: formValues.pitcherNotes.filter(
														(p, i) => i !== index
													),
												})
											}}>
											<DeleteOutline />
										</IconButton>
									</Grid>
								</Grid>
							))}
							<Grid container spacing={0} className="padding-top-12">
								<Grid item xs={12}>
									<Button
										disabled={
											currentScoutInfo.team.id === 0 ||
											currentScoutInfo.team.id === null
										}
										color="primary"
										onClick={() =>
											setFormValues({
												...formValues,
												pitchers: [...formValues.pitchers, ""],
												pitcherNotes: [...formValues.pitcherNotes, ""],
											})
										}>
										Add Pitcher
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={cancel} color="primary">
						Cancel
					</Button>
					<ButtonApiRequest
						loading={loading}
						onClick={editScoutPage}
						color="primary"
						text="Create"
					/>
				</DialogActions>
			</Dialog>
		</div>
	)
}
