import { Container, Grid } from "@material-ui/core"
import axios from "axios"
import { useEffect, useState } from "react"
import { useGlobals } from "../../../hooks"
import { Video } from "../../../interfaces/VideoInterface"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import { VideoCard } from "../../../shared/VideoCard"

interface VideosTabProps {
	playerId: number
}

export const VideosTab = ({ playerId }: VideosTabProps) => {
	const { apiHeader } = useGlobals()
	const [videos, setVideos] = useState<Video[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		const fetchVideos = async () => {
			try {
				setLoading(true)
				const response = await axios.get(
					`/api/video?&player_id=${playerId}&processing=False`,
					apiHeader
				)
				setVideos(response.data)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchVideos()
	}, [])

	return (
		<Container className="padding-top-24 padding-bottom-24">
			{loading ? (
				<LoadingBig />
			) : (
				<Grid container spacing={2}>
					{videos.map((video) => (
						<Grid key={video.id} item xs={12} sm={6} md={4}>
							<VideoCard video={video} />
						</Grid>
					))}
				</Grid>
			)}
		</Container>
	)
}
