import React,
{
	useContext,
	useEffect,
	useState} from "react"
	import axios from "axios"

// components
import {
	Button,
	Container,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import { LoadingBig } from "../../Loading/LoadingBig"

// types and contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { AtBat } from "../../../interfaces/AtBatInterface"
import { Game } from "../../../interfaces/GameInterface"
import { Practice } from "../../../interfaces/PracticeInterface"
import { Player } from "../../../interfaces/PlayerInterface"
import { HomeAwayToggle } from "../../HomeAwayToggle"
import { PitcherCard } from "./PitcherCard"

interface Props {
	variant: "game" | "practice"
	id: number
}

export const ReportTab = ({ id, variant }: Props) => {
	const globals = useContext(GlobalsContext)

	const [FAB, setFAB] = useState<{[name: string]: AtBat[]}>({})
	const [atBats, setAtBats] = useState<AtBat[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [stats, setStats] = useState<{ [key: number]: any }>({})
	const [whichTeam, setWhichTeam] = useState<"home" | "away">("home")
	const [teamId, setTeamId] = useState<number>(0)

	const [game, setGame] = useState<Game>()
	const [practice, setPractice] = useState<Practice>()

	// Handle home/away toggle
	useEffect(() => {
		if (variant === "game" && game) {
			if (whichTeam === "home") {
				setTeamId(game.home_team.id)
			} else {
				setTeamId(game.away_team.id)
			}
		} else {
			setTeamId(globals.teamId!)
		}
	}, [whichTeam, game, practice])

	// Fetch atBats for competition
	useEffect(() => {
		const fetchAtBats = async () => {
			try {
				const response = await axios.get(
					`/api/${variant}/${id}/at_bats`,
					globals.apiHeader
				)
				setAtBats(response.data)
			} catch (e) {
				console.log(e)
			}
		}
		const fetchCompetition = async () => {
			try {
				const response = await axios.get(
					`/api/${variant}/${id}`,
					globals.apiHeader
				)
				if (variant === "game") {
					setGame(response.data)
				} else {
					setPractice(response.data)
				}
			} catch (e) {
				console.log(e)
			}
		}
		const fetchData = async () => {
			await fetchCompetition()
			await fetchAtBats()
			setLoading(false)
		}
		fetchData()
	}, [])

	// filter the atbats depending on home/away is pressed, or all if it is a practice
	useEffect(() => {
		if (atBats.length > 0 && variant === "game") {
			let fabs = atBats.filter((atBat: AtBat) => {
					if (whichTeam === "home") {
						return atBat.player.team_id === game!.home_team.id
					} else {
						return atBat.player.team_id === game!.away_team.id
					}
				})

			let outings: {[name: string]: AtBat[]} = {}
			for(let ab in fabs){
				let pitcher_name = `#${fabs[ab].pitcher!.number} ${fabs[ab].pitcher!.firstname} ${fabs[ab].pitcher!.lastname}`
				if(pitcher_name in outings){
					outings[pitcher_name].push(fabs[ab])
				} else{
					outings[pitcher_name] = [fabs[ab]]
				}
			}
			setFAB(outings)
		} else {
			setFAB({})
		}
	}, [atBats, whichTeam])

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container style={{ paddingTop: "24px" }} >
			<Grid container spacing={0}>
				{variant === "game" && (
					<Grid item xs={12}>
						<HomeAwayToggle
							value={whichTeam}
							onChange={(value: "home" | "away") => {
								if (value) {
									setWhichTeam(value)
								}
							}}
						/>
					</Grid>
				)}
				<Grid item xs={6}>
					<h2>
						{variant === "game" &&
							whichTeam === "home" &&
							`${game!.home_team.name} - `}
						{variant === "game" &&
							whichTeam === "away" &&
							`${game!.away_team.name} - `}
					</h2>
				</Grid>
				<Grid item xs={12}>
					{Object.keys(FAB).map((pitcher) => (
						<React.Fragment key={pitcher}>
							<PitcherCard atBats={FAB[pitcher]} name={pitcher}/>
							<br />
						</React.Fragment>
					))}
				</Grid>
			</Grid>
			<br />
			<br />
			<br />
			<br />
			<br />
		</Container>
	)
}