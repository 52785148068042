import React, { useEffect, useState } from "react"
import { AppBar, Slide } from "@material-ui/core"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

import { BigScreenNav } from "./components/BigScreenNav"
import { SmallScreenNav } from "./components/SmallScreenNav"

import "../../../static/css/NavigationBar.css"
import { useLocation } from "react-router-dom"

interface Props {
	admin: boolean
}

export const NavigationBar = ({ admin }: Props) => {
	const [page, setPage] = useState<any>(false)
	const location = useLocation()

	useEffect(() => {
		const path = window.location.pathname
		if (path.includes("/pitching") || path.includes("/pitcher")) {
			setPage(0)
		} else if (path.includes("/hitting") || path.includes("/hitter")) {
			setPage(1)
		} else if (
			path.includes("/competitions") ||
			path.includes("/practice") ||
			path.includes("/game")
		) {
			setPage(2)
		} else if (path.includes("/teams") || path.includes("/team")) {
			setPage(3)
		} else if (path.includes("/resources")) {
			setPage(4)
		} else if (path.includes("/scouting")) {
			setPage(5)
		} else {
			setPage(false)
		}
	}, [page])

	const handlePageChange = (event: React.ChangeEvent<{}>, newPage: number) => {
		setPage(newPage)
	}

	const logout = () => {
		localStorage.removeItem("token")
		window.location.href = "/"
	}

	if (
		location.pathname.includes("/outing") &&
		location.pathname.includes("/track")
	) {
		return <></>
	}

	return (
		<React.Fragment>
			<div className="app-bar-root">
				<AppBar position="static">
					<SmallScreenNav
						admin={admin}
						page={page}
						setPage={setPage}
						logout={logout}
					/>
					<BigScreenNav
						logout={logout}
						page={page}
						setPage={setPage}
						handlePageChange={handlePageChange}
						admin={admin}
					/>
				</AppBar>
			</div>
		</React.Fragment>
	)
}
