import { teamId } from "../../index"
import { Outing } from "../../interfaces/OutingInterface"
import { formatDate } from "./FormatDate"

/**
 *
 * @param outing Outing object to provide a name for
 * @returns a string either M/D/YY @ Practice or M/D/YY vs. [TeamName]
 */
export const formatOutingName = (outing: Outing) => {
	const date = formatDate(outing.date)
	if (outing.game) {
		if (outing.game.away_team.id === teamId) {
			return `${date} vs. ${outing.game.home_team.name}`
		} else {
			return `${date} vs. ${outing.game.away_team.name}`
		}
	} else if (outing.practice) {
		return `${date} @Practice`
	} else {
		return date
	}
}
