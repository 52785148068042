import React, { useContext } from "react"

import { Grid, IconButton, Paper } from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"

import { Context } from "../../TrackTab"

export const Inning = () => {
	const { state, setState } = useContext(Context)

	const handleInningChange = (type: "INCREASE" | "DECREASE") => {
		if (type === "INCREASE") {
			setState({
				...state,
				outingState: {
					...state.outingState,
					inning: state.outingState.inning + 1,
				},
			})
		} else {
			setState({
				...state,
				outingState: {
					...state.outingState,
					inning: state.outingState.inning - 1,
				},
			})
		}
	}

	return (
		<Paper elevation={0} className="pt-outing-state-component">
			<div className="pt-outing-state-name">INNING</div>
			<Grid container spacing={0}>
				<Grid item xs={4} className="pt-inning-decrease">
					<IconButton
						disabled={state.outingState.inning === 1}
						size="small"
						color="primary"
						onClick={() => handleInningChange("DECREASE")}>
						<KeyboardArrowDownIcon color="primary" />
					</IconButton>
				</Grid>
				<Grid item xs={4}>
					<div className="pt-outing-state-value">
						{state.outingState.inning}
					</div>
				</Grid>
				<Grid item xs={4} className="pt-inning-increase">
					<IconButton
						disabled={state.outingState.inning === 9}
						size="small"
						color="primary"
						onClick={() => handleInningChange("INCREASE")}>
						<KeyboardArrowUpIcon color="primary" />
					</IconButton>
				</Grid>
			</Grid>
		</Paper>
	)
}
