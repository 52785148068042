import { AtBat } from "../../interfaces/AtBatInterface"
import { Pitch } from "../../interfaces/PitchInterface"

/**
 *
 * @param atBats an array of AtBat objects which has an array of pitches
 * @returns a dictionary calculating the following statistics for the outing:
 * 				- pitches = the total number of pitches for the outing
 * 				- battersFaced = the total number of batters faced for the outing
 * 				- hits = the total number of 1B, 2B, 3B, HR given up in the outing
 * 				- walks = total number of BB given up
 * 				- hitByPitches = total number of HBP given up
 * 				- strikePct = number of strikes / number of pitches rounded
 * 				- firstPitchStrikePct = number of strikes on first pitch of ab / number of at bats
 * 				- hitSpotPct = number of hit spots / number of pitches
 * 				- velocity = average velocity of FB and 2S pitch types
 */
export const calcOutingStats = (atBats: AtBat[]) => {
	let pitches = 0
	let hits = 0
	let strikeouts = 0
	let walks = 0
	let hitByPitches = 0
	let strikes = 0
	let firstPitchStrikes = 0
	let veloTotal = 0
	let pitchesWithVelo = 0
	let hitSpots = 0
	atBats.forEach((atBat: AtBat) => {
		pitches += atBat.pitches!.length
		if (atBat.result) {
			if (["1B", "2B", "3B", "HR"].includes(atBat.result)) {
				hits += 1
			} else if (
				["K", "ꓘ", "Drop Third (Out)", "Drop Third (Safe)"].includes(
					atBat.result
				)
			) {
				strikeouts += 1
			} else if (atBat.result === "BB") {
				walks += 1
			} else if (atBat.result === "HBP") {
				hitByPitches += 1
			}
		}
		if (atBat.pitches) {
			atBat.pitches.forEach((pitch: Pitch, index: number) => {
				if (pitch.pitch_result !== "B") {
					strikes += 1
				}
				if (pitch.ab_pitch_num === 1 && pitch.pitch_result !== "B") {
					firstPitchStrikes += 1
				}
				if (pitch.velocity && ["FB", "2S"].includes(pitch.pitch_type)) {
					veloTotal += pitch.velocity
					pitchesWithVelo += 1
				}
				if (pitch.hit_spot) hitSpots += 1
			})
		}
	})
	return {
		pitches,
		battersFaced: atBats.length,
		hits,
		strikeouts,
		walks,
		hitByPitches,
		strikePct: pitches > 0 ? Math.round((100 * strikes) / pitches) : "N/A",
		firstPitchStrikePct:
			atBats.length > 0
				? Math.round((100 * firstPitchStrikes) / atBats.length)
				: "N/A",
		hitSpotPct: pitches > 0 ? Math.round((100 * hitSpots) / pitches) : "N/A",
		velocity:
			pitchesWithVelo > 0 ? (veloTotal / pitchesWithVelo).toFixed(1) : "N/A",
	}
}
