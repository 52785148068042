import React, { ReactNode, useContext, useState } from "react"

import { Paper, Container } from "@material-ui/core"

import { Subnav } from "./Subnav"
import { TabPanel } from "./TabPanel"
import { GlobalsContext } from "../../../context/GlobalsContext"

interface Tab {
	name: string
	component: ReactNode
	admin?: boolean
}

interface Props {
	title: string
	tabs: Array<Tab>
}

export const SubnavTemplate = ({ title, tabs }: Props) => {
	const [tab, setTab] = useState<number>(0)
	const globals = useContext(GlobalsContext)

	tabs = tabs.filter((tab) => !tab.admin || (tab.admin && globals.admin))

	return (
		<div>
			<Paper
				style={{
					padding: "5px 0px",
					fontWeight: "bold",
				}}>
				<Container>
					<h1>{title}</h1>
				</Container>
			</Paper>
			<Subnav
				currentTab={tab}
				tabChangeHandler={(event: React.ChangeEvent<{}>, value: number) => {
					setTab(value)
				}}
				tabs={tabs.map((tab) => tab.name)}
			/>
			{tabs.map((t, i) => (
				<TabPanel key={i} index={i} value={tab}>
					{t.component}
				</TabPanel>
			))}
		</div>
	)
}
