import { useEffect, useRef } from "react"

import * as d3 from "d3"

import BaseballIcon from "../../../../../static/img/BaseballIcon.png"

interface Props {
	formState: { [key: string]: any }
	setFormState: React.Dispatch<
		React.SetStateAction<{
			[key: string]: any
		}>
	>
}

export const FieldD3 = ({ formState, setFormState }: Props) => {
	const svgRef = useRef(null)

	const xScale = d3.scaleLinear().range([350, 0]).domain([250, -250])
	const yScale = d3.scaleLinear().range([350, 0]).domain([-100, 400])

	// runs the first time and just draws the field
	useEffect(() => {
		const svg = d3.select(svgRef.current)
		svg.selectAll("path").remove()

		// some colors for the makeshift field
		const dirtColor = "#99552B"
		const grassColor = "#508632"

		const fieldOutlinePath = `
      M ${xScale(0)},${yScale(-45)}
        L ${xScale(50)},${yScale(-25)}
        L ${xScale(242)},${yScale(222)}
        L ${xScale(145)},${yScale(334)}
        L ${xScale(68)},${yScale(375)}
        L ${xScale(0)},${yScale(395)}
        L ${xScale(-68)},${yScale(375)}
        L ${xScale(-145)},${yScale(334)}
        L ${xScale(-242)},${yScale(222)}
        L ${xScale(-50)},${yScale(-25)}
        L ${xScale(0)},${yScale(-45)} Z`
		const fieldInnerOutlinePath = `
      M ${xScale(0)},${yScale(-40)}
        L ${xScale(50)},${yScale(-20)}
        L ${xScale(237)},${yScale(222)}
        L ${xScale(145)},${yScale(329)}
        L ${xScale(68)},${yScale(370)}
        L ${xScale(0)},${yScale(390)}
        L ${xScale(-68)},${yScale(370)}
        L ${xScale(-145)},${yScale(329)}
        L ${xScale(-237)},${yScale(222)}
        L ${xScale(-50)},${yScale(-20)}
        L ${xScale(0)},${yScale(-40)} Z`
		const infieldDirt = `
      M ${xScale(85)},${yScale(80)}
        L ${xScale(0)},${yScale(170)}
        L ${xScale(-85)},${yScale(80)}
        L ${xScale(0)},${yScale(-5)}
        L ${xScale(85)},${yScale(80)}
        C ${xScale(85)},${yScale(200)}
        ${xScale(-85)},${yScale(200)}
        ${xScale(-85)},${yScale(80)}
        L ${xScale(0)},${yScale(5)} Z`
		const infieldGrass = `
      M ${xScale(0)},${yScale(5)}
        L ${xScale(60)},${yScale(60)}
        L ${xScale(0)},${yScale(120)}
        L ${xScale(-60)},${yScale(60)} Z`
		const foulLines = `
      M ${xScale(-239)},${yScale(239)}
        L ${xScale(0)},${yScale(0)}
        L ${xScale(239)},${yScale(239)}
        L ${xScale(0)},${yScale(0)} Z`

		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", fieldOutlinePath)
			.style("stroke", "black")
			.style("fill", dirtColor)
			.style("stroke-width", 1)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", fieldInnerOutlinePath)
			.style("fill", grassColor)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", infieldDirt)
			.style("fill", dirtColor)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", infieldGrass)
			.style("fill", grassColor)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", foulLines)
			.style("stroke", "white")
			.style("stroke-width", 2)
	}, [])

	useEffect(() => {
		const svg = d3.select(svgRef.current)

		// if there is a spray location, draw that spray location
		if (formState.xSpray && formState.ySpray) {
			svg.select(".pt-field-spray-loc").remove()
			svg
				.append("image")
				.attr("class", "pt-field-spray-loc")
				.attr("x", xScale(formState.xSpray) - 10)
				.attr("y", yScale(formState.ySpray) - 10)
				.attr("width", "20")
				.attr("height", "20")
				.attr("xlink:href", BaseballIcon)
		}
	}, [formState.xSpray, formState.ySpray])

	return (
		<svg
			className="pt-field-svg"
			width={350}
			height={350}
			ref={svgRef}
			onClick={(event) => {
				// update spray location
				const [x, y] = d3.pointer(event)
				const xSpray = Math.round(xScale.invert(x) * 100) / 100
				const ySpray = Math.round(yScale.invert(y) * 100) / 100
				setFormState({ ...formState, xSpray, ySpray })
			}}></svg>
	)
}
