import React, { useContext } from "react"

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core"

import { TransitionUp } from "../../../../../../shared/ModalTransitions/TransitionUp"

import { Context } from "../../TrackTab"

import { EndAtBatHandler } from "../../utils/EndAtBatHandler"

/**
 * Let the user decide whether they want to include the last pitch of the at bat
 * 	or not when they want to end the at bat early
 */
export const IncludeLastPitchModal = () => {
	const { state, setState } = useContext(Context)

	const handleClose = () => {
		setState({ ...state, showIncludeLastPitchModal: false })
	}

	return (
		<div>
			<Dialog
				TransitionComponent={TransitionUp}
				open={state.showIncludeLastPitchModal}
				onClose={handleClose}>
				<DialogTitle>Include Pitch Data Entered?</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Do you want to include the pitch entered before proceeding with
						ending the at bat early?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Go Back
					</Button>
					<Button
						onClick={() => EndAtBatHandler(state, setState, false)}
						color="primary">
						No
					</Button>
					<Button
						onClick={() => EndAtBatHandler(state, setState, true)}
						color="primary"
						autoFocus>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
