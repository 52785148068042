import axios from "axios"
import { AtBat } from "../../../../../interfaces/AtBatInterface"
import { Outing } from "../../../../../interfaces/OutingInterface"
import { initialState, State } from "./Constants"
import { dispatchError } from "./DispatchFeedback"
import { predictNextBaserunners } from "./PredictNextBaserunners"

export const updateOutingState = async (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	apiHeader: any,
	newPitchEntry?: State["pitchEntry"],
	newSelectedBatter?: State["selectedBatter"]
) => {
	try {
		// gather the updating outing information
		const outingResponse = await axios.get(`/api/outing/${state.outing!.id}`, {
			...apiHeader,
			timeout: 5000,
		})
		const outing: Outing = outingResponse.data

		// declare variables of outing state that will be changed
		let inning = 1
		let balls = 0
		let strikes = 0
		if (outing.setting_counts_1_1) {
			balls = 1
			strikes = 1
		}
		let outs = 0
		let runnerFirst = false
		let runnerSecond = false
		let runnerThird = false
		let selectedBatter: number | string = ""
		let currentAtBat: AtBat | null = null
		let newInning = false

		// iterate through each of the at bats to infer the current state
		outing.at_bats.forEach((atbat) => {
			if (outing.setting_infer_outing_state) {
				// take the last atbats inning and outs
				if (atbat.inning) {
					inning = atbat.inning
				}
				if (atbat.outs === 0 || atbat.outs != null) {
					outs = atbat.outs
				}

				// try to infer the next number of outs and inning
				// 	based on previous at bat
				if (
					(outs === 2 && atbat.batter_out) ||
					(outs === 2 && atbat.result === "FC") ||
					(outs === 1 && atbat.result === "Double Play") ||
					(outs === 0 && atbat.result === "Triple Play")
				) {
					outs = 0
					inning += 1
					newInning = true
				} else if (atbat.batter_out || atbat.result === "FC") {
					outs += 1
					newInning = false
				} else {
					newInning = false
				}
			}

			// infer current batter state
			const lastPitch = atbat.pitches![atbat.pitches!.length - 1]
			balls = 0
			strikes = 0
			if (outing.setting_counts_1_1) {
				balls = 1
				strikes = 1
			}

			console.log(atbat.result)

			// last at bat hasn't finished but at least one pitch has been thrown
			if (!atbat.result && lastPitch) {
				// get balls and strikes from the last pitch in the at bat
				balls = lastPitch.balls!
				strikes = lastPitch.strikes!

				// get the next count based on the previous
				if (lastPitch.pitch_result === "B" && balls < 3) {
					balls += 1
				} else if (strikes < 2) {
					strikes += 1
				}

				// set the current at bat
				selectedBatter = atbat.batter
				currentAtBat = atbat

				if (outing.setting_infer_outing_state) {
					// update the inning and outs to be up to date
					// 	with the current at bat and outs
					if (currentAtBat.outs) {
						outs = currentAtBat.outs
					}
					if (currentAtBat.inning) {
						inning = currentAtBat.inning
					}
				}

				runnerFirst = lastPitch.runner_1!
				runnerSecond = lastPitch.runner_2!
				runnerThird = lastPitch.runner_3!

				// if the at bat has been set but no pitch has been thrown yet
			} else if (!atbat.result && !lastPitch) {
				selectedBatter = atbat.batter
				currentAtBat = atbat

				// the last at bat of the outing has a result to it
			} else if (atbat.result) {
				currentAtBat = null
				selectedBatter = ""

				const nextBaserunners = predictNextBaserunners(
					lastPitch.runner_1!,
					lastPitch.runner_2!,
					lastPitch.runner_3!,
					atbat.result,
					atbat.outs as 0 | 1 | 2,
					atbat.traj as "GB" | "FB" | "LD"
				)
				runnerFirst = nextBaserunners[0]
				runnerSecond = nextBaserunners[1]
				runnerThird = nextBaserunners[2]
			} else {
				runnerFirst = lastPitch.runner_1!
				runnerSecond = lastPitch.runner_2!
				runnerThird = lastPitch.runner_3!
			}

			if (newInning) {
				runnerFirst = false
				runnerSecond = false
				runnerThird = false
			}
		})

		// update the outing state from the inference above
		setState({
			...state,
			outing,
			selectedBatter:
				selectedBatter === "" && newSelectedBatter
					? newSelectedBatter
					: selectedBatter,
			currentAtBat,
			showFieldModal: false,
			showAtBatResultModal: false,
			pitchEntry: newPitchEntry
				? newPitchEntry
				: {
						...initialState.pitchEntry,
						runnerFirst,
						runnerSecond,
						runnerThird,
				  },
			atBatEntry: initialState.atBatEntry,
			outingState: {
				inning,
				balls,
				strikes,
				outs,
			},
		})
	} catch (e) {
		dispatchError(
			state,
			setState,
			"Something wen't wrong. Refreshing the page."
		)
		console.log(e)
	}
}
