import { useContext } from "react"

import axios from "axios"

// components
import { TableCell, TableRow, IconButton, Button } from "@material-ui/core"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import FavoriteIcon from "@material-ui/icons/Favorite"

// types and contexts
import { Team } from "../../../interfaces/TeamInterface"
import { GlobalsContext } from "../../../context/GlobalsContext"

interface Props {
	team: Team
	changeFavorite: (id: number, favorite: boolean) => void
}

export const TeamRow = ({ team, changeFavorite }: Props) => {
	const globals = useContext(GlobalsContext)

	const favorite = async () => {
		if (!globals.admin) return
		try {
			await axios.patch(
				`/api/team/${team.id}/favorite`,
				null,
				globals.apiHeader
			)
			changeFavorite(team.id, true)
		} catch (e) {
			console.log(e)
		}
	}

	const unFavorite = async () => {
		if (!globals.admin) return
		try {
			await axios.patch(
				`/api/team/${team.id}/unfavorite`,
				null,
				globals.apiHeader
			)
			changeFavorite(team.id, false)
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<TableRow>
			<TableCell>
				<IconButton
					onClick={team.favorite ? () => unFavorite() : () => favorite()}>
					{team.favorite ? (
						<FavoriteIcon color="primary" />
					) : (
						<FavoriteBorderIcon />
					)}
				</IconButton>
			</TableCell>
			<TableCell>
				<Button variant="outlined" color="primary" href={`/team/${team.id}`}>
					{team.name}
				</Button>
			</TableCell>
		</TableRow>
	)
}
