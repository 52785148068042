import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { GlobalsContext } from "../../../context/GlobalsContext"

// Interfaces
import { AtBat } from "../../../interfaces/AtBatInterface"
import { Player } from "../../../interfaces/PlayerInterface"
import { Pitch } from "../../../interfaces/PitchInterface"

// Components
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import { GamesPracticesToggle } from "../../../shared/GamesPracticesToggle"
import { SubnavTemplate } from "../../../shared/MainLayout/SubnavTemplate/SubnavTemplate"
import {
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core"
import { ThreeSixtyTwoTone } from "@material-ui/icons"
import { FirstPitchCard } from "./FirstPitchCard"
import { SecondPitchCard } from "./SecondPitchCard"

// Util functions
import { AtBat_processor } from "../../../shared/Classes/HitterAtBatProcessor"
import { formatDate } from "../../../shared/Functions/FormatDate"
import { OutPitchCard } from "./OutPitchCard"
import { HitPitchCard } from "./HitPitchCard"
import { ComebackPitchCard } from "./ComebackPitchCard"


interface Props {
	playerId: number
}

export const HitterGameReport = ({ playerId }: Props) => {
	const globals = useContext(GlobalsContext)
	const [loading, setLoading] = useState<boolean>(true)
	const xs_size = 6
	const md_size = 4

	const [compType, setCompType] = useState<"games" | "practices">("games")
	const [selectedComp, setSelectedComp] = useState<number | "">("")
	const [competitions, setCompetitions] = useState<{ [key: string]: any }[]>([])
	const [atBats, setAtBats] = useState<AtBat[]>([])

	const [fpFbPct, setFpFbPct] = useState<number>(0)
	const [fpOsPct, setFpOsPct] = useState<number>(0)
	const [spFbPct, setSpFbPct] = useState<number>(0)
	const [spOsPct, setSpOsPct] = useState<number>(0)
	const [fpFbStrSpFbPct, setFpFbStrSpFbPct] = useState<number>(0)
	const [fpFbBalSpFbPct, setFpFbBalSpFbPct] = useState<number>(0)
	const [fpOsStrSpFbPct, setFpOsStrSpFbPct] = useState<number>(0)
	const [fpOsBalSpFbPct, setFpOsBalSpFbPct] = useState<number>(0)
	const [opFbPct, setOpFbPct] = useState<number>(0)
	const [opOsPct, setOpOsPct] = useState<number>(0)
	const [hpFbPct, setHpFbPct] = useState<number>(0)
	const [hpOsPct, setHpOsPct] = useState<number>(0)

	var processor = new AtBat_processor(0)

	useEffect(() => {
		const fetchCompetitions = async () => {
			setLoading(true)
			try {
				const response = await axios.get(
					`/api/season/${globals.currentSeason}/${compType}`,
					globals.apiHeader
				)
				setCompetitions(response.data)
				setSelectedComp(response.data[response.data.length - 1].id)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchCompetitions()
	}, [compType])

	useEffect(() => {
		const fetchAtBatsForComp = async () => {
			setLoading(true)

			// if user selected a blank competition
			if (selectedComp === "") {
				return setLoading(false)
			}

			// either practice_id or game_id
			let url = `/api/atbat?player_id=${playerId}&practice_id=${selectedComp}`
			if (compType === "games") {
				url = `/api/atbat?player_id=${playerId}&game_id=${selectedComp}`
			}

			// get the at bats for the filter
			try {
				const response = await axios.get(url, globals.apiHeader)
				setAtBats(response.data)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchAtBatsForComp()
	}, [selectedComp])

	useEffect(() => {
		processor.process_AtBats(atBats)
		processor.do_calculations()

		setFpFbPct(processor.first_pitch_fb_pct)
		setFpOsPct(processor.first_pitch_offspeed_pct)
		setSpFbPct(processor.second_pitch_fb_pct)
		setSpOsPct(processor.second_pitch_offspeed_pct)
		setFpFbStrSpFbPct(processor.fp_fb_str_sp_fb_pct)
		setFpFbBalSpFbPct(processor.fp_fb_bal_sp_fb_pct)
		setFpOsStrSpFbPct(processor.fp_os_str_sp_fb_pct)
		setFpOsBalSpFbPct(processor.fp_os_bal_sp_fb_pct)
		setOpFbPct(processor.out_pitch_fb_pct)
		setOpOsPct(processor.out_pitch_os_pct)
		setHpFbPct(processor.hit_pitch_fb_pct)
		setHpOsPct(processor.hit_pitch_os_pct)
		
	}, [atBats])

	return (
		<Container className="padding-top-24 padding-bottom-24">
			<Grid container spacing={0}>
				<Grid container item xs={12} spacing={0} >
					<Grid item xs={6}>
							<GamesPracticesToggle
								value={compType}
								onChange={(value: "games" | "practices") => {
									if (value) {
										setCompType(value)
									}
								}}
							/>
					</Grid>
					<Grid item xs={6} className="align-right">
						<FormControl
							style={{
								backgroundColor: "white",
								minWidth: "150px",
								textAlign: "left",
							}}
							variant="outlined">
							<InputLabel>
								{compType === "games" ? "Game" : "Practice"}
							</InputLabel>
							<Select
								disabled={loading}
								label={compType === "games" ? "Game" : "Practice"}
								value={selectedComp}
								onChange={(event) =>
									setSelectedComp(event.target.value as number)
								}>
								{competitions.map((comp: { [key: string]: any }) => (
									<MenuItem key={comp.id} value={comp.id}>
										{!comp.home_team ? (
											formatDate(comp.date as string)
										) : (
											<>
												{comp.home_team.id === globals.teamId
													? `${formatDate(comp.date)} vs. ${comp.away_team.name}`
													: `${formatDate(comp.date)} @ ${comp.home_team.name}`}
											</>
										)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={2} >
					<Grid item xs={xs_size} md={md_size}>
						<FirstPitchCard fb_pct={fpFbPct} os_pct={fpOsPct}/>
					</Grid>
					<Grid item xs={xs_size} md={md_size}>
						<SecondPitchCard fb_pct={spFbPct} os_pct={spOsPct}/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={4}>
						<ComebackPitchCard
								fpFbStr={`${fpFbStrSpFbPct}% Fastballs`}
								fpFbBal={`${fpFbBalSpFbPct}% Fastballs`}
								fpOsStr={`${fpOsStrSpFbPct}% Fastballs`}
								fpOsBal={`${fpOsBalSpFbPct}% Fastballs`} />
					</Grid>
					<Grid item xs={xs_size} md={md_size}>
						<OutPitchCard fb_pct={opFbPct} os_pct={opOsPct} />
					</Grid>
					<Grid item xs={xs_size} md={md_size} >
						<HitPitchCard fb_pct={hpFbPct} os_pct={hpOsPct} />
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}

