import { useMediaQuery } from "@material-ui/core"
import * as d3 from "d3"
import { useEffect, useRef } from "react"
import { Pitch } from "../../../interfaces/PitchInterface"
import { GenerateTooltipHTML } from "../../../shared/Functions/GenerateTooltipHTML"
import { PitchAdjusted } from "./LocationsTab"

interface Props {
	pitches: PitchAdjusted[]
	id?: string | number
}

export const LocationsD3 = ({ pitches, id }: Props) => {
	const svgRef = useRef(null)
	const tooltipRef = useRef(null)

	const smallScreen = useMediaQuery("(max-width: 600px)")

	const width = smallScreen ? 300 : 500
	const height = smallScreen ? 300 : 500

	let xScale = d3.scaleLinear().range([width, 0]).domain([2, -2])
	let yScale = d3.scaleLinear().range([height, 0]).domain([0, 4])

	const pitchColors: { [key: string]: string } = {
		FB: "#E70B18", // red
		CB: "#2326FA", // blue
		SL: "#118A14", // green
		CH: "#C8C72A", // yellow
		CT: "#21C8C7", // baby blue
		"2S": "#FD8024", // orange
		SPL: "#653896", // purple
		OTH: "#000000", // black
	}

	useEffect(() => {
		const svg = d3.select(svgRef.current)
		svg.selectAll("*").remove()

		// strikezone
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-0.833)},${yScale(1.75)}
					L ${xScale(-0.833)},${yScale(3.4)}
					L ${xScale(0.833)},${yScale(3.4)}
					L ${xScale(0.833)},${yScale(1.75)}
					L ${xScale(-0.833)},${yScale(1.75)}`
			)
			.style("stroke", "black")
			.style("fill", "white")
			.style("stroke-width", 1)

		// homeplate
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(0)},${yScale(0.2)}
				L ${xScale(-0.863)},${yScale(0.4)}
				L ${xScale(-0.833)},${yScale(0.7)}
				L ${xScale(0.83)},${yScale(0.7)}
				L ${xScale(0.863)},${yScale(0.4)}
				L ${xScale(0)},${yScale(0.2)}`
			)
			.style("stroke", "black")
			.style("fill", "white")
			.style("stroke-width", 1)
	}, [width, height])

	useEffect(() => {
		const svg = d3.select(svgRef.current)
		svg.selectAll("circle").remove()
		pitches.forEach((pitch) => {
			if (pitch.loc_x && pitch.loc_y) {
				svg
					.append("circle")
					.attr("class", "outing-locations-pitch-circle")
					.attr("cx", xScale(pitch.loc_x))
					.attr("cy", yScale(pitch.loc_y))
					.attr("r", 7)
					.style("fill", pitchColors[pitch.pitch_type])
					.on("mouseover", function (event) {
						// generate a tooltip on hover
						const data = pitch
						const coordinates = d3.pointer(
							event,
							document.querySelector("body")
						)
						d3.select(tooltipRef.current)
							.style("display", "block")
							.style("position", "absolute")
							.style("left", `${coordinates[0] + 10}px`)
							.style("top", `${coordinates[1] + 10}px`)
							.html(
								GenerateTooltipHTML([
									{
										label: "Num",
										value: data.pitchNum,
									},
									{
										label: "Inning",
										value: data.inning,
									},
									{
										label: "Batter",
										value: data.batterName,
									},
									{
										label: "Type",
										value: data.pitch_type,
									},
									{
										label: "Velo",
										value: data.velocity!,
									},
									{
										label: "Result",
										value: data.pitch_result,
									},
									{
										label: "Count",
										value: `${data.balls}-${data.strikes}`,
									},
									{
										label: "Spot",
										value: data.hit_spot ? "Yes" : "No",
									},
									{
										label: "Notes",
										value: data.notes ? data.notes : "",
									},
								])
							)
					})
					.on("mouseout", function (event) {
						// hide the tooltip when the mouse leaves the circle
						d3.select(tooltipRef.current).style("display", "none")
					})
			}
		})
	}, [pitches, width, height])

	return (
		<>
			<svg
				id={id ? (id as string) : "locations-chart"}
				style={{ fill: "white" }}
				width={width}
				height={height}
				ref={svgRef}></svg>
			<div ref={tooltipRef} />
		</>
	)
}
