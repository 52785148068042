import { ChangeEvent, Fragment, useContext, useState } from "react"

import axios from "axios"

// components
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core"
import { ButtonApiRequest } from "../../shared/ButtonApiRequest"
import { TransitionUp } from "../../shared/ModalTransitions/TransitionUp"

// context
import { GlobalsContext } from "../../context/GlobalsContext"
import { PitchingPageContext } from "./PitchingPage"
import { FeedbackContext } from "../../context/FeedbackContext"

interface NewPitcherModalProps {
	open: boolean
	handleClose: () => void
}

export const NewPitcherModal = ({
	open,
	handleClose,
}: NewPitcherModalProps) => {
	const { apiHeader, teamId } = useContext(GlobalsContext)
	const { refetchPlayers, players } = useContext(PitchingPageContext)
	const { alertSuccess, alertError } = useContext(FeedbackContext)
	const activeHitters = players.filter(
		(player) => player.active && player.batter && !player.pitcher
	)
	const [hitterSelected, setHitterSelected] = useState<number | string>(
		activeHitters.length > 0 ? activeHitters[0].id : ""
	)
	const [loading, setLoading] = useState<boolean>(false)
	const [chooseFromHitters, setChooseFromHitters] = useState<"Yes" | "No">("No")
	const [formValues, setFormValues] = useState<{ [key: string]: any }>({
		firstname: "",
		lastname: "",
		number: "",
		bats: "",
		throws: "",
		year: "",
		position: "",
		pitcher: true,
		batter: false,
		team_id: teamId,
		active: true,
	})

	const clearForm = () => {
		setChooseFromHitters("No")
		setHitterSelected("")
		setFormValues({
			firstname: "",
			lastname: "",
			number: "",
			bats: "",
			throws: "",
			year: "",
			position: "",
			pitcher: true,
			batter: false,
			team_id: teamId,
			active: true,
		})
	}

	const formChange = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const newValues = { ...formValues }
		newValues[event.target.name] = event.target.value
		setFormValues(newValues)
	}

	const addPitcher = async () => {
		setLoading(true)
		try {
			if (chooseFromHitters === "Yes") {
				await axios.patch(
					`/api/player/${hitterSelected}`,
					{ pitcher: true },
					apiHeader
				)
				alertSuccess("Hitter now marked as a pitcher!")
			} else {
				await axios.post("/api/player", formValues, apiHeader)
				alertSuccess("Pitcher created!")
			}
			handleClose()
			clearForm()
			refetchPlayers()
		} catch (e) {
			alertError(
				"Something wen't wrong. Please refresh the page and try again!"
			)
			console.log(e)
		}
		setLoading(false)
	}

	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			open={open}
			onClose={handleClose}
			TransitionComponent={TransitionUp}>
			<DialogTitle>New Pitcher</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormControl fullWidth>
							<InputLabel>Choose from Existing Hitters</InputLabel>
							<Select
								fullWidth
								label="Choose from Existing Hitters"
								value={chooseFromHitters}
								onChange={(event) => {
									setChooseFromHitters(event.target.value as "No" | "Yes")
								}}>
								<MenuItem value={"No"}>No</MenuItem>
								<MenuItem value={"Yes"}>Yes</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{chooseFromHitters === "Yes" && (
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel>Active Hitters</InputLabel>
								<Select
									fullWidth
									label="Active Hitters"
									value={hitterSelected}
									onChange={(event) =>
										setHitterSelected(event.target.value as number)
									}>
									{activeHitters.map((player) => (
										<MenuItem value={player.id} key={player.id}>
											{player.firstname} {player.lastname}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}
					{chooseFromHitters === "No" && (
						<Fragment>
							<Grid item xs={12}>
								<TextField
									value={formValues.firstname}
									onChange={formChange}
									name="firstname"
									label="Firstname"
									type="text"
									required
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.lastname}
									onChange={formChange}
									name="lastname"
									label="Lastname"
									type="text"
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.number}
									onChange={formChange}
									name="number"
									label="Number"
									type="text"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.bats}
									onChange={formChange}
									name="bats"
									label="Bats"
									type="text"
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.throws}
									onChange={formChange}
									name="throws"
									label="Throws"
									type="text"
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.year}
									onChange={formChange}
									name="year"
									label="Year"
									type="text"
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={formValues.position}
									onChange={formChange}
									name="position"
									label="Position"
									type="text"
									fullWidth
								/>
							</Grid>
						</Fragment>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
				<ButtonApiRequest
					disabled={
						(chooseFromHitters === "No" &&
							(!formValues.firstname ||
								!formValues.lastname ||
								!formValues.bats ||
								!formValues.throws ||
								!formValues.year)) ||
						(chooseFromHitters === "Yes" && hitterSelected === "")
					}
					loading={loading}
					onClick={addPitcher}
					color="primary"
					text={chooseFromHitters === "Yes" ? "Mark as Pitcher Too" : "Create"}
				/>
			</DialogActions>
		</Dialog>
	)
}
