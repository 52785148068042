import {
	Container,
	Grid,
	Hidden,
	IconButton,
	Menu,
	MenuItem,
	Paper,
	Tooltip,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import SortIcon from "@material-ui/icons/Sort"
import { MouseEvent, useState } from "react"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import { PlayerSelector } from "../../../shared/PlayerSelector"
import { SortOrder } from "../../../shared/Table/utils"
import { EditVideoModal } from "./EditVideoModal"
import { NewVideoModal } from "./NewVideoModal"
import { VideoTable } from "./VideoTable/VideoTable"
import { useVideosTabContext } from "./VideoTabState"

type SortByOption = {
	orderBy: string
	order: SortOrder
	label: string
}

const sortByOptions: SortByOption[] = [
	{
		orderBy: "date",
		order: "desc",
		label: "Date: New to Old",
	},
	{
		orderBy: "date",
		order: "asc",
		label: "Date: Old to New",
	},
	{
		orderBy: "playerName",
		order: "asc",
		label: "Player Name",
	},
	{
		orderBy: "title",
		order: "asc",
		label: "Title",
	},
]

export const VideosTab = () => {
	const {
		openNewVideoModal,
		fetchingVideos,
		orderBy,
		order,
		handleSortChange,
		editVideoModal,
		selectedPlayer,
		selectedPlayerChanged,
	} = useVideosTabContext()

	const [orderByAnchorEl, setOrderByAnchorEl] = useState<HTMLElement>()

	return (
		<Container className="tab-container" maxWidth="lg">
			{fetchingVideos ? (
				<LoadingBig />
			) : (
				<Grid
					spacing={1}
					container
					component={Paper}
					style={{ padding: "0px 24px" }}
					alignItems="center"
					justify="space-between">
					<Grid item xs={8} sm={4} md={6}>
						<h2>Videos</h2>
					</Grid>
					<Hidden xsDown>
						<Grid item sm={6} md={5}>
							<PlayerSelector
								initialValue={selectedPlayer}
								variant="outlined"
								handleChange={(selected: number) => {
									selectedPlayerChanged(selected)
								}}
								margin="dense"
							/>
						</Grid>
					</Hidden>
					<Hidden mdUp>
						<Grid item xs={2} sm={1} className="align-right">
							<Tooltip title="Sort By">
								<IconButton
									onClick={(event: MouseEvent<HTMLElement>) => {
										setOrderByAnchorEl(event.currentTarget)
									}}>
									<SortIcon />
								</IconButton>
							</Tooltip>
							<Menu
								anchorEl={orderByAnchorEl}
								keepMounted
								open={Boolean(orderByAnchorEl)}
								onClose={() => setOrderByAnchorEl(undefined)}>
								{sortByOptions.map((option) => {
									const selected =
										option.orderBy === orderBy && option.order === order
									const handleClick = () => {
										handleSortChange(option.order, option.orderBy)
										setOrderByAnchorEl(undefined)
									}
									return (
										<MenuItem
											key={option.label}
											selected={selected}
											onClick={handleClick}>
											{option.label}
										</MenuItem>
									)
								})}
							</Menu>
						</Grid>
					</Hidden>
					<Grid item xs={2} sm={1} className="align-right">
						<Tooltip title="New Video">
							<IconButton onClick={openNewVideoModal}>
								<AddIcon />
							</IconButton>
						</Tooltip>
					</Grid>
					<Hidden smUp>
						<Grid item xs={12}>
							<PlayerSelector
								initialValue={selectedPlayer}
								variant="outlined"
								handleChange={(selected: number) => {
									selectedPlayerChanged(selected)
								}}
								margin="dense"
							/>
						</Grid>
					</Hidden>
					<Grid item xs={12} className="align-center">
						<VideoTable />
						<NewVideoModal />
						{editVideoModal && <EditVideoModal />}
					</Grid>
				</Grid>
			)}
		</Container>
	)
}
