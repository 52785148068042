import {
	CircularProgress,
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow,
	Tooltip,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { useState } from "react"
import { SimplifiedVideo } from ".."
import { useAlert, useGlobals } from "../../../../hooks"
import { formatDate } from "../../../../shared/Functions/FormatDate"
import { useVideosTabContext } from "../VideoTabState"

interface VideoRowProps {
	video: SimplifiedVideo
}

export const VideoRow = ({ video }: VideoRowProps) => {
	const { admin } = useGlobals()
	const { alertWarning } = useAlert()
	const { handleEdit, handleDelete } = useVideosTabContext()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const preventDefault = (event: any) => {
		event.preventDefault()
		event.stopPropagation()
	}

	const handleMoreIconClick = (event: React.MouseEvent<HTMLElement>) => {
		preventDefault(event)
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (event: any) => {
		preventDefault(event)
		setAnchorEl(null)
	}

	const handleClick = () => {
		if (video.processing) {
			alertWarning("Video is still processing")
		} else {
			window.open(video.link, "_blank")
		}
	}

	return (
		<TableRow hover style={{ cursor: "pointer" }} onClick={handleClick}>
			<TableCell align="left">
				{video.processing && (
					<>
						<CircularProgress style={{ width: "15px", height: "15px" }} />{" "}
					</>
				)}
				{formatDate(video.date)}
			</TableCell>
			<TableCell align="left">{video.season}</TableCell>
			<TableCell align="left">{video.playerName}</TableCell>
			<TableCell>{video.title}</TableCell>
			<TableCell align="left">{video.category}</TableCell>
			<TableCell align="left">{video.uploadType}</TableCell>
			{admin && (
				<TableCell align="center">
					<Tooltip title="More">
						<IconButton onClick={handleMoreIconClick}>
							<MoreVertIcon />
						</IconButton>
					</Tooltip>
					<Menu
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}>
						<MenuItem
							onClick={(event) => {
								preventDefault(event)
								handleEdit(video.id)
								handleClose(event)
							}}>
							<EditIcon /> &nbsp; Edit
						</MenuItem>
						<MenuItem
							onClick={(event) => {
								preventDefault(event)
								handleDelete(video.id)
								handleClose(event)
							}}>
							<DeleteIcon /> &nbsp; Delete
						</MenuItem>
					</Menu>
				</TableCell>
			)}
		</TableRow>
	)
}
