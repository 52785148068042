import React, { createContext, useContext, useEffect, useState } from "react"

import axios from "axios"
import { Redirect, useParams } from "react-router-dom"

import { GlobalsContext } from "../../../context/GlobalsContext"
import { Outing } from "../../../interfaces/OutingInterface"

import { SubnavTemplateCustomTitle } from "../../../shared/MainLayout/SubnavTemplateCustomTitle/SubnavTemplateCustomTitle"
import { TrackTab } from "./TrackTab/TrackTab"
import { PitchesTab } from "./PitchesTab/PitchesTab"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import { SettingsTab } from "./SettingsTab/SettingsTab"

interface ParamTypes {
	id: string | undefined
}

export const OutingContext = createContext({
	outing: (null as unknown) as Outing,
	setOuting: (null as unknown) as React.Dispatch<
		React.SetStateAction<Outing | null>
	>,
	outingUpdated: false,
	setOutingUpdated: (null as unknown) as React.Dispatch<
		React.SetStateAction<boolean>
	>,
})

export const TrackOuting = () => {
	const globals = useContext(GlobalsContext)
	const { id } = useParams<ParamTypes>()
	const [loading, setLoading] = useState<boolean>(true)
	const [outing, setOuting] = useState<Outing | null>(null)
	const [outingUpdated, setOutingUpdated] = useState<boolean>(false)

	useEffect(() => {
		const fetchOuting = async () => {
			setLoading(true)
			try {
				const response = await axios.get(`/api/outing/${id}`, globals.apiHeader)
				setOuting(response.data)
				setLoading(false)
			} catch (e) {
				setLoading(false)
				console.log(e)
			}
		}
		fetchOuting()
	}, [outingUpdated])

	if (loading) {
		return <LoadingBig />
	}

	if (!outing) return <Redirect to="/" />

	const tabs = [
		{ name: "Track", component: <TrackTab /> },
		{ name: "Pitches", component: <PitchesTab /> },
		{
			name: "Settings",
			component: <SettingsTab outing={outing} />,
		},
	]

	return (
		<OutingContext.Provider
			value={{ outing, setOuting, outingUpdated, setOutingUpdated }}>
			<SubnavTemplateCustomTitle title={<></>} tabs={tabs} />
		</OutingContext.Provider>
	)
}
