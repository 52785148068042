import { SecondPitchCard } from "../../pages/HitterPage/GameReport/SecondPitchCard"
import { AtBat } from "./../../interfaces/AtBatInterface"
import { Pitch } from "./../../interfaces/PitchInterface"

/**
 * Help hitters understand what the opponent is throwing them
 *
 * Important keys:
 * - [x] First Pitches
 * - [] What is thrown behind in counts
 * - [] What is thrown ahead in counts
 * - [x] If first pitch FB (strike and ball), what comes next
 * - [x] If first pitch CB (strike and ball), what comes next
 * - [x] Pitches we got out on
 * - [x] Pitches we got hits on
 * - [] Last pitch of at bat ?
 * - [] Location Chart (in and out certainty)
 *
 */
export class AtBat_processor {
	// Helpful ones
	id: number
	ball_codes: String[]
	fastball_codes: String[]
	hit_codes: String[]

	total_pitches: number
	num_walks: number
	num_hits: number
	num_ks: number
	num_batters: number

	first_pitches: Pitch[]
	first_pitch_ct: number
	fp_fb_ct: number
	fp_fb_bal_ct: number
	fp_fb_str_ct: number
	first_pitch_fb_pct: number
	fp_os_ct: number
	first_pitch_offspeed_pct: number

	second_pitches: Pitch[]
	second_pitch_ct: number
	sp_fb_ct: number
	fp_os_bal_ct: number
	fp_os_str_ct: number
	second_pitch_fb_pct: number
	sp_os_ct: number
	second_pitch_offspeed_pct: number

	fp_fb_second_pitch: Pitch[]
	fp_fb_sp_ct: number
	fp_fb_str_sp_fb_ct: number
	fp_fb_str_sp_fb_pct: number // First pitch fastball strike, second pitch fastball %
	fp_fb_bal_sp_fb_ct: number
	fp_fb_bal_sp_fb_pct: number // First pitch fastball ball, second pitch fastball %
	fp_os_second_pitch: Pitch[]
	fp_os_sp_ct: number
	fp_os_str_sp_fb_ct: number
	fp_os_str_sp_fb_pct: number // First pitch offspeed strike, second pitch fastball %
	fp_os_bal_sp_fb_ct: number
	fp_os_bal_sp_fb_pct: number // First pitch offspeed ball, second pitch fastball %

	out_pitches: Pitch[]
	out_pitch_fb_pct: number
	out_pitch_os_pct: number

	hit_pitches: Pitch[]
	hit_pitch_fb_pct: number
	hit_pitch_os_pct: number

	constructor(id: number) {
		this.id = id
		this.ball_codes = ["B"]
		this.fastball_codes = ["FB", "2S"]
		this.hit_codes = ["1B", "2B", "3B", "HR"]

		this.total_pitches = 0
		this.num_walks = 0
		this.num_hits = 0
		this.num_ks = 0
		this.num_batters = 0

		this.first_pitches = []
		this.first_pitch_ct = 0
		this.fp_fb_ct = 0
		this.fp_fb_bal_ct = 0
		this.fp_fb_str_ct = 0
		this.first_pitch_fb_pct = 0
		this.fp_os_ct = 0
		this.first_pitch_offspeed_pct = 0

		this.second_pitches = []
		this.second_pitch_ct = 0
		this.sp_fb_ct = 0
		this.fp_os_bal_ct = 0
		this.fp_os_str_ct = 0
		this.second_pitch_fb_pct = 0
		this.sp_os_ct = 0
		this.second_pitch_offspeed_pct = 0

		this.fp_fb_second_pitch = []
		this.fp_fb_sp_ct = 0
		this.fp_fb_str_sp_fb_ct = 0
		this.fp_fb_str_sp_fb_pct = 0
		this.fp_fb_bal_sp_fb_ct = 0
		this.fp_fb_bal_sp_fb_pct = 0

		this.fp_os_second_pitch = []
		this.fp_os_sp_ct = 0
		this.fp_os_str_sp_fb_ct = 0
		this.fp_os_str_sp_fb_pct = 0
		this.fp_os_bal_sp_fb_ct = 0
		this.fp_os_bal_sp_fb_pct = 0

		this.out_pitches = []
		this.out_pitch_fb_pct = 0
		this.out_pitch_os_pct = 0

		this.hit_pitches = []
		this.hit_pitch_fb_pct = 0
		this.hit_pitch_os_pct = 0
	}

	/**
	 * Process a single AtBat object
	 * @param ab A single AtBat object
	 */
	process_AtBat(ab: AtBat) {
		// Useful variables
		let first_pitch = ab.pitches![0]
		let last_pitch = ab.pitches!.slice(-1)[0]

		this.num_batters += 1
		this.total_pitches += ab.pitches!.length

		// Second Pitch Processing
		if (ab.pitches!.length > 1) {
			let second_pitch = ab.pitches![1]

			// Second pitch processing
			this.second_pitches.push(second_pitch)
			this.second_pitch_ct += 1
			if (this.fastball_codes.includes(second_pitch.pitch_type)) {
				this.sp_fb_ct += 1
			} else {
				this.sp_os_ct += 1
			}

			// Comeback Pitch Processing
			if (this.fastball_codes.includes(first_pitch.pitch_type)) {
				// FP FB
				this.fp_fb_sp_ct += 1
				this.fp_fb_second_pitch.push(second_pitch)
				if (this.ball_codes.includes(first_pitch.pitch_result)) {
					// FP FB BAL
					this.fp_fb_bal_ct += 1
					if (this.fastball_codes.includes(second_pitch.pitch_type)) {
						// FP FB BAL SP FB
						this.fp_fb_bal_sp_fb_ct += 1
					}
				} else {
					// FP FB STR
					this.fp_fb_str_ct += 1
					if (this.fastball_codes.includes(second_pitch.pitch_type)) {
						// FP FB STR SP FB
						this.fp_fb_str_sp_fb_ct += 1
					}
				}
			} else {
				// FP OS
				this.fp_os_sp_ct += 1
				this.fp_os_second_pitch.push(second_pitch)
				if (this.ball_codes.includes(first_pitch.pitch_result)) {
					// FP OS BAL
					this.fp_os_bal_ct += 1
					if (this.fastball_codes.includes(second_pitch.pitch_type)) {
						// FP OS BAL SP FB
						this.fp_os_bal_sp_fb_ct += 1
					}
				} else {
					// FP FB STR
					this.fp_os_str_ct += 1
					if (this.fastball_codes.includes(second_pitch.pitch_type)) {
						// FP OS STR SP FB
						this.fp_os_str_sp_fb_ct += 1
					}
				}
			}
		}

		// First Pitch numbers
		this.first_pitches.push(first_pitch)
		this.first_pitch_ct += 1
		if (this.fastball_codes.includes(first_pitch.pitch_type)) {
			this.fp_fb_ct += 1
		} else {
			this.fp_os_ct += 1
		}

		// Hit and out pitches
		// Potential options are hit, walk, or out
		if (!this.hit_codes.includes(ab.result)) {
			if (ab.batter_out) {
				// Out
				this.out_pitches.push(last_pitch)
				if (["K", "ꓘ"].includes(ab.result)) {
					this.num_ks += 1
				}
			} else if (ab.result == "BB") {
				// walk
				this.num_walks += 1
			}
		} else {
			// Hit
			this.hit_pitches.push(last_pitch)
			this.num_hits += 1
		}
	}

	/**
	 * Process an array of AtBat objects
	 * @param abs Array of AtBat Objects
	 */
	process_AtBats(abs: AtBat[]) {
		for (let i in abs) {
			this.process_AtBat(abs[i])
		}
	}

	/**
	 * Perform all of the calculations for the class
	 */
	do_calculations() {
		// First pitch usage statistics
		this.first_pitch_fb_pct =
			this.first_pitch_ct > 0
				? this.truncate_decimal(this.fp_fb_ct / this.first_pitch_ct)
				: 0
		this.first_pitch_offspeed_pct =
			this.first_pitch_ct > 0
				? this.truncate_decimal(this.fp_os_ct / this.first_pitch_ct)
				: 0

		// Second pitch usage statistics
		this.second_pitch_fb_pct =
			this.second_pitch_ct > 0
				? this.truncate_decimal(this.sp_fb_ct / this.second_pitch_ct)
				: 0
		this.second_pitch_offspeed_pct =
			this.second_pitch_ct > 0
				? this.truncate_decimal(this.sp_os_ct / this.second_pitch_ct)
				: 0

		// What pitcher comes back with second pitch
		this.fp_fb_str_sp_fb_pct =
			this.fp_fb_str_ct > 0
				? this.truncate_decimal(this.fp_fb_str_sp_fb_ct / this.fp_fb_str_ct)
				: 0

		this.fp_fb_bal_sp_fb_pct =
			this.fp_fb_bal_ct > 0
				? this.truncate_decimal(this.fp_fb_bal_sp_fb_ct / this.fp_fb_bal_ct)
				: 0

		this.fp_os_str_sp_fb_pct =
			this.fp_os_str_ct > 0
				? this.truncate_decimal(this.fp_os_str_sp_fb_ct / this.fp_os_str_ct)
				: 0

		this.fp_os_bal_sp_fb_pct =
			this.fp_os_bal_ct > 0
				? this.truncate_decimal(this.fp_os_bal_sp_fb_ct / this.fp_os_bal_ct)
				: 0

		// Out pitch usage
		this.out_pitch_fb_pct =
			this.out_pitches.length > 0
				? this.truncate_decimal(
						this.out_pitches.filter((p) =>
							this.fastball_codes.includes(p.pitch_type)
						).length / this.out_pitches.length
				  )
				: 0
		this.out_pitch_os_pct =
			this.out_pitches.length > 0
				? this.truncate_decimal(
						this.out_pitches.filter(
							(p) => !this.fastball_codes.includes(p.pitch_type)
						).length / this.out_pitches.length
				  )
				: 0

		this.hit_pitch_fb_pct =
			this.hit_pitches.length > 0
				? this.truncate_decimal(
						this.hit_pitches.filter((p) =>
							this.fastball_codes.includes(p.pitch_type)
						).length / this.hit_pitches.length
				  )
				: 0
		this.hit_pitch_os_pct =
			this.hit_pitches.length > 0
				? this.truncate_decimal(
						this.hit_pitches.filter(
							(p) => !this.fastball_codes.includes(p.pitch_type)
						).length / this.hit_pitches.length
				  )
				: 0
	}

	truncate_decimal(x: number): number {
		return Math.round(x * 100)
	}

	clear() {
		this.total_pitches = 0
		this.num_walks = 0
		this.num_hits = 0
		this.num_ks = 0
		this.num_batters = 0

		this.first_pitches = []
		this.first_pitch_ct = 0
		this.fp_fb_ct = 0
		this.fp_fb_bal_ct = 0
		this.fp_fb_str_ct = 0
		this.first_pitch_fb_pct = 0
		this.fp_os_ct = 0
		this.first_pitch_offspeed_pct = 0

		this.second_pitches = []
		this.second_pitch_ct = 0
		this.sp_fb_ct = 0
		this.fp_os_bal_ct = 0
		this.fp_os_str_ct = 0
		this.second_pitch_fb_pct = 0
		this.sp_os_ct = 0
		this.second_pitch_offspeed_pct = 0

		this.fp_fb_second_pitch = []
		this.fp_fb_sp_ct = 0
		this.fp_fb_str_sp_fb_ct = 0
		this.fp_fb_str_sp_fb_pct = 0
		this.fp_fb_bal_sp_fb_ct = 0
		this.fp_fb_bal_sp_fb_pct = 0

		this.fp_os_second_pitch = []
		this.fp_os_sp_ct = 0
		this.fp_os_str_sp_fb_ct = 0
		this.fp_os_str_sp_fb_pct = 0
		this.fp_os_bal_sp_fb_ct = 0
		this.fp_os_bal_sp_fb_pct = 0

		this.out_pitches = []
		this.out_pitch_fb_pct = 0
		this.out_pitch_os_pct = 0

		this.hit_pitches = []
		this.hit_pitch_fb_pct = 0
		this.hit_pitch_os_pct = 0
	}
}
