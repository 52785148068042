import React, { useContext, useEffect, useState } from "react"

import axios from "axios"

import {
	Button,
	Card,
	CardContent,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tooltip,
	useMediaQuery,
} from "@material-ui/core"
import DoneIcon from "@material-ui/icons/Done"
import DeleteIcon from "@material-ui/icons/Delete"
import CloseIcon from "@material-ui/icons/Close"

// field components to show/edit spray location
import { FieldModal } from "./FieldModal"
import { MiniField } from "./MiniField"

// contexts and types
import { GlobalsContext } from "../../../../../context/GlobalsContext"
import { OutingContext } from "../../TrackOuting"
import { AtBat } from "../../../../../interfaces/AtBatInterface"
import { Player } from "../../../../../interfaces/PlayerInterface"
import { FeedbackContext } from "../../../../../context/FeedbackContext"

interface Props {
	opponentId: number
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>
	atBat: AtBat
	updatedAtBat: (atBat: AtBat) => void
	deletedAtBat: (atBat: AtBat) => void
}

const resultOptions = [
	"K",
	"ꓘ",
	"BB",
	"HBP",
	"Out",
	"1B",
	"2B",
	"3B",
	"HR",
	"Double Play",
	"Triple Play",
	"FC",
	"Error",
	"Drop Third (Out)",
	"Drop Third (Safe)",
	"Catcher's Interference",
	"IBB",
	"Other (Out)",
	"Other (Safe)",
]

// i.e. to stop a user from adding a spray location on a strikeout
const notInPlayResultOptions = [
	"N/A",
	"K",
	"ꓘ",
	"BB",
	"HBP",
	"Drop Third (Out)",
	"Drop Third (Safe)",
	"Catcher's Interference",
	"IBB",
]

export const EditMode = ({
	opponentId,
	setIsEditMode,
	atBat,
	updatedAtBat,
	deletedAtBat,
}: Props) => {
	const globals = useContext(GlobalsContext)
	const { outingUpdated, setOutingUpdated } = useContext(OutingContext)
	const { setFeedback } = useContext(FeedbackContext)

	// for the dropdown to select the batter for the at bat
	const [batters, setBatters] = useState<Player[]>([])

	// to select a new spray location
	const [showFieldModal, setShowFieldModal] = useState<boolean>(false)

	// determines whether the field or button shows up
	const showField = useMediaQuery("(min-width: 960px)")

	// keep track of the user updating the at bat
	const [formState, setFormState] = useState<{ [key: string]: any }>({
		batter: atBat.batter,
		result: atBat.result ? atBat.result : "N/A",
		traj: atBat.traj ? atBat.traj : "N/A",
		hitHard: atBat.hit_hard ? "Yes" : atBat.hit_hard === null ? "N/A" : "No",
		fielder: atBat.fielder ? atBat.fielder : "N/A",
		outs: atBat.outs !== null ? atBat.outs : "N/A",
		xSpray: atBat.spray_x,
		ySpray: atBat.spray_y,
	})

	// change the formState when the user selects a new value
	const formChange = (
		event: React.ChangeEvent<{
			name?: string | undefined
			value: unknown
		}>,
		child: React.ReactNode
	) => {
		// if the at bat result is a "K" for example, there should not be a spray location
		if (
			["traj", "fielder", "hitHard"].includes(event.target.name!) &&
			notInPlayResultOptions.includes(formState.result)
		) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: `You can't update this field when the at bat result is ${formState.result}`,
			})
		}
		const newFormState = { ...formState }
		newFormState[event.target.name!] = event.target.value
		setFormState(newFormState)
	}

	// when the user presses "Done" icon to save their changes
	const editAtBat = async () => {
		try {
			const response = await axios.patch(
				`/api/atbat/${atBat.id}`,
				{
					batter: formState.batter,
					result: formState.result === "N/A" ? null : formState.result,
					traj: formState.traj === "N/A" ? null : formState.traj,
					hit_hard:
						formState.hitHard === "N/A" ? null : formState.hitHard === "Yes",
					fielder: formState.fielder === "N/A" ? null : formState.fielder,
					outs: formState.outs === "N/A" ? null : formState.outs,
					batter_out: [
						"K",
						"ꓘ",
						"Out",
						"Double Play",
						"Triple Play",
						"Drop Third (Out)",
						"Other (Out)",
					].includes(formState.result),
					spray_x: formState.xSpray,
					spray_y: formState.ySpray,
				},
				globals.apiHeader
			)
			updatedAtBat(response.data as AtBat)
			setIsEditMode(false)
			setFeedback({
				show: true,
				severity: "success",
				message: "At bat saved!",
			})
		} catch (e) {
			setIsEditMode(false)
			setFeedback({
				show: true,
				severity: "error",
				message: "Something wen't wrong while trying to save the at bat",
			})
			console.log(e)
		}
	}

	// when the user presses the "Trash" icon to delete the at bat
	const deleteAtBat = async () => {
		if (!window.confirm("Are you sure you want to delete this at bat?")) {
			return
		}
		try {
			await axios.delete(`/api/atbat/${atBat.id}`, globals.apiHeader)
			deletedAtBat(atBat)
			setIsEditMode(false)
		} catch (e) {
			setIsEditMode(false)
			setFeedback({
				show: true,
				severity: "error",
				message: "Something wen't wrong while trying to delete the at bat",
			})
			console.log(e)
		}
	}

	// when the user tries to edit the spray location
	const handleFieldClick = () => {
		if (notInPlayResultOptions.includes(formState.result)) {
			return setFeedback({
				severity: "warning",
				show: true,
				message: `You can't choose a spray location when the at bat result is ${formState.result}`,
			})
		}
		setShowFieldModal(true)
	}

	// runs on load to gather the options for the batter
	useEffect(() => {
		const fetchBatters = async () => {
			try {
				const battersResponse = await axios.get(
					`/api/team/${opponentId}/players?active=true`,
					globals.apiHeader
				)
				setBatters(battersResponse.data)
			} catch (e) {
				console.log(e)
			}
		}
		fetchBatters()
	}, [])

	// removes the in play options when the user changes
	// 	the at bat result to be something not in play
	useEffect(() => {
		if (notInPlayResultOptions.includes(formState.result)) {
			setFormState({
				...formState,
				traj: "N/A",
				hitHard: "N/A",
				fielder: "N/A",
				xSpray: null,
				ySpray: null,
			})
		}
	}, [formState.result])

	if (!atBat) {
		return (
			<Card>
				<CardContent>Error loading the at bat</CardContent>
			</Card>
		)
	}
	return (
		<>
			<br />
			<Card>
				<CardContent className="pt-pitches-ab-card">
					<Grid container spacing={0}>
						<Grid item xs={12} sm={12} md={1}>
							<Grid
								container
								spacing={0}
								className="pt-pitches-edit-ab-buttons">
								<Grid item xs={4} sm={4} md={12}>
									<Tooltip title="Save Changes">
										<IconButton onClick={editAtBat}>
											<DoneIcon />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item xs={4} sm={4} md={12}>
									<Tooltip title="Cancel">
										<IconButton onClick={() => setIsEditMode(false)}>
											<CloseIcon />
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item xs={4} sm={4} md={12}>
									<Tooltip title="Delete">
										<IconButton onClick={deleteAtBat}>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Grid container spacing={0} className="pt-pitches-edit-ab-main">
								<Grid item xs={12}>
									<FormControl className="pt-pitches-edit-ab-input-lg">
										<InputLabel>Select Batter</InputLabel>
										<Select
											name="batter"
											value={batters.length > 0 ? formState.batter : ""}
											onChange={formChange}>
											{batters.map((batter) => (
												<MenuItem key={batter.id} value={batter.id}>
													{batter.firstname} {batter.lastname} #{batter.number}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl className="pt-pitches-edit-ab-input-lg">
										<InputLabel>Result</InputLabel>
										<Select
											value={formState.result}
											name="result"
											onChange={formChange}>
											<MenuItem value="N/A">N/A</MenuItem>
											{resultOptions.map((result, index) => (
												<MenuItem key={index} value={result}>
													{result}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={4}>
							<Grid container spacing={0} className="pt-pitches-edit-ab-inplay">
								<Grid item xs={6}>
									<FormControl className="pt-pitches-edit-ab-input-sm">
										<InputLabel>Trajectory</InputLabel>
										<Select
											value={formState.traj}
											name="traj"
											onChange={formChange}>
											<MenuItem value="N/A">N/A</MenuItem>
											<MenuItem value="GB">GB</MenuItem>
											<MenuItem value="LD">LD</MenuItem>
											<MenuItem value="FB">FB</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={6} className="align-right">
									<FormControl className="pt-pitches-edit-ab-input-sm align-left">
										<InputLabel>Fielder</InputLabel>
										<Select
											value={formState.fielder}
											name="fielder"
											onChange={formChange}>
											<MenuItem value="N/A">N/A</MenuItem>
											<MenuItem value="P">P</MenuItem>
											<MenuItem value="C">C</MenuItem>
											<MenuItem value="1B">1B</MenuItem>
											<MenuItem value="2B">2B</MenuItem>
											<MenuItem value="3B">3B</MenuItem>
											<MenuItem value="SS">SS</MenuItem>
											<MenuItem value="LF">LF</MenuItem>
											<MenuItem value="CF">CF</MenuItem>
											<MenuItem value="RF">RF</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container spacing={0} className="pt-pitches-edit-ab-inplay">
								<Grid item xs={6}>
									<FormControl className="pt-pitches-edit-ab-input-sm">
										<InputLabel>Hit Hard</InputLabel>
										<Select
											value={formState.hitHard}
											name="hitHard"
											onChange={formChange}>
											<MenuItem value="N/A">N/A</MenuItem>
											<MenuItem value="Yes">Yes</MenuItem>
											<MenuItem value="No">No</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={6} className="align-right">
									<FormControl className="pt-pitches-edit-ab-input-sm align-left">
										<InputLabel>Outs</InputLabel>
										<Select
											value={formState.outs}
											name="outs"
											onChange={formChange}>
											<MenuItem value="N/A">N/A</MenuItem>
											<MenuItem value="0">0</MenuItem>
											<MenuItem value="1">1</MenuItem>
											<MenuItem value="2">2</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={3}>
							{showField ? (
								<div
									className="pt-pitches-edit-spray-field"
									onClick={handleFieldClick}>
									<MiniField
										xSpray={formState.xSpray}
										ySpray={formState.ySpray}
									/>
								</div>
							) : (
								<div className="pt-pitches-edit-spray-button">
									<Button
										fullWidth
										disableElevation
										variant="contained"
										color="primary"
										className="pt-edit-spray-button"
										onClick={() => setShowFieldModal(true)}>
										Edit Spray
									</Button>
								</div>
							)}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
			<FieldModal
				showFieldModal={showFieldModal}
				setShowFieldModal={setShowFieldModal}
				formState={formState}
				setFormState={setFormState}
			/>
		</>
	)
}
