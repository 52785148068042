import {
    Card,
    CardContent,
    Grid,
    Typography } from "@material-ui/core"
import React from "react"

interface Props {
    fb_pct: number
    os_pct: number
}

export const HitPitchCard = ({ fb_pct, os_pct }: Props) => {

    return (
        <Card>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={0}>
                        <Grid item xs={12}>
                            <h2>Hit Pitch Division</h2>
                        </Grid>
                        <Grid item xs={12}>
                            <p>Division of pitches hits were achieved on.</p>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={0}>
                        <Grid item xs={6}>
                            <h3>Fastball</h3>
                        </Grid>
                        <Grid item xs={6}>
                            <p>{fb_pct}</p>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={0}>
                        <Grid item xs={6}>
                            <h3>Offspeed</h3>
                        </Grid>
                        <Grid item xs={6}>
                            <p>{os_pct}</p>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}