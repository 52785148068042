import { Chip, TableCell } from "@material-ui/core"
import React from "react"
import { Outing } from "../../../interfaces/OutingInterface"

interface Props {
	outing: Outing
	pitchType: string
}

export const PitchTypeDataRow = ({ outing, pitchType }: Props) => {
	let num = 0
	let veloTotal = 0
	let numWithVelo = 0
	let veloMax = 0
	let veloMin = 1000
	let totalPitches = 0
	let strikes = 0
	let swingAndMisses = 0
	let swings = 0

	if (outing.at_bats) {
		outing.at_bats.forEach((atBat) => {
			atBat.pitches!.forEach((pitch) => {
				totalPitches += 1
				if (pitch.pitch_type === pitchType) {
					num += 1
					if (pitch.velocity) {
						veloTotal += pitch.velocity
						numWithVelo += 1
						if (pitch.velocity > veloMax) {
							veloMax = pitch.velocity
						}
						if (pitch.velocity < veloMin) {
							veloMin = pitch.velocity
						}
					}
					if (pitch.pitch_result !== "B") {
						strikes += 1
					}
					if (pitch.pitch_result === "SS") {
						swingAndMisses += 1
					}
					if (["SS", "IP", "F"].includes(pitch.pitch_result)) {
						swings += 1
					}
				}
			})
		})
	}

	return (
		<>
			<TableCell align="center">
				<Chip
					label={pitchType}
					className={`outing-locations-pitch-chip-${pitchType}`}
				/>
			</TableCell>
			<TableCell align="center">{num === 0 ? "-" : num}</TableCell>
			<TableCell align="center">
				{numWithVelo > 0 ? (veloTotal / numWithVelo).toFixed(1) : "-"}
			</TableCell>
			<TableCell align="center">{veloMax === 0 ? "-" : veloMax}</TableCell>
			<TableCell align="center">{veloMin === 1000 ? "-" : veloMin}</TableCell>
			<TableCell align="center">
				{num === 0 || totalPitches === 0
					? "-"
					: `${Math.round((100 * num) / totalPitches)}%`}
			</TableCell>
			<TableCell align="center">
				{num === 0 ? "-" : `${Math.round((100 * strikes) / num)}%`}
			</TableCell>
			<TableCell align="center">
				{num === 0 || swings === 0
					? "-"
					: `${Math.round((100 * swingAndMisses) / swings)}%`}
			</TableCell>
		</>
	)
}
