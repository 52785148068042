import React, { useContext } from "react"

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"

import { Context } from "../../TrackTab"

export const PitchResult = () => {
	const { state, setState } = useContext(Context)

	const pitchResults = ["SS", "CS", "F", "B", "IP"]

	const handlePitchResultChange = (
		event: React.MouseEvent<HTMLElement>,
		value: string | null
	) => {
		setState({
			...state,
			pitchEntry: { ...state.pitchEntry, pitchResult: value },
		})
	}

	return (
		<div id="pt-pitch-result">
			<ToggleButtonGroup
				value={state.pitchEntry.pitchResult}
				exclusive
				onChange={handlePitchResultChange}
				aria-label="pitch result">
				{pitchResults.map((pitchResult) => (
					<ToggleButton
						key={pitchResult}
						className="pt-toggle-button pt-pitch-result-toggle"
						value={pitchResult}
						aria-label="swinging strike">
						{pitchResult}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</div>
	)
}
