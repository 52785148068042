import { useState } from "react"

// components
import {
	Container,
	Grid,
	IconButton,
	Popover,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"

// types and contexts
import { VeloStats } from "./StatsTab"
import { PitchTypeChip } from "../../../shared/PitchTypeChip"

interface Props {
	veloStats: VeloStats[]
}

export const VeloTable = ({ veloStats }: Props) => {
	veloStats = veloStats.filter((pitch) => pitch.num !== 0)
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	return (
		<Paper className="padding-top-12 padding-bottom-24">
			<Container>
				<Grid container spacing={0}>
					<Grid item xs={8}>
						<h2>Velocity</h2>
					</Grid>
					<Grid
						item
						xs={4}
						className="align-right"
						style={{ marginTop: "10px" }}>
						<IconButton
							onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
								setAnchorEl(event.currentTarget)
							}>
							<InfoIcon />
						</IconButton>
						<Popover
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={() => setAnchorEl(null)}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}>
							<Container
								className="padding-top-24 padding-bottom-24"
								maxWidth="sm">
								<Grid container spacing={0}>
									<Grid item xs={12}>
										Num:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>
												The number of pitches thrown where a velocity was
												written down
											</li>
										</ul>
									</Grid>
									<Grid item xs={12}>
										Avg:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>The average velocity of this pitch</li>
										</ul>
									</Grid>
									<Grid item xs={12}>
										Min:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>The lowest velocity of this pitch</li>
										</ul>
									</Grid>
									<Grid item xs={12}>
										Max:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>
												The highest velocity of this pitch for the enitre season
											</li>
										</ul>
									</Grid>
								</Grid>
							</Container>
						</Popover>
					</Grid>
				</Grid>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow className="table-header">
								<TableCell align="center">Type</TableCell>
								<TableCell align="center">Num</TableCell>
								<TableCell align="center">Avg</TableCell>
								<TableCell align="center">Min</TableCell>
								<TableCell align="center">Max</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{veloStats.map((pitch, index) => (
								<TableRow key={index}>
									<TableCell align="center">
										<PitchTypeChip pitchType={pitch.type} />
									</TableCell>
									<TableCell align="center">{pitch.num}</TableCell>
									<TableCell align="center">{pitch.avg.toFixed(1)}</TableCell>
									<TableCell align="center">{pitch.min.toFixed(0)}</TableCell>
									<TableCell align="center">{pitch.max.toFixed(0)}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</Paper>
	)
}
