import { useState, useEffect, useContext, createContext } from "react"

import axios from "axios"

// components
import { Paper, Container, Grid } from "@material-ui/core"
import { GamesTab } from "./GamesTab/GamesTab"
import { PracticesTab } from "./PracticesTab/PracticesTab"
import { SubnavTemplateCustomTitle } from "../../shared/MainLayout/SubnavTemplateCustomTitle/SubnavTemplateCustomTitle"
import { SeasonSelector } from "../../shared/SeasonSelector"

// types
import { Game } from "../../interfaces/GameInterface"
import { Practice } from "../../interfaces/PracticeInterface"

// contexts
import { GlobalsContext } from "../../context/GlobalsContext"
import { FeedbackContext } from "../../context/FeedbackContext"

// styling
import "./CompetitionsPage.css"

export const CompetitionsPageContext = createContext({
	loading: true as boolean,
	games: [] as Game[],
	practices: [] as Practice[],
	season: 0 as number,
	refetchCompetitions: null as unknown as () => void,
})

export const CompetitionsPage = () => {
	const { apiHeader, teamId, currentSeason } = useContext(GlobalsContext)
	const { alertError } = useContext(FeedbackContext)
	const [loading, setLoading] = useState<boolean>(true)
	const [season, setSeason] = useState<number>(currentSeason!)
	const [games, setGames] = useState<Game[]>([])
	const [practices, setPractices] = useState<Practice[]>([])
	const [competitionsUpdated, setCompetitionsUpdated] = useState<boolean>(false)

	useEffect(() => {
		const fetchGames = async () => {
			try {
				const response = await axios.get(
					`/api/game?season_id=${season}&order=asc`,
					apiHeader
				)
				setGames(
					response.data.filter((game: Game) =>
						[game.home_team.id, game.away_team.id].includes(teamId!)
					)
				)
			} catch (e) {
				alertError("Something wen't wrong while fetching games")
			}
		}
		const fetchPractices = async () => {
			try {
				const response = await axios.get(
					`/api/practice?season_id=${season}&order=asc`,
					apiHeader
				)
				setPractices(response.data)
			} catch (e) {
				alertError("Something wen't wrong while fetching practices")
			}
		}
		const fetchData = async () => {
			setLoading(true)
			await fetchGames()
			await fetchPractices()
			setLoading(false)
		}
		fetchData()
	}, [competitionsUpdated, season])

	const tabs = [
		{ name: "Games", component: GamesTab },
		{ name: "Practices", component: PracticesTab },
	]

	const refetchCompetitions = () => setCompetitionsUpdated(!competitionsUpdated)

	const title = (
		<Paper className="comps-title-paper">
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={8} md={10} className="align-left">
						<span className="comps-title">Competitions</span>
					</Grid>
					<Grid item xs={12} sm={4} md={2}>
						<SeasonSelector
							disabled={loading}
							fullWidth
							onChange={(value: number) => setSeason(value)}
						/>
					</Grid>
				</Grid>
			</Container>
		</Paper>
	)

	return (
		<CompetitionsPageContext.Provider
			value={{ loading, games, practices, season, refetchCompetitions }}>
			<SubnavTemplateCustomTitle title={title} tabs={tabs} />
		</CompetitionsPageContext.Provider>
	)
}
