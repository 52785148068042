import { useContext, useEffect, useState } from "react"

// components
import { Container, Grid } from "@material-ui/core"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import { VeloTable } from "./VeloTable"
import { CommandTable } from "./CommandTable"
import { InPlayTable } from "./InPlayTable"
import { PitchResultsTable } from "./PitchResultsTable"

// types
import { DetailedPitch } from "../../../interfaces/DetailedPitch"
import { PitchStatistics } from "./PitchStatistics"

// contexts
import { PitcherContext } from "../PitcherPage"

export interface VeloStats {
	type: string
	num: number
	avg: number
	max: number
	min: number
	stdev: number
	variance: number
}

export interface CommandStats {
	type: string
	num: number
	strikePct: number
	fpsPct: number
	cswPct: number
}

export interface InPlayStats {
	type: string
	hitHardPct: number
	groundballPct: number
	linedrivePct: number
	flyballPct: number
}

export interface PitchResultStats {
	type: string
	ballPct: number
	calledStrikePct: number
	swingingStrikePct: number
	foulBallPct: number
	inPlayPct: number
}

export const StatsTab = () => {
	const { outingParsers, loading } = useContext(PitcherContext)

	// will hold all the pitches thrown for the pitcher with the given filters
	const [pitches, setPitches] = useState<DetailedPitch[]>([])

	// holds all the different stats for each pitch type
	const [veloStats, setVeloStats] = useState<VeloStats[]>([])
	const [commandStats, setCommandStats] = useState<CommandStats[]>([])
	const [inPlayStats, setInPlayStats] = useState<InPlayStats[]>([])
	const [pitchResultStats, setPitchResultStats] = useState<PitchResultStats[]>(
		[]
	)

	let FB = new PitchStatistics("FB")
	let SM = new PitchStatistics("2S")
	let CB = new PitchStatistics("CB")
	let SL = new PitchStatistics("SL")
	let CH = new PitchStatistics("CH")
	let CT = new PitchStatistics("CT")
	let SPL = new PitchStatistics("SPL")
	let OTH = new PitchStatistics("OTH")

	useEffect(() => {
		let newPitches: DetailedPitch[] = []
		outingParsers.forEach((outingParser) => {
			outingParser.outing.at_bats.forEach((atBat) => {
				atBat.pitches!.forEach((pitch) =>
					newPitches.push({
						...pitch,
						seasonId: outingParser.outing.season_id,
						outing: outingParser.outingName,
						date: outingParser.outing.date,
						wasGame: outingParser.outing.game !== null,
						outs: atBat.outs,
						inning: atBat.inning,
						hitHard: atBat.hit_hard,
						lastPitch: pitch.ab_pitch_num === atBat.pitches!.length,
						atBatResult: atBat.result,
						traj: atBat.traj,
					})
				)
			})
		})
		setPitches(newPitches)
	}, [outingParsers])

	useEffect(() => {
		pitches.forEach((pitch) => {
			switch (pitch.pitch_type) {
				case "FB":
					FB.addPitch(pitch)
					break
				case "2S":
					SM.addPitch(pitch)
					break
				case "CB":
					CB.addPitch(pitch)
					break
				case "SL":
					SL.addPitch(pitch)
					break
				case "CH":
					CH.addPitch(pitch)
					break
				case "CT":
					CT.addPitch(pitch)
					break
				case "SPL":
					SPL.addPitch(pitch)
					break
				case "OTH":
					OTH.addPitch(pitch)
					break
			}
		})

		FB.calculateStatistics()
		SM.calculateStatistics()
		CB.calculateStatistics()
		SL.calculateStatistics()
		CH.calculateStatistics()
		CT.calculateStatistics()
		SPL.calculateStatistics()
		OTH.calculateStatistics()

		setVeloStats([
			FB.getVeloStatistics(),
			SM.getVeloStatistics(),
			CB.getVeloStatistics(),
			SL.getVeloStatistics(),
			CH.getVeloStatistics(),
			CT.getVeloStatistics(),
			SPL.getVeloStatistics(),
			OTH.getVeloStatistics(),
		])

		setCommandStats([
			FB.getCommandStatistics(),
			SM.getCommandStatistics(),
			CB.getCommandStatistics(),
			SL.getCommandStatistics(),
			CH.getCommandStatistics(),
			CT.getCommandStatistics(),
			SPL.getCommandStatistics(),
			OTH.getCommandStatistics(),
		])

		setInPlayStats([
			FB.getInPlayStatistics(),
			SM.getInPlayStatistics(),
			CB.getInPlayStatistics(),
			SL.getInPlayStatistics(),
			CH.getInPlayStatistics(),
			CT.getInPlayStatistics(),
			SPL.getInPlayStatistics(),
			OTH.getInPlayStatistics(),
		])

		setPitchResultStats([
			FB.getPitchResultStatistics(),
			SM.getPitchResultStatistics(),
			CB.getPitchResultStatistics(),
			SL.getPitchResultStatistics(),
			CH.getPitchResultStatistics(),
			CT.getPitchResultStatistics(),
			SPL.getPitchResultStatistics(),
			OTH.getPitchResultStatistics(),
		])
	}, [pitches])

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container className="tab-container">
			<Grid container spacing={2}>
				<Grid item md={6} xs={12}>
					<VeloTable veloStats={veloStats} />
				</Grid>
				<Grid item md={6} xs={12}>
					<CommandTable commandStats={commandStats} />
				</Grid>
				<Grid item md={6} xs={12}>
					<InPlayTable inPlayStats={inPlayStats} />
				</Grid>
				<Grid item md={6} xs={12}>
					<PitchResultsTable pitchResultStats={pitchResultStats} />
				</Grid>
			</Grid>
		</Container>
	)
}
