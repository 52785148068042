import { useState } from "react"

// components
import {
	Container,
	Grid,
	IconButton,
	Popover,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import { PitchTypeChip } from "../../../shared/PitchTypeChip"

// types
import { PitchResultStats } from "./StatsTab"

interface Props {
	pitchResultStats: PitchResultStats[]
}

export const PitchResultsTable = ({ pitchResultStats }: Props) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	pitchResultStats = pitchResultStats.filter(
		(pitch) =>
			pitch.ballPct !== 0 ||
			pitch.calledStrikePct !== 0 ||
			pitch.swingingStrikePct !== 0 ||
			pitch.foulBallPct !== 0 ||
			pitch.inPlayPct !== 0
	)
	return (
		<Paper className="padding-top-12 padding-bottom-24">
			<Container>
				<Grid container spacing={0}>
					<Grid item xs={8}>
						<h2>Pitch Results</h2>
					</Grid>
					<Grid
						item
						xs={4}
						className="align-right"
						style={{ marginTop: "10px" }}>
						<IconButton
							onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
								setAnchorEl(event.currentTarget)
							}>
							<InfoIcon />
						</IconButton>
						<Popover
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={() => setAnchorEl(null)}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}>
							<Container
								className="padding-top-24 padding-bottom-24"
								maxWidth="sm">
								<Grid container spacing={0}>
									<Grid item xs={12}>
										B%:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>How often this pitch is thrown for a ball</li>
										</ul>
									</Grid>
									<Grid item xs={12}>
										CS%:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>How often this pitch gets taken for a strike</li>
										</ul>
									</Grid>
									<Grid item xs={12}>
										SS%:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>How often this pitch results in a swing and miss</li>
										</ul>
									</Grid>
									<Grid item xs={12}>
										F%:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>How often this pitch is fouled off</li>
										</ul>
									</Grid>
									<Grid item xs={12}>
										IP%:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>How often this pitch gets put in play</li>
										</ul>
									</Grid>
								</Grid>
							</Container>
						</Popover>
					</Grid>
				</Grid>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow className="table-header">
								<TableCell align="center">Type</TableCell>
								<TableCell align="center">B%</TableCell>
								<TableCell align="center">CS%</TableCell>
								<TableCell align="center">SS%</TableCell>
								<TableCell align="center">F%</TableCell>
								<TableCell align="center">IP%</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{pitchResultStats.map((pitch, index) => (
								<TableRow key={index}>
									<TableCell align="center">
										<PitchTypeChip pitchType={pitch.type} />
									</TableCell>
									<TableCell align="center">
										{pitch.ballPct.toFixed(0)}%
									</TableCell>
									<TableCell align="center">
										{pitch.calledStrikePct.toFixed(0)}%
									</TableCell>
									<TableCell align="center">
										{pitch.swingingStrikePct.toFixed(0)}%
									</TableCell>
									<TableCell align="center">
										{pitch.foulBallPct.toFixed(0)}%
									</TableCell>
									<TableCell align="center">
										{pitch.inPlayPct.toFixed(0)}%
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</Paper>
	)
}
