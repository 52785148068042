import React, { useContext, useState } from "react"

import { IconButton, TableCell, TableRow } from "@material-ui/core"
import EditIcon from "@material-ui/icons/EditOutlined"

import { EditModeRow } from "./EditModeRow"
import { ViewRunners } from "./ViewRunners"
import { MiniZone } from "./MiniZone"

import { Pitch } from "../../../../../../interfaces/PitchInterface"
import { AtBat } from "../../../../../../interfaces/AtBatInterface"

interface Props {
	pitch: Pitch
	updatedAtBat: (atBat: AtBat) => void
}

export const PitchRow = ({ pitch, updatedAtBat }: Props) => {
	const [isEditMode, setIsEditMode] = useState<boolean>(false)

	if (isEditMode) {
		return (
			<EditModeRow
				pitch={pitch}
				closeEdit={() => setIsEditMode(false)}
				updatedAtBat={updatedAtBat}
			/>
		)
	}

	return (
		<TableRow>
			<TableCell align="center">
				<IconButton onClick={() => setIsEditMode(true)}>
					<EditIcon />
				</IconButton>
			</TableCell>
			<TableCell align="center">{pitch.ab_pitch_num!}</TableCell>
			<TableCell align="center">{pitch.hit_spot ? "Yes" : "No"}</TableCell>
			<TableCell align="center">
				{pitch.pitch_type}
				{pitch.roll_through && "-RT"}
				{pitch.short_set && "-SS"}
			</TableCell>
			<TableCell align="center">{pitch.pitch_result}</TableCell>
			<TableCell align="center">{pitch.velocity}</TableCell>
			<TableCell align="center">{pitch.time_to_plate}</TableCell>
			<TableCell align="center" style={{ minWidth: "100px" }}>
				<ViewRunners
					runner1={pitch.runner_1!}
					runner2={pitch.runner_2!}
					runner3={pitch.runner_3!}
				/>
			</TableCell>
			<TableCell align="center">
				{pitch.balls}-{pitch.strikes}
			</TableCell>
			<TableCell align="center">{pitch.notes}</TableCell>
			<TableCell align="center">
				<MiniZone xLoc={pitch.loc_x!} yLoc={pitch.loc_y!} />
			</TableCell>
		</TableRow>
	)
}
