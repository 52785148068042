import React, { useContext } from "react"

import { Button, Grid } from "@material-ui/core"

import { Context } from "../../TrackTab"
import { GlobalsContext } from "../../../../../../context/GlobalsContext"

import { AddPitchHandler } from "../../utils/AddPitchHandler"
import { EndAtBatHandler } from "../../utils/EndAtBatHandler"

import "../../../../../../static/css/TrackTab/SubmitButtons.css"

export const SubmitButtons = () => {
	const globals = useContext(GlobalsContext)
	const { state, setState, setLoading } = useContext(Context)

	return (
		<div id="pt-submit-buttons-div">
			<Grid container spacing={0}>
				<Grid item xs={6}>
					<Button
						id="pt-end-at-bat-button"
						size="large"
						onClick={() => EndAtBatHandler(state, setState)}
						disableElevation
						fullWidth
						variant="outlined"
						color="primary">
						End at Bat
					</Button>
				</Grid>
				<Grid item xs={6} className="align-right">
					<Button
						id="pt-add-pitch-button"
						size="large"
						onClick={() => {
							AddPitchHandler(
								{ ...state, endAtBatWithoutPitch: false },
								setState,
								globals.apiHeader,
								setLoading
							)
						}}
						disableElevation
						fullWidth
						variant="contained"
						color="primary">
						Add Pitch
					</Button>
				</Grid>
			</Grid>
		</div>
	)
}
