import React, { useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	Button,
	Container,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import { LoadingBig } from "../../Loading/LoadingBig"

// types and contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { AtBat } from "../../../interfaces/AtBatInterface"
import { Game } from "../../../interfaces/GameInterface"
import { Practice } from "../../../interfaces/PracticeInterface"
import { HomeAwayToggle } from "../../HomeAwayToggle"

interface Props {
	variant: "game" | "practice"
	id: number
}

export const HittingTab = ({ id, variant }: Props) => {
	const globals = useContext(GlobalsContext)

	// holds all the at bats for this competition
	const [atBats, setAtBats] = useState<AtBat[]>([])

	// these are filtered based on home/away if a game, or all if a practice
	const [filteredAtBats, setFilteredAtBats] = useState<AtBat[]>([])

	// while fetching the at bats, show a loading circle
	const [loading, setLoading] = useState<boolean>(true)

	// holds the summary for each batter on that team
	const [stats, setStats] = useState<{ [key: number]: any }>({})

	// which team atbats are displayed
	const [whichTeam, setWhichTeam] = useState<"home" | "away">("home")

	// holds the team id for filtering the at bats by home/away
	const [teamId, setTeamId] = useState<number>(0)

	// depending on if it is a game or practice, holds that information here
	const [game, setGame] = useState<Game>()
	const [practice, setPractice] = useState<Practice>()

	// when the toggle button is pressed, update the current teamId
	useEffect(() => {
		if (variant === "game" && game) {
			if (whichTeam === "home") {
				setTeamId(game.home_team.id)
			} else {
				setTeamId(game.away_team.id)
			}
		} else {
			setTeamId(globals.teamId!)
		}
	}, [whichTeam, game, practice])

	// on load, fetch the atBats for the game/practice
	useEffect(() => {
		const fetchAtBats = async () => {
			try {
				const response = await axios.get(
					`/api/${variant}/${id}/at_bats`,
					globals.apiHeader
				)
				setAtBats(response.data)
			} catch (e) {
				console.log(e)
			}
		}
		const fetchCompetition = async () => {
			try {
				const response = await axios.get(
					`/api/${variant}/${id}`,
					globals.apiHeader
				)
				if (variant === "game") {
					setGame(response.data)
				} else {
					setPractice(response.data)
				}
			} catch (e) {
				console.log(e)
			}
		}
		const fetchData = async () => {
			await fetchCompetition()
			await fetchAtBats()
			setLoading(false)
		}
		fetchData()
	}, [])

	// filter the atbats depending on home/away is pressed, or all if it is a practice
	useEffect(() => {
		if (atBats.length > 0 && variant === "game") {
			setFilteredAtBats(
				atBats.filter((atBat: AtBat) => {
					if (whichTeam === "home") {
						return atBat.player.team_id === game!.home_team.id
					} else {
						return atBat.player.team_id === game!.away_team.id
					}
				})
			)
		} else {
			setFilteredAtBats(atBats)
		}
	}, [atBats, whichTeam])

	// calculate the stats for the filtered at bats
	useEffect(() => {
		let rows: { [key: number]: any } = {}
		filteredAtBats.forEach((atBat) => {
			if (!Object.keys(rows).includes(`${atBat.batter}`)) {
				rows[atBat.batter] = {
					batter: `${atBat.player.firstname} ${atBat.player.lastname}`,
					plateAppearances: 0,
					pitchesSeen: 0,
					hardHits: 0,
					hits: 0,
					strikeouts: 0,
					walks: 0,
					hitByPitches: 0,
				}
			}
			rows[atBat.batter] = {
				...rows[atBat.batter],
				plateAppearances: (rows[atBat.batter].plateAppearances += 1),
				pitchesSeen: (rows[atBat.batter].pitchesSeen += atBat.pitches!.length),
				hardHits: atBat.hit_hard
					? (rows[atBat.batter].hardHits += 1)
					: rows[atBat.batter].hardHits,
				hits: ["1B", "2B", "3B", "HR"].includes(atBat.result)
					? (rows[atBat.batter].hits += 1)
					: rows[atBat.batter].hits,
				strikeouts: ["ꓘ", "K"].includes(atBat.result)
					? (rows[atBat.batter].strikeouts += 1)
					: rows[atBat.batter].strikeouts,
				walks:
					atBat.result === "BB"
						? (rows[atBat.batter].walks += 1)
						: rows[atBat.batter].walks,
				hitByPitches:
					atBat.result === "HBP"
						? (rows[atBat.batter].hitByPitches += 1)
						: rows[atBat.batter].hitByPitches,
			}
		})
		setStats(rows)
	}, [filteredAtBats])

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container style={{ paddingTop: "24px" }}>
			<Grid container spacing={0}>
				{variant === "game" && (
					<Grid item xs={12}>
						<HomeAwayToggle
							value={whichTeam}
							onChange={(value: "home" | "away") => {
								if (value) {
									setWhichTeam(value)
								}
							}}
						/>
					</Grid>
				)}
				<Grid item xs={6}>
					<h2>
						{variant === "game" &&
							whichTeam === "home" &&
							`${game!.home_team.name} - `}
						{variant === "game" &&
							whichTeam === "away" &&
							`${game!.away_team.name} - `}
						At Bats
					</h2>
				</Grid>
				<Grid item xs={12}>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell className="table-header">Batter</TableCell>
									<TableCell align="center" className="table-header">
										PA
									</TableCell>
									<TableCell align="center" className="table-header">
										Pitches
									</TableCell>
									<TableCell align="center" className="table-header">
										Hard Hits
									</TableCell>
									<TableCell align="center" className="table-header">
										Hits
									</TableCell>
									<TableCell align="center" className="table-header">
										K
									</TableCell>
									<TableCell align="center" className="table-header">
										BB
									</TableCell>
									<TableCell align="center" className="table-header">
										HBP
									</TableCell>
								</TableRow>
							</TableHead>
							{stats && (
								<TableBody>
									{Object.keys(stats).map((batter) => (
										<TableRow key={batter}>
											<TableCell>
												<Button color="primary">
													{stats[parseInt(batter)].batter}
												</Button>
											</TableCell>
											<TableCell align="center">
												{stats[parseInt(batter)].plateAppearances}
											</TableCell>
											<TableCell align="center">
												{stats[parseInt(batter)].pitchesSeen}
											</TableCell>
											<TableCell align="center">
												{stats[parseInt(batter)].hardHits}
											</TableCell>
											<TableCell align="center">
												{stats[parseInt(batter)].hits}
											</TableCell>
											<TableCell align="center">
												{stats[parseInt(batter)].strikeouts}
											</TableCell>
											<TableCell align="center">
												{stats[parseInt(batter)].walks}
											</TableCell>
											<TableCell align="center">
												{stats[parseInt(batter)].hitByPitches}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							)}
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			<br />
			<br />
			<br />
			<br />
		</Container>
	)
}
