import moment from "moment"
import React, { useContext, useEffect, useState } from "react"
import {
	Chip,
	IconButton,
	Input,
	MenuItem,
	Select,
	TableCell,
	TableRow,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/EditOutlined"

import { Pitch } from "../../../interfaces/PitchInterface"
import { AtBat } from "../../../interfaces/AtBatInterface"
import { GlobalsContext } from "../../../context/GlobalsContext"

interface Props {
	atBat: AtBat
	index: Number
}

const formatDate = (dateString: string) => {
	return `${moment(dateString).format("ddd M/D/YY")}`
}

export const SpraysTableRow = ({ atBat, index }: Props) => {
	return (
		<TableRow>
			<TableCell>{index}</TableCell>
			<TableCell>{formatDate(atBat.date! as string)}</TableCell>
			<TableCell>
				{atBat.pitcher!.firstname} {atBat.pitcher!.lastname}
			</TableCell>
			{atBat.pitches && atBat.pitches.length > 0 ? (
				<>
					<TableCell>{atBat.pitches.slice(-1)[0].pitch_type}</TableCell>
					<TableCell>
						{atBat.pitches.slice(-1)[0].balls}-
						{atBat.pitches.slice(-1)[0].strikes}
					</TableCell>
				</>
			) : (
				<>
					<TableCell>N/A</TableCell>
					<TableCell>N/A</TableCell>
				</>
			)}
			<TableCell>
				{atBat.result == "In Play (Out)" ? "Out" : atBat.result}
			</TableCell>
			<TableCell>{atBat.traj}</TableCell>
			<TableCell>{atBat.fielder}</TableCell>
			<TableCell>{atBat.hit_hard ? "x" : ""}</TableCell>
			<TableCell>{atBat.notes}</TableCell>
		</TableRow>
	)
}
