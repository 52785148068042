import { useState } from "react"

// components
import {
	Container,
	Grid,
	IconButton,
	Popover,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import { PitchTypeChip } from "../../../shared/PitchTypeChip"

// types and contexts
import { CommandStats } from "./StatsTab"

interface Props {
	commandStats: CommandStats[]
}

export const CommandTable = ({ commandStats }: Props) => {
	commandStats = commandStats.filter((pitch) => pitch.num !== 0)
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	let totalPitches = 0
	commandStats.forEach((pitchType) => (totalPitches += pitchType.num))

	return (
		<Paper className="padding-top-12 padding-bottom-24">
			<Container>
				<Grid container spacing={0}>
					<Grid item xs={8}>
						<h2>Command/Stuff</h2>
					</Grid>
					<Grid
						item
						xs={4}
						className="align-right"
						style={{ marginTop: "10px" }}>
						<IconButton
							onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
								setAnchorEl(event.currentTarget)
							}>
							<InfoIcon />
						</IconButton>
						<Popover
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={() => setAnchorEl(null)}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}>
							<Container
								className="padding-top-24 padding-bottom-24"
								maxWidth="sm">
								<Grid container spacing={0}>
									<Grid item xs={12}>
										Usage%:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>How often this pitch is thrown</li>
										</ul>
									</Grid>
									<Grid item xs={12}>
										Strike%:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>How often this pitch is thrown for a strike</li>
										</ul>
									</Grid>
									<Grid item xs={12}>
										FPS%:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>
												How often this pitch is thrown for a strike when thrown
												for the first pitch of an at bat
											</li>
										</ul>
									</Grid>
									<Grid item xs={12}>
										CSW%:
									</Grid>
									<Grid item xs={12}>
										<ul>
											<li>
												How often this pitch is either called a strike or a
												swing and miss
											</li>
										</ul>
									</Grid>
								</Grid>
							</Container>
						</Popover>
					</Grid>
				</Grid>
				<TableContainer>
					<Table size="small">
						<TableHead>
							<TableRow className="table-header">
								<TableCell align="center">Type</TableCell>
								<TableCell align="center">Usage%</TableCell>
								<TableCell align="center">Strike%</TableCell>
								<TableCell align="center">FPS%</TableCell>
								<TableCell align="center">CSW%</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{commandStats.map((pitch, index) => (
								<TableRow key={index}>
									<TableCell align="center">
										<PitchTypeChip pitchType={pitch.type} />
									</TableCell>
									<TableCell align="center">
										{((100 * pitch.num) / totalPitches).toFixed(0)}%
									</TableCell>
									<TableCell align="center">
										{pitch.strikePct.toFixed(0)}%
									</TableCell>
									<TableCell align="center">
										{pitch.fpsPct.toFixed(0)}%
									</TableCell>
									<TableCell align="center">
										{pitch.cswPct.toFixed(0)}%
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
		</Paper>
	)
}
