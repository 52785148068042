import React from "react"

import { Box } from "@material-ui/core"

import "../../../static/css/TabPanel.css"

interface TabPanelProps {
	children?: React.ReactNode
	index: any
	value: any
}

export const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props
	return (
		<div
			className="tab-panel"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && <Box p={0}>{children}</Box>}
		</div>
	)
}
