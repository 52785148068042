import React, { useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	Container,
	FormControlLabel,
	Grid,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from "@material-ui/core"
import { TeamRow } from "./TeamRow"

// types and contexts
import { Team } from "../../../interfaces/TeamInterface"
import { GlobalsContext } from "../../../context/GlobalsContext"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"

export const AllTeamsTab = () => {
	const globals = useContext(GlobalsContext)

	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<boolean>(false)

	// based on if the "show all" switch is on or off and with search
	const [filteredTeams, setFilteredTeams] = useState<Team[]>([])

	// holds all of the teams in the database
	const [teams, setTeams] = useState<Team[]>([])

	// which page of the table you are on
	const [page, setPage] = useState<number>(0)

	// determines the user selection on the number of rows per page
	const [rowsPerPage, setRowsPerPage] = useState<number>(25)

	// the user typing in a search for the teams
	const [searchQuery, setSearchQuery] = useState<string>("")

	// the switch whether or not to show all of the teams
	const [showAll, setShowAll] = useState<boolean>(false)

	// runs once on load to fetch every team in the db
	useEffect(() => {
		const fetchTeams = async () => {
			try {
				const response = await axios.get("/api/team/all", globals.apiHeader)
				setTeams(response.data)
				setFilteredTeams(response.data.filter((team: Team) => team.favorite))
			} catch (e) {
				console.log(e)
				setError(true)
			}
			setLoading(false)
		}
		fetchTeams()
	}, [])

	const changeFavorite = (id: number, favorite: boolean) => {
		setTeams(
			teams.map((team) => {
				if (team.id === id) {
					team.favorite = favorite
				}
				return team
			})
		)
		updateFilteredTeams()
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const updateFilteredTeams = () => {
		setFilteredTeams(
			teams.filter((team) => {
				if (!showAll && !team.favorite) return false
				return team.name.toLowerCase().includes(searchQuery)
			})
		)
	}

	useEffect(() => updateFilteredTeams(), [showAll, searchQuery])

	if (loading) {
		return <LoadingBig />
	}

	if (error) {
		return (
			<div style={{ textAlign: "center", paddingTop: "50px" }}>
				<h1>There was an error loading all teams</h1>
			</div>
		)
	}

	return (
		<Container style={{ paddingTop: "12px" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						onChange={(event) => setSearchQuery(event.target.value)}
						style={{ width: "100%", backgroundColor: "var(--white)" }}
						label="Search for teams"
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12}>
					<Paper style={{ overflow: "scroll", paddingTop: "12px" }}>
						<Container>
							<FormControlLabel
								control={
									<Switch
										checked={showAll}
										onChange={(event) => setShowAll(event.target.checked)}
										color="primary"
									/>
								}
								label="Show All"
							/>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell className="table-header">Favorite</TableCell>
										<TableCell className="table-header">Team Name</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredTeams
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((team) => (
											<TeamRow
												key={team.id}
												team={team}
												changeFavorite={changeFavorite}
											/>
										))}
								</TableBody>
							</Table>
							<TablePagination
								rowsPerPageOptions={[25, 50, 100]}
								component="div"
								count={filteredTeams.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						</Container>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	)
}
