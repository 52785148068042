/**
 *
 * @param url a string which should be url
 * @returns whether of not the string provided is a YouTube link
 */
export const isYouTubeUrl = (url: string) => {
	const expression = /(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/
	const regex = new RegExp(expression)
	return url.match(regex)
}
