import React from "react"

import ToggleButton from "@material-ui/lab/ToggleButton"

interface Props {
	onChange: () => void
	runner: boolean
	disabled?: boolean
}

export const Base = ({ onChange, runner, disabled }: Props) => {
	return (
		<ToggleButton
			disabled={disabled}
			size="medium"
			className="pt-runners-base"
			value="check"
			selected={runner}
			onChange={onChange}>
			<></>
		</ToggleButton>
	)
}
