import React, { useContext } from "react"

import { Grid, Paper } from "@material-ui/core"

import { Context } from "../../TrackTab"

export const Count = () => {
	const { state } = useContext(Context)
	return (
		<Paper elevation={0} className="pt-outing-state-component">
			<Grid container spacing={0}>
				<Grid item xs={6}>
					<div className="pt-outing-state-name" id="pt-count-balls">
						B
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className="pt-outing-state-name" id="pt-count-strikes">
						S
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className="pt-outing-state-value">{state.outingState.balls}</div>
				</Grid>
				<Grid item xs={6}>
					<div className="pt-outing-state-value">
						{state.outingState.strikes}
					</div>
				</Grid>
			</Grid>
		</Paper>
	)
}
