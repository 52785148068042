import { State } from "./Constants"

export const dispatchWarning = (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	text: string
) => {
	setState({
		...state,
		feedback: {
			show: true,
			text,
			severity: "warning",
		},
	})
}

export const dispatchError = (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	text: string
) => {
	setState({
		...state,
		feedback: {
			show: true,
			text,
			severity: "error",
		},
	})
}
