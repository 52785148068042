import { useContext } from "react"

// components
import { Container, Paper, useMediaQuery } from "@material-ui/core"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import { Line } from "react-chartjs-2"

// types
import { OutingParser } from "../../../classes/OutingParser"

// contexts
import { PitcherContext } from "../PitcherPage"

// component styling
import { pitchColors, pitchTypes } from "../../.."
import "./VeloTab.css"

export const VeloTab = () => {
	const { outingParsers, loading } = useContext(PitcherContext)

	const smallScreen = useMediaQuery("(max-width:600px)")

	let datasets: { [key: string]: any }[] = []
	let labels: string[] = []

	pitchTypes.forEach((pitchType) => {
		datasets.push({
			data: [],
			label: pitchType,
			backgroundColor: pitchColors[pitchType],
			borderColor: pitchColors[pitchType],
		})
	})

	outingParsers.forEach((outingParser: OutingParser) => {
		if (!smallScreen) {
			labels.push(outingParser.outingName)
		} else {
			labels.push("")
		}

		datasets.forEach((dataset, index) => {
			const pitchType = pitchTypes[index]
			const velo = outingParser.outingStats.avgVelocities[pitchType]
			if (velo !== "0.0") {
				datasets[index].data.push(parseFloat(velo))
			} else {
				datasets[index].data.push(null)
			}
		})
	})

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container className="tab-container">
			<Paper className="pitcher-velo-tab-paper">
				<Line data={{ labels, datasets }} />
			</Paper>
		</Container>
	)
}
