import { createContext, Dispatch } from "react"

import { Player } from "../../../interfaces/PlayerInterface"
import { User } from "../../../interfaces/UserInterface"

export interface State {
	loading: boolean
	error: boolean
	users: Array<User>
	players: Array<Player>
	newUserModal: boolean
	feedbackOpen: boolean
	feedbackText: string
	usersUpdated: boolean
}

export interface StateAction {
	type: string
	payload?: {
		users: Array<User>
		players: Array<Player>
	}
}

export const UsersTabContext = createContext({
	state: (null as unknown) as State,
	dispatch: (null as unknown) as Dispatch<StateAction>,
})
