import { useEffect, useState } from "react"

import { Link } from "react-router-dom"

// components
import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core"
import { LoadingBig } from "../../Loading/LoadingBig"

// types and contexts
import { AtBat } from "../../../interfaces/AtBatInterface"
import { Outing } from "../../../interfaces/OutingInterface"
import { Pitch } from "../../../interfaces/PitchInterface"

interface Props {
	outings: Outing[]
}

export const OutingTable = ({ outings }: Props) => {
	const [loading, setLoading] = useState<boolean>(true)
	const [outingRows, setOutingRows] = useState<Array<{ [key: string]: any }>>(
		[]
	)
	const [totals, setTotals] = useState<{ [key: string]: any }>({})

	useEffect(() => {
		let strikesTotal = 0
		let firstPitchStrikesTotal = 0
		let hitSpotsTotal = 0
		let veloTotalAll = 0
		let pitchesWithVeloTotal = 0
		let outingTotals = {
			battersFaced: 0,
			pitches: 0,
			hits: 0,
			strikeouts: 0,
			walks: 0,
			hitByPitches: 0,
			strikePct: "N/A",
			firstPitchStrikePct: "N/A",
			hitSpotPct: "N/A",
			velocity: "N/A",
		}
		const parsedOutings = outings.map((outing: Outing) => {
			let pitches = 0
			let hits = 0
			let strikeouts = 0
			let walks = 0
			let hitByPitches = 0
			let strikes = 0
			let firstPitchStrikes = 0
			let veloTotal = 0
			let pitchesWithVelo = 0
			let hitSpots = 0
			outing.at_bats.forEach((atBat: AtBat) => {
				pitches += atBat.pitches!.length
				if (atBat.result) {
					if (["1B", "2B", "3B", "HR"].includes(atBat.result)) {
						hits += 1
					} else if (
						["K", "ꓘ", "Drop Third (Out)", "Drop Third (Safe)"].includes(
							atBat.result
						)
					) {
						strikeouts += 1
					} else if (atBat.result === "BB") {
						walks += 1
					} else if (atBat.result === "HBP") {
						hitByPitches += 1
					}
				}
				if (atBat.pitches) {
					atBat.pitches.forEach((pitch: Pitch, index: number) => {
						if (pitch.pitch_result !== "B") {
							strikes += 1
						}
						if (pitch.ab_pitch_num === 1 && pitch.pitch_result !== "B") {
							firstPitchStrikes += 1
						}
						if (pitch.velocity && ["FB", "2S"].includes(pitch.pitch_type)) {
							veloTotal += pitch.velocity
							pitchesWithVelo += 1
						}
						if (pitch.hit_spot) hitSpots += 1
					})
				}
			})
			outingTotals.battersFaced += outing.at_bats.length
			outingTotals.pitches += pitches
			outingTotals.hits += hits
			outingTotals.strikeouts += strikeouts
			outingTotals.walks += walks
			outingTotals.hitByPitches += hitByPitches
			strikesTotal += strikes
			firstPitchStrikesTotal += firstPitchStrikes
			hitSpotsTotal += hitSpots
			veloTotalAll += veloTotal
			pitchesWithVeloTotal += pitchesWithVelo

			return {
				id: outing.id,
				name: `${outing.pitcher.firstname} ${outing.pitcher.lastname}`,
				pitches,
				battersFaced: outing.at_bats.length,
				hits,
				strikeouts,
				walks,
				hitByPitches,
				strikePct: pitches > 0 ? Math.round((100 * strikes) / pitches) : "N/A",
				firstPitchStrikePct:
					outing.at_bats.length > 0
						? Math.round((100 * firstPitchStrikes) / outing.at_bats.length)
						: "N/A",
				hitSpotPct:
					pitches > 0 ? Math.round((100 * hitSpots) / pitches) : "N/A",
				velocity:
					pitchesWithVelo > 0
						? (veloTotal / pitchesWithVelo).toFixed(1)
						: "N/A",
			}
		})
		setOutingRows(parsedOutings)
		setTotals({
			...outingTotals,
			strikePct:
				outingTotals.pitches > 0
					? Math.round((100 * strikesTotal) / outingTotals.pitches)
					: "N/A",
			firstPitchStrikePct:
				outingTotals.battersFaced > 0
					? Math.round(
							(100 * firstPitchStrikesTotal) / outingTotals.battersFaced
					  )
					: "N/A",
			hitSpotPct:
				outingTotals.pitches > 0
					? Math.round((100 * hitSpotsTotal) / outingTotals.pitches)
					: "N/A",
			velocity:
				pitchesWithVeloTotal > 0
					? (veloTotalAll / pitchesWithVeloTotal).toFixed(1)
					: "N/A",
		})
		setLoading(false)
	}, [outings])

	if (loading) {
		return <LoadingBig />
	}

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className="table-header">Pitcher</TableCell>
						<TableCell align="center" className="table-header">
							BF
						</TableCell>
						<TableCell align="center" className="table-header">
							Pitches
						</TableCell>
						<TableCell align="center" className="table-header">
							Hits
						</TableCell>
						<TableCell align="center" className="table-header">
							K
						</TableCell>
						<TableCell align="center" className="table-header">
							BB
						</TableCell>
						<TableCell align="center" className="table-header">
							HBP
						</TableCell>
						<TableCell align="center" className="table-header">
							Strike%
						</TableCell>
						<TableCell align="center" className="table-header">
							FPS%
						</TableCell>
						<TableCell align="center" className="table-header">
							HitSpot%
						</TableCell>
						<TableCell align="center" className="table-header">
							Velo
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{outingRows.map((outing) => (
						<TableRow key={outing.id}>
							<TableCell component="th" scope="row">
								<Button
									component={Link}
									to={`/outing/${outing.id}`}
									color="primary">
									{outing.name}
								</Button>
							</TableCell>
							<TableCell align="center">{outing.battersFaced}</TableCell>
							<TableCell align="center">{outing.pitches}</TableCell>
							<TableCell align="center">{outing.hits}</TableCell>
							<TableCell align="center">{outing.strikeouts}</TableCell>
							<TableCell align="center">{outing.walks}</TableCell>
							<TableCell align="center">{outing.hitByPitches}</TableCell>
							<TableCell align="center">
								{outing.strikePct}
								{outing.strikePct !== "N/A" && <>%</>}
							</TableCell>
							<TableCell align="center">
								{outing.firstPitchStrikePct}
								{outing.firstPitchStrikePct !== "N/A" && <>%</>}
							</TableCell>
							<TableCell align="center">
								{outing.hitSpotPct}
								{outing.hitSpotPct !== "N/A" && <>%</>}
							</TableCell>
							<TableCell align="center">{outing.velocity}</TableCell>
						</TableRow>
					))}
					<TableRow>
						<TableCell align="left" style={{ fontWeight: "bold" }}>
							Totals
						</TableCell>
						<TableCell align="center" style={{ fontWeight: "bold" }}>
							{totals.battersFaced}
						</TableCell>
						<TableCell align="center" style={{ fontWeight: "bold" }}>
							{totals.pitches}
						</TableCell>
						<TableCell align="center" style={{ fontWeight: "bold" }}>
							{totals.hits}
						</TableCell>
						<TableCell align="center" style={{ fontWeight: "bold" }}>
							{totals.strikeouts}
						</TableCell>
						<TableCell align="center" style={{ fontWeight: "bold" }}>
							{totals.walks}
						</TableCell>
						<TableCell align="center" style={{ fontWeight: "bold" }}>
							{totals.hitByPitches}
						</TableCell>
						<TableCell align="center" style={{ fontWeight: "bold" }}>
							{totals.strikePct}
							{totals.strikePct !== "N/A" && <>%</>}
						</TableCell>
						<TableCell align="center" style={{ fontWeight: "bold" }}>
							{totals.firstPitchStrikePct}
							{totals.firstPitchStrikePct !== "N/A" && <>%</>}
						</TableCell>
						<TableCell align="center" style={{ fontWeight: "bold" }}>
							{totals.hitSpotPct}
							{totals.hitSpotPct !== "N/A" && <>%</>}
						</TableCell>
						<TableCell align="center" style={{ fontWeight: "bold" }}>
							{totals.velocity}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	)
}
