import { useEffect, useRef } from "react"

import * as d3 from "d3"

interface Props {
	xLoc: number
	yLoc: number
}

export const MiniZone = ({ xLoc, yLoc }: Props) => {
	const svgRef = useRef(null)

	let xScale = d3.scaleLinear().range([100, 0]).domain([2, -2])
	let yScale = d3.scaleLinear().range([100, 0]).domain([0, 4])

	// runs the first time
	useEffect(() => {
		// remove everything on the svg
		const svg = d3.select(svgRef.current)
		svg.selectAll("*").remove()

		// strikezone
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(-0.833)},${yScale(1.75)}
					L ${xScale(-0.833)},${yScale(3.4)}
					L ${xScale(0.833)},${yScale(3.4)}
					L ${xScale(0.833)},${yScale(1.75)}
					L ${xScale(-0.858)},${yScale(1.75)}`
			)
			.style("stroke", "black")
			.style("fill", "white")
			.style("stroke-width", 1)

		// homeplate
		svg
			.append("path")
			.attr(
				"d",
				`M ${xScale(0)},${yScale(0.2)}
				L ${xScale(-0.863)},${yScale(0.4)}
				L ${xScale(-0.833)},${yScale(0.7)}
				L ${xScale(0.83)},${yScale(0.7)}
				L ${xScale(0.863)},${yScale(0.4)}
				L ${xScale(0)},${yScale(0.2)}`
			)
			.style("stroke", "black")
			.style("fill", "white")
			.style("stroke-width", 1)
	}, [])

	useEffect(() => {
		const svg = d3.select(svgRef.current)
		svg.selectAll("circle").remove()
		if (xLoc && yLoc) {
			svg
				.append("circle")
				.attr("cx", xScale(xLoc))
				.attr("cy", yScale(yLoc))
				.attr("r", 3)
				.style("fill", "black")
		}
	}, [xLoc, yLoc])

	return <svg width={100} height={100} ref={svgRef}></svg>
}
