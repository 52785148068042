import React, { useState } from "react"

import {
	Toolbar,
	IconButton,
	Box,
	Drawer,
	List,
	ListItem,
	ListItemText,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import MenuIcon from "@material-ui/icons/Menu"

import BearHead from "../../../../static/img/BearHead.png"

interface Props {
	page: any
	setPage: React.Dispatch<any>
	logout: () => void
	admin: boolean
}

export const SmallScreenNav = ({ page, setPage, logout, admin }: Props) => {
	const [showSideNav, setShowSideNav] = useState<boolean>(false)

	return (
		<Toolbar id="screen-small">
			<Box display="flex" flexGrow={1}>
				<IconButton
					aria-label="account of current user"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					onClick={() => setShowSideNav(true)}
					color="inherit">
					<MenuIcon />
				</IconButton>
				<Drawer
					anchor="left"
					open={showSideNav}
					onClose={() => setShowSideNav(false)}>
					<List className="sidenav-drawer-list">
						<ListItem
							button
							key={"Home"}
							component={Link}
							selected={window.location.pathname === "/"}
							to="/"
							onClick={() => setShowSideNav(false)}>
							<ListItemText primary={"Home"} />
						</ListItem>
						<ListItem
							button
							key={"Pitching"}
							component={Link}
							selected={window.location.pathname === "/pitching"}
							to="/pitching"
							onClick={() => setShowSideNav(false)}>
							<ListItemText primary={"Pitching"} />
						</ListItem>
						<ListItem
							button
							key={"Hitters"}
							component={Link}
							selected={window.location.pathname === "/hitting"}
							to="/hitting"
							onClick={() => setShowSideNav(false)}>
							<ListItemText primary={"Hitting"} />
						</ListItem>
						<ListItem
							button
							key={"Games"}
							component={Link}
							selected={window.location.pathname === "/competitions"}
							to="/competitions"
							onClick={() => setShowSideNav(false)}>
							<ListItemText primary={"Competitions"} />
						</ListItem>
						<ListItem
							button
							key={"Teams"}
							component={Link}
							selected={window.location.pathname === "/teams"}
							to="/teams"
							onClick={() => setShowSideNav(false)}>
							<ListItemText primary={"Teams"} />
						</ListItem>
						<ListItem
							button
							key={"Resources"}
							component={Link}
							selected={window.location.pathname === "/resources"}
							to="/resources"
							onClick={() => setShowSideNav(false)}>
							<ListItemText primary={"Resources"} />
						</ListItem>
						<ListItem
							button
							key={"Scouting"}
							component={Link}
							selected={window.location.pathname === "/scouting"}
							to="/scouting"
							onClick={() => setShowSideNav(false)}>
							<ListItemText primary={"Scouting"} />
						</ListItem>
						<ListItem
							button
							key={"Profile"}
							component={Link}
							selected={window.location.pathname === "/profile"}
							to="/profile"
							onClick={() => setShowSideNav(false)}>
							<ListItemText primary={"Profile"} />
						</ListItem>
						{admin ? (
							<ListItem
								button
								key={"Admin"}
								component={Link}
								selected={window.location.pathname === "/admin"}
								to="/admin"
								onClick={() => setShowSideNav(false)}>
								<ListItemText primary={"Admin"} />
							</ListItem>
						) : (
							<></>
						)}
						<ListItem button key={"Logout"} onClick={logout}>
							<ListItemText primary={"Logout"} />
						</ListItem>
					</List>
				</Drawer>
			</Box>
			<IconButton
				edge="start"
				color="inherit"
				aria-label="home"
				component={Link}
				to="/"
				onClick={() => setPage(false)}>
				<img className="app-bar-logo" src={BearHead} alt="bear-head" />
			</IconButton>
		</Toolbar>
	)
}
