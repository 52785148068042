import { Button } from "@material-ui/core"
import { useContext } from "react"
import { Link } from "react-router-dom"
import { Context } from "../../TrackTab"

/**
 * This is used to tell the user to increase the width of their screen to track
 * 	the outing
 */
export const ScreenToSmall = () => {
	const { state } = useContext(Context)

	return (
		<div className="pt-error-text-div">
			<h1>Please increase the width of your screen to track this outing</h1>
			<Button
				component={Link}
				to={`/outing/${state.outing!.id}`}
				disableElevation
				color="primary"
				variant="contained">
				Exit
			</Button>
		</div>
	)
}
