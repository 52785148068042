import { useContext } from "react"

import axios from "axios"
import { Link } from "react-router-dom"

// components
import { Button, IconButton, TableCell, TableRow } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"

// types
import { Game } from "../../../interfaces/GameInterface"

// contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { CompetitionsPageContext } from "../CompetitionsPage"

// utils
import { formatDate } from "../../../shared/Functions/FormatDate"

interface Props {
	game: Game
	handleEdit: () => void
}

export const GameRow = ({ game, handleEdit }: Props) => {
	const { user, apiHeader } = useContext(GlobalsContext)
	const { alertSuccess, alertError } = useContext(FeedbackContext)
	const { refetchCompetitions } = useContext(CompetitionsPageContext)

	const handleDelete = async () => {
		if (confirm("Are you sure you wan't to delete this game?")) {
			try {
				await axios.delete(`/api/game/${game.id}`, apiHeader)
				alertSuccess("Game deleted!")
				refetchCompetitions()
			} catch (e) {
				alertError(
					"Something went wrong. Try deleting the outings for the game first."
				)
			}
		}
	}

	return (
		<TableRow>
			<TableCell align="left">{formatDate(game.date as string)}</TableCell>
			<TableCell align="left">
				<Button to={`/game/${game.id}`} component={Link} color="primary">
					{`${game.away_team.name} @ ${game.home_team.name}`}
				</Button>
			</TableCell>
			{user!.admin && (
				<TableCell align="center">
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={handleDelete}>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			)}
		</TableRow>
	)
}
