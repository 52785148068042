import { useEffect, useState, useContext } from "react"

import {
	Container,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	ListSubheader,
	Paper,
	Switch,
} from "@material-ui/core"
import axios from "axios"
import { GlobalsContext } from "../../../../context/GlobalsContext"
import { FeedbackContext } from "../../../../context/FeedbackContext"
import { Outing } from "../../../../interfaces/OutingInterface"
import { LoadingBig } from "../../../../shared/Loading/LoadingBig"

interface Props {
	outing: Outing
}

export const SettingsTab = ({ outing }: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)
	const [oneOneCounts, setOneOneCounts] = useState<boolean>(
		outing.setting_counts_1_1
	)
	const [inferOutingState, setInferOutingState] = useState<boolean>(
		outing.setting_infer_outing_state
	)

	const updateSettings = async (counts: boolean, infer: boolean) => {
		try {
			await axios.patch(
				`/api/outing/${outing.id}`,
				{
					setting_counts_1_1: counts,
					setting_infer_outing_state: infer,
				},
				globals.apiHeader
			)
			setFeedback({
				show: true,
				severity: "success",
				message: "Settings saved!",
			})
		} catch (e) {
			console.log(e)
			setFeedback({
				show: true,
				severity: "error",
				message: "Something went wrong",
			})
		}
	}

	if (!outing) {
		return <LoadingBig />
	}

	return (
		<Container className="padding-top-24">
			<Paper>
				<List subheader={<ListSubheader>Settings</ListSubheader>}>
					<ListItem>
						<ListItemText
							primary="1-1 Counts"
							secondary="Start each atbat with a 1-1 count"
						/>
						<ListItemSecondaryAction>
							<Switch
								color="primary"
								edge="end"
								onChange={(event) => {
									setOneOneCounts(event.target.checked)
									updateSettings(event.target.checked, inferOutingState)
								}}
								checked={oneOneCounts}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Infer Outs/Inning"
							secondary="Automatically infer and update the outs and inning while tracking the outing"
						/>
						<ListItemSecondaryAction>
							<Switch
								color="primary"
								edge="end"
								onChange={(event) => {
									setInferOutingState(event.target.checked)
									updateSettings(oneOneCounts, event.target.checked)
								}}
								checked={inferOutingState}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				</List>
			</Paper>
		</Container>
	)
}
