import React from "react"

import { Grid } from "@material-ui/core"

import { Count } from "./Count"
import { Inning } from "./Inning"
import { Outs } from "./Outs"
import { Runners } from "./Runners"

import "../../../../../../static/css/TrackTab/OutingState.css"

export const OutingState = () => {
	return (
		<Grid container spacing={0} className="pt-outing-state align-center">
			<Grid item xs={3} className="pt-inning-grid">
				<Inning />
			</Grid>
			<Grid item xs={3} className="pt-count-grid">
				<Count />
			</Grid>
			<Grid item xs={3} className="pt-outs-grid">
				<Outs />
			</Grid>
			<Grid item xs={3} className="pt-runners-grid">
				<Runners />
			</Grid>
		</Grid>
	)
}
