import { useContext, useState, useEffect, createContext } from "react"

import axios from "axios"

// components
import { SubnavTemplate } from "../../shared/MainLayout/SubnavTemplate/SubnavTemplate"
import { ActiveTab } from "./ActiveTab"
import { InactiveTab } from "./InactiveTab"
import { NewPitcherModal } from "./NewPitcherModal"
import { EditPitcherModal } from "./EditPitcherModal"

// types
import { Player } from "../../interfaces/PlayerInterface"

// contexts
import { GlobalsContext } from "../../context/GlobalsContext"

// styling
import "./PitchingPage.css"

export const PitchingPageContext = createContext({
	loading: true,
	players: [] as Player[],
	refetchPlayers: null as unknown as () => void,
	openNewPitcherModal: null as unknown as () => void,
	openEditPitcherModal: null as unknown as (player: Player) => void,
})

export const PitchingPage = () => {
	const { apiHeader, teamId } = useContext(GlobalsContext)
	const [loading, setLoading] = useState<boolean>(true)
	const [players, setPlayers] = useState<Player[]>([])
	const [playersUpdated, setPlayersUpdated] = useState<boolean>(false)
	const [newPitcherModal, setNewPitcherModal] = useState<boolean>(false)
	const [editPitcherModal, setEditPitcherModal] = useState<Player | null>(null)

	useEffect(() => {
		const fetchPlayers = async () => {
			try {
				const response = await axios.get(
					`/api/team/${teamId}/players`,
					apiHeader
				)
				setPlayers(response.data)
			} catch (e) {
				console.log(e)
			}
			setLoading(false)
		}
		fetchPlayers()
	}, [playersUpdated])

	const tabs = [
		{
			name: "Active",
			component: <ActiveTab />,
		},
		{
			name: "Inactive",
			component: <InactiveTab />,
		},
	]

	const refetchPlayers = () => setPlayersUpdated(!playersUpdated)
	const openNewPitcherModal = () => setNewPitcherModal(true)
	const openEditPitcherModal = (player: Player) => setEditPitcherModal(player)

	return (
		<PitchingPageContext.Provider
			value={{
				loading,
				players,
				refetchPlayers,
				openNewPitcherModal,
				openEditPitcherModal,
			}}>
			<SubnavTemplate title="Pitching" tabs={tabs} />
			<NewPitcherModal
				open={newPitcherModal}
				handleClose={() => setNewPitcherModal(false)}
			/>
			<EditPitcherModal
				open={editPitcherModal !== null}
				handleClose={() => setEditPitcherModal(null)}
				player={editPitcherModal}
			/>
		</PitchingPageContext.Provider>
	)
}
