import { useContext, useEffect, useState } from "react"

import { Link } from "react-router-dom"

// components
import {
	Button,
	Container,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"

// types
import { OutingStats } from "../../../interfaces/OutingStats"
import { OutingParser } from "../../../classes/OutingParser"

// contexts
import { PitcherContext } from "../PitcherPage"

// util functions
import { calcTotalsForMultipleOutings } from "../../../shared/Functions/CalcTotalsForMultipleOutings"

// component styling
import "./OutingsTab.css"

export const OutingsTab = () => {
	const { loading, outingParsers } = useContext(PitcherContext)

	// holds the totals for all the outings during the season
	const [outingTotals, setOutingTotals] = useState<OutingStats>({
		battersFaced: 0,
		pitches: 0,
		hits: 0,
		strikeouts: 0,
		walks: 0,
		hitByPitches: 0,
		strikePct: "N/A",
		firstPitchStrikePct: "N/A",
		hitSpotPct: "N/A",
		velocity: "N/A",
		avgVelocities: {},
	})

	useEffect(() => {
		setOutingTotals(
			calcTotalsForMultipleOutings(outingParsers.map((parser) => parser.outing))
		)
	}, [outingParsers])

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container className="tab-container">
			{outingParsers.length === 0 ? (
				<h1>No outings</h1>
			) : (
				<Grid container spacing={0} component={Paper}>
					<Grid item xs={12}>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell className="table-header"></TableCell>
										<TableCell align="center" className="table-header">
											BF
										</TableCell>
										<TableCell align="center" className="table-header">
											Pitches
										</TableCell>
										<TableCell align="center" className="table-header">
											H
										</TableCell>
										<TableCell align="center" className="table-header">
											K
										</TableCell>
										<TableCell align="center" className="table-header">
											BB
										</TableCell>
										<TableCell align="center" className="table-header">
											HBP
										</TableCell>
										<TableCell align="center" className="table-header">
											Strike%
										</TableCell>
										<TableCell align="center" className="table-header">
											FPS%
										</TableCell>
										<TableCell align="center" className="table-header">
											HitSpot%
										</TableCell>
										<TableCell align="center" className="table-header">
											Velo
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{outingParsers.map((outingParser: OutingParser) => {
										const outingStats = outingParser.outingStats
										return (
											<TableRow key={outingParser.outing.id}>
												<TableCell>
													<Button
														component={Link}
														to={`/outing/${outingParser.outing.id}`}
														color="primary">
														{outingParser.outingName}
													</Button>
												</TableCell>
												<TableCell align="center">
													{outingStats.battersFaced}
												</TableCell>
												<TableCell align="center">
													{outingStats.pitches}
												</TableCell>
												<TableCell align="center">{outingStats.hits}</TableCell>
												<TableCell align="center">
													{outingStats.strikeouts}
												</TableCell>
												<TableCell align="center">
													{outingStats.walks}
												</TableCell>
												<TableCell align="center">
													{outingStats.hitByPitches}
												</TableCell>
												<TableCell align="center">
													{outingStats.strikePct}
													{outingStats.strikePct !== "N/A" && "%"}
												</TableCell>
												<TableCell align="center">
													{outingStats.firstPitchStrikePct}
													{outingStats.firstPitchStrikePct !== "N/A" && "%"}
												</TableCell>
												<TableCell align="center">
													{outingStats.hitSpotPct}
													{outingStats.hitSpotPct !== "N/A" && "%"}
												</TableCell>
												<TableCell align="center">
													{outingStats.velocity}
												</TableCell>
											</TableRow>
										)
									})}
									<TableRow>
										<TableCell align="left" className="bold">
											Totals
										</TableCell>
										<TableCell align="center" className="bold">
											{outingTotals.battersFaced}
										</TableCell>
										<TableCell align="center" className="bold">
											{outingTotals.pitches}
										</TableCell>
										<TableCell align="center" className="bold">
											{outingTotals.hits}
										</TableCell>
										<TableCell align="center" className="bold">
											{outingTotals.strikeouts}
										</TableCell>
										<TableCell align="center" className="bold">
											{outingTotals.walks}
										</TableCell>
										<TableCell align="center" className="bold">
											{outingTotals.hitByPitches}
										</TableCell>
										<TableCell align="center" className="bold">
											{outingTotals.strikePct}
										</TableCell>
										<TableCell align="center" className="bold">
											{outingTotals.firstPitchStrikePct}
										</TableCell>
										<TableCell align="center" className="bold">
											{outingTotals.hitSpotPct}
										</TableCell>
										<TableCell align="center" className="bold">
											{outingTotals.velocity}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			)}
		</Container>
	)
}
