import { useState, useContext, useEffect } from "react"

import axios from "axios"

// components
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
} from "@material-ui/core"

// types and contexts
import { GlobalsContext } from "../../../context/GlobalsContext"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { Season } from "../../../interfaces/SeasonInterface"
import { SeasonsUpdatedContext } from "./SeasonsTab"

// holds the information about the season
interface FormValues {
	name: string
	year: number
	currentSeason: boolean
}

interface Props {
	open: boolean
	close: () => void
	season: Season
}

export const EditSeasonModal = ({ open, close, season }: Props) => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)
	const { refetchSeasons } = useContext(SeasonsUpdatedContext)

	// holds all the intitial season information before user edits it
	const initialFormValues: FormValues = {
		name: season.name,
		year: season.year,
		currentSeason: season.current_season,
	}

	// stores the updated values as the user edits the season
	const [formValues, setFormValues] = useState<FormValues>(initialFormValues)

	useEffect(() => {
		resetFormValues()
	}, [season])

	// when the user presses "Save"
	const editSeason = async () => {
		// make sure all three important parts of a season are there
		if (!formValues.name || !formValues.year) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please fill out all the required fields below",
			})
		}

		if (season.current_season && !formValues.currentSeason) {
			return setFeedback({
				show: true,
				severity: "warning",
				message: "Please make another season the 'Current Season'",
			})
		}

		// send a request api to adjust the season
		try {
			await axios.patch(
				`/api/season/${season.id}`,
				{
					name: formValues.name,
					year: formValues.year,
					current_season: formValues.currentSeason,
				},
				globals.apiHeader
			)
			close()
			setFeedback({
				show: true,
				severity: "success",
				message: "Season saved!",
			})
			refetchSeasons()
		} catch (e) {
			setFeedback({
				show: true,
				severity: "error",
				message: "Something went wrong",
			})
			resetFormValues()
		}
	}

	const resetFormValues = () => {
		setFormValues(initialFormValues)
	}

	const cancel = () => {
		close()
		resetFormValues()
	}

	return (
		<div>
			<Dialog fullWidth maxWidth="sm" open={open} onClose={cancel}>
				<DialogTitle>Edit Season</DialogTitle>
				<DialogContent>
					<Grid container spacing={0}>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								value={formValues.name}
								label="Name"
								type="text"
								variant="outlined"
								onChange={(event) =>
									setFormValues({ ...formValues, name: event.target.value })
								}
							/>
						</Grid>
						<Grid item xs={12} className="padding-top-24">
							<FormControl
								required
								fullWidth
								variant="outlined"
								style={{ backgroundColor: "white" }}>
								<InputLabel required>Year</InputLabel>
								<Select
									required
									label="Year"
									value={formValues.year}
									onChange={(event) =>
										setFormValues({
											...formValues,
											year: event.target.value as number,
										})
									}>
									{[
										2015,
										2016,
										2017,
										2018,
										2019,
										2020,
										2021,
										2022,
										2023,
										2024,
										2025,
										2026,
										2027,
										2028,
										2029,
									].map((year) => (
										<MenuItem key={year} value={year}>
											{year}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} className="padding-top-24">
							<FormControlLabel
								control={
									<Switch
										checked={formValues.currentSeason}
										onChange={(event) =>
											setFormValues({
												...formValues,
												currentSeason: event.target.checked,
											})
										}
										color="primary"
									/>
								}
								label="Current Season"
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={cancel} color="primary">
						Cancel
					</Button>
					<Button onClick={editSeason} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
