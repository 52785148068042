import React, { useContext, useEffect, useState } from "react"

import { Draggable } from "react-beautiful-dnd"

import {
	Card,
	CardContent,
	Collapse,
	Grid,
	Hidden,
	IconButton,
	Tooltip,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/EditOutlined"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"

// components
import { MiniField } from "./MiniField"
import { EditMode } from "./EditMode"
import { PitchesTable } from "./PitchesTable/PitchesTable"

// contexts and interfaces
import { AtBat } from "../../../../../interfaces/AtBatInterface"

interface Props {
	atBat: AtBat
	index: number
	opponentId: number
	deletedAtBat: (atBat: AtBat) => void
	updatedAtBat: (atBat: AtBat) => void // when an at bat is updated, make sure state is updated as well
}

export const AtBatCard = ({
	atBat,
	index,
	opponentId,
	updatedAtBat,
	deletedAtBat,
}: Props) => {
	const [isEditMode, setIsEditMode] = useState<boolean>(false)
	const [showPitches, setShowPitches] = useState<boolean>(false)

	// show/hide pitches
	const handleShowPitches = () => {
		setShowPitches(!showPitches)
	}

	// show an error message if the atbat failed to load
	if (!atBat) {
		return (
			<Card>
				<CardContent className="pt-pitches-ab-card">
					Error loading the at bat
				</CardContent>
			</Card>
		)
	}

	// if the atbat is being edited
	if (isEditMode) {
		return (
			<EditMode
				deletedAtBat={deletedAtBat}
				opponentId={opponentId}
				setIsEditMode={setIsEditMode}
				atBat={atBat}
				updatedAtBat={updatedAtBat}
			/>
		)
	}

	return (
		<Draggable
			key={atBat.id}
			draggableId={`draggable-${atBat.id}`}
			index={index}>
			{(provided) => (
				<div ref={provided.innerRef} {...provided.draggableProps}>
					<br />
					<Card>
						<CardContent
							{...provided.dragHandleProps}
							className="pt-pitches-ab-card">
							<Grid container spacing={0}>
								<Hidden xsDown>
									<Grid item xs={4} sm={2} md={1}>
										<Grid container spacing={0}>
											<Grid item xs={12}>
												<Tooltip title="Edit At Bat">
													<IconButton onClick={() => setIsEditMode(true)}>
														<EditIcon />
													</IconButton>
												</Tooltip>
											</Grid>
											<Grid item xs={12}>
												<IconButton onClick={handleShowPitches}>
													{showPitches ? (
														<ExpandLessIcon />
													) : (
														<ExpandMoreIcon />
													)}
												</IconButton>
											</Grid>
										</Grid>
									</Grid>
								</Hidden>
								<Grid item xs={12} sm={5} md={4} className="align-center">
									<Grid container spacing={0}>
										<Grid item xs={6} sm={12}>
											<div className="pt-pitches-ab-field-name">BATTER</div>
											<div className="pt-pitches-ab-field-value">
												#{atBat.player.number} {atBat.player.firstname}{" "}
												{atBat.player.lastname}
											</div>
										</Grid>
										<Grid
											item
											xs={6}
											sm={12}
											className="pt-pitches-ab-card-result">
											<div className="pt-pitches-ab-field-name">RESULT</div>
											<div className="pt-pitches-ab-field-value">
												{atBat.result ? atBat.result : "N/A"}
											</div>
										</Grid>
									</Grid>
								</Grid>
								<Grid
									item
									xs={12}
									sm={5}
									md={3}
									className="align-center pt-pitches-ab-card-traj-container">
									<Grid container spacing={0}>
										<Grid item xs={6}>
											<div className="pt-pitches-ab-field-name">TRAJECTORY</div>
											<div className="pt-pitches-ab-field-value">
												{atBat.traj ? atBat.traj : "N/A"}
											</div>
										</Grid>
										<Grid item xs={6}>
											<div className="pt-pitches-ab-field-name">FIELDER</div>
											<div className="pt-pitches-ab-field-value">
												{atBat.fielder ? atBat.fielder : "N/A"}
											</div>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={0}
										className="pt-pitches-ab-hit-hard-container">
										<Grid item xs={6}>
											<div className="pt-pitches-ab-field-name">HIT HARD</div>
											<div className="pt-pitches-ab-field-value">
												{atBat.hit_hard
													? "Yes"
													: atBat.hit_hard === null
													? "N/A"
													: "No"}
											</div>
										</Grid>
										<Grid item xs={6}>
											<div className="pt-pitches-ab-field-name">OUTS</div>
											<div className="pt-pitches-ab-field-value">
												{atBat.outs !== null ? atBat.outs : "N/A"}
											</div>
										</Grid>
									</Grid>
								</Grid>
								<Hidden smUp>
									<Grid item xs={12}>
										<br />
										<Grid container spacing={0} className="align-center">
											<Grid item xs={6}>
												<IconButton onClick={handleShowPitches}>
													{showPitches ? (
														<ExpandLessIcon />
													) : (
														<ExpandMoreIcon />
													)}
												</IconButton>
											</Grid>
											<Grid item xs={6}>
												<Tooltip title="Edit At Bat">
													<IconButton onClick={() => setIsEditMode(true)}>
														<EditIcon />
													</IconButton>
												</Tooltip>
											</Grid>
										</Grid>
									</Grid>
								</Hidden>
								<Hidden smDown>
									<Grid item md={2} className="align-center">
										<div className="pt-pitches-ab-field-name">NOTES</div>
										<div className="pt-pitches-ab-field-value">
											{atBat.notes !== null && atBat.notes !== ""
												? atBat.notes
												: "N/A"}
										</div>
									</Grid>
									<Grid item md={2} className="align-center">
										<MiniField xSpray={atBat.spray_x} ySpray={atBat.spray_y} />
									</Grid>
								</Hidden>
							</Grid>
						</CardContent>
						<Collapse in={showPitches} timeout={0} unmountOnExit>
							<PitchesTable atBat={atBat} updatedAtBat={updatedAtBat} />
						</Collapse>
					</Card>
				</div>
			)}
		</Draggable>
	)
}
