import React, { useContext, useEffect, useReducer } from "react"

import axios from "axios"

import {
	Button,
	CircularProgress,
	Container,
	Grid,
	Paper,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core"

import { NewUserModal } from "./NewUserModal"
import { UserRow } from "./UserRow"

import { User } from "../../../interfaces/UserInterface"
import { GlobalsContext } from "../../../context/GlobalsContext"
import { State, StateAction, UsersTabContext } from "./TypesAndContexts"

const initialState: State = {
	loading: true,
	error: false,
	users: [],
	players: [],
	newUserModal: false,
	feedbackOpen: false,
	feedbackText: "",
	usersUpdated: false,
}

const stateReducer = (state: State, action: StateAction) => {
	switch (action.type) {
		case "FETCH_SUCCESS":
			return {
				...state,
				loading: false,
				error: false,
				users: action.payload!.users,
				players: action.payload!.players,
			}
		case "FETCH_ERROR":
			return {
				loading: false,
				error: true,
				users: [],
				players: [],
				newUserModal: false,
				feedbackOpen: true,
				feedbackText: "Something wen't wrong",
				usersUpdated: false,
			}
		case "OPEN_NEW_USER_MODAL":
			return {
				...state,
				newUserModal: true,
			}
		case "CLOSE_NEW_USER_MODAL":
			return {
				...state,
				newUserModal: false,
			}
		case "NEW_USER_CREATED":
			return {
				...state,
				newUserModal: false,
				feedbackOpen: true,
				feedbackText: "User created!",
			}
		case "ADD_USER_ERROR":
			return {
				...state,
				newUserModal: false,
				feedbackOpen: true,
				feedbackText: "Something wen't wrong",
			}
		case "CLOSE_FEEDBACK":
			return {
				...state,
				feedbackOpen: false,
				feedbackText: "",
			}
		case "USER_UPDATED":
			return {
				...state,
				feedbackOpen: true,
				feedbackText: "User saved!",
				usersUpdated: !state.usersUpdated,
			}
		case "USER_DELETED":
			return {
				...state,
				feedbackOpen: true,
				feedbackText: "User deleted!",
				usersUpdated: !state.usersUpdated,
			}
		case "USER_UPDATED_FAILED":
			return {
				...state,
				newUserModal: false,
				feedbackOpen: true,
				feedbackText: "Something wen't wrong",
			}
		default:
			return state
	}
}

export const UsersTab = () => {
	const [state, dispatch] = useReducer(stateReducer, initialState)
	const globals = useContext(GlobalsContext)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const usersResponse = await axios.get(
					"/api/user/all",
					globals.apiHeader
				)
				const players = await axios.get(
					`/api/team/${globals.teamId}/players?active=true`,
					globals.apiHeader
				)
				const users = usersResponse.data.map((user: User) => {
					return { ...user, isEditMode: false }
				})
				dispatch({
					type: "FETCH_SUCCESS",
					payload: { users, players: players.data },
				})
			} catch (e) {
				dispatch({ type: "FETCH_ERROR", payload: { users: [], players: [] } })
			}
		}
		fetchData()
	}, [state.newUserModal, state.usersUpdated])

	if (state.loading) {
		return (
			<div style={{ textAlign: "center", paddingTop: "50px" }}>
				<CircularProgress className="app-loader" color="primary" />
			</div>
		)
	}

	if (state.error) {
		return (
			<div style={{ textAlign: "center", paddingTop: "50px" }}>
				<h1>There was an error loading your team's users</h1>
			</div>
		)
	}

	return (
		<UsersTabContext.Provider value={{ state, dispatch }}>
			<Container style={{ paddingTop: "24px" }}>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<Paper>
							<Container>
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<h1 className="admin-users-table-title">Active Users</h1>
									</Grid>
									<Grid
										item
										xs={6}
										className="align-right admin-users-add-user-button">
										<Button
											variant="contained"
											color="primary"
											onClick={() => dispatch({ type: "OPEN_NEW_USER_MODAL" })}>
											Add User
										</Button>
									</Grid>
								</Grid>
							</Container>
							<div style={{ overflow: "scroll" }}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell align="left"></TableCell>
											<TableCell align="left">Firstname</TableCell>
											<TableCell align="left">Lastname</TableCell>
											<TableCell align="left">Email</TableCell>
											<TableCell align="left">Is Player</TableCell>
											<TableCell align="left">Is Coach</TableCell>
											<TableCell align="left">Admin</TableCell>
											<TableCell align="left">Player</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{state.users.map((user) => (
											<UserRow key={user.id} userId={user.id} />
										))}
									</TableBody>
								</Table>
							</div>
						</Paper>
					</Grid>
				</Grid>
				<NewUserModal />
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={state.feedbackOpen}
					onClose={() => dispatch({ type: "CLOSE_FEEDBACK" })}
					message={state.feedbackText}
				/>
			</Container>
		</UsersTabContext.Provider>
	)
}
