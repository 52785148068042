import {
    Box,
    Card,
    CardContent,
    Grid } from "@material-ui/core"
import React from "react"

interface Props {
    fpFbStr: string
    fpFbBal: string
    fpOsStr: string
    fpOsBal: string
}

export const ComebackPitchCard = ({ fpFbStr, fpFbBal, fpOsStr, fpOsBal }: Props) => {

    let border_size = 1

    return (
        <Card>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid container item xs={12} spacing={0}>
                        <Grid item xs={12}>
                            <h2>Comeback Pitch Analysis</h2>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={0}>
                        <Grid item xs={4}>
                            <h4>First Pitch</h4>
                        </Grid>
                        <Grid item xs={4}>
                            <h4>Result</h4>
                        </Grid>
                        <Grid item xs={4}>
                            <h4>Majority Comeback</h4>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={0}>
                        <Grid item xs={4}>
                            <Box><h5>Fastball</h5></Box>
                        </Grid>
                        <Grid container item xs={4} spacing={0}>
                            <Grid item xs={12}><Box borderBottom={border_size}>Strike</Box></Grid>
                            <Grid item xs={12}>Ball</Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={0}>
                            <Grid item xs={12}><Box borderBottom={border_size}>{fpFbStr}</Box></Grid>
                            <Grid item xs={12}>{fpFbBal}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={0}>
                        <Grid item xs={4}>
                            <Box><h5>Offspeed</h5></Box>
                        </Grid>
                        <Grid container item xs={4} spacing={0}>
                            <Grid item xs={12}><Box borderBottom={border_size}>Strike</Box></Grid>
                            <Grid item xs={12}>Ball</Grid>
                        </Grid>
                        <Grid container item xs={4} spacing={0}>
                            <Grid item xs={12}><Box borderBottom={border_size}>{fpOsStr}</Box></Grid>
                            <Grid item xs={12}>{fpOsBal}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}