import React from "react"

import { Grid, Paper } from "@material-ui/core"

import { Base } from "./Base"

interface Props {
	runner1: boolean
	runner2: boolean
	runner3: boolean
}

export const ViewRunners = ({ runner1, runner2, runner3 }: Props) => {
	return (
		<Paper elevation={0}>
			<Grid container spacing={0}>
				<Grid item xs={12} className="align-center">
					<Base
						disabled
						runner={runner2}
						onChange={() => {
							return
						}}
					/>
				</Grid>
				<Grid
					item
					xs={6}
					className="align-right"
					style={{ paddingRight: "14px" }}>
					<Base
						disabled
						runner={runner3}
						onChange={() => {
							return
						}}
					/>
				</Grid>
				<Grid
					item
					xs={6}
					className="align-left"
					style={{ paddingLeft: "14px" }}>
					<Base
						disabled
						runner={runner1}
						onChange={() => {
							return
						}}
					/>
				</Grid>
			</Grid>
		</Paper>
	)
}
