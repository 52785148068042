import React, { useContext } from "react"

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"

import { Context } from "../../TrackTab"

export const ABResult = () => {
	const { state, setState } = useContext(Context)

	const results1 = ["Out", "1B", "2B", "3B", "HR"]

	const results2 = ["FC", "Error", "Double Play", "Triple Play", "Other (Safe)"]

	const handleAtBatResultChange = (
		event: React.MouseEvent<HTMLElement>,
		result: string | null
	) => {
		setState({
			...state,
			atBatEntry: { ...state.atBatEntry, result },
		})
	}

	return (
		<div className="pt-field-ab-result-div">
			<ToggleButtonGroup
				value={state.atBatEntry.result}
				exclusive
				onChange={handleAtBatResultChange}>
				{results1.map((result) => (
					<ToggleButton
						key={result}
						className="pt-toggle-button pt-field-ab-result ab-result-top"
						value={result}>
						{result}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
			<ToggleButtonGroup
				value={state.atBatEntry.result}
				exclusive
				onChange={handleAtBatResultChange}
				aria-label="pitch result">
				{results2.map((result) => (
					<ToggleButton
						key={result}
						className="pt-toggle-button pt-field-ab-result ab-result-bottom"
						value={result}>
						{result}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</div>
	)
}
