import { useContext } from "react"

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@material-ui/core"
import { PlayerRow } from "./PlayerRow"

// types
import { Player } from "../../../interfaces/PlayerInterface"

// contexts
import { GlobalsContext } from "../../../context/GlobalsContext"

interface HitterTableProps {
	players: Player[]
}

export const HitterTable = ({ players }: HitterTableProps) => {
	const { user } = useContext(GlobalsContext)

	return (
		<TableContainer>
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell className="table-header">&nbsp;&nbsp;Name</TableCell>
						<TableCell className="table-header" align="center">
							Year
						</TableCell>
						<TableCell className="table-header" align="center">
							Bats
						</TableCell>
						<TableCell className="table-header" align="center">
							Number
						</TableCell>
						{user!.admin && (
							<TableCell className="table-header" align="center">
								Actions
							</TableCell>
						)}
					</TableRow>
				</TableHead>
				<TableBody>
					{players.map((player) => (
						<PlayerRow key={player.id} player={player} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
