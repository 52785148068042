import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core"

interface FilterOption {
	label: any
	value: any
}

interface FilterProps {
	label: string
	value: any
	handleChange: (value: any) => void
	options: FilterOption[]
}

export const Filter = ({
	label,
	value,
	handleChange,
	options,
}: FilterProps) => {
	return (
		<FormControl fullWidth variant="outlined">
			<InputLabel>{label}</InputLabel>
			<Select
				required
				label={label}
				value={value}
				onChange={(event) => handleChange(event.target.value)}>
				{options.map((option, idx) => (
					<MenuItem key={idx} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
