import React, { useContext } from "react"

import { FormControlLabel, Grid, Switch } from "@material-ui/core"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"

import { Context } from "../../TrackTab"

export const Trajectory = () => {
	const { state, setState } = useContext(Context)

	const trajectories = ["GB", "LD", "FB"]

	const handleTrajChange = (
		event: React.MouseEvent<HTMLElement>,
		value: string | null
	) => {
		setState({
			...state,
			atBatEntry: { ...state.atBatEntry, traj: value },
		})
	}

	const handleHitHardChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		hitHard: boolean
	) => {
		setState({
			...state,
			atBatEntry: { ...state.atBatEntry, hitHard },
		})
	}

	return (
		<div className="pt-field-traj-div">
			<Grid container spacing={0}>
				<Grid xs={8} item>
					<ToggleButtonGroup
						value={state.atBatEntry.traj}
						exclusive
						onChange={handleTrajChange}>
						{trajectories.map((traj) => (
							<ToggleButton
								key={traj}
								className="pt-toggle-button pt-field-traj"
								value={traj}>
								{traj}
							</ToggleButton>
						))}
					</ToggleButtonGroup>
				</Grid>
				<Grid
					xs={4}
					item
					className="align-center"
					style={{ paddingTop: "6px" }}>
					<FormControlLabel
						control={
							<Switch
								color="primary"
								value={state.atBatEntry.hitHard}
								onChange={handleHitHardChange}
							/>
						}
						label="Hit Hard"
						labelPlacement="start"
					/>
				</Grid>
			</Grid>
		</div>
	)
}
