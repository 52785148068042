import { FreeBreakfastOutlined } from "@material-ui/icons"
import * as d3 from "d3"
import sv from "date-fns/esm/locale/sv/index.js"
import React, {useEffect, useRef} from "react"
import { AtBat } from "../../../interfaces/AtBatInterface"

interface Props {
    atBats: AtBat[]
    width: number
    height: number
}

export const DensityField = ({ atBats, width, height }: Props) => {
    const svgRef = useRef(null)

    const xScale = d3.scaleLinear().range([width, 0]).domain([250, -250])
    const yScale = d3.scaleLinear().range([height, 0]).domain([-100, 400])

    // So that the draw useEffect does not trigger before the one to add
    // Data values

    var pos_1b: any
    var pos_2b: any
    var pos_ss: any
    var pos_3b: any

    var pos_lf: any
    var pos_cf: any
    var pos_rf: any

    useEffect(() => {
        const svg = d3.select(svgRef.current)
        svg.selectAll("path").remove()
        svg.selectAll("text").remove()

        const field_outline_path = `
        M ${xScale(0)} ${yScale(-75)}
        L ${xScale(230)} ${yScale(155)} 
        L ${xScale(75)} ${yScale(300)} 
        L ${xScale(-75)} ${yScale(300)} 
        L ${xScale(-230)} ${yScale(155)} 
        L ${xScale(0)} ${yScale(-75)} 
        Z
        `
        const infield_outline_path = `
        M ${xScale(100)} ${yScale(25)} 
        L ${xScale(0)} ${yScale(125)} 
        L ${xScale(-100)} ${yScale(25)} 
        L ${xScale(0)} ${yScale(-75)} 
        L ${xScale(100)} ${yScale(25)} 
        Z
        `
        //  Infield Positions
        const position_3b = `
        M ${xScale(0)} ${yScale(-75)} 
        L ${xScale(-100)} ${yScale(25)} 
        L ${xScale(-50)} ${yScale(75)} 
        Z
        `
        const position_ss = `
        M ${xScale(0)} ${yScale(-75)} 
        L ${xScale(-50)} ${yScale(75)} 
        L ${xScale(-0)} ${yScale(125)} 
        Z
        `
        const position_2b = `
        M ${xScale(0)} ${yScale(-75)} 
        L ${xScale(0)} ${yScale(125)} 
        L ${xScale(50)} ${yScale(75)} 
        Z
        `
        const position_1b = `
        M ${xScale(0)} ${yScale(-75)} 
        L ${xScale(50)} ${yScale(75)} 
        L ${xScale(100)} ${yScale(25)} 
        Z
        `
        // Outfield Positions
        const position_lf = `
        M ${xScale(-100)} ${yScale(25)} 
        L ${xScale(-230)} ${yScale(155)} 
        L ${xScale(-75)} ${yScale(300)} 
        L ${xScale(-25)} ${yScale(100)} 
        Z
        `
        const position_cf = `
        M ${xScale(-25)} ${yScale(100)} 
        L ${xScale(-75)} ${yScale(300)} 
        L ${xScale(75)} ${yScale(300)} 
        L ${xScale(25)} ${yScale(100)} 
        L ${xScale(0)} ${yScale(125)} 
        Z
        `
        const position_rf = `
        M ${xScale(25)} ${yScale(100)} 
        L ${xScale(75)} ${yScale(300)} 
        L ${xScale(230)} ${yScale(155)} 
        L ${xScale(100)} ${yScale(25)} 
        Z
        `

        svg.append("path")
            .attr("class", "pt-d3-field")
            .attr("d", field_outline_path)
            .style("stroke", "black")
            .style("fill", "none")
            .style("stroke-width", 2)
        svg.append("path")
            .attr("class", "pt-d3-field")
            .attr("d", infield_outline_path)
            .style("stroke", "black")
            .style("fill", "none")
            .style("stroke-width", 2)
        // Infield Outlines
        pos_3b = svg.append("path")
            .attr("class", "pt-d3-field")
            .attr("d", position_3b)
            .style("stroke", "black")
            .style("fill", "none")
            .style("stroke-width", 2)
        pos_ss = svg.append("path")
            .attr("class", "pt-d3-field")
            .attr("d", position_ss)
            .style("stroke", "black")
            .style("fill", "none")
            .style("stroke-width", 2)
        pos_2b = svg.append("path")
            .attr("class", "pt-d3-field")
            .attr("d", position_2b)
            .style("stroke", "black")
            .style("fill", "none")
            .style("stroke-width", 2)
        pos_1b = svg.append("path")
            .attr("class", "pt-d3-field")
            .attr("d", position_1b)
            .style("stroke", "black")
            .style("fill", "none")
            .style("stroke-width", 2)
        // Outline outfield positions
        pos_lf = svg.append("path")
            .attr("class", "pt-d3-field")
            .attr("d", position_lf)
            .style("stroke", "black")
            .style("fill", "none")
            .style("stroke-width", 2)
        pos_cf = svg.append("path")
            .attr("class", "pt-d3-field")
            .attr("d", position_cf)
            .style("stroke", "black")
            .style("fill", "none")
            .style("stroke-width", 2)
        pos_rf = svg.append("path")
            .attr("class", "pt-d3-field")
            .attr("d", position_rf)
            .style("stroke", "black")
            .style("fill", "none")
            .style("stroke-width", 2)

        var shades: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0]
        var total: number = 0
        var ab:any
        for(ab in atBats){
            const fielder = atBats[ab].fielder
            if(fielder != null){
                total += 1
                switch(fielder){
                    case "P":
                        shades[0] += 1
                        break
                    case "C":
                        shades[1] += 1
                        break
                    case "1B":
                        shades[2] += 1
                        break
                    case "2B":
                        shades[3] += 1
                        break
                    case "3B":
                        shades[4] += 1
                        break
                    case "SS":
                        shades[5] += 1
                        break
                    case "LF":
                        shades[6] += 1
                        break
                    case "CF":
                        shades[7] += 1
                        break
                    case "RF":
                        shades[8] += 1
                        break
                }
            }
        }

        let s:any
        for(s in shades){
            shades[s] = Math.round(255 - (shades[s]/total) * 255)
            var text: boolean = true
            var x_trans: number = -200
            var y_trans: number = -200
            var rgb:string = `rgb(${shades[s]}, ${shades[s]}, ${shades[s]})`
            switch(+s+1){
                case 3:
                    x_trans = 55
                    y_trans = 10
                    pos_1b.style("fill", rgb)
                    break
                case 4:
                    x_trans = 22
                    y_trans = 60
                    pos_2b.style("fill", rgb)
                    break
                case 5:
                    x_trans = -55
                    y_trans = 10
                    pos_3b.style("fill", rgb)
                    break
                case 6:
                    x_trans = -22
                    y_trans = 60
                    pos_ss.style("fill", rgb)
                    break
                case 7:
                    x_trans = -115
                    y_trans = 160
                    pos_lf.style("fill", rgb)
                    break
                case 8:
                    x_trans = 0
                    y_trans = 190
                    pos_cf.style("fill", rgb)
                    break
                case 9:
                    x_trans = 115
                    y_trans = 160
                    pos_rf.style("fill", rgb)
                    break
                default:
                    text=false
                    break
            }
            if(text){
                svg.append("text")
                    .attr("class", "density-text")
                    .attr("font-size", "12pt")
                    .attr("fill", "black")
                    .attr("y", yScale(y_trans))
                    .attr("x", xScale(x_trans))
                    .attr("dy", 5)
                    .attr("text-anchor", "middle")
                    .text(Math.round((1 - shades[s]/255)*100)+ "%")
            }
        }
    }, [atBats, width, height])

    return <svg width={width} height={height} ref={svgRef}></svg>
}