import {
	Card,
	CardContent,
	Container,
	Grid,
	Hidden,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import WashUColor from "../../static/img/WashUColor.png"
import HomePageImg from "../../static/img/HomePage.png"
import { GlobalsContext } from "../../context/GlobalsContext"
import { Season } from "../../interfaces/SeasonInterface"
import { FeedbackContext } from "../../context/FeedbackContext"
import axios from "axios"
import { Title } from "./components/Title"
import { LoadingBig } from "../../shared/Loading/LoadingBig"
import { RecentGames } from "./components/RecentGames"

import "../../static/css/HomePage.css"
import { RecentPractices } from "./components/RecentPractices"

export const HomePage = () => {
	const globals = useContext(GlobalsContext)
	const { setFeedback } = useContext(FeedbackContext)
	const [loading, setLoading] = useState<boolean>(true)
	const [currentSeason, setCurrentSeason] = useState<Season>()

	const fetchCurrentSeason = async () => {
		try {
			const response = await axios.get("/api/season/current", globals.apiHeader)
			setCurrentSeason(response.data)
		} catch (e) {
			console.log(e)
			setFeedback({
				show: true,
				severity: "error",
				message: "Something wen't wrong. Please reload the page.",
			})
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			await fetchCurrentSeason()
			setLoading(false)
		}
		fetchData()
	}, [])

	if (loading) {
		return <LoadingBig />
	}

	return (
		<Container className="padding-top-24">
			<Grid container spacing={0}>
				<Grid item xs={6}>
					<Title currentSeason={currentSeason} />
				</Grid>
				<Grid item xs={6} className="align-right">
					<img width={143} height={120} src={WashUColor} alt="WashU Logo" />
				</Grid>
			</Grid>
			<Grid container spacing={3} className="padding-top-24">
				<Grid item xs={12} md={6} lg={4}>
					<RecentGames />
				</Grid>
				<Hidden mdDown>
					<Grid item xs={12} md={6} lg={4} className="align-center">
						<img
							style={{
								borderRadius: "4px",
								height: "253px",
								boxShadow: "0px 1px 2px rgba(0,0,0,0.5)",
							}}
							src={HomePageImg}
							alt="Webster Win"
						/>
					</Grid>
				</Hidden>
				<Grid item xs={12} md={6} lg={4}>
					<RecentPractices />
				</Grid>
			</Grid>
		</Container>
	)
}
