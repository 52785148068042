import { useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	IconButton,
	Menu,
	MenuItem,
	TableCell,
	TableRow,
} from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { EditSeasonModal } from "./EditSeasonModal"
import { LoadingBig } from "../../../shared/Loading/LoadingBig"
import CheckIcon from "@material-ui/icons/Check"

// types and contexts
import { Season } from "../../../interfaces/SeasonInterface"
import { FeedbackContext } from "../../../context/FeedbackContext"
import { GlobalsContext } from "../../../context/GlobalsContext"
import { SeasonsUpdatedContext } from "./SeasonsTab"

interface Props {
	season: Season
}

export const SeasonRow = ({ season }: Props) => {
	const { setFeedback } = useContext(FeedbackContext)
	const { refetchSeasons } = useContext(SeasonsUpdatedContext)
	const globals = useContext(GlobalsContext)

	// whether or not to show the EditSeasonModal
	const [editSeasonModal, setEditSeasonModal] = useState<boolean>(false)

	// holds the num of practices/games for the season
	const [numGames, setNumGames] = useState<number | null>(null)
	const [numPractices, setNumPractices] = useState<number | null>(null)

	// used for the menu to edit or delete the season
	const [
		seasonMenuAnchorEl,
		setSeasonMenuAnchorEl,
	] = useState<null | HTMLElement>(null)

	// fetches the num games and num practices on load
	useEffect(() => {
		const fetchNumGames = async () => {
			try {
				const response = await axios.get(
					`/api/game?season_id=${season.id}`,
					globals.apiHeader
				)
				setNumGames(response.data.length)
			} catch (e) {
				console.log(e)
				setFeedback({
					show: true,
					severity: "error",
					message: "Something wen't wrong",
				})
			}
		}
		const fetchNumPractices = async () => {
			try {
				const response = await axios.get(
					`/api/practice?season_id=${season.id}`,
					globals.apiHeader
				)
				setNumPractices(response.data.length)
			} catch (e) {
				console.log(e)
				setFeedback({
					show: true,
					severity: "error",
					message: "Something wen't wrong",
				})
			}
		}
		fetchNumGames()
		fetchNumPractices()
	}, [])

	// when the "Delete" menu item was selected
	const handleDelete = async () => {
		if (confirm("Are you sure you want to delete this season?")) {
			try {
				await axios.delete(`/api/season/${season.id}`, globals.apiHeader)
				setFeedback({
					show: true,
					severity: "success",
					message: "Season deleted",
				})
				refetchSeasons()
			} catch (e) {
				console.log(e)
				setFeedback({
					show: true,
					severity: "error",
					message: `Something wen't wrong, there may be 
          games, practices, outings, or videos associated with this season`,
				})
			}
		}
	}

	return (
		<>
			<TableRow>
				<TableCell align="left">{season.name}</TableCell>
				<TableCell align="left">{season.year}</TableCell>
				<TableCell align="center">
					{numGames !== null ? numGames : <LoadingBig />}
				</TableCell>
				<TableCell align="center">
					{numPractices !== null ? numPractices : <LoadingBig />}
				</TableCell>
				<TableCell align="center">
					{season.current_season && <CheckIcon />}
				</TableCell>
				<TableCell align="center">
					<IconButton
						onClick={(event) => setSeasonMenuAnchorEl(event.currentTarget)}>
						<MoreVertIcon />
					</IconButton>
				</TableCell>
			</TableRow>
			<Menu
				anchorEl={seasonMenuAnchorEl}
				keepMounted
				open={Boolean(seasonMenuAnchorEl)}
				onClose={() => setSeasonMenuAnchorEl(null)}>
				<MenuItem
					onClick={() => {
						setSeasonMenuAnchorEl(null)
						setEditSeasonModal(true)
					}}>
					Edit
				</MenuItem>
				<MenuItem
					onClick={() => {
						setSeasonMenuAnchorEl(null)
						handleDelete()
					}}>
					Delete
				</MenuItem>
			</Menu>
			<EditSeasonModal
				open={editSeasonModal}
				close={() => setEditSeasonModal(false)}
				season={season}
			/>
		</>
	)
}
