import { Outing } from "../interfaces/OutingInterface"
import { formatDate } from "../shared/Functions/FormatDate"
import { teamId } from "../index"
import { AtBat } from "../interfaces/AtBatInterface"
import { Pitch } from "../interfaces/PitchInterface"
import { OutingStats } from "../interfaces/OutingStats"
import { DetailedPitch } from "../interfaces/DetailedPitch"

export class OutingParser {
	outing: Outing
	outingName: string
	outingStats: OutingStats

	constructor(outing: Outing) {
		this.outing = outing
		this.outingName = OutingParser.formatOutingName(outing)
		this.outingStats = this.calculateOutingStats()
	}

	static formatOutingName(outing: Outing) {
		const date = formatDate(outing.date)
		if (outing.game) {
			if (outing.game.away_team.id === teamId) {
				return `${date} vs. ${outing.game.home_team.name}`
			} else {
				return `${date} vs. ${outing.game.away_team.name}`
			}
		} else if (outing.practice) {
			return `${date} @Practice`
		} else {
			return date
		}
	}

	getDetailedPitches() {
		const pitches: DetailedPitch[] = []

		// iterate through each atbat and gather information
		this.outing.at_bats.forEach((atBat: AtBat) => {
			const outs = atBat.outs
			const inning = atBat.inning
			const hitHard = atBat.hit_hard
			const atBatResult = atBat.result
			const traj = atBat.traj
			if (atBat.pitches) {
				const numPitches = atBat.pitches.length

				// iterate through the pitches and gather information
				atBat.pitches.forEach((pitch: Pitch) => {
					pitches.push({
						...pitch,
						seasonId: this.outing.season_id,
						date: this.outing.date,
						outing: this.outingName,
						wasGame: this.isGame(),
						outs,
						inning,
						hitHard,
						atBatResult,
						traj,
						lastPitch: pitch.ab_pitch_num === numPitches,
					})
				})
			}
		})

		return pitches
	}

	isGame() {
		if (this.outing.game) {
			return true
		} else {
			return false
		}
	}

	calculateOutingStats() {
		let pitches = 0
		let hits = 0
		let strikeouts = 0
		let walks = 0
		let hitByPitches = 0
		let strikes = 0
		let firstPitchStrikes = 0
		let hitSpots = 0

		let velocityTotals: { [key: string]: { [key: string]: number } } = {
			FB: {
				num: 0,
				total: 0,
			},
			"2S": {
				num: 0,
				total: 0,
			},
			CB: {
				num: 0,
				total: 0,
			},
			SL: {
				num: 0,
				total: 0,
			},
			CH: {
				num: 0,
				total: 0,
			},
			CT: {
				num: 0,
				total: 0,
			},
			SPL: {
				num: 0,
				total: 0,
			},
			OTH: {
				num: 0,
				total: 0,
			},
		}

		this.outing.at_bats.forEach((atBat: AtBat) => {
			pitches += atBat.pitches!.length
			if (atBat.result) {
				if (["1B", "2B", "3B", "HR"].includes(atBat.result)) {
					hits += 1
				} else if (
					["K", "ꓘ", "Drop Third (Out)", "Drop Third (Safe)"].includes(
						atBat.result
					)
				) {
					strikeouts += 1
				} else if (atBat.result === "BB") {
					walks += 1
				} else if (atBat.result === "HBP") {
					hitByPitches += 1
				}
			}
			if (atBat.pitches) {
				atBat.pitches.forEach((pitch: Pitch, index: number) => {
					if (pitch.pitch_result !== "B") {
						strikes += 1
					}
					if (pitch.ab_pitch_num === 1 && pitch.pitch_result !== "B") {
						firstPitchStrikes += 1
					}
					if (
						pitch.velocity &&
						["FB", "2S", "CB", "SL", "CH", "CT", "SPL", "OTH"].includes(
							pitch.pitch_type
						)
					) {
						velocityTotals[pitch.pitch_type].total += pitch.velocity
						velocityTotals[pitch.pitch_type].num += 1
					}
					if (pitch.hit_spot) hitSpots += 1
				})
			}
		})

		const numAtBats = this.outing.at_bats.length

		let avgVelocities: { [key: string]: string } = {}

		Object.keys(velocityTotals).forEach((pitchType) => {
			const { num, total } = velocityTotals[pitchType]
			if (num > 0) {
				avgVelocities[pitchType] = (total / num).toFixed(1)
			} else {
				avgVelocities[pitchType] = "0.0"
			}
		})

		let velocity = "N/A"
		const num = velocityTotals["FB"].num + velocityTotals["2S"].num
		if (num > 0) {
			velocity = (
				(velocityTotals["FB"].total + velocityTotals["2S"].total) /
				num
			).toFixed(1)
		}

		return {
			pitches,
			battersFaced: numAtBats,
			hits,
			strikeouts,
			walks,
			hitByPitches,
			strikePct: pitches > 0 ? ((100 * strikes) / pitches).toFixed(0) : "N/A",
			firstPitchStrikePct:
				numAtBats > 0
					? ((100 * firstPitchStrikes) / numAtBats).toFixed(0)
					: "N/A",
			hitSpotPct: pitches > 0 ? ((100 * hitSpots) / pitches).toFixed(0) : "N/A",
			velocity,
			avgVelocities,
		}
	}
}
