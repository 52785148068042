import { atBatResultOptions, State } from "./Constants"
import { dispatchWarning } from "./DispatchFeedback"

// this function handles the event when
// 	"END AT BAT" is clicked
export const EndAtBatHandler = async (
	state: State,
	setState: React.Dispatch<React.SetStateAction<State>>,
	includePitchData?: boolean
) => {
	// you can't end an at bat that hasn't started yet
	if (!state.selectedBatter) {
		return dispatchWarning(
			{ ...state, showIncludeLastPitchModal: false },
			setState,
			"Please select a batter"
		)

		// this runs to check if the user wants to end the at bat
		// with or without pitch data (i.e. an IBB may just end the at bat without a pitch)
	} else if (includePitchData === undefined) {
		setState({
			...state,
			showIncludeLastPitchModal: true,
		})

		// if the user wants to end the at bat early and include some pitch data (like a HBP)
	} else if (includePitchData) {
		// make sure all the pitch data is there
		if (
			!state.pitchEntry.xLoc ||
			!state.pitchEntry.yLoc ||
			!state.pitchEntry.pitchType ||
			!state.pitchEntry.pitchResult
		) {
			return dispatchWarning(
				{ ...state, showIncludeLastPitchModal: false },
				setState,
				"Make sure to select a location, pitch type, and pitch result for the pitch"
			)
		}

		// let the user select the result of the at bat
		setState({
			...state,
			endAtBatWithoutPitch: false,
			showAtBatResultModal: true,
			showIncludeLastPitchModal: false,
			currentAtBatResultOptions: atBatResultOptions.endAtBatEarlyWithPitch,
		})

		// this happens when the user wants to end the at bat WITHOUT a pitch (like IBB or pickoff)
	} else {
		setState({
			...state,
			endAtBatWithoutPitch: true,
			showAtBatResultModal: true,
			showIncludeLastPitchModal: false,
			currentAtBatResultOptions: atBatResultOptions.endAtBatEarlyWithoutPitch,
		})
	}
}
