import { useContext, useEffect, useState } from "react"

import axios from "axios"

// components
import {
	Button,
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"

// types and contexts
import { FeedbackContext } from "../../../context/FeedbackContext"
import { GlobalsContext } from "../../../context/GlobalsContext"
import { ResourceCategory } from "../../../interfaces/ResourceCategory"
import { CategoriesUpdatedContext } from "./ResourcesTab"
import { ButtonApiRequest } from "../../../shared/ButtonApiRequest"

interface Props {
	open: boolean
	close: () => void
	categories: ResourceCategory[]
}

export const EditOrderModal = ({ open, close, categories }: Props) => {
	const { setFeedback } = useContext(FeedbackContext)
	const globals = useContext(GlobalsContext)

	// once the drag order is done, refetch all categories
	const { refetchCategories } = useContext(CategoriesUpdatedContext)

	// holds the dragged and dropped order
	const [order, setOrder] = useState<ResourceCategory[]>(categories)

	// while adjusting the order, lets the user know that it is loading
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		setOrder(categories)
	}, [categories])

	// once the "save" button is pressed
	const saveOrder = async () => {
		let index = 0
		setLoading(true)
		for (const category of order) {
			// if it's not in the right order, send request to api
			if (category.order_num !== index + 1) {
				try {
					await axios.patch(
						`/api/resource_category/${category.id}`,
						{ order_num: index + 1 },
						globals.apiHeader
					)
				} catch (e) {
					setFeedback({
						show: true,
						severity: "error",
						message: "Something wen't wrong",
					})
					console.log(e)
				}
			}
			index += 1
		}
		close()
		refetchCategories()
		setLoading(false)
	}

	// adjusts the state after someone "drops"
	const onDragEnd = ({ ...props }) => {
		const { source, destination } = props

		if (source.index === destination.index) {
			return
		}

		const category = order[source.index]

		let newOrder = [...order]

		newOrder.splice(source.index, 1)
		newOrder.splice(destination.index, 0, category)

		setOrder(newOrder)
	}

	return (
		<Dialog fullWidth maxWidth="sm" open={open} onClose={close}>
			<DialogTitle>Edit Order (Drag to reorder below)</DialogTitle>
			<DialogContent style={{ backgroundColor: "#F6F6F6" }}>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId={`droppable`}>
						{(provided) => (
							<div ref={provided.innerRef} {...provided.droppableProps}>
								{order.map((category, index) => (
									<div key={category.id}>
										<Draggable index={index} draggableId={`${category.id}`}>
											{(provided) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}>
													<br />
													<Card {...provided.dragHandleProps}>
														<CardContent>{category.name}</CardContent>
													</Card>
												</div>
											)}
										</Draggable>
									</div>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="primary">
					Cancel
				</Button>
				<ButtonApiRequest
					color="primary"
					text="Save"
					loading={loading}
					onClick={saveOrder}
				/>
			</DialogActions>
		</Dialog>
	)
}
