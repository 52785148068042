import React, { useContext, useEffect, useState } from "react"

import { Grid, Paper } from "@material-ui/core"

import { Context } from "../../TrackTab"

import "../../../../../../static/css/TrackTab/OutingStats.css"
import { AtBat } from "../../../../../../interfaces/AtBatInterface"

export const OutingStats = () => {
	const { state } = useContext(Context)

	// keep track of different outing statistics
	const [strikeouts, setStrikeouts] = useState<number>(0)
	const [walks, setWalks] = useState<number>(0)
	const [hits, setHits] = useState<number>(0)
	const [hitByPitches, setHitByPitches] = useState<number>(0)
	const [pitches, setPitches] = useState<number>(0)
	const [pitchesInning, setPitchesInning] = useState<number>(0)

	// run any time the outing gets updated (like a pitch being added)
	useEffect(() => {
		let strikeouts = 0
		let walks = 0
		let hits = 0
		let hitByPitches = 0
		let pitches = 0
		let pitchesInning = 0

		// iterate through the at bats and calculate the outing stats so far
		state.outing!.at_bats.forEach((atBat: AtBat) => {
			pitches += atBat.pitches!.length

			if (
				atBat.result === "K" ||
				atBat.result === "ꓘ" ||
				atBat.result === "Drop Third (Out)" ||
				atBat.result === "Drop Third (Safe)"
			) {
				strikeouts += 1
			} else if (atBat.result === "BB") {
				walks += 1
			} else if (
				atBat.result === "1B" ||
				atBat.result === "2B" ||
				atBat.result === "3B" ||
				atBat.result === "HR"
			) {
				hits += 1
			} else if (atBat.result === "HBP") {
				hitByPitches += 1
			}

			if (atBat.inning === state.outingState.inning) {
				pitchesInning += atBat.pitches!.length
			}
		})

		setStrikeouts(strikeouts)
		setWalks(walks)
		setHits(hits)
		setHitByPitches(hitByPitches)
		setPitches(pitches)
		setPitchesInning(pitchesInning)
	}, [state.outing, state.outingState.inning])

	const outingStats = [
		{ value: strikeouts, label: "K" },
		{ value: walks, label: "BB" },
		{ value: hits, label: "H" },
		{ value: hitByPitches, label: "HBP" },
		{ value: `${pitches}(${pitchesInning})`, label: "P" },
	]

	return (
		<Paper elevation={0} className="pt-outing-stats">
			<Grid container spacing={0} className="align-center">
				{outingStats.map((stat, index) => (
					<Grid
						key={index}
						item
						xs={stat.label === "P" ? 4 : 2}
						className="pt-outing-stat">
						<span className="pt-outing-stat-value">{stat.value}</span>
						<span className="pt-outing-stat-name">{stat.label}</span>
					</Grid>
				))}
			</Grid>
		</Paper>
	)
}
