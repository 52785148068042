import moment from "moment"

/**
 *
 * @param date a string formatted at YYYY-MM-DD
 * @returns a nicely formatted string for display on the website (M/D/YY)
 */
export const formatDate = (date: string) => {
	return moment(date).format("M/D/YY")
}
