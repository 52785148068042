import { Season } from "../../../interfaces/SeasonInterface"

interface Props {
	currentSeason?: Season
}

export const Title = ({ currentSeason }: Props) => {
	return (
		<>
			<div style={{ fontSize: "40px", fontWeight: 700 }}>WashU Baseball</div>
			<div>
				{currentSeason && currentSeason.name}{" "}
				{currentSeason && currentSeason.year}
			</div>
		</>
	)
}
