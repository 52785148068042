import React, { useContext } from "react"

import { Snackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import { TransitionDown } from "../../../../../../shared/ModalTransitions/TransitionDown"

import { initialState } from "../../utils/Constants"
import { Context } from "../../TrackTab"

/**
 * This is a drop down for feedback to the user
 */
export const Feedback = () => {
	const { state, setState } = useContext(Context)

	const handleClose = () => {
		setState({ ...state, feedback: { ...initialState.feedback } })
	}

	return (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={state.feedback.show}
			TransitionComponent={TransitionDown}
			autoHideDuration={4000}
			onClose={handleClose}>
			<MuiAlert
				elevation={6}
				onClose={handleClose}
				severity={
					state.feedback.severity as
						| "success"
						| "info"
						| "warning"
						| "error"
						| undefined
				}>
				{state.feedback.text}
			</MuiAlert>
		</Snackbar>
	)
}
