import { useEffect, useRef, useContext } from "react"

import * as d3 from "d3"

import { Context } from "../../TrackTab"

import BaseballIcon from "../../../../../../static/img/BaseballIcon.png"

/**
 * This is used as a clickable field to select the fielder and select spray location
 */
export const FieldD3 = () => {
	const { state, setState } = useContext(Context)
	const svgRef = useRef(null)

	const xScale = d3.scaleLinear().range([400, 0]).domain([250, -250])
	const yScale = d3.scaleLinear().range([400, 0]).domain([-100, 400])

	const positions = [
		{ x: -120, y: 220, text: "LF" },
		{ x: 120, y: 220, text: "RF" },
		{ x: -60, y: 100, text: "3B" },
		{ x: 60, y: 100, text: "1B" },
		{ x: -30, y: 150, text: "SS" },
		{ x: 30, y: 150, text: "2B" },
		{ x: 0, y: 280, text: "CF" },
		{ x: 0, y: 60, text: "P" },
		{ x: 0, y: 0, text: "C" },
	]

	// runs the first time and just draws the field
	useEffect(() => {
		const svg = d3.select(svgRef.current)
		svg.selectAll("path").remove()

		// some colors for the makeshift field
		const dirtColor = "#99552B"
		const grassColor = "#508632"

		const fieldOutlinePath = `
      M ${xScale(0)},${yScale(-45)}
        L ${xScale(50)},${yScale(-25)}
        L ${xScale(242)},${yScale(222)}
        L ${xScale(145)},${yScale(334)}
        L ${xScale(68)},${yScale(375)}
        L ${xScale(0)},${yScale(395)}
        L ${xScale(-68)},${yScale(375)}
        L ${xScale(-145)},${yScale(334)}
        L ${xScale(-242)},${yScale(222)}
        L ${xScale(-50)},${yScale(-25)}
        L ${xScale(0)},${yScale(-45)} Z`
		const fieldInnerOutlinePath = `
      M ${xScale(0)},${yScale(-40)}
        L ${xScale(50)},${yScale(-20)}
        L ${xScale(237)},${yScale(222)}
        L ${xScale(145)},${yScale(329)}
        L ${xScale(68)},${yScale(370)}
        L ${xScale(0)},${yScale(390)}
        L ${xScale(-68)},${yScale(370)}
        L ${xScale(-145)},${yScale(329)}
        L ${xScale(-237)},${yScale(222)}
        L ${xScale(-50)},${yScale(-20)}
        L ${xScale(0)},${yScale(-40)} Z`
		const infieldDirt = `
      M ${xScale(85)},${yScale(80)}
        L ${xScale(0)},${yScale(170)}
        L ${xScale(-85)},${yScale(80)}
        L ${xScale(0)},${yScale(-5)}
        L ${xScale(85)},${yScale(80)}
        C ${xScale(85)},${yScale(200)}
        ${xScale(-85)},${yScale(200)}
        ${xScale(-85)},${yScale(80)}
        L ${xScale(0)},${yScale(5)} Z`
		const infieldGrass = `
      M ${xScale(0)},${yScale(5)}
        L ${xScale(60)},${yScale(60)}
        L ${xScale(0)},${yScale(120)}
        L ${xScale(-60)},${yScale(60)} Z`
		const foulLines = `
      M ${xScale(-239)},${yScale(239)}
        L ${xScale(0)},${yScale(0)}
        L ${xScale(239)},${yScale(239)}
        L ${xScale(0)},${yScale(0)} Z`

		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", fieldOutlinePath)
			.style("stroke", "black")
			.style("fill", dirtColor)
			.style("stroke-width", 1)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", fieldInnerOutlinePath)
			.style("fill", grassColor)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", infieldDirt)
			.style("fill", dirtColor)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", infieldGrass)
			.style("fill", grassColor)
		svg
			.append("path")
			.attr("class", "pt-d3-field")
			.attr("d", foulLines)
			.style("stroke", "white")
			.style("stroke-width", 2)
	}, [])

	// for rendering the spray location
	useEffect(() => {
		const svg = d3.select(svgRef.current)

		// if there is a spray location, draw that spray location
		if (state.atBatEntry.xSpray && state.atBatEntry.ySpray) {
			svg.select(".pt-field-spray-loc").remove()
			svg
				.append("image")
				.attr("class", "pt-field-spray-loc")
				.attr("x", xScale(state.atBatEntry.xSpray!) - 10)
				.attr("y", yScale(state.atBatEntry.ySpray!) - 10)
				.attr("width", "20")
				.attr("height", "20")
				.attr("xlink:href", BaseballIcon)
		}

		// add the spray location wherever the user clicks
		d3.selectAll(".pt-d3-field").on("click", (event) => {
			const [x, y] = d3.pointer(event)
			const xSpray = Math.round(xScale.invert(x) * 100) / 100
			const ySpray = Math.round(yScale.invert(y) * 100) / 100
			setState({
				...state,
				atBatEntry: { ...state.atBatEntry, xSpray, ySpray },
			})
		})
	}, [state.atBatEntry.xSpray, state.atBatEntry.ySpray])

	// for rendering the fielders
	useEffect(() => {
		const svg = d3.select(svgRef.current)

		// remove the positions and rerender them with the selected fielder
		svg.selectAll(".pt-field-position-circle").remove()
		svg.selectAll(".pt-field-position-text").remove()
		positions.forEach((pos) => {
			svg
				.append("circle")
				.attr("class", () => {
					if (state.atBatEntry.fielder === pos.text) {
						return "pt-field-position-circle fielder-selected"
					}
					return "pt-field-position-circle"
				})
				.attr("value", pos.text)
				.attr("cx", xScale(pos.x))
				.attr("cy", yScale(pos.y))
				.attr("r", "15")
				.attr("fill", "var(--white)")
				.attr("stroke", "gray")
				.attr("stroke-width", 1)
				.on("click", function () {
					const fielder = this.getAttribute("value")
					setState({
						...state,
						atBatEntry: { ...state.atBatEntry, fielder },
					})

					// changing the styling of the selected element
					d3.selectAll(".pt-field-position-circle").attr(
						"class",
						"pt-field-position-circle"
					)
					d3.selectAll(".pt-field-position-text").attr(
						"class",
						"pt-field-position-text"
					)
					this.setAttribute(
						"class",
						" pt-field-position-circle fielder-selected"
					)
					d3.select(`#pt-field-position-${fielder}`).attr(
						"class",
						"pt-field-position-text fielder-selected"
					)
				})

			// append the text element for that position
			svg
				.append("text")
				.attr("class", () => {
					if (state.atBatEntry.fielder === pos.text) {
						return "pt-field-position-text fielder-selected"
					}
					return "pt-field-position-text"
				})
				.attr("id", `pt-field-position-${pos.text}`)
				.attr("x", xScale(pos.x))
				.attr("y", yScale(pos.y - 7))
				.text(pos.text)
				.attr("text-anchor", "middle")
				.attr("pointer-events", "none")
		})
	}, [state.atBatEntry.xSpray, state.atBatEntry.ySpray])

	return (
		<svg
			className="pt-field-svg"
			width={400}
			height={400}
			ref={svgRef}
			onClick={(event) => {
				const [x, y] = d3.pointer(event)
				const xSpray = Math.round(xScale.invert(x) * 100) / 100
				const ySpray = Math.round(yScale.invert(y) * 100) / 100
				setState({
					...state,
					atBatEntry: { ...state.atBatEntry, xSpray, ySpray },
				})
			}}></svg>
	)
}
