import React from "react"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"

interface Props {
	value: "home" | "away"
	onChange: (value: "home" | "away") => void
}

export const HomeAwayToggle = ({ onChange, value }: Props) => {
	const handleChange = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		value: any
	) => {
		onChange(value)
	}

	return (
		<ToggleButtonGroup value={value} exclusive onChange={handleChange}>
			<ToggleButton value="home" className="toggle-button">
				HOME
			</ToggleButton>
			<ToggleButton value="away" className="toggle-button">
				AWAY
			</ToggleButton>
		</ToggleButtonGroup>
	)
}
