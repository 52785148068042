import React, { useContext } from "react"

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"

import { Context } from "../../TrackTab"

import "../../../../../../static/css/TrackTab/PitchDataEntry.css"

export const PitchType = () => {
	const { state, setState } = useContext(Context)

	const pitchTypes = ["FB", "2S", "CB", "SL", "CH", "CT", "SPL", "OTH"]

	const handlePitchTypeChange = (
		event: React.MouseEvent<HTMLElement>,
		pitchType: string | null
	) => {
		setState({
			...state,
			pitchEntry: { ...state.pitchEntry, pitchType },
		})
	}

	return (
		<div className="pt-pitch-type-div">
			<ToggleButtonGroup
				className="pt-pitch-type-btn-group"
				value={state.pitchEntry.pitchType}
				exclusive
				onChange={handlePitchTypeChange}>
				{pitchTypes.map((pitchType) => (
					<ToggleButton
						key={pitchType}
						className="pt-toggle-button pt-pitch-type-toggle"
						value={pitchType}>
						{pitchType}
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</div>
	)
}
