import {
	CircularProgress,
	Divider,
	IconButton,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	Tooltip,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { Fragment, useState } from "react"
import { useAlert } from "../../../../hooks"
import { formatDate } from "../../../../shared/Functions/FormatDate"
import { SimplifiedVideo, useVideosTabContext } from "../VideoTabState"

interface VideoListItemProps {
	video: SimplifiedVideo
}

export const VideoListItem = ({ video }: VideoListItemProps) => {
	const { alertWarning } = useAlert()
	const { handleEdit, handleDelete } = useVideosTabContext()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const preventDefault = (event: any) => {
		event.preventDefault()
		event.stopPropagation()
	}

	const handleMoreIconClick = (event: React.MouseEvent<HTMLElement>) => {
		preventDefault(event)
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (event: any) => {
		preventDefault(event)
		setAnchorEl(null)
	}

	const handleClick = () => {
		if (video.processing) {
			alertWarning("Video is still processing")
		} else {
			window.open(video.link, "_blank")
		}
	}

	return (
		<Fragment key={video.id}>
			<ListItem button onClick={handleClick}>
				<ListItemText
					primary={
						<span style={{ fontSize: "13px" }}>
							{video.processing && (
								<>
									<CircularProgress style={{ width: "15px", height: "15px" }} />{" "}
								</>
							)}
							{`${video.playerName} (${video.title})`}
						</span>
					}
					secondary={
						<span style={{ fontSize: "11px" }}>
							{`${formatDate(video.date)} | ${video.season} | ${
								video.category
							}`}
						</span>
					}
				/>
				<ListItemSecondaryAction>
					<Tooltip title="More">
						<IconButton onClick={handleMoreIconClick}>
							<MoreVertIcon />
						</IconButton>
					</Tooltip>
				</ListItemSecondaryAction>
			</ListItem>
			<Divider />
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem
					onClick={(event) => {
						preventDefault(event)
						handleEdit(video.id)
					}}>
					<EditIcon /> &nbsp; Edit
				</MenuItem>
				<MenuItem
					onClick={(event) => {
						preventDefault(event)
						handleDelete(video.id)
					}}>
					<DeleteIcon /> &nbsp; Delete
				</MenuItem>
			</Menu>
		</Fragment>
	)
}
