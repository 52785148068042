import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { FeedbackContext } from "../context/FeedbackContext"
import { GlobalsContext } from "../context/GlobalsContext"
import { Player } from "../interfaces/PlayerInterface"

interface PlayerSelectorProps {
	handleChange: (selected: number) => unknown
	variant: "outlined" | "standard"
	initialValue?: number
	margin?: "dense" | "none" | "normal"
	required?: boolean
}

export const PlayerSelector = ({
	handleChange,
	variant,
	initialValue,
	margin,
	required,
}: PlayerSelectorProps) => {
	const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null)
	const [error, setError] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(true)
	const [players, setPlayers] = useState<Player[]>([])
	const { alertError } = useContext(FeedbackContext)
	const { teamId, apiHeader } = useContext(GlobalsContext)

	useEffect(() => {
		const fetchPlayers = async () => {
			try {
				const response = await axios.get(
					`/api/team/${teamId}/players`,
					apiHeader
				)
				setPlayers(response.data)
				if (initialValue) {
					setSelectedPlayer(
						response.data.find((player: Player) => player.id === initialValue)
					)
				}
			} catch (e) {
				alertError("Failed to fetch players")
				setError(true)
				console.log(e)
			}
			setLoading(false)
		}
		fetchPlayers()
	}, [])

	if (loading) {
		return (
			<TextField
				fullWidth
				disabled
				variant={variant}
				margin={margin}
				required
				label="Player"
			/>
		)
	}

	return (
		<Autocomplete
			value={selectedPlayer}
			disabled={loading || error}
			options={players.sort((a, b) => (b.active as any) - (a.active as any))}
			groupBy={(option) => (option.active ? "Active" : "Inactive")}
			getOptionLabel={(option) => `${option.lastname}, ${option.firstname}`}
			onChange={(event: React.ChangeEvent<{}>, value: Player | null) => {
				handleChange(value ? value.id : 0)
				setSelectedPlayer(value ?? null)
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					variant={variant}
					margin={margin}
					required={required}
					label="Player"
					disabled={loading || error}
				/>
			)}
		/>
	)
}
