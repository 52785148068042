import DateFnsUtils from "@date-io/date-fns"
import {
	AppBar,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CloseIcon from "@material-ui/icons/Close"
import ErrorIcon from "@material-ui/icons/Error"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import axios from "axios"
import "date-fns"
import { extname } from "path"
import { Fragment, useEffect, useState } from "react"
import { useAlert, useGlobals } from "../../../hooks"
import { Outing } from "../../../interfaces/OutingInterface"
import { formatOutingName } from "../../../shared/Functions/FormatOutingName"
import { isYouTubeUrl } from "../../../shared/Functions/IsYoutubeUrl"
import { TransitionUp } from "../../../shared/ModalTransitions/TransitionUp"
import { PlayerSelector } from "../../../shared/PlayerSelector"
import { SeasonSelector } from "../../../shared/SeasonSelector"
import { useVideosTabContext } from "./VideoTabState"

export type VideoType = "Upload" | "YouTube"

export type VideoContentType = "Pitching" | "Hitting"

type VideoFormState = {
	videoType: VideoType
	file?: File
	youtubeLink: string
	playerId?: number
	title: string
	date: Date
	seasonId?: number
	outingId: number | ""
	videoContent: VideoContentType
}

export const NewVideoModal = () => {
	// some state management for the videos tab
	const { newVideoModalOpen, closeNewVideoModal, fetchVideos } =
		useVideosTabContext()

	// global context
	const { currentSeason, apiHeader } = useGlobals()
	const { alertError } = useAlert()

	// used for styling on all screen sizes
	const theme = useTheme()
	const sm = useMediaQuery(theme.breakpoints.up("sm"))
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

	// handling the state of the form
	const initialVideoFormState: VideoFormState = {
		videoType: "Upload",
		youtubeLink: "",
		title: "",
		date: new Date(),
		videoContent: "Pitching",
		outingId: "",
		playerId: undefined,
		file: undefined,
		seasonId: currentSeason ?? undefined,
	}
	const [videoFormState, setVideoFormState] = useState<VideoFormState>(
		initialVideoFormState
	)

	// handling generating a list of outings for a player and season
	const [outings, setOutings] = useState<Outing[]>([])
	const [fetchingOutings, setFetchingOutings] = useState<boolean>(true)

	// the status of the upload
	const [videoUploadProgress, setVideoUploadProgress] = useState<number>()
	const [uploadStatus, setUploadStatus] = useState<
		"success" | "error" | "uploading" | "uploading-youtube"
	>()

	const handleReset = () => {
		setVideoFormState(initialVideoFormState)
		setUploadStatus(undefined)
		setVideoUploadProgress(undefined)
	}

	const handleClose = () => {
		handleReset()
		closeNewVideoModal()
	}

	const handleSubmit = async () => {
		const { file, title, date, playerId, seasonId, youtubeLink } =
			videoFormState
		if ((file || youtubeLink) && title && date && seasonId && playerId) {
			try {
				if (file) {
					setUploadStatus("uploading")
					const response = await uploadVideoInfo(extname(file.name), file.type)
					const video = response.data.video
					const signedUrlInfo = response.data.signed_url_info
					await uploadToBucket(file, signedUrlInfo.url, signedUrlInfo.fields)
					await convertToMp4(video.id, extname(file.name))
					setUploadStatus("success")
				} else {
					setUploadStatus("uploading-youtube")
					await uploadVideoInfo()
					setUploadStatus("success")
				}
				fetchVideos()
			} catch (e) {
				setUploadStatus("error")
				console.error(e)
			}
		}
	}

	const convertToMp4 = (id: number, fileExtension: string) => {
		return axios.get(
			`/api/video/convert-to-mp4?id=${id}&file_ext=${fileExtension}`,
			apiHeader
		)
	}

	const uploadToBucket = async (
		file: File,
		url: string,
		fields: { [key: string]: any }
	) => {
		const fileUploadData = new FormData()
		for (let key in fields) {
			fileUploadData.append(key, fields[key])
		}
		fileUploadData.append("file", file)
		await axios.post(url, fileUploadData, {
			onUploadProgress: (event) => {
				if (event.lengthComputable && event.loaded && event.total) {
					setVideoUploadProgress((100 * event.loaded) / event.total)
				}
			},
			headers: {
				ContentType: file.type,
			},
		})
	}

	const uploadVideoInfo = (fileExtension?: string, fileType?: string) => {
		return axios.post(
			`/api/video`,
			{
				title: videoFormState.title,
				date: videoFormState.date,
				category: videoFormState.videoContent,
				upload_type: videoFormState.videoType,
				player_id: videoFormState.playerId,
				season_id: videoFormState.seasonId,
				outing_id:
					videoFormState.outingId === "" ? null : videoFormState.outingId,
				file_ext: fileExtension,
				file_type: fileType,
				link:
					videoFormState.youtubeLink === ""
						? undefined
						: videoFormState.youtubeLink,
			},
			apiHeader
		)
	}

	// listen for changes to playerId, seasonId, and videoContent to refetch a list of outings for the player
	useEffect(() => {
		const { videoContent, playerId, seasonId } = videoFormState
		if (videoContent == "Pitching" && playerId && seasonId) {
			const fetchOutings = async () => {
				setFetchingOutings(true)
				try {
					const response = await axios.get(
						`/api/outing?pitcher_id=${playerId}&season_id=${seasonId}&bare=true&order_by=date`,
						apiHeader
					)
					setOutings(response.data)
				} catch (e) {
					setOutings([])
					alertError("Something wen't wrong fetching the players outing")
					console.error(e)
				}
				setFetchingOutings(false)
			}
			fetchOutings()
		} else {
			setOutings([])
		}
	}, [
		videoFormState.playerId,
		videoFormState.seasonId,
		videoFormState.videoContent,
	])

	const { videoType, file, youtubeLink, playerId, title, date, seasonId } =
		videoFormState
	const disableSubmit = Boolean(
		(videoType === "Upload" && !file) ||
			(videoType === "YouTube" && !isYouTubeUrl(youtubeLink)) ||
			!playerId ||
			!title ||
			!date ||
			!seasonId
	)

	const loading = ["uploading", "uploading-youtube"].includes(
		uploadStatus ?? ""
	)

	return (
		<Dialog
			disableBackdropClick={loading}
			fullScreen={fullScreen}
			scroll="body"
			fullWidth
			maxWidth="sm"
			open={newVideoModalOpen}
			onClose={handleClose}
			TransitionComponent={TransitionUp}>
			{/* only show title if on big screens and video isn't uploading */}
			{!uploadStatus && !fullScreen && <DialogTitle>Upload Video</DialogTitle>}
			<DialogContent
				style={{
					marginBottom: "32px",
					marginTop: fullScreen ? "56px" : "0px",
				}}>
				<Grid container spacing={2} alignItems="center">
					{/* for small screens and a full screen dialog, show a toolbar at the top */}
					{fullScreen && (
						<Grid item xs={12}>
							<AppBar>
								<Toolbar style={{ alignItems: "center" }}>
									<IconButton
										edge="start"
										color="inherit"
										disabled={loading}
										onClick={handleClose}>
										<CloseIcon />
									</IconButton>
									<Typography variant="h6">New Video</Typography>
									<div style={{ flexGrow: 1, textAlign: "right" }}>
										<Button
											disabled={disableSubmit || loading}
											onClick={uploadStatus ? handleReset : handleSubmit}
											color="inherit"
											variant="text">
											{loading && ""}
											{uploadStatus === "success" && "Upload Another"}
											{uploadStatus === "error" && "Try Again"}
											{!uploadStatus && "Submit"}
										</Button>
									</div>
								</Toolbar>
							</AppBar>
						</Grid>
					)}
					{/* if the video is uploading to s3 bucket */}
					{uploadStatus === "uploading" && (
						<Grid item xs={12} className="align-center">
							<h2>Uploading...</h2>
							<Box position="relative" display="inline-flex">
								<CircularProgress
									variant="determinate"
									value={videoUploadProgress}
									style={{ width: "150px", height: "150px" }}
								/>
								<Box
									top={0}
									left={0}
									bottom={0}
									right={0}
									position="absolute"
									display="flex"
									alignItems="center"
									justifyContent="center">
									<Typography
										style={{ fontSize: "40px" }}
										variant="caption"
										component="div"
										color="textSecondary">{`${Math.round(
										videoUploadProgress ?? 0
									)}%`}</Typography>
								</Box>
							</Box>
						</Grid>
					)}
					{/* if a youtube link is being uploaded */}
					{uploadStatus === "uploading-youtube" && (
						<Grid item xs={12} className="align-center">
							<CircularProgress
								variant="indeterminate"
								style={{ width: "150px", height: "150px" }}
							/>
						</Grid>
					)}
					{/* if the video was uploaded, show a success message */}
					{uploadStatus === "success" && (
						<Grid item xs={12} className="align-center">
							<h2>Upload complete!</h2>
							<CheckCircleIcon
								color="inherit"
								style={{ color: "green", fontSize: "150px" }}
							/>
						</Grid>
					)}
					{/* if the video upload failed, show a error message */}
					{uploadStatus === "error" && (
						<Grid item xs={12} className="align-center">
							<h2>Failed to upload video</h2>
							<ErrorIcon
								color="inherit"
								style={{ color: "red", fontSize: "150px" }}
							/>
						</Grid>
					)}
					{/* nothing is happening, so the user can enter information about the video to upload */}
					{!uploadStatus && (
						<Fragment>
							<Grid item xs={12} sm={6}>
								<div style={{ fontSize: "16px", paddingBottom: "4px" }}>
									Video Type
								</div>
								<ToggleButtonGroup
									value={videoFormState.videoType}
									exclusive
									onChange={(event, value) => {
										if (value) {
											setVideoFormState({
												...videoFormState,
												videoType: value as VideoType,
											})
										}
									}}>
									<ToggleButton value="Upload" className="toggle-button">
										Upload
									</ToggleButton>
									<ToggleButton value="YouTube" className="toggle-button">
										YouTube
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								className={sm ? "align-right" : "align-left"}>
								<div style={{ fontSize: "16px", paddingBottom: "4px" }}>
									Video Content
								</div>
								<ToggleButtonGroup
									value={videoFormState.videoContent}
									exclusive
									onChange={(event, value) => {
										if (value) {
											setVideoFormState({
												...videoFormState,
												videoContent: value as VideoContentType,
												outingId: "",
											})
										}
									}}>
									<ToggleButton value="Pitching" className="toggle-button">
										Pitching
									</ToggleButton>
									<ToggleButton value="Hitting" className="toggle-button">
										Hitting
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
							<Grid item xs={12} style={{ marginTop: "8px" }}>
								<PlayerSelector
									required
									variant="standard"
									initialValue={videoFormState.playerId}
									handleChange={(playerId: number) => {
										setVideoFormState({
											...videoFormState,
											playerId,
											outingId: "",
										})
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									required
									value={videoFormState.title}
									onChange={(
										event: React.ChangeEvent<
											HTMLTextAreaElement | HTMLInputElement
										>
									) =>
										setVideoFormState({
											...videoFormState,
											title: event.target.value as string,
										})
									}
									name="title"
									label="Title"
									type="text"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										required
										style={{ width: "100%" }}
										disableToolbar
										inputVariant="standard"
										format="MM/dd/yyyy"
										margin="normal"
										label="Date"
										autoOk={true}
										value={videoFormState.date}
										onChange={(date) =>
											setVideoFormState({
												...videoFormState,
												date: date as Date,
											})
										}
										KeyboardButtonProps={{
											"aria-label": "change date",
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>
							<Grid item xs={12}>
								<SeasonSelector
									variant="standard"
									fullWidth
									onChange={(seasonId) =>
										setVideoFormState({ ...videoFormState, seasonId })
									}
								/>
							</Grid>
							{/* if user is uploading a pitching video, allow them to connect it to an outing */}
							{videoFormState.videoContent === "Pitching" &&
								videoFormState.playerId && (
									<Grid item xs={12}>
										<FormControl fullWidth>
											<InputLabel>Outing</InputLabel>
											<Select
												disabled={fetchingOutings}
												label="Outing"
												value={videoFormState.outingId}
												onChange={(event) =>
													setVideoFormState({
														...videoFormState,
														outingId: event.target.value as number,
													})
												}>
												<MenuItem value="">None</MenuItem>
												{outings.map((outing) => (
													<MenuItem key={outing.id} value={outing.id}>
														{formatOutingName(outing)}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
								)}
							{/* show an upload file component */}
							<Grid item xs={12}>
								{videoFormState.videoType === "Upload" && (
									<Box style={{ overflowX: "clip", marginTop: "8px" }}>
										<label htmlFor="upload-video">
											<input
												accept="video/*"
												style={{ display: "none" }}
												id="upload-video"
												name="upload-video"
												type="file"
												onChange={(event) =>
													setVideoFormState({
														...videoFormState,
														file: event.target.files![0],
													})
												}
											/>
											<Button
												fullWidth
												disableElevation
												color="primary"
												component="span"
												variant="outlined">
												{videoFormState.file
													? videoFormState.file.name
													: "Select Video"}
											</Button>
										</label>
									</Box>
								)}
								{/* show a text field to enter in a youtube link */}
								{videoFormState.videoType === "YouTube" && (
									<TextField
										value={videoFormState.youtubeLink}
										onChange={(event) =>
											setVideoFormState({
												...videoFormState,
												youtubeLink: event.target.value as string,
											})
										}
										name="link"
										label="Link"
										type="text"
										fullWidth
									/>
								)}
							</Grid>
						</Fragment>
					)}
				</Grid>
			</DialogContent>
			{!loading && uploadStatus && !fullScreen && (
				<DialogActions>
					<Button onClick={handleReset} color="primary">
						{uploadStatus === "success" ? "Upload Another" : "Try Again"}
					</Button>
					<Button onClick={handleClose} color="primary" variant="contained">
						Close
					</Button>
				</DialogActions>
			)}
			{!uploadStatus && !fullScreen && (
				<DialogActions>
					<Button onClick={handleClose} color="primary" disabled={loading}>
						Cancel
					</Button>
					<Button
						disabled={disableSubmit}
						onClick={handleSubmit}
						color="primary"
						variant="contained">
						Submit
					</Button>
				</DialogActions>
			)}
		</Dialog>
	)
}
